import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Box,
  Button,
  Breadcrumbs,
  Container,
  LinearProgress,
  Link,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";

import Moment from "react-moment";
import "moment-timezone";

import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableCell: { padding: theme.spacing(1), minHeight: "84px" },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  preview: {
    maxHeight: "60px",
    maxWidth: "150px",
  },
  popover: {
    padding: theme.spacing(2),
  },
  paddingBottom1: {
    paddingBottom: theme.spacing(1),
  },
}));

export default function PagesList() {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [allowLoadMore, setAllowLoadMore] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [allRows, setAllRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const fetchRows = async () => {
    setAllowLoadMore(false);
    setLoading(true);
    const queryStringParameters = {
      pageSize: 10,
      inclAuthor: true,
      inclDrafts: true,
      postType: "Page",
    };
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }
    const apiGetData = await API.get("sfwApi", "/post", {
      queryStringParameters: queryStringParameters,
    });
    if (apiGetData) {
      if (apiGetData.Items) {
        var allItems = [...allRows, ...apiGetData.Items];
        setAllRows(allItems);

        // refilter??
        setFilteredRows(allItems);
      }
      if (apiGetData.LastEvaluatedKey) {
        setLastEvaluatedKey(apiGetData.LastEvaluatedKey);
        setAllowLoadMore(true);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    const check = async () => {
      fetchRows();
    };
    check();
  }, []);

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/app/admin">
          <HomeIcon color="inherit" />
        </Link>
        <Typography color="textPrimary">Pages</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>
        Pages{" "}
        <Button
          href="/app/admin/page"
          type="submit"
          color="primary"
          variant="outlined"
        >
          Add New
        </Button>
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Title</TableCell>
              <TableCell className={classes.tableCell}>Preview</TableCell>
              <TableCell className={classes.tableCell}>Author</TableCell>
              <TableCell className={classes.tableCell}>Status</TableCell>
              <TableCell className={classes.tableCell}>
                <Link href="#" onClick={handleClick}>
                  Date
                </Link>
              </TableCell>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <Box className={classes.popover}>
                  <Typography className={classes.paddingBottom1}>
                    U: Updated Date
                  </Typography>
                  <Typography>P: Published Date</Typography>
                </Box>
              </Popover>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, index) => {
              return (
                <TableRow key={row.ID} hover role="checkbox" tabIndex={-1}>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    <Link href={`/app/admin/page/${row.id}`} color="inherit">
                      {row.title}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link href={`/${row.slug}`} color="inherit">
                      {row.slug}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.myAuthor.username || ""}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {row.status || ""}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="caption" display="block" gutterBottom>
                      {"U: "}
                      <Moment format="YYYY-MM-DD hh:mm">
                        {row.UpdatedAt || row.CreatedAt}
                      </Moment>
                    </Typography>
                    <Typography variant="caption" display="block" gutterBottom>
                      {row.PublishedAt && (
                        <>
                          {"P: "}
                          <Moment format="YYYY-MM-DD hh:mm">
                            {row.PublishedAt}
                          </Moment>
                        </>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableFooter>
            {loading && (
              <TableRow sx={{ height: 84 }}>
                <TableCell colSpan={5}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow sx={{ height: 84 }}>
              <TableCell sx={{ textAlign: "center" }} colSpan={5}>
                <Button
                  onClick={fetchRows}
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={!allowLoadMore}
                >
                  Load More
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
}
