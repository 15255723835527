import React, { useState, useEffect } from "react";
import {
  Alert,
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Fade,
  Grid,
  IconButton,
  Link,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import CancelIcon from "@mui/icons-material/Cancel";
import SwitchCameraIcon from "@mui/icons-material/SwitchCamera";
import {
  customAPIHeader,
  getResizedFileName,
} from "../../utils/UtilityFunctions";
import { Formik } from "formik";
import { postNotification } from "../../utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import { API, Auth } from "aws-amplify";
// import awsExports from "../../../aws-exports";

const useStyles = makeStyles((theme) => ({
  // Avatars
  avatarLarge: {
    width: "250px",
    height: "250px",
    [theme.breakpoints.down("lg")]: { width: "225px", height: "225px" },
    [theme.breakpoints.down("md")]: { width: "200px", height: "200px" },
  },
  avatarWrapper: {
    //[theme.breakpoints.down("sm")]: {
    textAlign: "center",
    display: "flex",
    flexFirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //},
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
  modalHeader: { marginBottom: theme.spacing(2) },
  containerLoading: {
    width: "100%",
    height: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCloseBtn: {
    float: "right",
    position: "absolute",
    right: "20px",
    top: "10px",
    zIndex: "1000",
    padding: "5px",
  },
  avatarListContainer: { overflow: "auto", maxHeight: "70vh" },
  paper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "90%",
    //maxWidth: 500,
    backgroundColor: theme.palette.background.default,
    border: "1px solid #242424",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  container: {
    width: "100%",
    height: "200px",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

const SmallAvatar = withStyles((theme) => ({
  root: {
    width: 42,
    height: 42,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Avatar);

export default function AvatarTab({ profile, setLoading, userState }) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [myAvatar, setMyAvatar] = useState(null);
  const [mainState, setMainState] = useState({
    loading: false,
    processing: false,
    avatars: [],
  });
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [referralTooltip, setReferralTooltip] = useState(false);

  const fetchRows = async (LastEvaluatedKey) => {
    var allItems = [];
    const queryStringParameters = {
      pageSize: 36,
      inclFeaturedImage: true,
    };
    if (LastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(LastEvaluatedKey);
    }
    const apiGetData = await API.get("sfwApi", "/avatar", {
      queryStringParameters: queryStringParameters,
    });
    var nextRows = [];
    if (apiGetData) {
      if (apiGetData.Items) {
        allItems = apiGetData.Items;
      }

      if (apiGetData.LastEvaluatedKey) {
        nextRows = await fetchRows(apiGetData.LastEvaluatedKey);
      }
    }

    return [...allItems, ...nextRows];
  };

  useEffect(() => {
    const check = async () => {
      if (profile.myAvatar) {
        setMyAvatar(profile.myAvatar);
      }
      setMainState({ ...mainState, loading: true });
      var allItems = await fetchRows(null);
      allItems.sort((a, b) => {
        if (!a.points && !b.points) return 0;
        if (!a.points) return -1;
        if (!b.points) return 1;

        return a.points - b.points;
      });
      setMainState({
        ...mainState,
        loading: false,
        processing: false,
        avatars: allItems,
      });
    };

    check();
  }, [profile]);

  useEffect(() => {
    const delayOff = async () => {
      await delay(3 * 1000);
      setReferralTooltip(false);
    };
    if (referralTooltip) {
      delayOff();
    }
  }, [referralTooltip]);

  return (
    <>
      <Formik
        initialValues={{
          myAvatar: myAvatar,
        }}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          setMainState({ ...mainState, processing: true });
          var postBody = {
            avatarId: values.myAvatar.id,
          };
          if (profile.username && profile.username !== "") {
            var apiPostData = await API.post(
              "sfwApi",
              "/user/" + profile.username,
              {
                body: postBody,
                headers: await customAPIHeader(),
              }
            );

            if (apiPostData.error) {
              postNotification("error", apiPostData.error);
            } else {
              setMyAvatar({
                ...values.myAvatar,
                ...apiPostData.data?.myAvatar,
              });
              userState.setAvatar({
                ...values.myAvatar,
                ...apiPostData.data?.myAvatar,
              });
              postNotification("success", "Account updated");
              await Auth.currentAuthenticatedUser({ bypassCache: true });
            }
          }
          setSubmitting(false);
          setMainState({ ...mainState, processing: false });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className={classes.avatarWrapper}>
                  <IconButton
                    aria-label="avatar"
                    onClick={handleOpen}
                    size="large"
                  >
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        <SmallAvatar>
                          <SwitchCameraIcon color="primary" />
                        </SmallAvatar>
                      }
                    >
                      <Avatar
                        alt={values.myAvatar?.name}
                        src={values.myAvatar?.url}
                        className={classes.avatarLarge}
                      />
                    </Badge>
                  </IconButton>
                </Box>
                <Modal
                  disableAutoFocus
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  sx={{ zIndex: 99990 }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: ["95vw", "85vw", 600],
                      maxHeight: "95vh",

                      bgcolor: "background.paper",
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: 1,
                      boxShadow: 24,
                    }}
                  >
                    <Card>
                      <CardHeader
                        title="Select Avatar"
                        action={
                          <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            size="large"
                          >
                            <CancelIcon />
                          </IconButton>
                        }
                      />

                      <CardContent className={classes.cardContent}>
                        {mainState.loading && (
                          <Container className={classes.containerLoading}>
                            <CircularProgress size={100} />
                          </Container>
                        )}
                        {!mainState.loading && (
                          <Grid container spacing={2}>
                            {mainState.avatars.map((avatarItem) => (
                              <Grid item xs={12} sm={6}>
                                <Box className={classes.avatarWrapper}>
                                  <IconButton
                                    aria-label="avatar"
                                    onClick={() => {
                                      setFieldValue("myAvatar", avatarItem);
                                      handleClose();
                                    }}
                                    size="large"
                                  >
                                    <Avatar
                                      alt={avatarItem.name}
                                      src={avatarItem.url}
                                      className={classes.avatarLarge}
                                    />
                                  </IconButton>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                </Modal>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box className={classes.buttonWrapper}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Update Avatar
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        color="primary"
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              {/* <Grid item xs={12}>
                <Box
                  sx={{
                    // mt: 2,
                    mb: 2,
                    p: "6px 16px",
                    borderRadius: "4px",
                    backgroundColor: "#A55645",
                    color: "#ffffff",
                  }}
                >
                  <Grid container sx={{ pt: 1, pb: 1 }} spacing={2}>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        textAlign: ["center", "center", "left"],
                      }}
                    >
                      <Typography sx={{ mb: 0, fontSize: "0.875rem" }}>
                        Earn Guardian Points to unlock more avatars!
                      </Typography>
                      <Link
                        color="inherit"
                        href="/forge-guardian"
                        target="_blank"
                        underline="always"
                      >
                        <Typography variant="caption">Learn more</Typography>
                      </Link>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      sx={{
                        textAlign: ["center", "center", "right"],
                      }}
                    >
                      <Tooltip open={referralTooltip} title="Copied">
                        <Typography
                          sx={{
                            mb: 0,
                            fontSize: "0.875rem",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${window.location.origin}/?referral=${profile.username}`
                            );
                            setReferralTooltip(true);
                          }}
                        >
                          {window.location.origin}/?referral={profile.username}{" "}
                          <FileCopyIcon sx={{ fontSize: 14 }} />
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Box>
              </Grid> */}
            </Grid>
            {!mainState.loading && (
              <Grid
                container
                spacing={2}
                alignContent="center"
                justifyContent="center"
              >
                {mainState.avatars.map((avatarItem) => (
                  <Grid item>
                    <Button
                      aria-label="avatar"
                      onClick={() => {
                        setFieldValue("myAvatar", avatarItem);
                      }}
                      size="large"
                      disabled={
                        avatarItem.points > 0 &&
                        (!profile.ambassadorPoints ||
                          avatarItem.points > profile.ambassadorPoints)
                      }
                      sx={{
                        // position: "relative",
                        background: `${
                          avatarItem.points > 0 &&
                          (!profile.ambassadorPoints ||
                            avatarItem.points > profile.ambassadorPoints)
                            ? "linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), "
                            : ""
                        }url(${avatarItem.url})`,
                        backgroundSize: "cover",
                        padding: 0,
                        width: "100px",
                        height: "100px",
                        borderRadius: "50%",
                        borderColor:
                          myAvatar?.id === avatarItem.id
                            ? "#B53114"
                            : "#FFFFFF",
                        borderWidth: "8px",
                        borderStyle: "solid",
                        "&:hover": {
                          backgroundColor: "unset",
                        },
                      }}
                    >
                      {avatarItem.points > 0 &&
                        (!profile.ambassadorPoints ||
                          avatarItem.points > profile.ambassadorPoints) && (
                          <Box
                            sx={{
                              position: "absolute",
                              left: 0,
                              right: 0,
                              bottom: 0,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              color: "#000000",
                              width: "100%",
                              height: "100%",
                              lineHeight: "16px",
                            }}
                          >
                            <Grid container>
                              <Grid item xs={12}>
                                <LockIcon
                                  color="inherit"
                                  sx={{ fontSize: 20 }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography
                                  sx={{
                                    textTransform: "capitalize",
                                    fontSize: "14px",
                                  }}
                                >
                                  {avatarItem.points} Points
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        )}
                      {/* <Avatar
                          alt={avatarItem.name}
                          src={
                            avatarItem.myFeaturedImage &&
                            `${
                              avatarItem.myFeaturedImage.url
                            }${getResizedFileName(
                              avatarItem.myFeaturedImage.fileName,
                              "medium"
                            )}`
                          }
                          sx={{
                            width: "115px",
                            height: "115px",
                            borderColor:
                              myAvatar?.id === avatarItem.id ? "#B53114" : null,
                            borderWidth:
                              myAvatar?.id === avatarItem.id ? "8px" : null,
                            borderStyle:
                              myAvatar?.id === avatarItem.id ? "solid" : null,
                          }}
                        /> */}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            )}
          </form>
        )}
      </Formik>
    </>
  );
}
function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}
