import { useEffect, useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import googleTimezoneApi from "google-timezone-api";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { Box } from "@mui/material";

const libraries = ["places"];

export default function GoogleAddressSearch(props) {
  const { label, locationData, setLocationData, initialSearch, sx } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const [center, setCenter] = useState({
    lat: 41.850033,
    lng: -87.6500523,
  });

  useEffect(() => {
    if (!isLoaded) return;
    if (locationData?.coordinate) {
      setCenter({
        lat: locationData.coordinate[1],
        lng: locationData.coordinate[0],
      });
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCenter({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => null
      );
    }
  }, [props, isLoaded]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  return (
    <Search
      label={label}
      center={center}
      initialSearch={initialSearch}
      setLocationData={setLocationData}
      sx={sx}
    />
  );
}

function Search(props) {
  const { label, center, initialSearch, setLocationData, sx } = props;
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: {
        lat: () => center.lat,
        lng: () => center.lng,
      },
      radius: 200 * 1000,
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const result = await getLatLng(results[0]);
      const { lat, lng } = result;
      const timezone = await googleTimezoneApi({
        location: `${lat},${lng}`,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      });

      var loc = {
        coordinate: [lng, lat],
        formatted_address: results[0]?.formatted_address,
      };
      if (timezone?.timeZoneId) loc.timeZone = timezone.timeZoneId;
      if (results[0]?.address_components) {
        results[0].address_components.forEach(
          ({ long_name, short_name, types }) => {
            if (types.includes("street_number")) loc.streetNum = short_name;
            if (types.includes("route")) loc.streetName = long_name;
            if (types.includes("locality")) loc.city = long_name;
            if (types.includes("administrative_area_level_1"))
              loc.state = short_name;
            if (types.includes("country")) loc.country = long_name;
            if (types.includes("postal_code")) loc.zip = short_name;
          }
        );
      }
      setLocationData(loc);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    setValue(initialSearch, false);
  }, [initialSearch]);

  return (
    <Box
      sx={{
        ...sx,
      }}
    >
      <Combobox onSelect={handleSelect}>
        <ComboboxInput
          name="searchBox"
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={label || "Search Location"}
          style={{
            width: "100%",
            minHeight: "58.88px",
            padding: "16.5px 14px",
            fontSize: "1em",
            borderRadius: "4px",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "rgba(0, 0, 0, 0.23)",
          }}
        />
        <ComboboxPopover
          style={{
            zIndex: "9999",
          }}
        >
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </Box>
  );
}
