import React, { useContext } from "react";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";

import * as Yup from "yup";
import { Formik } from "formik";

import { UserContext } from "../../context/UserContext";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function VerifyForm({
  setAuthStep,
  postNotification,
  sharedInfo,
  setSharedInfo,
  processing,
  setProcessing,
  redirect,
}) {
  const classes = useStyles();
  const userState = useContext(UserContext);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Verify username
        </Typography>
        <Typography color="textSecondary">
          Please check your email for a verification code
        </Typography>
        <Formik
          initialValues={{ username: sharedInfo.username, verifyCode: "" }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(255).required("Username is required"),
            verifyCode: Yup.string()
              .max(255)
              .required("Verify Code is required"),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setProcessing(true);
            await verifySignUp(values, setSubmitting, redirect);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
            setFieldTouched,
          }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    autoComplete="username"
                    name="username"
                    variant="outlined"
                    color="secondary"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.verifyCode && errors.verifyCode)}
                    helperText={touched.verifyCode && errors.verifyCode}
                    name="verifyCode"
                    variant="outlined"
                    color="secondary"
                    required
                    fullWidth
                    id="verifyCode"
                    label="Verify Code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.verifyCode}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={async (e) => {
                      setFieldTouched("username");
                      if (Boolean(errors.username) === false) {
                        setProcessing(true);
                        await resendCode(values, isSubmitting);
                        setProcessing(false);
                      }
                    }}
                  >
                    Resend Code
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Verify
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => {
                      setAuthStep(0);
                    }}
                    variant="body2"
                  >
                    Sign In?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    onClick={() => {
                      setAuthStep(1);
                    }}
                    variant="body2"
                  >
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );

  async function verifySignUp(verifyInfo, setSubmitting, redirect) {
    const { username, verifyCode } = verifyInfo;

    if (!!username && !!verifyCode) {
      try {
        await Auth.confirmSignUp(username.toLowerCase(), verifyCode);
        setSharedInfo({
          username: username,
          email: sharedInfo.email,
          password: sharedInfo.password,
        });
        postNotification("success", "Email Verified");
        if (
          sharedInfo.username &&
          sharedInfo.username !== "" &&
          sharedInfo.password &&
          sharedInfo.password !== ""
        ) {
          postNotification("info", "Logging you in");
          userState
            .login(sharedInfo, postNotification, setSubmitting)
            .then((user) => {
              if (redirect) {
                window.location.assign(redirect);
              }
            })
            .catch((error) => {
              setAuthStep(0);
              setProcessing(false);
            });
        } else {
          setAuthStep(0);
          setProcessing(false);
        }
      } catch (error) {
        var unhandledError = true;
        if (error.code) {
          if (error.code === "UserNotFoundException") {
            unhandledError = false;
            postNotification("error", "Unknown Username");
            setProcessing(false);
          } else if (error.code === "CodeMismatchException") {
            unhandledError = false;
            postNotification("error", "Invalid Verification Code");
            setProcessing(false);
          }
        }
        if (unhandledError) {
          postNotification(
            "error",
            cognitoFriendlyError(
              error,
              "Something went wrong with your verificaition, please try again."
            )
          );
          setProcessing(false);
        }
      }
    } else {
      postNotification("error", "Missing required fields");
      setProcessing(false);
    }
  }

  async function resendCode(verifyInfo, setSubmitting) {
    const { username } = verifyInfo;

    try {
      if (username) {
        await Auth.resendSignUp(username);

        postNotification(
          "info",
          "Please check your email for verification code"
        );

        setSharedInfo({
          username: username,
        });
      } else {
        postNotification("error", "Something went wrong");
      }
    } catch (error) {
      postNotification(
        "error",
        cognitoFriendlyError(
          error,
          "something went wrong with your sign up, please try again."
        )
      );
    }
  }

  function cognitoFriendlyError(error, def) {
    if (error) {
      if (error.code === "UserNotFoundException") {
        // confirmSignUp
        return "Username/Email not found.";
      } else if (
        error.code === "NotAuthorizedException" &&
        error.message ===
          "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        // confirmSignUp
        return "Email has already been verified. Try logging in.";
      } else if (error.code === "UsernameExistsException") {
        // signUp
        return "Email already exists, please try to login instead.";
      }

      if (error.message) {
        return error.message;
      }
    }
    if (def) {
      return def;
    }
    return "Unknown Error";
  }
}
