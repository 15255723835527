import { useContext } from "react";
import { useRoutes } from "react-router-dom";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

// global
import ReactGA from "react-ga4";
import Routes from "./Routes";
import { themeJson } from "./theme/Default";
import { UserContext } from "./context/UserContext";

import "./App.css";

import Amplify, { Auth } from "aws-amplify";
import awsExports from "./aws-exports";
import { getEnv } from "./components/utils/UtilityFunctions";

const env = getEnv();

const amplifyConfig = {
  // ...awsExports,
  aws_project_region: awsExports.aws_project_region,
  aws_user_files_s3_bucket: awsExports.aws_user_files_s3_bucket,
  aws_user_files_s3_bucket_region: awsExports.aws_user_files_s3_bucket_region,
  aws_dynamodb_all_tables_region: awsExports.aws_dynamodb_all_tables_region,
  aws_dynamodb_table_schemas: awsExports.aws_dynamodb_table_schemas,
  API: {
    endpoints: [
      ...awsExports.aws_cloud_logic_custom,
      {
        name: "BCPDevApi",
        endpoint:
          "https://ej0fet52ij.execute-api.us-east-1.amazonaws.com/solfordev",
        region: "us-east-1",
        custom_header: async () => {
          var header = {};
          try {
            // check url for JWT token, use that, otherwise use currentSession
            header = {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          } catch (err) {
            header = {};
          }
          return header;
        },
      },
      {
        name: "BCPProdApi",
        endpoint:
          "https://hpp2exnbyd.execute-api.us-east-1.amazonaws.com/solforprod",
        region: "us-east-1",
        custom_header: async () => {
          var header = {};
          try {
            // check url for JWT token, use that, otherwise use currentSession
            header = {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          } catch (err) {
            header = {};
          }
          return header;
        },
      },
    ],
  },
  Auth:
    env === "main"
      ? {
          region: awsExports.aws_cognito_region,

          identityPoolId: awsExports.aws_cognito_identity_pool_id,
          identityPoolRegion: awsExports.aws_cognito_region,
          userPoolId: awsExports.aws_user_pools_id,
          userPoolWebClientId: awsExports.aws_user_pools_web_client_id,

          cookieStorage: {
            domain: ".solforgefusion.com",
            secure: true,
          },
        }
      : {
          region: awsExports.aws_cognito_region,

          identityPoolId: awsExports.aws_cognito_identity_pool_id,
          identityPoolRegion: awsExports.aws_cognito_region,
          userPoolId: awsExports.aws_user_pools_id,
          userPoolWebClientId: awsExports.aws_user_pools_web_client_id,
        },
};

Amplify.configure(amplifyConfig);

const theme = createTheme(themeJson);

ReactGA.initialize("G-EYW2FP1W2Y");
ReactGA.send("pageview");

const App = () => {
  var userState = useContext(UserContext);
  const routing = useRoutes(
    Routes(userState.isAuthenticated, userState.access, userState.isLoading)
  );

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{routing}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
