import React from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  FormControlLabel,
  FormHelperText,
  TextField,
  Checkbox,
  Link,
  Grid,
  Typography,
} from "@mui/material";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

import * as Yup from "yup";
import { Formik } from "formik";
import Cookies from "js-cookie";
import makeStyles from "@mui/styles/makeStyles";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUpForm({
  setAuthStep,
  formFields,
  postNotification,
  sharedInfo,
  setSharedInfo,
  processing,
  setProcessing,
}) {
  const classes = useStyles();

  var initialValues = {};
  var validationSchema = {};

  formFields.forEach((formField) => {
    if (formField.inputProps.type === "checkbox") {
      initialValues[formField.type] = !!formField.inputProps.default
        ? true
        : false;
      if (formField.inputProps.required === true) {
        validationSchema[formField.type] = Yup.boolean().oneOf(
          [true],
          "This field must be checked"
        );
      }
    } else {
      if (sharedInfo[formField.type]) {
        initialValues[formField.type] = sharedInfo[formField.type];
      } else {
        initialValues[formField.type] = "";
      }
      validationSchema[formField.type] = Yup.string().max(255);

      if (formField.inputProps.min) {
        validationSchema[formField.type] = validationSchema[formField.type].min(
          formField.inputProps.min
        );
      }

      if (formField.inputProps.type === "email") {
        validationSchema[formField.type] = validationSchema[
          formField.type
        ].email("Must be a valid email");
      }

      if (formField.inputProps.required === true) {
        validationSchema[formField.type] = validationSchema[
          formField.type
        ].required(`${formField.label} is required`);
      }
    }
  });

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape(validationSchema)}
          onSubmit={async (values, { setSubmitting }) => {
            setProcessing(true);
            await signUp(values);
            setProcessing(false);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                {formFields.map((formField) => {
                  if (formField.inputProps.type === "checkbox") {
                    return (
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={formField.type}
                              checked={values[formField.type]}
                              defaultChecked={false}
                              onChange={handleChange}
                            />
                          }
                          label={formField.label}
                        />

                        {Boolean(
                          touched[formField.type] && errors[formField.type]
                        ) && (
                          <FormHelperText error>
                            {errors[formField.type]}
                          </FormHelperText>
                        )}
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(
                            touched[formField.type] && errors[formField.type]
                          )}
                          helperText={
                            touched[formField.type] && errors[formField.type]
                          }
                          autoComplete={
                            formField.inputProps.autocomplete &&
                            formField.inputProps.autocomplete
                          }
                          type={
                            formField.type === "password" ? "password" : "text"
                          }
                          name={formField.type}
                          variant="outlined"
                          color="secondary"
                          required={!!formField.inputProps.required}
                          fullWidth
                          id={formField.type}
                          label={formField.label}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values[formField.type]}
                        />
                      </Grid>
                    );
                  }
                })}
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign Up
              </Button>
              <Grid container justifyContent="flex-end">
                <Grid item>
                  <Link
                    onClick={() => {
                      setAuthStep(0);
                    }}
                    variant="body2"
                  >
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );

  async function signUp(signUpInfo) {
    const { preferred_username, email, given_name, family_name, password } =
      signUpInfo;

    try {
      var signUpData = {
        username: preferred_username
          ? preferred_username.trim().toLowerCase()
          : email.trim().toLowerCase(),
        password: password,
        attributes: {},
        clientMetadata: {},
      };

      if (email) signUpData.attributes.email = email.trim().toLowerCase();
      if (given_name) signUpData.attributes.given_name = given_name.trim();
      if (family_name) signUpData.attributes.family_name = family_name.trim();

      // custom attributes
      signUpData.attributes["custom:allow_marketing"] = "false";
      if (!!signUpInfo["custom:allow_marketing"]) {
        signUpData.attributes["custom:allow_marketing"] = "true";
      }
      var referralCode = Cookies.get("referralCode");
      if (referralCode) {
        signUpData.attributes["custom:referral_code"] = referralCode;
      }

      const user = await Auth.signUp(signUpData);
      if (user) {
        postNotification(
          "info",
          "User created, please check your email for verification code"
        );

        setSharedInfo({
          username: signUpData.username,
          email: signUpData.attributes.email ? signUpData.attributes.email : "",
          password: password,
        });

        setAuthStep(3);
      } else {
        postNotification("error", "Something went wrong");
      }
    } catch (error) {
      postNotification(
        "error",
        cognitoFriendlyError(
          error,
          "something went wrong with your sign up, please try again."
        )
      );
    }
  }

  function cognitoFriendlyError(error, def) {
    if (error) {
      if (error.code === "UserNotFoundException") {
        // confirmSignUp
        return "Username/Email not found.";
      } else if (
        error.code === "NotAuthorizedException" &&
        error.message ===
          "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        // confirmSignUp
        return "Email has already been verified. Try logging in.";
      } else if (error.code === "UsernameExistsException") {
        // signUp
        return "Username already exists, please try another.";
      }

      if (error.message) {
        return error.message;
      }
    }
    if (def) {
      return def;
    }
    return "Unknown Error";
  }
}
