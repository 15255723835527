import React, { useEffect, useState } from "react";

import {
  Avatar,
  Box,
  Divider,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import { AccountCircle } from "@mui/icons-material";
import CallMergeIcon from "@mui/icons-material/CallMerge";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonIcon from "@mui/icons-material/Person";
import LogoutIcon from "@mui/icons-material/Logout";
import PiratePeteIcon from "../icons/PiratePeteIcon";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { getEnv } from "../utils/UtilityFunctions";

const env = getEnv();

export default function ProfileMenu({ userState, navigate }) {
  var [profileMenu, setProfileMenu] = useState(null);
  const theme = useTheme();

  const [avatar, setAvatar] = useState(
    userState?.avatar
      ? userState?.avatar
      : userState?.avatarUrl
      ? {
          name: userState?.avatarName || "Avatar",
          url: userState?.avatarUrl,
        }
      : userState?.user?.avatar
      ? userState?.user?.avatar
      : userState?.user?.avatarUrl
      ? {
          name: userState?.user?.avatarName || "Avatar",
          url: userState?.user?.avatarUrl,
        }
      : null
  );
  useEffect(() => {
    setAvatar(
      userState?.avatar
        ? userState?.avatar
        : userState?.avatarUrl
        ? {
            name: userState?.avatarName || "Avatar",
            url: userState?.avatarUrl,
          }
        : userState?.user?.avatar
        ? userState?.user?.avatar
        : userState?.user?.avatarUrl
        ? {
            name: userState?.user?.avatarName || "Avatar",
            url: userState?.user?.avatarUrl,
          }
        : null
    );
  }, [userState]);

  return (
    <>
      <Box>
        <IconButton
          aria-haspopup="true"
          color="inherit"
          aria-controls="profile-menu"
          onClick={(e) => {
            if (Boolean(profileMenu)) setProfileMenu(null);
            else setProfileMenu(e.currentTarget);
          }}
          size="large"
        >
          {Boolean(profileMenu) && <ArrowDropUpIcon />}
          {!Boolean(profileMenu) && <ArrowDropDownIcon />}
          {avatar?.url ? (
            <Avatar
              alt={avatar?.name || "Avatar"}
              src={avatar?.url}
              sx={{ width: 28, height: 28, border: "2px solid #FFFFFF" }}
            />
          ) : (
            <AccountCircle
              sx={{
                fontSize: 28,
                "&:hover": {
                  color: theme.palette.primary.main,
                  filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
                    theme.palette.primary.main
                  })`,
                },
              }}
            />
          )}
        </IconButton>
      </Box>
      <Menu
        id="profile-menu"
        open={Boolean(profileMenu)}
        anchorEl={profileMenu}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setProfileMenu(null)}
        sx={{ mt: 1 }}
        disableAutoFocusItem
        disableScrollLock
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: 2,
          }}
        >
          <Typography variant="h4" weight="medium">
            {`${userState.user.attributes.given_name} ${userState.user.attributes.family_name}`}
          </Typography>
        </Box>
        {userState?.user?.userId && (
          <Link
            href={`${
              env === "main"
                ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
            }/user/${userState.user.userId}`}
            sx={{ textDecoration: "none" }}
          >
            <MenuItem
              sx={{
                color: "text.hint",
              }}
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>My Profile</ListItemText>
            </MenuItem>
          </Link>
        )}
        <Link
          href={`${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/decks`}
          sx={{ textDecoration: "none" }}
        >
          <MenuItem
            sx={{
              color: "text.hint",
            }}
          >
            <ListItemIcon>
              <LibraryBooksIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>My Decks</ListItemText>
          </MenuItem>
        </Link>
        <Link
          href={`${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/fused`}
          sx={{ textDecoration: "none" }}
        >
          <MenuItem
            sx={{
              color: "text.hint",
            }}
          >
            <ListItemIcon>
              <CallMergeIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>My Fused Decks</ListItemText>
          </MenuItem>
        </Link>
        <Divider />
        <Link
          href={`${
            process.env.REACT_APP_HEADER_SOLFORGE_URL || ""
          }/app/account`}
          sx={{ textDecoration: "none" }}
        >
          <MenuItem
            sx={{
              color: "text.hint",
            }}
          >
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Account Settings</ListItemText>
          </MenuItem>
        </Link>
        {(userState?.access?.isAdmin ||
          userState?.access?.isEditor ||
          userState?.user?.isSFAdmin ||
          userState?.user?.isSFEditor) && (
          <Link
            href={`${
              process.env.REACT_APP_HEADER_SOLFORGE_URL || ""
            }/app/admin`}
            sx={{ textDecoration: "none" }}
          >
            <MenuItem
              sx={{
                color: "text.hint",
              }}
            >
              <ListItemIcon>
                <AdminPanelSettingsIcon fontSize="small" color="primary" />
              </ListItemIcon>
              <ListItemText>Site Admin</ListItemText>
            </MenuItem>
          </Link>
        )}

        {(userState?.user?.isAdmin || userState?.access?.isAdmin) && (
          <Link
            href={`${
              env === "main"
                ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
            }/admin`}
            sx={{ textDecoration: "none" }}
          >
            <MenuItem
              sx={{
                color: "text.hint",
              }}
            >
              <ListItemIcon>
                <PiratePeteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Events Admin</ListItemText>
            </MenuItem>
          </Link>
        )}

        <Link
          noWrap
          variant="body2"
          onClick={() => {
            userState.logout(navigate);
          }}
          sx={{ textDecoration: "none" }}
        >
          <MenuItem color="primary">
            <ListItemIcon>
              <LogoutIcon fontSize="small" color="primary" />
            </ListItemIcon>
            <ListItemText>Sign Out</ListItemText>
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
}
