import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";
import PropTypes from "prop-types";
import {
  AppBar,
  Avatar,
  Box,
  Backdrop,
  CircularProgress,
  Modal,
  Grid,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  Alert,
  Link,
} from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import { AccountCircle } from "@mui/icons-material";

import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

import Dot from "../utils/Dot";
import Widget from "../utils/Widget";
import { replaceWithIcons } from "./CardWidget";
import { rarities } from "../utils/Rarity";

import Moment from "react-moment";
import "moment-timezone";

import {
  getEnv,
  getS3Bucket,
  hsl_col_perc,
  splitUrl,
  // getResizedFileName,
} from "../utils/UtilityFunctions";

import { postNotification } from "../../components/utils/Notifications";

import { API, Auth } from "aws-amplify";

import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { useNavigate } from "react-router";
import FactionChip from "../utils/FactionChip";
import RankChip from "../utils/RankChip";
import ListResponsive from "../layout/ListResponsive";
import Markdown from "../layout/Markdown";

const env = getEnv();

const useStyles = makeStyles((theme) => ({
  socialButton: {
    // paddingTop: "0",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "unset", //alpha(theme.palette.primary.main, 0.05),
    },
  },
  tabContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
  tabHeader: {
    backgroundColor: "unset",
    boxShadow: "unset",
    color: "unset",
  },
  cardGrid: {
    // paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: `0px 0px 70px -12.125px ${alpha(
        theme.palette.primary.main,
        0.4
      )}`,
    },
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  filterButtonSelected: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
  },
  listItemSmall: { paddingTop: "0px", paddingBottom: "0px" },
  listItemAvatarSmall: { minWidth: "30px" },
  listItemTextSmall: { "& span": { fontSize: "0.85rem" } },
  avatarSmall: { width: "20px", height: "20px" },
  cardImg: {
    width: "100%",
    maxWidth: "100%",
  },
  cardLink: {
    "&:hover": {
      textDecoration: "none",
      color: theme.palette.primary.main,
    },
  },
  formControlWrapper: {},
  carouselWrapper: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  modalCloseBtn: {
    float: "right",
    position: "absolute",
    right: "10px",
    top: "0px",
    zIndex: "1000",
    padding: "5px",
  },
  modalCardImg: {
    maxHeight: "75vh",
    width: "100%",
    maxWidth: "100%",
  },
  progressModalPaper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

const filters = {
  factions: {
    Alloyin: {
      name: "Alloyin",
      img: "/images/factions/alloyin.png",
    },
    Nekrium: {
      name: "Nekrium",
      img: "/images/factions/nekrium.png",
    },
    Tempys: {
      name: "Tempys",
      img: "/images/factions/tempys.png",
    },
    Uterra: {
      name: "Uterra",
      img: "/images/factions/uterra.png",
    },
  },
  levels: {
    1: { name: "1", img: "/images/cards/levels/lv1-icon.png" },
    2: { name: "2", img: "/images/cards/levels/lv2-icon.png" },
    3: { name: "3", img: "/images/cards/levels/lv3-icon.png" },
  },
};

const storyTabIndex = 2;

export default function Deck(props) {
  const { user, name, deck, fusedDeck, fused } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const mediaLT300 = useMediaQuery("(max-width:300px)");
  // const mediaLT350 = useMediaQuery("(max-width:350px)");
  const mediaLT450 = useMediaQuery("(max-width:450px)");

  const mediaLT900 = useMediaQuery("(max-width:899px)");

  const [tabId, setTabId] = useState(0);
  const handleChange = (event, newTabId) => {
    if (newTabId === storyTabIndex) {
      // navigate(`/${deck.storySlug}`);
      var win = window.open(`/${deck.storySlug}`, "_blank");
      win.focus();
      return;
    }
    setTabId(newTabId);
  };
  const [modalOpen, setModalOpen] = useState(false);
  const [processing, setProcessing] = useState(false);
  var initialFactions = [];
  var userState = useContext(UserContext);
  if (userState.cookieConsent) {
    var getGetCachedData = localStorage.getItem(`factions`);
    if (getGetCachedData) {
      initialFactions = JSON.parse(getGetCachedData);
    }
  }
  initialFactions = initialFactions.filter((faction) => {
    return (
      props.decks.filter((deck) => {
        return deck.faction === faction.name;
      }).length > 0
    );
  });
  const [decks, setDecks] = useState(props.decks);
  const [unlockCode, setUnlockCode] = useState(null);
  const [filterFactions, setFilterFactions] = useState(initialFactions);
  const [filterFactionsState, setFilterFactionsState] = useState({});
  const [filterLevelsState, setFilterLevelsState] = useState({});
  const [cardState, setCardState] = useState(
    props.decks?.[0]?.forgeborn || {
      cardNo: null,
      faction: null,
      default: true,
      name: "Solforge Fusion",
      imageUrl: "/images/cards/default.png",
    }
  );

  const toggleFactionsFilter = (id) => {
    var newState = { ...filterFactionsState };
    if (!!newState[id]) {
      delete newState[id];
    } else {
      newState[id] = true;
    }
    setFilterFactionsState(newState);
  };
  const toggleLevelsFilter = (id) => {
    var newState = { ...filterLevelsState };
    if (!!newState[id]) {
      delete newState[id];
    } else {
      newState[id] = true;
    }
    setFilterLevelsState(newState);
  };

  const handleModalOpen = (card) => {
    setIndex(0);
    setCardState(card);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };

  const [images, setImages] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadImage, setLoadImage] = useState(null);
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const CardListItem = ({ card, onClick }) => {
    if (
      Boolean(
        !card.faction ||
          Object.keys(filterFactionsState).length === 0 ||
          filterFactionsState[card.faction]
      )
    ) {
      return (
        <Box
          onClick={(e) => {
            if (mediaLT900) {
              handleModalOpen(card);
            } else {
              setIndex(0);
              setCardState(card);
            }
          }}
        >
          <ListItem button className={classes.listItemSmall}>
            {card.faction && (
              <ListItemAvatar className={classes.listItemAvatarSmall}>
                <Avatar
                  className={classes.avatarSmall}
                  alt={card.faction}
                  src={filters.factions[card.faction].img}
                />
              </ListItemAvatar>
            )}

            <ListItemText
              primary={card.name || card.title}
              className={classes.listItemTextSmall}
            />

            {card.rarity && (
              <ListItemAvatar className={classes.listItemAvatarSmall}>
                <Avatar
                  className={classes.avatarSmall}
                  alt={card.rarity}
                  src={rarities[card.rarity]?.[card.SK]?.img}
                />
              </ListItemAvatar>
            )}
          </ListItem>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const CardForgeborn = ({ card }) => {
    if (
      Boolean(
        !card.faction ||
          Object.keys(filterFactionsState).length === 0 ||
          filterFactionsState[card.faction]
      )
    ) {
      if (card.imageUrl) {
        card.myImage = splitUrl(card.imageUrl);
      }
      return (
        <Box
          onClick={(e) => {
            if (mediaLT900) {
              handleModalOpen(card);
            } else {
              setIndex(0);
              setCardState(card);
            }
          }}
        >
          <ListItem button className={classes.listItemSmall}>
            {card.level && (
              <ListItemAvatar className={classes.listItemAvatarSmall}>
                <Avatar
                  className={classes.avatarSmall}
                  alt={card.level}
                  src={filters.levels[card.level].img}
                />
              </ListItemAvatar>
            )}
            {card.faction && (
              <ListItemAvatar className={classes.listItemAvatarSmall}>
                <Avatar
                  className={classes.avatarSmall}
                  alt={card.faction}
                  src={filters.factions[card.faction].img}
                />
              </ListItemAvatar>
            )}

            <ListItemText
              primary={card.name || card.title}
              className={classes.listItemTextSmall}
            />
          </ListItem>
        </Box>
      );
    } else {
      return <></>;
    }
  };

  const FilterIcon = ({ filter, filterState }) => {
    return (
      <IconButton
        variant="contained"
        color="primary"
        className={
          !!filterState[filter.name] ? classes.filterButtonSelected : null
        }
        onClick={() => {
          if (filter.tableType === "Faction") toggleFactionsFilter(filter.name);
          else if (filter.tableType === "Level") toggleLevelsFilter(filter.id);
        }}
        size="large"
      >
        <Avatar
          className={classes.avatarSmall}
          alt={filter.name}
          src={filter.imageUrl}
          color="primary"
        />
      </IconButton>
    );
  };

  const fetchFactions = async (lastEvaluatedKey) => {
    const queryStringParameters = {
      pageSize: 36,
    };
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }
    const apiGetData = await API.get("sfwApi", "/faction", {
      queryStringParameters: queryStringParameters,
    });

    if (apiGetData.error) {
    }
    if (apiGetData.LastEvaluatedKey) {
      var nextFetch = await fetchFactions(apiGetData.LastEvaluatedKey);
      return [...apiGetData.Items, ...nextFetch];
    } else {
      return apiGetData.Items || [];
    }
  };

  useEffect(() => {
    const check = async () => {
      if (props.decks) {
        setDecks(props.decks);
        var tempImages = {};
        var update = false;
        props.decks.forEach((deck) => {
          setUnlockCode(deck.unlockCode);
          if (deck.imageUrl && !images[deck.imageUrl]) {
            tempImages[deck.imageUrl] = false;
            update = true;
          }
        });
        if (update) {
          setImages({ ...images, ...tempImages });
        }
      }
    };
    check();
  }, [props.decks]);

  useEffect(() => {
    setImageLoaded(false);
  }, [cardState]);

  useEffect(() => {
    const check = async () => {
      //initialFactions
      var data = await fetchFactions(null);
      // console.log(data);
      data = data.filter((faction) => {
        return (
          props.decks.filter((deck) => {
            return deck.faction === faction.name;
          }).length > 0
        );
      });
      setFilterFactions(data);
      if (userState.cookieConsent) {
        localStorage.setItem("factions", JSON.stringify(data));
        var getCachedItems = localStorage.getItem("CachedItems");
        var cachedItems = getCachedItems ? JSON.parse(getCachedItems) : {};
        cachedItems["factions"] = true;
        localStorage.setItem("CachedItems", JSON.stringify(cachedItems));
      }
    };
    check();
  }, [userState.cookieConsent]);

  const CardCarousel = ({ height, width, isModal }) => {
    var offset = -2250 * (width / 250);

    return (
      <>
        <Box
          sx={{
            width: "100%",
            // height: !isModal ? height : null,
            maxWidth: "95vw",
            // maxHeight: height,
            position: "relative",
            backgroundColor: "#E9E9E9",
            border: "2px solid #D8D8D8",
            borderRadius: "4px",
            pt: 2,
            pl: 2,
            pr: 2,
          }}
        >          
          {cardState?.default && <DefaultCard height={height} width={width} />}
          {!cardState?.default &&
            cardState?.rarity == "Solbind" &&
            cardState?.cardType !== "Forgeborn" &&
            cardState?.levels && (
              <SolbindCard
                index={index}
                isModal={isModal}
                handleSelect={handleSelect}
                height={height}
                width={width}
                cardState={cardState}
              />
            )}
          {!cardState?.default &&
            cardState?.rarity !== "Solbind" &&
            cardState?.cardType !== "Forgeborn" &&
            cardState?.levels && (
              <StandardCard
                index={index}
                isModal={isModal}
                handleSelect={handleSelect}
                height={height}
                width={width}
                cardState={cardState}
              />
            )}
          {!cardState?.default &&
            cardState?.cardType === "Forgeborn" &&
            (
              <ForgebornCard
                isModal={isModal}
                height={height}
                width={width}
                cardState={cardState}
              />
            )}
        </Box>

        <img
          alt="loader"
          style={{ display: "none" }}
          src={cardState.imageUrl}
          onLoad={() => {
            setImageLoaded(true);
          }}
        />
      </>
    );
  };
  let solbindCards = []

  return (
    <>
      <Grid container spacing={1}>
        <Grid item sx={{ flex: 1 }}>
          <Typography variant="h5" gutterBottom>
            <Typography
              variant="caption"
              color="inherit"
              sx={{
                fontSize: "inherit",
                fontWeight: "inherit",
                fontFamily: "inherit",
                display: ["block", "unset"],
              }}
            >
              {name}
            </Typography>
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            display: ["none", "block"],
            minWidth: ["100%", "100%", "315px"],
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: ["flex-start", "flex-start", "flex-end"],
            }}
          >
            <IconButton
              edge="end"
              aria-label="TTS Help"
              href="/tts-help"
              color="inherit"
              target="_blank"
              size="large"
              sx={{
                "&:hover": {
                  color: "primary.main",
                  backgroundColor: "unset",
                },
              }}
            >
              <HelpIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <AppBar position="static" className={classes.tabHeader}>
          <Tabs
            value={tabId}
            indicatorColor="primary"
            onChange={handleChange}
            aria-label="Deck"
            orientation={mediaLT450 ? "vertical" : null}
          >
            <Tab label="Cards" {...a11yProps("cards", 0)} />
            <Tab label="Stats" {...a11yProps("stats", 1)} />
            {(deck?.storySlug || fusedDeck?.storySlug) && (
              <Tab label="Story" {...a11yProps("story", 2)} />
            )}
          </Tabs>
        </AppBar>
        <TabPanel value={tabId} index={0}>
          <Grid container spacing={1} sx={{ mt: fused ? 1 : 0 }}>
            {!fused && (
              <>
                <Grid item xs={12}>
                  <Alert
                    icon={
                      <HelpIcon fontSize="inherit" sx={{ color: "#ffffff" }} />
                    }
                    sx={{
                      mt: 2,
                      mb: 2,
                      backgroundColor: "#A55645",
                      color: "#ffffff",
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Did you know:</span> You
                    can play SolForge Fusion online! To learn more,{" "}
                    <Link
                      href="https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffdyk"
                      color="inherit"
                      underline="always"
                      target="_blank"
                    >
                      click here
                    </Link>
                    .
                  </Alert>
                </Grid>
                {decks &&
                  decks.map((deck) => {
                    return (
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            // mt: 2,
                            mb: 2,
                            p: "6px 16px",
                            borderRadius: "4px",
                            backgroundColor: "#2C2C2C",
                            color: "#ffffff",
                          }}
                        >
                          <Grid container sx={{ pt: 1, pb: 1 }} spacing={2}>
                            <Grid item>
                              <RankChip rank={deck?.rank} />
                            </Grid>

                            <Grid item>
                              <Typography
                                gutterBottom
                                sx={{
                                  color: "#D2D2D2",
                                  fontFamily: "Prometo",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "19px",
                                  letterSpacing: "0em",
                                }}
                              >
                                REGISTERED
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "18px",
                                  fontWeight: "700",
                                  lineHeight: "19px",
                                  letterSpacing: "0em",
                                }}
                              >
                                {deck.registeredDate !== undefined ? (
                                  <Moment format="MMMM D, YYYY">
                                    {deck.registeredDate}
                                  </Moment>
                                ) : (
                                  "Unregistered"
                                )}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Typography
                                gutterBottom
                                sx={{
                                  color: "#D2D2D2",
                                  fontFamily: "Prometo",
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  lineHeight: "19px",
                                  letterSpacing: "0em",
                                }}
                              >
                                PAIRINGS
                              </Typography>
                              <Typography
                                gutterBottom
                                sx={{
                                  fontFamily: "Inter",
                                  fontSize: "18px",
                                  fontWeight: "700",
                                  lineHeight: "19px",
                                  letterSpacing: "0em",
                                }}
                              >
                                {deck.stats?.matches || 0}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    );
                  })}
              </>
            )}
            <Grid item xs={12} md={7}>
              <Grid container spacing={1}>
                {fused && (
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={2}>
                        Filter By
                      </Grid>
                      <Grid item xs={12} sm={10}>
                        {filterFactions.map((filterFaction) => {
                          return (
                            <FilterIcon
                              filter={{
                                ...filterFaction,
                                tableType: "Faction",
                              }}
                              filterState={filterFactionsState}
                            />
                          );
                        })}
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography className={classes.listTitle} gutterBottom>
                    Forgeborn
                  </Typography>
                  <List>
                    {decks &&
                      decks.map((deck) => {
                        if(deck.forgeborn.solbindCards){
                          deck.forgeborn.solbindCards.forEach(solbindCard => {
                            solbindCards.push(solbindCard)
                          })
                        }
                        return <CardForgeborn card={deck.forgeborn} />;
                      })}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.listTitle} gutterBottom>
                    Creatures
                  </Typography>
                  <List>
                    {decks &&
                      decks.map((deck) => {
                        return deck.creatures.map((card) => {
                          if(card.solbindCards){
                            card.solbindCards.forEach(solbindCard => {
                              solbindCards.push(solbindCard)
                            })
                          }
                          return <CardListItem onClick={null} card={card} />;
                        });
                      })}
                  </List>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography className={classes.listTitle} gutterBottom>
                    Spells
                  </Typography>
                  <List>
                    {decks &&
                      decks.map((deck) => {
                        return deck.spells.map((card) => {
                          if(card.solbindCards){
                            card.solbindCards.forEach(solbindCard => {
                              solbindCards.push(solbindCard)
                            })
                          }
                          return <CardListItem onClick={null} card={card} />;
                        });
                      })}
                  </List>
                  {solbindCards.length > 0 && (
                    <Typography className={classes.listTitle} gutterBottom>
                      Solbind
                    </Typography>
                    )                  
                  }
                  <List>
                    {solbindCards.length > 0 &&
                        solbindCards.map((card) => {
                          return <CardListItem onClick={null} card={card} />;
                      })}
                  </List>
                </Grid>
              </Grid>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} sm={5}>
                <CardCarousel height={350} width={250} isModal={false} />
              </Grid>
            </Hidden>
          </Grid>
        </TabPanel>
        <TabPanel value={tabId} index={1}>
          {!fused && <DeckAchievements deck={decks?.[0]} />}
          <Grid container spacing={4}>
            {props.stats &&
              props.stats.map((stat) => {
                return (
                  <Grid item lg={3} md={4} sm={6} xs={12}>
                    <Widget
                      title={stat.title}
                      upperTitle
                      className={classes.card}
                      disableWidgetMenu
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <ResponsiveContainer width="100%" height={144}>
                            <PieChart>
                              <Pie
                                data={stat.legend}
                                innerRadius={30}
                                outerRadius={40}
                                dataKey="value"
                              >
                                {stat.legend.map((entry, index) => {
                                  let colour = hsl_col_perc(
                                    ((index + 1) / stat.legend.length) * 100,
                                    "turquoise",
                                    "purple"
                                  );
                                  return (
                                    <Cell key={`cell-${index}`} fill={colour} />
                                  );
                                })}
                              </Pie>
                            </PieChart>
                          </ResponsiveContainer>
                        </Grid>
                        <Grid item xs={6}>
                          <Box className={classes.pieChartLegendWrapper}>
                            {stat.legend.map(({ name, value }, index) => {
                              let colour = hsl_col_perc(
                                ((index + 1) / stat.legend.length) * 100,
                                "turquoise",
                                "purple"
                              );
                              return (
                                <Box
                                  key={index}
                                  className={classes.legendItemContainer}
                                >
                                  <Dot color={colour} />
                                  <Typography
                                    sx={{
                                      whiteSpace: "nowrap",
                                      fontSize: 12,
                                    }}
                                  >
                                    {value}&nbsp;{name}&nbsp;
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Box>
                        </Grid>
                      </Grid>
                    </Widget>
                  </Grid>
                );
              })}
          </Grid>
        </TabPanel>
      </Box>

      <Modal // `disableBackdropClick` is removed by codemod.
        // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)
        open={modalOpen}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ zIndex: 99990 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // width: ["95vw", "85vw", 600],
            // maxHeight: "95vh",

            // bgcolor: "background.paper",
            // border: "1px solid rgba(0, 0, 0, 0.12)",
            // borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Box
            sx={{
              position: "relative",
              // maxWidth: ["90vw", null],
              // width: ["90vw", null], //["90vw", "90vw", `${250 * 1.5}px`],
              minWidth: ["95vw", "80vw", "70vw"],
              maxHeight: "90vh",
            }}
          >
            <IconButton
              edge="end"
              aria-label="Close"
              onClick={handleModalClose}
              variant="contained"
              color="inherit"
              fontSize="large"
              className={classes.modalCloseBtn}
              size="large"
            >
              <CancelIcon />
            </IconButton>

            <CardCarousel
              height={
                350 * (mediaLT300 ? 0.95 : 1.1)
                // (mediaLT300 ? 0.95 : mediaLT350 ? 1.1 : mediaLT400 ? 1.25 : 1.5)
              }
              width={
                250 * (mediaLT300 ? 0.95 : 1.1)
                // (mediaLT300 ? 0.95 : mediaLT350 ? 1.1 : mediaLT400 ? 1.25 : 1.5)
              }
              isModal={true}
            />
          </Box>
        </Box>
      </Modal>

      <Modal
        disableAutoFocus
        open={processing}
        onClose={handleModalClose}
        disableScrollLock
      >
        <Box className={classes.progressModalPaper}>
          <CircularProgress color="primary" />
        </Box>
      </Modal>
    </>
  );
}

const DeckAchievements = (props) => {
  const { deck } = props;
  const [placings, setPlacings] = useState(null);

  const fetchPlacings = async (lastEvaluatedKey) => {
    const queryStringParameters = {
      pageSize: 100,
    };
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }

    const apiGetData = await API.get("sfwApi", `/placings/decks/${deck.id}`, {
      queryStringParameters: queryStringParameters,
    });

    if (apiGetData.LastEvaluatedKey) {
      var nextFetch = await fetchPlacings(apiGetData.LastEvaluatedKey);
      return [...apiGetData.Items, ...nextFetch];
    } else {
      return apiGetData.Items || [];
    }
  };

  const getPlacings = async () => {
    try {
      var apiPlacings = await fetchPlacings();
      setPlacings(apiPlacings);
    } catch (err) {
      postNotification("error", err.message);
    }
  };

  useEffect(() => {
    if (!placings) {
      getPlacings();
    }
  }, [deck]);
  return (
    <Box
      sx={{
        p: 1,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        borderRadius: 1,
        boxShadow: 1,
        backgroundColor: "common.white",
        mt: 2,
        mb: 2,
        // textAlign: "center",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h2">Deck Achievement History</Typography>
        </Grid>
        <Grid item xs={12}>
          <ListResponsive
            originalList={placings ? placings.filter((el) => el.points) : null}
            doPages={true}
            rowProps={{
              headings: (
                <Hidden mdDown>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      p: 1,
                      // color: "#767676",

                      borderTopLeftRadius: "4px",
                      borderTopRightRadius: "4px",
                      backgroundColor: "#2C2C2C",
                      color: "#ffffff",
                    }}
                  >
                    <AchievementRow heading />
                  </Grid>
                </Hidden>
              ),
              mapFunction: (row, index) => (
                <Grid
                  item
                  xs={12}
                  key={index}
                  sx={{
                    p: 1,
                    backgroundColor: isOdd(index + 1) ? "#E7E7E7" : null,
                  }}
                >
                  <AchievementRow key={index} index={index} placing={row} />
                </Grid>
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const AchievementRow = (props) => {
  const { heading, placing, index } = props;

  const mediaLT600 = useMediaQuery("(max-width:599px)");
  const mediaLT800 = useMediaQuery("(max-width:799px)");
  const mediaLT900 = useMediaQuery("(max-width:899px)");

  if (heading) {
    if (mediaLT900) {
      return <></>;
    }
    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={
          {
            // mt: 2,
            // mb: 2,
            // p: "6px 16px",
            // borderRadius: "4px",
            // backgroundColor: "#2C2C2C",
            // color: "#ffffff",
          }
        }
      >
        <Grid
          item
          sx={{
            flex: 1,
            maxWidth: "calc(100% - 160px - 200px - 200px - 100px)",
          }}
        >
          Event
        </Grid>
        <Grid item sx={{ minWidth: "160px" }}>
          Date
        </Grid>
        <Grid item sx={{ minWidth: "200px" }}>
          Played By
        </Grid>
        <Grid item sx={{ minWidth: "200px" }}>
          Deck Rank
        </Grid>
        <Grid item sx={{ minWidth: "100px" }}>
          Points
        </Grid>
      </Grid>
    );
  }

  if (mediaLT900) {
    return (
      <Grid
        container
        spacing={1}
        alignItems="center"
        sx={{
          // mt: 2,
          mb: 2,
          // p: "6px 16px",
          // borderRadius: "4px",
          // backgroundColor: "#2C2C2C",
          // color: "#ffffff",
        }}
      >
        <Grid item xs={4}>
          Event
        </Grid>
        <Grid item xs={8} textAlign="right">
          <Link
            href={`${
              env === "main"
                ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
            }/event/${placing?.event?.id}`}
            target="_blank"
            underline="none"
          >
            {placing?.event?.name}
          </Link>
        </Grid>

        <Grid item xs={4}>
          Date
        </Grid>
        <Grid item xs={8} textAlign="right">
          <Moment format="MMMM D, YYYY">{placing?.event?.eventEndDate}</Moment>
        </Grid>

        <Grid item xs={4}>
          Played By
        </Grid>
        <Grid item xs={8} textAlign="right">
          <Link
            href={`${
              env === "main"
                ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
            }/user/${placing?.user?.playId}`}
            target="_blank"
            underline="none"
            sx={{ display: "flex", flexDirection: "row-reverse" }}
          >
            {placing?.user?.avatar?.url ? (
              <Avatar
                alt={placing.user.avatar.name || "Avatar"}
                src={placing.user.avatar.url}
                sx={{ width: 28, height: 28, border: "2px solid #FFFFFF" }}
              />
            ) : (
              <AccountCircle
                sx={{
                  fontSize: 28,
                }}
              />
            )}

            {placing?.user?.displayName
              ? placing?.user?.displayName
              : // : placing?.user?.givenName || placing?.user?.familyName
                // ? `${placing?.user?.givenName} ${placing?.user?.familyName}`
                "Anonymous"}
          </Link>
        </Grid>

        <Grid item xs={4}>
          Points
        </Grid>
        <Grid item xs={8} textAlign="right">
          +{placing?.points}
        </Grid>

        <Grid item xs={4}>
          Deck Rank
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ display: "flex", flexDirection: "row-reverse" }}
        >
          <RankChip rank={placing?.deckRank} />
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      sx={{
        // mt: 2,
        mb: 2,
        // p: "6px 16px",
        // borderRadius: "4px",
        // backgroundColor: "#2C2C2C",
        // color: "#ffffff",
      }}
    >
      <Grid
        item
        sx={{ flex: 1, maxWidth: "calc(100% - 160px - 200px - 200px - 100px)" }}
      >
        <Link
          href={`${
            env === "main"
              ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
              : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
          }/event/${placing?.event?.id}`}
          target="_blank"
          underline="none"
        >
          {placing?.event?.name}
        </Link>
      </Grid>
      <Grid item sx={{ minWidth: "160px" }}>
        <Moment format="MMMM D, YYYY">{placing?.event?.eventEndDate}</Moment>
      </Grid>
      <Grid item sx={{ minWidth: "200px" }}>
        <Link
          href={`${
            env === "main"
              ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
              : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
          }/user/${placing?.user?.playId}`}
          target="_blank"
          underline="none"
          sx={{ display: "flex", flexDirection: "row" }}
        >
          {placing?.user?.avatar?.url ? (
            <Avatar
              alt={placing.user.avatar.name || "Avatar"}
              src={placing.user.avatar.url}
              sx={{ width: 28, height: 28, border: "2px solid #FFFFFF" }}
            />
          ) : (
            <AccountCircle
              sx={{
                fontSize: 28,
              }}
            />
          )}

          {placing?.user?.displayName
            ? placing?.user?.displayName
            : // : placing?.user?.givenName || placing?.user?.familyName
              // ? `${placing?.user?.givenName} ${placing?.user?.familyName}`
              "Anonymous"}
        </Link>
      </Grid>
      <Grid item sx={{ minWidth: "200px" }}>
        <RankChip rank={placing?.deckRank} />
      </Grid>
      <Grid item sx={{ minWidth: "100px" }}>
        +{placing?.points}
      </Grid>
    </Grid>
  );
};

const DefaultCard = (props) => {
  const { width, height } = props;
  return (
    <Box
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
        visibility: "visible",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <img
        alt={"SolForge Fusion"}
        style={{
          width: `${width}px`,
          height: `${height}px`,
          maxWidth: "100%",
          marginBottom: "16px",
        }}
        src="/images/cards/default.png"
      />
    </Box>
  );
};

const StandardCard = (props) => {
  const { height, width, cardState, isModal, index, handleSelect } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  const [tabId, setTabId] = useState(0);
  const handleChange = (event, newTabId) => {
    setTabId(newTabId);
    setImageLoaded(false);
  };
  useEffect(() => {
    setImageLoaded(false);
  }, [cardState]);

  const levels = [
    {
      name: "Level 1",
      image: "/images/cards/levels/lv1-icon.png",
    },
    {
      name: "Level 2",
      image: "/images/cards/levels/lv2-icon.png",
    },
    {
      name: "Level 3",
      image: "/images/cards/levels/lv3-icon.png",
    },
  ];
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Box
          sx={{
            width: `${width}px`,
            height: `${height}px`,
            minWidth: `${width}px`,
            maxWidth: "100%",
            maxHeight: "100%",
            visibility: "visible",
          }}
        >
          {!imageLoaded && (
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                left: "calc(50% - 20px)",
                top: `${height / 2 - 20}px`,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <img
            alt={cardState.name || cardState.title || "SolForge Fusion"}
            style={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: height || "100%",
              marginBottom: "-8px",
            }}
            onLoad={() => {
              setImageLoaded(true);
            }}
            src={`https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
              cardState.PK
            }_${parseInt(tabId) + 1}.jpg`}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          ml: -2,
          mr: -2,
          p: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {!isModal ? (
                <Typography
                  variant="caption"
                  color="inherit"
                  sx={{
                    mb: 0,
                    fontFamily: "'Inter'",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19px",
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                  }}
                >
                  {cardState.name || cardState.title}
                </Typography>
              ) : (
                <Box
                  sx={{
                    mb: 0,
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Typography
                      variant="caption"
                      color="inherit"
                      sx={{
                        mb: 0,
                        fontFamily: "'Inter'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "19px",
                        // display: "block",
                        alignItems: "center",
                      }}
                    >
                      {cardState.name || cardState.title}
                    </Typography>
                    <FactionChip faction={cardState.faction} />
                  </Box>
                </Box>
              )}

              <Avatar
                alt={cardState.rarity}
                src={rarities[cardState.rarity]?.[cardState.SK]?.img}
                color="primary"
                sx={{ display: "inline-flex" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                ml: -2,
                mr: -2,
              }}
            >
              <Tabs
                value={tabId}
                indicatorColor="primary"
                variant="fullWidth"
                onChange={handleChange}
              >
                {levels.map((level, index) => {
                  return (
                    <Tab
                      key={index}
                      sx={{ padding: "6px" }}
                      label={
                        <Box sx={{ display: "flex" }}>
                          <Avatar
                            alt={level.name}
                            src={level.image}
                            sx={{
                              borderRadius: "unset",
                              maxHeight: "19px",
                              maxWidth: "19px",
                            }}
                          />
                          <Typography
                            variant="caption"
                            color="inherit"
                            sx={{
                              ml: "4px",
                              mb: 0,
                              fontFamily: "'Inter'",
                              fontStyle: "normal",
                              fontWeight: 800,
                              fontSize: "14px",
                              lineHeight: "17px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {level.name}
                          </Typography>
                        </Box>
                      }
                      {...a11yProps("levels", index)}
                    />
                  );
                })}
              </Tabs>
            </Box>
          </Grid>

          {!isModal && (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "fit-content",
                    backgroundColor: `factions.${cardState.faction?.toLowerCase()}`,
                    color: "#ffffff",
                    borderRadius: "4px",
                    p: 1,
                  }}
                >
                  <Avatar
                    alt={cardState.faction}
                    src={`/images/factions/${cardState.faction?.toLowerCase()}-w.png`}
                    sx={{
                      borderRadius: "unset",
                      maxHeight: "19px",
                      maxWidth: "19px",
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="inherit"
                    sx={{
                      ml: 1,
                      mb: 0,
                      fontFamily: "'Inter'",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "19px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {cardState.faction}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {cardState?.cardType}
                {cardState?.cardSubType && cardState?.cardSubType !== "0"
                  ? ` - ${cardState.cardSubType}`
                  : ""}
              </Grid>
              {cardState?.levels?.[tabId + 1] && (
                <>
                  {Boolean(
                    cardState.levels[tabId + 1].attack ||
                      cardState.levels[tabId + 1].health
                  ) && (
                    <Grid item xs={12}>
                      <img
                        src={`/images/icons/attack.png`}
                        alt={`Attack`}
                        style={{
                          height: "28px",
                          marginBottom: "-4px",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Prometo",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "28px",
                          color: "#92421C",
                        }}
                      >
                        {cardState.levels[tabId + 1].attack}
                      </span>{" "}
                      <img
                        src={`/images/icons/health.png`}
                        alt={`Health`}
                        style={{
                          height: "28px",
                          marginLeft: "8px",
                          marginBottom: "-4px",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Prometo",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "28px",
                          color: "#437530",
                        }}
                      >
                        {cardState.levels[tabId + 1].health}
                      </span>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {cardState.levels[tabId + 1].text &&
                      cardState.levels[tabId + 1].text !== "0" && (
                        <>
                          {cardState.levels[tabId + 1].text
                            .split("\n")
                            .map((i, key) => (
                              <Typography
                                sx={{
                                  fontFamily: "'Inter'",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  minHeight: "0.875rem",
                                }}
                                key={key}
                              >
                                {replaceWithIcons(i)}
                              </Typography>
                            ))}
                        </>
                      )}
                  </Grid>
                </>
              )}

              {Boolean(cardState.faq) && cardState.faq.trim() !== "" && (
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    FAQ
                  </Typography>
                  <Markdown source={cardState?.faq} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

const SolbindCard = (props) => {
  const { height, width, cardState, isModal, index, handleSelect } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  const [tabId, setTabId] = useState(0);
  const handleChange = (event, newTabId) => {
    setTabId(newTabId);
    setImageLoaded(false);
  };
  useEffect(() => {
    setImageLoaded(false);
  }, [cardState]);

  const levels = [
    {
      name: "Level 1",
      image: "/images/cards/levels/lv1-icon.png",
    },
    {
      name: "Level 2",
      image: "/images/cards/levels/lv2-icon.png",
    },
    {
      name: "Level 3",
      image: "/images/cards/levels/lv3-icon.png",
    },
  ];
  let startingLevel = 1
  let isStartingLevel = false;
  for(let index=0; index < levels.length; index++) {
    if(cardState.levels[index+1]) {
      if(isStartingLevel == false) {
        startingLevel = Number(Object.keys(cardState.levels)[0]);
        isStartingLevel = true;
      }
    }
  }
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Box
          sx={{
            width: `${width}px`,
            height: `${height}px`,
            minWidth: `${width}px`,
            maxWidth: "100%",
            maxHeight: "100%",
            visibility: "visible",
          }}
        >
          {!imageLoaded && (
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                left: "calc(50% - 20px)",
                top: `${height / 2 - 20}px`,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <img
            alt={cardState.name || cardState.title || "SolForge Fusion"}
            style={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: height || "100%",
              marginBottom: "-8px",
            }}
            onLoad={() => {
              setImageLoaded(true);
            }}
            src={`https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
              cardState.PK
            }_${parseInt(tabId) + startingLevel}.jpg`}
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          ml: -2,
          mr: -2,
          p: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
              }}
            >
              {!isModal ? (
                <Typography
                  variant="caption"
                  color="inherit"
                  sx={{
                    mb: 0,
                    fontFamily: "'Inter'",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "16px",
                    lineHeight: "19px",
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                  }}
                >
                  {cardState.name || cardState.title}
                </Typography>
              ) : (
                <Box
                  sx={{
                    mb: 0,
                    display: "flex",
                    alignItems: "center",
                    flexGrow: 1,
                  }}
                >
                  <Box sx={{ display: "block" }}>
                    <Typography
                      variant="caption"
                      color="inherit"
                      sx={{
                        mb: 0,
                        fontFamily: "'Inter'",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "16px",
                        lineHeight: "19px",
                        // display: "block",
                        alignItems: "center",
                      }}
                    >
                      {cardState.name || cardState.title}
                    </Typography>
                    <FactionChip faction={cardState.faction} />
                  </Box>
                </Box>
              )}

              <Avatar
                alt={cardState.rarity}
                src={rarities[cardState.rarity]?.[cardState.SK]?.img}
                color="primary"
                sx={{ display: "inline-flex" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box
              sx={{
                ml: -2,
                mr: -2,
              }}
            >
              <Tabs
                value={tabId}
                indicatorColor="primary"
                variant="fullWidth"
                onChange={handleChange}
              >
                {levels.map((level, index) => {
                  if(cardState.levels[index+1]) {
                    return (
                      <Tab
                        key={index}
                        sx={{ padding: "6px" }}
                        label={
                          <Box sx={{ display: "flex" }}>
                            <Avatar
                              alt={level.name}
                              src={level.image}
                              sx={{
                                borderRadius: "unset",
                                maxHeight: "19px",
                                maxWidth: "19px",
                              }}
                            />
                            <Typography
                              variant="caption"
                              color="inherit"
                              sx={{
                                ml: "4px",
                                mb: 0,
                                fontFamily: "'Inter'",
                                fontStyle: "normal",
                                fontWeight: 800,
                                fontSize: "14px",
                                lineHeight: "17px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {level.name}
                            </Typography>
                          </Box>
                        }
                        {...a11yProps("levels", index)}
                      />
                  );
                }
                })}
              </Tabs>
            </Box>
          </Grid>

          {!isModal && (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "fit-content",
                    backgroundColor: `factions.${cardState.faction?.toLowerCase()}`,
                    color: "#ffffff",
                    borderRadius: "4px",
                    p: 1,
                  }}
                >
                  <Avatar
                    alt={cardState.faction}
                    src={`/images/factions/${cardState.faction?.toLowerCase()}-w.png`}
                    sx={{
                      borderRadius: "unset",
                      maxHeight: "19px",
                      maxWidth: "19px",
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="inherit"
                    sx={{
                      ml: 1,
                      mb: 0,
                      fontFamily: "'Inter'",
                      fontStyle: "normal",
                      fontWeight: 700,
                      fontSize: "16px",
                      lineHeight: "19px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {cardState.faction}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {cardState?.cardType}
                {cardState?.cardSubType && cardState?.cardSubType !== "0"
                  ? ` - ${cardState.cardSubType}`
                  : ""}
              </Grid>
              {cardState?.levels?.[tabId + startingLevel] && (
                <>
                  {Boolean(
                    cardState.levels[tabId + startingLevel].attack ||
                      cardState.levels[tabId + startingLevel].health
                  ) && (
                    <Grid item xs={12}>
                      <img
                        src={`/images/icons/attack.png`}
                        alt={`Attack`}
                        style={{
                          height: "28px",
                          marginBottom: "-4px",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Prometo",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "28px",
                          color: "#92421C",
                        }}
                      >
                        {cardState.levels[tabId + startingLevel].attack}
                      </span>{" "}
                      <img
                        src={`/images/icons/health.png`}
                        alt={`Health`}
                        style={{
                          height: "28px",
                          marginLeft: "8px",
                          marginBottom: "-4px",
                        }}
                      />
                      <span
                        style={{
                          fontFamily: "Prometo",
                          fontStyle: "normal",
                          fontWeight: "700",
                          fontSize: "24px",
                          lineHeight: "28px",
                          color: "#437530",
                        }}
                      >
                        {cardState.levels[tabId + startingLevel].health}
                      </span>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    {cardState.levels[tabId + startingLevel].text &&
                      cardState.levels[tabId + startingLevel].text !== "0" && (
                        <>
                          {cardState.levels[tabId + startingLevel].text
                            .split("\n")
                            .map((i, key) => (
                              <Typography
                                sx={{
                                  fontFamily: "'Inter'",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  minHeight: "0.875rem",
                                }}
                                key={key}
                              >
                                {replaceWithIcons(i)}
                              </Typography>
                            ))}
                        </>
                      )}
                  </Grid>
                </>
              )}

              {Boolean(cardState.faq) && cardState.faq.trim() !== "" && (
                <Grid item xs={12}>
                  <Typography variant="h5" gutterBottom>
                    FAQ
                  </Typography>
                  <Markdown source={cardState?.faq} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

const ForgebornCard = (props) => {
  const { height, width, isModal, cardState } = props;

  const [error, setError] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    setError(false);
    setImageLoaded(false);
  }, [cardState]);
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", pb: 2 }}>
        <Box
          sx={{
            width: `${height}px`,
            maxWidth: "100%",
            height: `${width}px`,
            maxHeight: "100%",
            visibility: "visible",
            pb: 2,
          }}
        >
          {!imageLoaded && (
            <Box
              sx={{
                width: "100%",
                position: "absolute",
                left: "calc(50% - 20px)",
                top: `${width / 2 - 20}px`,
              }}
            >
              <CircularProgress />
            </Box>
          )}
          <img
            alt={cardState.name || cardState.title || "SolForge Fusion"}
            style={{
              width: "100%",
              maxWidth: "100%",
              maxHeight: width || "100%",
            }}
            onLoad={() => {
              setImageLoaded(true);
            }}
            onError={() => {
              setError(true);
            }}
            src={
              error
                ? cardState.imageUrl
                : cardState.PK
                ? `https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${cardState.PK.replaceAll(
                    "-",
                    " "
                  )}.jpg`
                : cardState.imageUrl
            }
          />
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "background.paper",
          ml: -2,
          mr: -2,
          p: 2,
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {cardState.name || cardState.title}
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                width: "fit-content",
                backgroundColor: `factions.${cardState.faction?.toLowerCase()}`,
                color: "#ffffff",
                borderRadius: "4px",
                p: 1,
              }}
            >
              <Avatar
                alt={cardState.faction}
                src={`/images/factions/${cardState.faction?.toLowerCase()}-w.png`}
                sx={{
                  borderRadius: "unset",
                  maxHeight: "19px",
                  maxWidth: "19px",
                }}
              />
              <Typography
                variant="caption"
                color="inherit"
                sx={{
                  ml: 1,
                  mb: 0,
                  fontFamily: "'Inter'",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "19px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {cardState.faction}
              </Typography>
            </Box>
          </Grid>
          {!isModal && (
            <>
              <Grid item xs={12}>
                Forgeborn
              </Grid>
              {cardState?.a2n && (
                <ForgebornLevelRow
                  level={2}
                  name={cardState.a2n}
                  text={cardState.a2t}
                />
              )}
              {cardState?.a3n && (
                <ForgebornLevelRow
                  level={3}
                  name={cardState.a3n}
                  text={cardState.a3t}
                />
              )}
              {cardState?.a4n && (
                <ForgebornLevelRow
                  level={4}
                  name={cardState.a4n}
                  text={cardState.a4t}
                />
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

const ForgebornLevelRow = (props) => {
  const { level, name, text } = props;
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs style={{ paddingTop: 2, maxWidth: "70px" }}>
          <Avatar
            alt={`Level ${level}`}
            src={`/images/cards/levels/lv${level}-icon.png`}
            sx={{
              borderRadius: "unset",
              maxHeight: "19px",
              maxWidth: "19px",
            }}
          />
        </Grid>
        <Grid item xs>
          <Typography
            sx={{
              fontFamily: "'Inter'",
              fontWeight: 700,
              fontSize: "16px",
            }}
          >
            {name}
          </Typography>
          {text && text !== "0" && (
            <>
              {text.split("\n").map((i, key) => (
                <Typography
                  sx={{
                    fontFamily: "'Inter'",
                    fontWeight: 400,
                    fontSize: "16px",
                    minHeight: "0.875rem",
                  }}
                  key={key}
                >
                  {replaceWithIcons(i)}
                </Typography>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(key, index) {
  return {
    id: `tab-${key}-${index}`,
    "aria-controls": `tabpanel-${key}-${index}`,
  };
}

function isOdd(num) {
  return num % 2;
}
