import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { Container, Button, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({}));

export default function Dashboard() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  if (!userState.access.isAdmin && !userState.access.isEditor) {
    navigate("/access-denied");
  }

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Admin Dashboard Placeholder
      </Typography>
      <Button
        variant="contained"
        color="primary"
        href="https://www.solforgefusion.com/amplify/adminui"
        target="_blank"
      >
        Amplify Admin UI
      </Button>
    </Container>
  );
}
