import React, { useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Fade,
  Grid,
  IconButton,
  Link,
  LinearProgress,
  Modal,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import { getResizedFileName } from "../utils/UtilityFunctions";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

import MediaItem from "../media/MediaItem";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
}));

export default function MediaSelectModal(props) {
  const classes = useStyles();
  const [mainState, setMainState] = useState({
    loading: false,
    loadMore: false,
    loadMoreLabel: "Load More",
    LastEvaluatedKey: null,
    mediaItems: [],
    existingImage: props.existingImage || null,
  });

  const fetchMedia = async () => {
    setMainState({
      ...mainState,
      loading: true,
      loadMore: false,
    });
    const queryStringParameters = {
      fileType: "Image",
      pageSize: 9,
    };
    if (mainState.LastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey = JSON.stringify(
        mainState.LastEvaluatedKey
      );
    }
    const apiGetData = await API.get("sfwApi", "/media", {
      queryStringParameters: queryStringParameters,
    });

    var allItems = mainState.mediaItems;
    if (apiGetData) {
      if (apiGetData.Items) {
        if (allItems.length === 0 && mainState.existingImage) {
          allItems.push(mainState.existingImage);
        }
        apiGetData.Items.forEach((item) => {
          if (
            !mainState.existingImage ||
            mainState.existingImage.id !== item.id
          ) {
            allItems.push(item);
          }
        });
      }
    }

    setMainState({
      ...mainState,
      loading: false,
      loadMore: apiGetData.LastEvaluatedKey !== undefined,
      loadMoreLabel:
        apiGetData.LastEvaluatedKey !== undefined ? "Load More" : "No More",
      LastEvaluatedKey: apiGetData.LastEvaluatedKey || null,
      mediaItems: allItems,
    });
  };

  function updateMediaItems(item, push) {
    var allItems = mainState.mediaItems;
    if (push) {
      allItems.push(item);
    } else {
      allItems.unshift(item);
    }
    setMainState({
      ...mainState,
      mediaItems: allItems,
    });
  }

  return (
    <Box>
      <Button
        type="button"
        color="primary"
        variant="outlined"
        fullWidth
        onClick={() => {
          props.setOpen(true);
          if (mainState.mediaItems.length <= 1) {
            fetchMedia();
          }
        }}
      >
        {mainState.existingImage ? "Change Image" : "Select Image"}
      </Button>

      <Modal
        disableAutoFocus
        open={props.open}
        onClose={() => {
          props.setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{ zIndex: 99990 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Select Image"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />
            {mainState.loading && <LinearProgress />}
            <CardContent className={classes.cardContent}>
              <Grid container spacing={2}>
                {mainState.mediaItems.map((mediaItem, index) => {
                  return (
                    <Grid item xs={4}>
                      <Link
                        href="#"
                        onClick={() => {
                          props.setFieldValue(props.linkedField, mediaItem);
                          props.setOpen(false);
                        }}
                      >
                        <CardMedia
                          className={classes.cardMedia}
                          image={
                            mediaItem.tempUrl
                              ? mediaItem.tempUrl
                              : mediaItem.fileName && mediaItem.url
                              ? `${mediaItem.url}${getResizedFileName(
                                  mediaItem.fileName,
                                  "medium"
                                )}`
                              : "/images/logo.png"
                          }
                          title={mediaItem.name || "SolForge Fusion"}
                        />
                        <CardContent>
                          {mediaItem.name || "SolForge Fusion"}
                        </CardContent>
                      </Link>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
            <CardActions className={classes.cardActions}>
              <MediaItem updateItems={updateMediaItems} />
              <Button
                onClick={fetchMedia}
                type="button"
                color="primary"
                variant="contained"
                disabled={!mainState.loadMore}
              >
                {mainState.loadMoreLabel}
              </Button>
            </CardActions>
          </Card>
        </Box>
      </Modal>
    </Box>
  );
}
