import { Container, Typography, Grid, IconButton } from "@mui/material";
import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitch } from "@fortawesome/free-brands-svg-icons";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  heading: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexFirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  socialButton: {
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "unset", //alpha(theme.palette.primary.main, 0.05),
    },
  },
}));

const socialLinks = [
  {
    label: "Facebook",
    icon: <FacebookIcon />,
    url: "https://www.facebook.com/StoneBladeEnt/",
  },
  {
    label: "Twitter",
    icon: <TwitterIcon />,
    url: "https://twitter.com/SolforgeFusion",
  },
  {
    label: "Instagram",
    icon: <InstagramIcon />,
    url: "https://www.instagram.com/stone_blade/",
  },
  {
    label: "YouTube",
    icon: <YouTubeIcon />,
    url: "https://www.youtube.com/channel/UC5bMVEUdJyNboIbTIdy2iuQ",
  },
  {
    label: "Twitch",
    icon: <FontAwesomeIcon className="MuiSvgIcon-root" icon={faTwitch} />,
    url: "https://www.twitch.tv/stoneblade",
  },
  {
    label: "Discord",
    icon: <FontAwesomeIcon className="MuiSvgIcon-root" icon={faDiscord} />,
    url: "https://discord.gg/FQnAnrb2Sw",
  },
  // {
  //   label: "Kickstarter",
  //   icon: <FontAwesomeIcon className="MuiSvgIcon-root" icon={faKickstarterK} />,
  //   url: "https://www.kickstarter.com/projects/ascensiontactics/solforge-fusion-hybrid-deck-game",
  // },
];

export default function SocialsGroup(props) {
  const classes = useStyles();
  return (
    <>
      {props.title && props.title !== "" && (
        <Typography
          className={classes.heading}
          gutterBottom
          variant={
            props.titleSize && props.titleSize !== "" ? props.titleSize : "h4"
          }
          component="h2"
        >
          {props.title}
        </Typography>
      )}
      <Container maxWidth="md">
        <Grid container spacing={1} justifyContent="center">
          {socialLinks.map((socialLink, index) => (
            <Grid key={index} item>
              <IconButton
                edge="end"
                aria-label={socialLink.label}
                href={socialLink.url}
                color="inherit"
                target="_blank"
                className={classes.socialButton}
                size="large"
              >
                {socialLink.icon}
              </IconButton>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
