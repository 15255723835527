import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Backdrop, Fade, Modal } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    //backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    //boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  circularProgress: {
    "& circle": {
      fill: "rgba(256,256,256,.6)",
    },
  },
}));

export default function ProgressModal({ progress, open }) {
  const classes = useStyles();
  return (
    <Modal
      disableAutoFocus
      open={open}
      closeAfterTransition
      disableScrollLock
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndedx: 99999,
      }}
    >
      <Fade in={open}>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // position: "absolute",
            // top: "50%",
            // left: "50%",
            // transform: "translate(-50%, -50%)",
            // width: ["95vw", "85vw", 600],
            // maxHeight: "95vh",

            // // bgcolor: "background.paper",
            // border: "1px solid rgba(0, 0, 0, 0.12)",
            // borderRadius: 1,
            // boxShadow: 24,
          }}
        >
          <Box className={classes.modalPaper}>
            <Box position="relative" display="inline-flex">
              {Boolean(!progress && progress !== 0) && (
                <CircularProgress size={100} />
              )}
              {Boolean(progress || progress === 0) && (
                <>
                  <CircularProgress
                    size={100}
                    variant="determinate"
                    value={progress}
                    className={classes.circularProgress}
                  />
                  <Box
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="textSecondary"
                    >{`${Math.round(progress)}%`}</Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

/*export default function ProgressModal() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}*/
