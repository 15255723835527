import { SvgIcon } from "@mui/material";

export default function PiratePeteIcon(props) {
  const { color, ...rest } = props;

  return (
    <SvgIcon {...rest}>
      <svg
        id="a"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 316.34 302.71"
      >
        <g>
          <path
            id="c"
            d="M290.24,.01c-5.6,.1-15.7,4.7-33.9,12.2-6.2,3.1-12.1,6.2-17.8,9.1C216.04,8.41,190.54,1.61,164.64,1.61,81.84,1.61,14.74,69.01,14.74,152.11h0c0,23.3,5.4,46.3,15.8,67.1-6.6,7.7-14.1,12.9-24.5,15.4-7.1,1.7-7.4,2.2-3.7,5.5,13.7,16.3,43.3,24.7,67.8,28.7,26.7,21.9,60.1,33.8,94.6,33.9,22.1-.1,43.9-5,63.8-14.5l23.5,7.7,26.5,2.3,3.7-12.6-4.9-34.2c24-27.4,37.3-62.7,37.4-99.2,0-13.7-1.9-27.4-5.6-40.6,4.7-27.1,6.4-54.6,7.2-79.8-1.4-50.6-12.8-21.3-14.5-16.1-3.1,9.8-4.2,15.7-4.7,3.3,0-13.6-.5-19.2-6.9-19Z"
            style={{ fill: "#e3dac9" }}
          />
          <g id="d">
            <path
              id="e"
              d="M159.64,194.41l18.4,42.8-5.6,5.6,4.8,28.6,74.6,24.6,26.5,2.3,3.7-12.6-6.4-44.5-4,12.7-13.5-14.3-14.3,15.1-5.4,.8-7.9-12.7-12.7,14.3-20.6-9.5-25.5-58.7-12.1,5.5Z"
              style={{ fill: "#000000", fillRule: "evenodd" }}
            />
            <path
              id="f"
              d="M213.84,229.31l10.3,16.7h13.5l23-13.5,17.5,11.1,4-5.6-3.2-7.9-11.1-11.9h-15.1l-19.1,15.9-19.8-4.8Z"
              style={{ fill: "#000000", fillRule: "evenodd" }}
            />
            <path
              id="g"
              d="M205.44,171.61c1.3-6.3,8.5-11.2,17.1-13.8,14.1-1.7,21.8,2.1,34.2,4.8l-6.4,11.1-30.1-6c-5.6,1.3-6.3,3.3-14.8,3.9Z"
              style={{ fill: "#000000", fillRule: "evenodd" }}
            />
            <path
              id="h"
              d="M263.84,161.81c-.2,3.7-5.1,7.4,0,11.1,8.3-2.1,14.7-4.1,23.7-2.5-5.9-5.9-15.6-6-23.7-8.6Z"
              style={{ fill: "#000000", fillRule: "evenodd" }}
            />
            <path
              id="i"
              d="M284.54,161.81l-25.4,7.9c-9.6,11.4-4.5,16.4-21.4,27.8-15.7,5.3-21.9-2.6-27.1-8.7-6.1-.6-43.4,20.3-59.3,27.2l5.8-11.4,51.2-26.2c13.4-13.8,22-11.3,40.9-14.6,11.4-5.2,20.7-7.3,32.1-10.8l3.2,8.8Z"
              style={{ fill: "#6b4328", fillRule: "evenodd" }}
            />
            <path
              id="j"
              d="M172.54,172.11l52.1-18.7c13-4.4,37.3-4.8,45.7-2.7l19,19.8,4.1-28.8c-34.3-23.8-58.6-20.6-97-9.8l-23.9,40.2Z"
              style={{ fill: "#a80f0f", fillRule: "evenodd" }}
            />
            <path
              id="k"
              d="M101.84,299.91l10.3-36.5,5.6-31.7,31-84.2,76.2-29.4c-8,19.8-24.3,40.7-23.8,69-.9-5-2.5-16.1-4-18.2-3.1,6.5-6.5,12.7-5.6,23.8-2.5-8.1-4.7-8.3-11.1-10.3-1.4,18-1.7,29.1-3.2,42.7-14.8-18,.4-24.7-16.6-21-.2,6.1-4.5,20.8-6.4,29.4-4-17.6-12.1-10.2-2.6,20.9,1,5.7,.4,15.2,1.3,25.6-12.6-16.8-7.8-23.2-15.2-27.7-.8,13.4-.5,28.1-1.1,40.5-6.4-9.6-3.6-23.1-10-23.9-9.1,6.7-15.8,18.4-24.8,31h0Z"
              style={{ fill: "#000000", fillRule: "evenodd" }}
            />
            <path
              id="l"
              d="M104.24,272.11c-16.1-.1-79.8-5.6-101.9-32-3.7-3.3-3.4-3.8,3.7-5.5,25.9-6.2,33.2-28.7,56.1-66.1C124.14,81.91,164.64,58.91,256.34,12.21c38.7-16,40.8-18.7,40.7,6.7,.5,12.4,1.6,6.5,4.7-3.3,1.7-5.1,13.1-34.5,14.5,16.1-1.5,50.6-7,110.5-32.5,157.8,1.9-14.8,7.3-34.9,5.6-44.5-10.9-11.7-5.8-14.6-36.5-18.3-55.5,2.7-81.7,38-112.8,77-15.5,34.4-30.1,60.1-35.8,68.4h0Z"
              style={{ fill: "#6b4328", fillRule: "evenodd" }}
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
