import { Button, Avatar, Box, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { useTheme } from "@mui/styles";

export default function FactionButton(props) {
  const { faction, active, sx, ...rest } = props;
  const theme = useTheme();
  return (
    <Button
      sx={{
        color: active ? "#ffffff" : `factions.${faction?.toLowerCase()}`,
        backgroundColor: active
          ? `factions.${faction?.toLowerCase()}`
          : "#ffffff",
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: active ? "#ffffff" : `factions.${faction?.toLowerCase()}`,
        "&:hover": {
          backgroundColor: active
            ? alpha(theme.palette.factions[faction.toLowerCase()], 0.8)
            : null,
        },
        ...sx,
      }}
      {...rest}
    >
      <Avatar
        alt={faction}
        src={`/images/factions/${faction?.toLowerCase()}${
          active ? "-w" : ""
        }.png`}
        sx={{
          borderRadius: "unset",
          maxHeight: "19px",
          maxWidth: "19px",
        }}
      />
      <Typography
        variant="caption"
        color="inherit"
        sx={{
          ml: 1,
          mb: 0,
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {faction}
      </Typography>
    </Button>
  );
}
