import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

import FusedList from "../../components/decks/FusedList";

export default function FusedDecks() {
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  let { userId } = useParams();
  let label = ''

  useEffect(() => {
    const check = async () => {
      if (
        userState &&
        userState.isLoading === false &&
        !userState.isAuthenticated &&
        !userId
      ) {
        navigate("/login");
      }
    };
    check();
  }, [userState]);

  if (
    userState &&
    !userState.user &&
    userId
    ) {
    userState.user = {};
    userState.user.username = userId;
    const capitalUserId = userId.charAt(0).toUpperCase() + userId.slice(1)
    label = capitalUserId + "'s Fused Decks"
  } else if(userState) {
    userId = userState.user.username;
    label = "My Fused Decks";
  }

  return (
    <>
      <FusedList
        label={label}
        userState={userState}
        username={userState && userState.user && userState.user.username}
      />
    </>
  );
}
