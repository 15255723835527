import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { API } from "aws-amplify";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FaSearch } from "react-icons/fa";

import SearchIcon from "@mui/icons-material/Search";

import { Formik } from "formik";
import * as Yup from "yup";

import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { useTheme } from "@mui/styles";
import { UserContext } from "../../context/UserContext";
import { handleBPCPaging, cleanLocation, getDistance } from "./BCPUtilities";
import LoadingModal from "../modals/LoadingModal";
import ListResponsive from "../layout/ListResponsive";
import { postNotification } from "../utils/Notifications";
import GenericPicker from "../utils/GenericPicker";
import GoogleAddressSearch from "./GoogleAddressSearch";
import OrganizationCard from "./OrganizationCard";

const libraries = ["places"];
const searchLimit = 100;

export default function OrganizationSearch(props) {
  const { organizationType } = props;
  const theme = useTheme();
  const userState = useContext(UserContext);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  var initalDistanceType = "miles";
  if (userState.cookieConsent) {
    var getGetCachedData = localStorage.getItem(`distanceType`);
    if (getGetCachedData) {
      initalDistanceType = getGetCachedData;
    }
  }
  const [distanceType, setDistanceType] = useState(initalDistanceType);

  const [searchReady, setSearchReady] = useState(false);
  const [showSearch, setShowSearch] = useState(true);
  const [searchValues, setSearchValues] = useState({
    location: "Global",
    selectedAddress: "",
    selectedLocation: null,
    distance: 50,
    searchString: "",
  });

  const [searchLocation, setSearchLocation] = useState(null);
  const [geoLocation, setGeoLocation] = useState(null);
  // const [locationOpen, setLocationOpen] = useState(false);
  // const [locationSearchAvailable, setLocationSearchAvailable] = useState(false);

  const [organizations, setOrganizations] = useState(null);
  const [filteredOrganizations, setFilteredOrganizations] = useState(null);
  const [activeOrganization, setActiveOrganization] = useState(null);
  const [isSearching, setIsSearching] = useState(null);

  const [center, setCenter] = useState({
    lat: 41.850033,
    lng: -87.6500523,
  });

  const mapRef = useRef();
  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = useCallback(({ lat, lng }) => {
    setCenter({ lat, lng });

    if (mapRef.current) {
      mapRef.current.panTo({ lat, lng });
      mapRef.current.setZoom(13);
    }
  }, []);

  useEffect(() => {
    const check = async () => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(function (position) {
          setGeoLocation(position.coords);
          // setLocationSearchAvailable(true);
        });
      } else {
        setSearchValues({
          ...searchValues,
          location: "Global",
        });
      }

      setSearchReady(true);
    };
    check();
  }, []);

  useEffect(() => {
    if (!isLoaded) return;

    panTo({
      lat:
        searchLocation?.center?.lat !== undefined
          ? searchLocation?.center?.lat
          : center.lat,
      lng:
        searchLocation?.center?.long !== undefined
          ? searchLocation?.center?.long
          : center.lng,
    });
  }, [props, isLoaded]);

  if (loadError) return "Error loading maps";
  if (!isLoaded) return <></>;

  return (
    <Box sx={{ width: "100%" }}>
      <Container
        sx={{
          pt: 2,
          pb: 2,
        }}
        maxWidth="lg"
      >
        <Grid container sx={{ gap: 2 }}>
          <Grid item xs={12}>
            <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
              Find A Store
            </Typography>
          </Grid>
          {showSearch && (
            <Grid item xs={12}>
              <Formik
                initialValues={searchValues}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  searchString: Yup.string()
                    .min(3, "Minimum 3 Characters!")
                    .max(255, "Maximum 255 Characters!"),
                })}
                onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                  try {
                    setIsSearching(true);
                    setOrganizations(null);
                    setFilteredOrganizations(null);
                    var filters = {};

                    if (organizationType !== undefined) {
                      filters.organizationType = organizationType;
                    }

                    var location;

                    if (values.location === "Current Location") {
                      location = {
                        distance: values.distance,
                        center: {
                          lat: geoLocation.latitude,
                          long: geoLocation.longitude,
                        },
                      };
                    } else if (
                      values.location !== "Global" &&
                      values.selectedLocation?.coordinate?.length === 2
                    ) {
                      location = {
                        distance: values.distance,
                        center: {
                          lat: values.selectedLocation.coordinate[1],
                          long: values.selectedLocation.coordinate[0],
                        },
                      };
                    }

                    if (location) {
                      if (distanceType === "kms") {
                        // convert to miles
                        location.distance = location.distance * 0.6214;
                      }
                      filters.location = JSON.stringify(location);
                      setSearchLocation(location);
                    }

                    if (values.searchString?.length > 0) {
                      filters.searchString = values.searchString.toLowerCase();
                    }

                    var apiGetData = await handleBPCPaging("/gamestores", {
                      limit: 100,
                      ...filters,
                    });
                    if (apiGetData.length === 0) {
                      postNotification(
                        "info",
                        "No stores found matching your search criteria."
                      );
                      setIsSearching(false);
                      setShowSearch(true);
                      return;
                    } else {
                      apiGetData.forEach((organization) => {
                        if (organization.location?.coordinate) {
                          if (location?.center) {
                            organization.distanceType = distanceType;
                            organization.distance = getDistance(
                              organization.location.coordinate[1],
                              organization.location.coordinate[0],
                              {
                                latitude: location.center.lat,
                                longitude: location.center.long,
                              }
                            );

                            if (distanceType === "kms") {
                              // convert to kms
                              organization.distance =
                                organization.distance / 0.6214;
                            }
                          }
                        }
                      });

                      apiGetData.sort((a, b) => {
                        if (
                          a.distance !== undefined &&
                          b.distance !== undefined
                        ) {
                          return a.distance - b.distance;
                        }
                        if (a.distance !== undefined) {
                          return 1;
                        }
                        if (b.distance !== undefined) {
                          return -1;
                        }
                        return 0;
                      });
                    }

                    if (location) {
                      panTo({
                        lat:
                          location?.center?.lat !== undefined
                            ? location?.center?.lat
                            : center.lat,
                        lng:
                          location?.center?.long !== undefined
                            ? location?.center?.long
                            : center.lng,
                      });
                    }

                    setOrganizations(apiGetData);
                    setFilteredOrganizations(apiGetData);
                    setIsSearching(false);
                    setShowSearch(false);
                  } catch (error) {
                    postNotification("error", error);
                    setIsSearching(false);
                    //setSubmitting(false);
                    // setLoading(false);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  touched,
                  values,
                  setFieldValue,
                  submitForm,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <GoogleAddressSearch
                          label="Enter Town or ZIP Code *"
                          sx={{
                            marginTop: "16px",
                            marginBottom: "8px",

                            "& input": {
                              border: "0",
                              background: "none",
                              height: "unset !important",
                              lineHeight: "21px",
                              margin: "0",
                              display: "block",
                              animationDuration: "10ms",
                              padding: "16.5px 14px !important",
                              borderColor: `rgba(0, 0, 0, 0.23) !important`,
                              "&:focus-visible": {
                                outlineColor: `${theme.palette.primary.main} !important`,
                              },
                            },
                          }}
                          setLocationData={(e) => {
                            setFieldValue(
                              "selectedAddress",
                              e?.formatted_address ? e.formatted_address : ""
                            );
                            setFieldValue(
                              "location",
                              e?.formatted_address
                                ? e.formatted_address
                                : "Current Location"
                            );
                            setFieldValue("selectedLocation", e);
                            // setLocationOpen(false);
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          name="distance"
                          label={`Distance (in ${distanceType})`}
                          value={values.distance}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.distance && errors.distance)}
                          helperText={touched.distance && errors.distance}
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <GenericPicker
                                  inclAny={false}
                                  list={["miles", "kms"]}
                                  value={distanceType}
                                  formControlSX={{
                                    p: 0,
                                    mt: 0,
                                    mb: 0,
                                    "& fieldset": {
                                      borderWidth: 0,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      border: 0,
                                    },
                                    "& .MuiSelect-select": {
                                      p: "4px",
                                    },
                                  }}
                                  handleChange={(e) => {
                                    setDistanceType(e.target.value);
                                    if (userState.cookieConsent) {
                                      localStorage.setItem(
                                        "distanceType",
                                        e.target.value
                                      );
                                      var getCachedItems =
                                        localStorage.getItem("CachedItems");
                                      var cachedItems = getCachedItems
                                        ? JSON.parse(getCachedItems)
                                        : {};
                                      cachedItems["distanceType"] = true;
                                      localStorage.setItem(
                                        "CachedItems",
                                        JSON.stringify(cachedItems)
                                      );
                                    }
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          name="searchString"
                          label="Store Name (Optional)"
                          value={values.searchString}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(
                            touched.searchString && errors.searchString
                          )}
                          helperText={
                            touched.searchString && errors.searchString
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Button
                          type="submit"
                          color={"primary"}
                          variant={"contained"}
                          fullWidth
                          startIcon={<SearchIcon />}
                          // sx={{ height: "56px" }}
                        >
                          Search
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Formik>
            </Grid>
          )}
          {isSearching !== null && !showSearch && (
            <>
              <Grid
                item
                xs={12}
                sx={
                  {
                    // p: 2,
                    // borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    // borderRadius: 1,
                    // boxShadow: 1,
                    // backgroundColor: "common.white",
                  }
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      sx={{
                        "& .MuiOutlinedInput-input": {
                          padding: 1,
                        },
                        "& .MuiOutlinedInput-adornedEnd": {
                          paddingRight: 0,
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <FaSearch />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search Results"
                      variant="outlined"
                      onChange={(e) => {
                        // performSearch(e.target.value.toLowerCase());
                        // setCurrentSearch(e.target.value.toLowerCase());
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      onClick={() => setShowSearch(true)}
                      startIcon={<SearchIcon />}
                    >
                      New Search
                    </Button>
                  </Grid>

                  {organizations?.length > 0 && (
                    <Grid item xs={12}>
                      <GoogleMap
                        id="map"
                        mapContainerStyle={{
                          width: "100%",
                          height: "50vh",
                        }}
                        zoom={5}
                        center={center}
                        options={{
                          disableDefaultUI: true,
                          zoomControl: true,
                        }}
                        onLoad={onMapLoad}
                      >
                        {organizations.map((organization, index) => {
                          if (!organization.location?.coordinate?.length) {
                            return <></>;
                          }

                          return (
                            <Marker
                              key={index}
                              position={{
                                lat: organization.location.coordinate[1],
                                lng: organization.location.coordinate[0],
                              }}
                              onClick={(e) => {
                                setActiveOrganization(organization);
                              }}
                            />
                          );
                        })}
                      </GoogleMap>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  p: 2,
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: 1,
                  boxShadow: 1,
                  backgroundColor: "common.white",
                }}
              >
                <ListResponsive
                  originalList={!!isSearching ? null : filteredOrganizations}
                  doPages
                  initialRowsPerPage={24}
                  rowsPerPageArray={[
                    12,
                    24,
                    48,
                    96,
                    { label: "All", value: -1 },
                  ]}
                  rowProps={{
                    mapFunction: (row, index) => (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{ p: 1 }}
                      >
                        <OrganizationCard organization={row} key={index} />
                      </Grid>
                    ),
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        {activeOrganization && (
          <Dialog
            open={true}
            onClose={() => {
              setActiveOrganization(null);
            }}
          >
            <DialogTitle>{`${activeOrganization.name}${
              activeOrganization.distance !== undefined
                ? ` (${
                    activeOrganization.distance > 10
                      ? parseInt(activeOrganization.distance)
                      : activeOrganization.distance.toFixed(2)
                  } Miles)`
                : ""
            }`}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography gutterBottom>
                  <Link
                    underline="none"
                    href={`https://google.com/maps/place/${
                      activeOrganization.location?.streetNum || ""
                    } ${activeOrganization.location?.streetName || ""} ${
                      activeOrganization.location?.city
                    } ${activeOrganization.location?.zip || ""} ${
                      activeOrganization.location?.state || ""
                    } ${activeOrganization.location?.country || ""}`.trim()}
                    target="_blank"
                  >
                    <Box
                      sx={{
                        minWidth: "35px",
                        verticalAlign: "bottom",
                        display: "inline-flex",
                      }}
                    >
                      <Tooltip title="Location">
                        <LocationOnIcon
                          sx={{
                            color: "text.secondary",
                            verticalAlign: "bottom",
                          }}
                        />
                      </Tooltip>
                    </Box>
                    {cleanLocation(activeOrganization.location)}
                  </Link>
                </Typography>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        )}
      </Container>
      {(!!isSearching || !searchReady) && <LoadingModal />}
    </Box>
  );
}
