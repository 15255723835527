import React, { useState } from "react";
import {
  Box,
  Button,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export default function CustomMenuItem(props) {
  const { id, label, href, target, children, onClick, MenuIcon } = props;
  const [menu, setMenu] = useState(null);
  const toggleMenu = (e) => {
    if (onClick) {
      onClick(e);
      return;
    }
    if (children) {
      if (Boolean(menu)) setMenu(null);
      else setMenu(e.currentTarget);
    }
  };
  const closeMenu = () => {
    setMenu(null);
  };
  return (
    <>
      <Box>
        <Button
          color="inherit"
          noWrap
          key={id}
          variant="body2"
          href={href}
          target={target ? target : ""}
          onClick={toggleMenu}
          sx={{
            minWidth: "unset",
            padding: 0,
            fontSize: "14px",
            fontWeight: "700",
            fontFamily: '"Prometo", sans-serif',
            textTransform: "uppercase",
            "@media (max-width: 1100px)": {
              fontSize: "13px",
            },
            "@media (max-width: 1000px)": {
              fontSize: "12px",
            },
            "@media (max-width: 950px)": {
              fontSize: "11px",
            },

            // "@media (max-width: 1100px)": {
            //   fontSize: "10px",
            // },
            "&:hover": {
              color: "primary.main",
              // filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
              //   theme.palette.primary.main
              // })`,
              textDecoration: "none",
            },
            "& svg": {
              "@media (max-width: 1100px)": {
                fontSize: "1rem",
              },
            },
          }}
        >
          {MenuIcon}
          {label}
          {children && Boolean(menu) && <ArrowDropUpIcon />}
          {children && !Boolean(menu) && <ArrowDropDownIcon />}
        </Button>
      </Box>
      {children && (
        <Menu
          id="profile-menu"
          open={Boolean(menu)}
          anchorEl={menu}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={closeMenu}
          disableAutoFocusItem
          disableScrollLock
          sx={{ mt: 2 }}
        >
          {children.map((child, index) => {
            return (
              <Link
                key={index}
                sx={{
                  textDecoration: "none",
                  "&:hover": { textDecoration: "none" },
                }}
                {...child}
              >
                <MenuItem
                  sx={{
                    color: "text.hint",
                  }}
                >
                  {child.MenuIcon && (
                    <ListItemIcon
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: "1.25rem",
                          color: "primary.main",
                        },
                      }}
                    >
                      {child.MenuIcon}
                    </ListItemIcon>
                  )}
                  <ListItemText>{child.label}</ListItemText>
                </MenuItem>
              </Link>
            );
          })}
        </Menu>
      )}
    </>
  );
}
