import { toast } from "react-toastify";

export const Notification = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default function processUrlMessages(
  params,
  postNotification,
  navigate,
  route
) {
  var needsNavigate = false;
  var messages = ["success", "info", "error", "warning"];
  messages.forEach(function (message) {
    if (params.has(message)) {
      postNotification(message, params.get(message));
      params.delete(message);
      needsNavigate = true;
    }
  });
  if (needsNavigate) {
    navigate(
      route + (params.toString() !== "" ? "?" + params.toString() : ""),
      {
        replace: false,
      }
    );
  }
}

export function postNotification(toastType, toastMessage, notification=Notification) {
  var message =
    typeof toastMessage === "object"
      ? toastMessage.response?.data?.errorMessage ||
        toastMessage.response?.data?.error ||
        toastMessage.response?.data ||
        toastMessage.message ||
        toastMessage
      : toastMessage;
  if (toastType === "info") {
    toast.info(message, notification);
  } else if (toastType === "success") {
    toast.success(message, notification);
  } else if (toastType === "warning") {
    toast.warning(message, notification);
  } else if (toastType === "error") {
    toast.error(message, notification);
  } else if (toastType === "dark") {
    toast.dark(message, notification);
  } else {
    toast(message, notification);
  }
}
