import React, { useState, useEffect } from "react";
import ProgressModal from "../../components/modals/ProgressModal";
import MediaSelectModal from "../../components/modals/MediaSelectModal";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { getResizedFileName } from "../../components/utils/UtilityFunctions";
import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  preview: {
    maxWidth: "100%",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
}));

export default function FactionItem(props) {
  const classes = useStyles();

  const initialValues = {
    id: null,
    name: "",
    myFeaturedImage: null,
  };
  const [faction, setFaction] = useState(initialValues);

  const validationSchema = {
    name: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
  };

  const [open, setOpen] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);
  const [featuredOpen, setFeaturedOpen] = useState(false);

  useEffect(() => {
    const check = async () => {
      if (props.factionId) {
        const apiGetData = await API.get(
          "sfwApi",
          "/faction/" + props.factionId
        );
        //("apiGetData", apiGetData);
        //setAvatar({ ...avatar, ...apiGetData });
      }
      setOpen(false);
    };

    check();
  }, [props.factionId]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="button"
        color={props.color || "secondary"}
        variant={props.variant || "contained"}
      >
        Add Faction
      </Button>
      <Modal
        disableAutoFocus
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ zIndex: 99990 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Add Faction"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />

            <ProgressModal open={progressOpen} />
            <Formik
              initialValues={faction}
              enableReinitialize={true}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                try {
                  setProgressOpen(true);
                  if (
                    values.myFeaturedImage &&
                    values.myFeaturedImage.url &&
                    values.myFeaturedImage.fileName
                  ) {
                    var postBody = {
                      name: values.name,
                      imageUrl:
                        values.myFeaturedImage.url +
                        values.myFeaturedImage.fileName,
                    };
                    var vFactionId = values.id;

                    // console.log("postBody", postBody);

                    var apiPostData = await API.post(
                      "sfwApi",
                      `/faction${vFactionId ? "/" + vFactionId : ""}`,
                      {
                        body: postBody,
                      }
                    );
                    if (apiPostData.data) {
                      apiPostData.data.myFeaturedImage = {
                        ...apiPostData.data.myFeaturedImage,
                        ...values.myFeaturedImage,
                      };
                      props.updateItems(apiPostData.data, false);

                      if (
                        !props.factionId &&
                        apiPostData.data &&
                        apiPostData.data.id
                      ) {
                        setFieldValue("id", apiPostData.data.id);
                      }
                      setOpen(false);
                      postNotification(
                        "success",
                        `Faction ${props.factionId ? "updated" : "created"}`
                      );
                    } else if (apiPostData.error) {
                      postNotification("error", apiPostData.error);
                    }
                  } else {
                    postNotification("error", "Please select a Media Item");
                  }

                  setSubmitting(false);
                  setProgressOpen(false);
                } catch (error) {
                  postNotification(
                    "error",
                    error.message || JSON.stringify(error)
                  );
                  setSubmitting(false);
                  setProgressOpen(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Name"
                          margin="normal"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      {values.myFeaturedImage &&
                        values.myFeaturedImage.fileName &&
                        values.myFeaturedImage.url && (
                          <Grid item xs={12}>
                            <img
                              src={`${
                                values.myFeaturedImage.url
                              }${getResizedFileName(
                                values.myFeaturedImage.fileName,
                                "medium"
                              )}`}
                              alt={values.name}
                              className={classes.preview}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <MediaSelectModal
                          open={featuredOpen}
                          setOpen={setFeaturedOpen}
                          existingImage={values.myFeaturedImage}
                          setFieldValue={setFieldValue}
                          linkedField="myFeaturedImage"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button type="submit" color="primary" variant="contained">
                      Save
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      </Modal>
    </>
  );
}
