import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Typography, Box, Grid, Link } from "@mui/material";

import MainPageHeader from "../components/layout/MainPageHeader";
import LoadingModal from "../components/modals/LoadingModal";
import { UserContext } from "../context/UserContext";
import PiratePeteIcon from "../components/icons/PiratePeteIcon";
import OrganizationSearch from "../components/bcp/OrganizationSearch";
import { fetchBCPFile, handleBPCPaging } from "../components/bcp/BCPUtilities";
import { getEnv } from "../components/utils/UtilityFunctions";

const env = getEnv();

export default function Products() {
  const seo = {
    title: "Products | SolForge Fusion",
    description: "SolForge Fusion - the next generation of card games",
    image: "https://solforgefusion.com/images/icon-200x200.jpg",
  };
  var userState = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState(null);
  const fetchProducts = async () => {
    try {
      var apiProducts = await handleBPCPaging(`/products`, {
        limit: 100,
      });
      console.log(apiProducts);
      setProducts(apiProducts);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const go = async () => {
      await fetchProducts();
      setLoading(false);
    };
    go();
  }, []);
  return (
    <>
      <Helmet
        title={seo.title}
        description={seo.description}
        meta={[
          {
            name: "description",
            property: "og:description",
            content: seo.description,
          },
          { property: "og:title", content: seo.title },
          { property: "og:image", content: seo.image },
          { property: "og:image:type", content: "image/jpeg" },
          { property: "twitter:image:src", content: seo.image },
          { property: "twitter:title", content: seo.title },
          { property: "twitter:description", content: seo.description },
        ]}
      />
      <MainPageHeader title={"Store Locator"} />
      {loading && <LoadingModal />}
      {!loading && (
        <Box sx={{ width: "100%" }}>
          <OrganizationSearch organizationType="store" />
          <Container sx={{ pt: "60px", pb: "60px" }} maxWidth="lg">
            <Grid container spacing={4}>
              {products?.length > 0 &&
                products.map((product, index) => {
                  return <ProductCard key={index} product={product} />;
                })}
            </Grid>
            {(userState?.user?.isAdmin || userState?.access?.isAdmin) && (
              <Box sx={{ textAlign: "right", mt: 2 }}>
                <Link
                  href={`${
                    env === "main"
                      ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                      : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
                  }/admin/shop/products`}
                  sx={{ textDecoration: "none" }}
                >
                  <PiratePeteIcon fontSize="small" /> Products Admin
                </Link>
              </Box>
            )}
          </Container>
        </Box>
      )}
    </>
  );
}

const ProductCard = (props) => {
  const {
    // theme,
    product,
  } = props;
  const [image, setImage] = useState(null);
  useEffect(() => {
    const go = async () => {
      setImage(null);
      if (product?.imageFileId) {
        try {
          var apiImage = await fetchBCPFile(product?.imageFileId);
          setImage(apiImage);
        } catch (err) {
          console.log(err);
        }
      }
    };
    go();
  }, [product]);
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxShadow: 1,
        }}
      >
        <Box
          sx={{
            background: `url(${image?.url})`,
            backgroundSize: "cover",
            backgroundColor: "#E6E6E6",
            width: "100%",
            pb: "100%",
            borderRadius: "4px 4px 0px 0px",
          }}
        />
        <Box sx={{ width: "100%", p: 1 }}>
          <Typography gutterBottom variant="h5" component="h2">
            {product?.name}
          </Typography>
          {product?.description &&
            product?.description !== "" &&
            product?.description.split("\n").map((row, index) => {
              if (row.trim() === "") {
                return (
                  <Box key={index} sx={{ width: "100%", height: "5px" }} />
                );
              }
              return (
                <Typography
                  key={index}
                  paragraph
                  variant="body1"
                  sx={{ mb: 0 }}
                >
                  {row}
                </Typography>
              );
            })}
          {product?.externalLinks?.length > 0 &&
            product.externalLinks.map((externalLink, index) => {
              return (
                <Link
                  key={index}
                  sx={{ mb: 0 }}
                  href={externalLink.href}
                  target="_blank"
                >
                  {externalLink.label}
                </Link>
              );
            })}
        </Box>
      </Box>
    </Grid>
  );
};
