import React, { useState, useEffect } from "react";
import ProgressModal from "../../components/modals/ProgressModal";
import MediaSelectModal from "../../components/modals/MediaSelectModal";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  IconButton,
  Modal,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { getResizedFileName } from "../../components/utils/UtilityFunctions";
import * as Yup from "yup";
import { FieldArray, Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

import CustomChip from "../../components/utils/CustomChip";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  preview: {
    maxWidth: "100%",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
}));

export default function AccountTagItem(props) {
  const classes = useStyles();

  const initialValues = {
    id: null,
    name: "",
    order: 9999,
    levels: [
      {
        label: "",
        color: "#000000",
        background: "#FFFFFF",
        border: "#000000",
        points: 0,
        manual: true,
      },
    ],
    // ...props.accountTag,
  };
  const [accountTag, setAccountTag] = useState(initialValues);

  const validationSchema = {
    name: Yup.string().max(255, "Too Long").required("Required"),
    order: Yup.number().min(0).required("Required"),
    levels: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().max(255, "Too Long").required("Required"),
        color: Yup.string()
          .required("Required")
          .matches(
            /^#[0-9A-F]{6}$/,
            "Must be a color hex beginning with #, eg. #020202"
          ),
        background: Yup.string()
          .required("Required")
          .matches(
            /^#[0-9A-F]{6}$/,
            "Must be a color hex beginning with #, eg. #020202"
          ),
        border: Yup.string()
          .required("Required")
          .matches(
            /^#[0-9A-F]{6}$/,
            "Must be a color hex beginning with #, eg. #020202"
          ),
        points: Yup.number()
          .min(0)
          .test(
            "oneOfRequired",
            "You must select Manual or assign a number of points",
            function (item) {
              return this.parent.manual || this.parent.points > 0;
            }
          ),
        manual: Yup.boolean().test(
          "oneOfRequired",
          "You must select Manual or assign a number of points",
          function (item) {
            return this.parent.manual || this.parent.points > 0;
          }
        ),
      })
    ),
  };

  const [open, setOpen] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);

  useEffect(() => {
    const check = async () => {
      setAccountTag({ ...initialValues });
      if (props.accountTagId) {
        setOpen(true);
        setProgressOpen(true);
        const apiGetData = await API.get(
          "sfwApi",
          "/accountTag/" + props.accountTagId
        );
        if (apiGetData.order !== undefined) {
          apiGetData.order = parseInt(apiGetData.order);
        }
        setAccountTag({ ...initialValues, ...apiGetData });
        setProgressOpen(false);
      } else {
        setOpen(false);
      }
    };

    check();
  }, [props.accountTagId]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="button"
        color={props.color || "secondary"}
        variant={props.variant || "contained"}
      >
        {props.accountTagId ? "Edit" : "Add"} Account Tag
      </Button>

      <Modal
        disableAutoFocus
        open={open}
        onClose={() => {
          props.setAccountTag(null);
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{ zIndex: 9999 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Add Account Tag"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    props.setAccountTag(null);
                    setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />

            <Formik
              initialValues={accountTag}
              enableReinitialize={true}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                try {
                  setProgressOpen(true);

                  var postBody = {
                    ...values,
                  };
                  delete postBody.id;
                  var vAccountTagId = values.id;

                  var apiPostData = await API.post(
                    "sfwApi",
                    `/accountTag${vAccountTagId ? "/" + vAccountTagId : ""}`,
                    {
                      body: postBody,
                    }
                  );
                  if (apiPostData.data) {
                    props.updateItems(apiPostData.data, false);

                    props.setAccountTag(null);
                    setOpen(false);
                    postNotification(
                      "success",
                      `Account Tag ${
                        props.accountTagId ? "updated" : "created"
                      }`
                    );
                  } else if (apiPostData.error) {
                    postNotification("error", apiPostData.error);
                  }

                  setSubmitting(false);
                  setProgressOpen(false);
                } catch (error) {
                  postNotification(
                    "error",
                    error.message || JSON.stringify(error)
                  );
                  setSubmitting(false);
                  setProgressOpen(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          required
                          helperText={touched.name && errors.name}
                          label="Name"
                          margin="normal"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.order && errors.order)}
                          fullWidth
                          required
                          helperText={touched.order && errors.order}
                          label="Order"
                          margin="normal"
                          name="order"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.order}
                          color="secondary"
                          variant="outlined"
                          type="number"
                          inputProps={{
                            min: 0,
                          }}
                        />
                      </Grid>
                      <FieldArray
                        name="levels"
                        render={(arrayHelpers) => (
                          <>
                            {values.levels?.length > 0 &&
                              values.levels.map((level, index) => {
                                return (
                                  <Grid
                                    key={index}
                                    item
                                    xs={12}
                                    sx={{
                                      borderTop:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                      borderBottom:
                                        "1px solid rgba(0, 0, 0, 0.12)",
                                    }}
                                  >
                                    <Grid
                                      container
                                      spacing={1}
                                      sx={{ mb: 2 }}
                                      alignItems="center"
                                    >
                                      <Grid item xs={12}>
                                        <Typography gutterBottom>
                                          Level {index + 1}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          name={`levels[${index}].label`}
                                          variant="outlined"
                                          fullWidth
                                          margin="normal"
                                          label="Label"
                                          error={Boolean(
                                            touched.levels?.[index]?.label &&
                                              errors.levels?.[index]?.label
                                          )}
                                          helperText={
                                            touched.levels?.[index]?.label &&
                                            errors.levels?.[index]?.label
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.levels[index].label}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          name={`levels[${index}].color`}
                                          variant="outlined"
                                          fullWidth
                                          margin="normal"
                                          label="Text Color"
                                          error={Boolean(
                                            touched.levels?.[index]?.color &&
                                              errors.levels?.[index]?.color
                                          )}
                                          helperText={
                                            touched.levels?.[index]?.color &&
                                            errors.levels?.[index]?.color
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.levels[index].color}
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          name={`levels[${index}].background`}
                                          variant="outlined"
                                          fullWidth
                                          margin="normal"
                                          label="Background Color"
                                          error={Boolean(
                                            touched.levels?.[index]
                                              ?.background &&
                                              errors.levels?.[index]?.background
                                          )}
                                          helperText={
                                            touched.levels?.[index]
                                              ?.background &&
                                            errors.levels?.[index]?.background
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={
                                            values.levels[index].background
                                          }
                                        />
                                      </Grid>
                                      <Grid item xs={12}>
                                        <TextField
                                          name={`levels[${index}].border`}
                                          variant="outlined"
                                          fullWidth
                                          margin="normal"
                                          label="Border Color"
                                          error={Boolean(
                                            touched.levels?.[index]?.border &&
                                              errors.levels?.[index]?.border
                                          )}
                                          helperText={
                                            touched.levels?.[index]?.border &&
                                            errors.levels?.[index]?.border
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.levels[index].border}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <Typography gutterBottom>
                                          Preview:
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <CustomChip
                                          label={values.levels[index].label}
                                          wrapperSX={{
                                            background:
                                              values.levels[index].background,
                                            color: values.levels[index].color,
                                            border: `2px solid ${values.levels[index].border}`,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              name={`levels[${index}].manual`}
                                              checked={
                                                values.levels[index].manual
                                              }
                                              defaultChecked={
                                                values.levels[index].manual
                                              }
                                              onChange={handleChange}
                                            />
                                          }
                                          label="Manual Assignment"
                                        />

                                        {Boolean(
                                          touched.levels?.[index]?.manual &&
                                            errors.levels?.[index]?.manual
                                        ) && (
                                          <FormHelperText error>
                                            {errors.levels?.[index]?.manual}
                                          </FormHelperText>
                                        )}
                                      </Grid>
                                      <Grid item xs={12} sm={6}>
                                        <TextField
                                          name={`levels[${index}].points`}
                                          variant="outlined"
                                          fullWidth
                                          margin="normal"
                                          label="Points"
                                          error={Boolean(
                                            touched.levels?.[index]?.points &&
                                              errors.levels?.[index]?.points
                                          )}
                                          helperText={
                                            touched.levels?.[index]?.points &&
                                            errors.levels?.[index]?.points
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.levels[index].points}
                                          type="number"
                                          inputProps={{
                                            min: 0,
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} textAlign="right">
                                        <Button
                                          onClick={(e) => {
                                            arrayHelpers.remove(index);
                                          }}
                                          type="button"
                                          color="error"
                                          variant="contained"
                                        >
                                          Delete
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            <Grid
                              item
                              xs={12}
                              sx={{
                                borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                              }}
                            >
                              <Button
                                onClick={(e) => {
                                  arrayHelpers.push({
                                    label: `Level ${values.levels?.length + 1}`,
                                    color: "#000000",
                                    background: "#FFFFFF",
                                    border: "#000000",
                                    points: 0,
                                    manual: true,
                                  });
                                }}
                                fullWidth
                                type="button"
                                color="success"
                                variant="contained"
                              >
                                Add Tag
                              </Button>
                            </Grid>
                          </>
                        )}
                      />
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button
                      fullWidth
                      type="submit"
                      color="primary"
                      variant="contained"
                    >
                      Save
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      </Modal>
      <ProgressModal open={progressOpen} />
    </>
  );
}
