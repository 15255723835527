import React, { useEffect, useState } from "react";

import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
  Alert,
  Tooltip,
  Stack,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";

import ShareIcon from "@mui/icons-material/Share";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from "@mui/icons-material/Download";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import ProgressModal from "../../components/modals/ProgressModal";

import { alpha } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";
import { copyShareDeckLink } from "../../components/utils/UtilityFunctions"

import { API, Auth } from "aws-amplify";
import ScanDeck from "./ScanDeck";
import MainPageHeader from "../layout/MainPageHeader";
import LoadingModal from "../modals/LoadingModal";
import ListResponsive from "../layout/ListResponsive";
import FactionChip from "../utils/FactionChip";
import FactionButton from "../utils/FactionButton";

import RankChip from "../utils/RankChip";
import CustomChip from "../utils/CustomChip";
import { customAPIHeader } from "../utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 500,
  },
  tableRow: {},
  tableCell: {
    textDecoration: "none",
    padding: theme.spacing(1),
    minHeight: "84px",
  },
  previewImg: { maxHeight: "80px", maxWidth: "150px" },
  accordionRoot: {
    marginBottom: theme.spacing(2),
    "& .Mui-expanded": {
      margin: `${theme.spacing(1)} 0`,
      maxHeight: "48px",
      minHeight: "unset",
    },
  },
  accordionHeading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  accordionDetails: {
    paddingTop: 0,
  },
  textField: {
    marginTop: theme.spacing(1),
  },
  filterButtonSelected: {
    backgroundColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
  },
  listItemSmall: { paddingTop: "0px", paddingBottom: "0px" },
  listItemAvatarSmall: { minWidth: "30px" },
  listItemTextSmall: { "& span": { fontSize: "0.85rem" } },
  avatarSmall: { width: "20px", height: "20px" },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    width: "60%",
    maxWidth: "60%",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
  cardGrid: {
    // paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardLink: {
    color: "unset",
    textDecoration: "none",
  },
  heading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexFirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: `0px 0px 70px -12.125px ${alpha(
        theme.palette.primary.main,
        0.4
      )}`,
    },
  },
  cardMedia: {
    paddingTop: "73.20%", // 250:183
  },
  cardContent: {
    flexGrow: 1,
  },
  cardMediaLoading: {
    paddingTop: "20%",
  },
  containerLoading: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "22%",
  },
  topButtonContainer: {
    paddingBottom: theme.spacing(2),
  },
  topButton: {
    marginRight: theme.spacing(1),
  },
}));

export default function HalfDeckList({ userState, filters, username, label }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  var initialDecks = [];
  if (userState.cookieConsent) {
    var getGetCachedData = localStorage.getItem(`halfDecks`);
    if (getGetCachedData) {
      initialDecks = JSON.parse(getGetCachedData);
    }
  }
  const [decks, setDecks] = useState(initialDecks);

  const [progressOpen, setProgressOpen] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [searchState, setSearchState] = useState({
    open: false,
    values: {
      simpleSearch: "",
      factions: {},
    },
  });

  const [tagState, setTagState] = useState({
    dialogOpen: false,
    dialogProgress: false,
    tagItem: null,
    values: {
      categoryList: "",
      myCategories: {},
    },
  });
  function handleDeleteCategory(category, values, setFieldValue) {
    var myCategories = {};
    var catList = "";
    Object.keys(values.myCategories).forEach((catKey) => {
      if (catKey !== category) {
        myCategories[catKey] = values.myCategories[catKey];
        if (catList !== "") {
          catList += ",";
        }
        catList += values.myCategories[catKey];
      }
    });

    setFieldValue("categoryList", catList);
    setFieldValue("myCategories", myCategories);
  }
  function categorySearch(text, categories) {
    if (text !== "" && categories) {
      var keys = Object.keys(categories);
      for (var i = 0; i < keys.length; i++) {
        if (categories[keys[i]].toLowerCase().includes(text.toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }

  const [deleteState, setDeleteState] = useState({
    dialogOpen: false,
    dialogProgress: false,
    deleteItem: null,
  });
  const handleDelete = async () => {
    try {
      if (deleteState.deleteItem && deleteState.deleteItem.id) {
        setDeleteState({
          ...deleteState,
          dialogProgress: true,
        });
        var allItems = decks;
        const apiDeleteData = await API.del(
          "sfwApi",
          "/deck/" + deleteState.deleteItem.id,
          { headers: await customAPIHeader() }
        );
        if (apiDeleteData.success) {
          if (deleteState.deleteItem.index !== undefined) {
            delete allItems[deleteState.deleteItem.index];
          }
          setDeleteState({
            ...deleteState,
            dialogProgress: false,
            dialogOpen: false,
            deleteItem: null,
          });
          setDecks(allItems);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Changed for Dynamo call since there is an issue with OpenSearch decks
  const fetchDecks = async (lastEvaluatedKey) => {
    const queryStringParameters = {
      // pageSize: 500,
      // inclCards: true,
      // inclCategories: true,
      inclPve: true
    };
    if (!filters) {
      if (username) {
        queryStringParameters.username = username;
      }
    }
    if (lastEvaluatedKey) {
      //   queryStringParameters.exclusiveStartKey =
      //     JSON.stringify(lastEvaluatedKey);
      if (lastEvaluatedKey.SK && lastEvaluatedKey.PK) {
        queryStringParameters.exclusiveStartKeyPK = lastEvaluatedKey.PK;
        queryStringParameters.exclusiveStartKeySK = lastEvaluatedKey.SK;
      }
    }
    const apiGetData = await API.get("sfwApi", "/deck/app", {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    if (apiGetData.error) {
    }
    return { decks: apiGetData.Items, lastEvaluatedKey: apiGetData.LastEvaluatedKey } || {};
  };

  useEffect(() => {
    const check = async () => {
      if (userState && userState.isLoading === false) {
        var data = await fetchDecks(null);
        var decks = data?.decks
        var lastEvaluatedKey = data?.lastEvaluatedKey
        setDecks(decks);
        if (userState.cookieConsent) {
          localStorage.setItem(
            "halfDecks",
            JSON.stringify(decks?.length > 0 ? decks.slice(0, 10) : [])
          );

          var getCachedItems = localStorage.getItem("CachedItems");
          var cachedItems = getCachedItems ? JSON.parse(getCachedItems) : {};
          cachedItems["halfDecks"] = true;
          cachedItems["factions"] = true;
          localStorage.setItem("CachedItems", JSON.stringify(cachedItems));
        }
        setLoading(false);
        while(lastEvaluatedKey) {
            var data = await fetchDecks(lastEvaluatedKey);
            var extraDecks = data?.decks
            lastEvaluatedKey = data?.lastEvaluatedKey
            var decks = [...decks, ...extraDecks]
            setDecks(decks);
            if (userState.cookieConsent) {
              localStorage.setItem(
                "halfDecks",
                JSON.stringify(decks?.length > 0 ? decks.slice(0, 10) : [])
              );

              var getCachedItems = localStorage.getItem("CachedItems");
              var cachedItems = getCachedItems ? JSON.parse(getCachedItems) : {};
              cachedItems["halfDecks"] = true;
              cachedItems["factions"] = true;
              localStorage.setItem("CachedItems", JSON.stringify(cachedItems));
            }
          }
      }
    };
    check();
  }, [userState, filters]);

  return (
    <>
      <MainPageHeader
        title={label}
        right={userState && userState.user && <Button
          aria-label="Share Deck"
          sx={{
            minWidth: "unset",
            color: "#ffffff",
          }}
          onClick={async (e) => {
            await copyShareDeckLink(window.location.origin + "/userDecks/" + userState.user.username);
          }}
          size="large"
        >
          <ShareIcon />
        </Button>}
      // right={
      //   userState.isAuthenticated ? (
      //     <Button
      //       variant="outlined"
      //       startIcon={<DownloadIcon />}
      //       disabled={!decks?.length}
      //       onClick={async () => {
      //         try {
      //           setProcessing(true);

      //           await downloadDecks(decks);
      //         } catch (error) {
      //           postNotification(
      //             "error",
      //             error.message || JSON.stringify(error)
      //           );
      //         }
      //         setProcessing(false);
      //       }}
      //       sx={{
      //         backgroundColor: "#FFFFFF",
      //         color: "#B53114",
      //         borderColor: "#B53114",
      //         "&:hover": {
      //           backgroundColor: "#FFFFFF",
      //         },
      //       }}
      //     >
      //       Download All to TTS
      //     </Button>
      //   ) : null
      // }
      />

      {loading ? (
        <LoadingModal />
      ) : (
        <>
          {decks?.length > 0 ? (
            <>
              <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
                <Grid container spacing={1} alignItems="center">
                  <Grid item sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      className={classes.searchField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                      variant="outlined"
                      size="small"
                      value={searchState?.values?.simpleSearch}
                      onChange={(e) => {
                        setSearchState({
                          ...searchState,
                          values: {
                            ...searchState?.values,
                            simpleSearch: e.target.value.toLowerCase(),
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <Button
                      variant="contained"
                      color="gray"
                      fullWidth
                      startIcon={<FilterAltIcon />}
                      endIcon={
                        searchState.open ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                      onClick={() => {
                        setSearchState({
                          ...searchState,
                          open: !searchState.open,
                        });
                      }}
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              {searchState.open && (
                <Box sx={{ widht: "100%", background: "#E0E0E0" }}>
                  <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
                    <Grid container spacing={1} alignItems="center">
                      {["Alloyin", "Tempys", "Uterra", "Nekrium"].map(
                        (faction, index) => {
                          return (
                            <Grid key={index} item xs={6} sm="auto">
                              <FactionButton
                                faction={faction}
                                fullWidth
                                active={
                                  searchState?.values?.factions?.[faction]
                                }
                                onClick={() => {
                                  var newFilterState = {
                                    ...searchState.values.factions,
                                  };
                                  if (!!newFilterState[faction]) {
                                    delete newFilterState[faction];
                                  } else {
                                    newFilterState[faction] = true;
                                  }
                                  setSearchState({
                                    ...searchState,
                                    values: {
                                      ...searchState?.values,
                                      factions: newFilterState,
                                    },
                                  });
                                }}
                              />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Container>
                </Box>
              )}
              <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Alert
                      icon={
                        <HelpIcon
                          fontSize="inherit"
                          sx={{ color: "#ffffff" }}
                        />
                      }
                      sx={{
                        mt: 2,
                        mb: 2,
                        backgroundColor: "#A55645",
                        color: "#ffffff",
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>Did you know:</span> You
                      can play SolForge Fusion online! To learn more,{" "}
                      <Link
                        href="https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffdyk"
                        color="inherit"
                        underline="always"
                        target="_blank"
                      >
                        click here
                      </Link>
                      .
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <ListResponsive
                      originalList={decks.filter((deck) => {
                        if (
                          Object.keys(searchState.values?.factions).length !==
                          0 &&
                          !searchState.values?.factions?.[deck?.faction]
                        ) {
                          return false;
                        }

                        if (
                          searchState.values?.simpleSearch &&
                          searchState.values.simpleSearch !== ""
                        ) {
                          var searchFields =
                            searchState.values.simpleSearch.split(" ");

                          for (let searchField of searchFields) {
                            var found = false;
                            if (
                              deck?.name
                                ?.toLowerCase()
                                .includes(searchField.toLowerCase()) ||
                              deck?.forgeborn?.title
                                ?.toLowerCase()
                                .includes(searchField.toLowerCase()) ||
                              categorySearch(
                                searchField.toLowerCase(),
                                deck?.myCategories
                              )
                            ) {
                              found = true;
                            }
                            if (!found) return false;
                          }
                        }
                        return true;
                      })}
                      doPages={true}
                      rowProps={{
                        headings: (
                          <Hidden mdDown>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                p: 1,
                                color: "#767676",
                              }}
                            >
                              <DeckRow heading />
                            </Grid>
                          </Hidden>
                        ),
                        mapFunction: (row, index) => (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            sx={{
                              backgroundColor: row?.digital == 1 ? ("#daf0ff") : ("#ffffff"),
                              p: 1,
                              "&:hover": {
                                backgroundColor: "#E7E7E7",
                              },
                              // backgroundColor: isOdd(index + 1)
                              //   ? "#E7E7E7"
                              //   : null,
                            }}
                          >
                            <DeckRow
                              key={index}
                              index={index}
                              deck={row}
                              setProcessing={setProcessing}
                              tagState={tagState}
                              setTagState={setTagState}
                              deleteState={deleteState}
                              setDeleteState={setDeleteState}
                              userState={userState}
                            />
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>

                {userState && userState.isAuthenticated && (
                  <>
                    <Modal
                      disableAutoFocus
                      open={tagState.dialogOpen}
                      onClose={() => {
                        setTagState({ ...tagState, dialogOpen: false });
                      }}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                      sx={{ zIndex: 99990 }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: ["95vw", "85vw", 600],
                          maxHeight: "95vh",

                          bgcolor: "background.paper",
                          border: "1px solid rgba(0, 0, 0, 0.12)",
                          borderRadius: 1,
                          boxShadow: 24,
                        }}
                      >
                        <Card>
                          <CardHeader
                            title="Tag Deck"
                            action={
                              <IconButton
                                aria-label="close"
                                onClick={() => {
                                  setTagState({
                                    ...tagState,
                                    dialogOpen: false,
                                  });
                                }}
                                size="large"
                              >
                                <CancelIcon />
                              </IconButton>
                            }
                          />

                          <Formik
                            initialValues={tagState.values}
                            enableReinitialize={true}
                            onSubmit={async (
                              values,
                              { setSubmitting, setFieldValue }
                            ) => {
                              try {
                                setProgressOpen(true);
                                var postBody = {
                                  myCategories: values.myCategories,
                                };

                                var apiPostData = await API.post(
                                  "sfwApi",
                                  "/deck/" + tagState.tagItem.id,
                                  {
                                    body: postBody,
                                    headers: await customAPIHeader(),
                                  }
                                );
                                if (apiPostData.error) {
                                  postNotification("error", apiPostData.error);
                                } else {
                                  var allItems = decks;

                                  allItems[tagState.tagItem.index] =
                                    apiPostData.data;
                                  setDecks(allItems);
                                  setTagState({
                                    ...tagState,
                                    dialogOpen: false,
                                  });
                                  postNotification(
                                    "success",
                                    "Congratulations! Your deck has been tagged."
                                  );
                                }

                                setSubmitting(false);
                                setProgressOpen(false);
                              } catch (error) {
                                postNotification(
                                  "error",
                                  error.message || JSON.stringify(error)
                                );
                                setSubmitting(false);
                                setProgressOpen(false);
                              }
                            }}
                          >
                            {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values,
                              setFieldValue,
                            }) => (
                              <form onSubmit={handleSubmit}>
                                <CardContent className={classes.cardContent}>
                                  <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Tags"
                                        margin="normal"
                                        name="categoryList"
                                        onBlur={async (e) => {
                                          var myCategories = {};
                                          var addCommaBack =
                                            e.target.value.length > 0 &&
                                            e.target.value.charAt(
                                              e.target.value.length - 1
                                            );
                                          var cats = e.target.value.split(",");
                                          var catList = "";
                                          cats.forEach((cat) => {
                                            if (cat !== "") {
                                              myCategories[
                                                cat
                                                  .trim()
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .join("-")
                                              ] = cat;
                                              if (catList !== "") {
                                                catList += ",";
                                              }
                                              catList += cat;
                                            }
                                          });
                                          if (addCommaBack) {
                                            catList += ",";
                                          }
                                          e.target.value = catList;
                                          handleBlur(e);
                                          setFieldValue(
                                            "myCategories",
                                            myCategories
                                          );
                                        }}
                                        onChange={(e) => {
                                          var myCategories = {};
                                          var addCommaBack =
                                            e.target.value.length > 0 &&
                                            e.target.value.charAt(
                                              e.target.value.length - 1
                                            ) === ",";
                                          var cats = e.target.value.split(",");
                                          var catList = "";
                                          cats.forEach((cat) => {
                                            if (cat !== "") {
                                              myCategories[
                                                cat
                                                  .trim()
                                                  .toLowerCase()
                                                  .split(" ")
                                                  .join("-")
                                              ] = cat;
                                              if (catList !== "") {
                                                catList += ",";
                                              }
                                              catList += cat;
                                            }
                                          });
                                          if (addCommaBack) {
                                            catList += ",";
                                          }
                                          e.target.value = catList;
                                          handleChange(e);
                                          setFieldValue(
                                            "myCategories",
                                            myCategories
                                          );
                                        }}
                                        value={values.categoryList}
                                        color="secondary"
                                        variant="outlined"
                                        helperText="Comma Separated List"
                                      />
                                      <Stack spacing={1} direction="row">
                                        {Object.keys(values.myCategories).map(
                                          (cat, index) => {
                                            return (
                                              <Chip
                                                label={cat}
                                                onDelete={(e) => {
                                                  handleDeleteCategory(
                                                    cat,
                                                    values,
                                                    setFieldValue
                                                  );
                                                }}
                                                color="secondary"
                                                variant="outlined"
                                              />
                                            );
                                          }
                                        )}
                                      </Stack>
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <CardActions className={classes.cardActions}>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                  >
                                    Update Deck
                                  </Button>
                                </CardActions>
                              </form>
                            )}
                          </Formik>
                        </Card>
                      </Box>
                    </Modal>

                    <Dialog
                      open={deleteState.dialogOpen}
                      onClose={(e) =>
                        setDeleteState({ ...deleteState, dialogOpen: false })
                      }
                    >
                      <DialogTitle>Release Deck</DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <Typography variant="body1" gutterBottom>
                            You may release a deck from your collection. When
                            you do, it becomes available for other players to
                            register if they have access to the deck's QR code.
                          </Typography>
                          {/* Are you sure you wish to remove this deck? This will
                            Archive any Fused Decks that contain{" "}
                            {deleteState.deleteItem &&
                              deleteState.deleteItem.name}
                            . */}
                          <Typography variant="body1" gutterBottom>
                            Are you sure you want to release this deck?
                          </Typography>
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={(e) =>
                            setDeleteState({
                              ...deleteState,
                              dialogOpen: false,
                            })
                          }
                          variant="outlined"
                          color="primary"
                        >
                          Cancel
                        </Button>
                        <Button
                          onClick={handleDelete}
                          variant="contained"
                          color="primary"
                          autoFocus
                        >
                          Yes, Release Deck
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
                <ProgressModal open={progressOpen} />
                <Modal disableAutoFocus open={processing} disableScrollLock>
                  <Box
                    sx={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      position: "absolute",
                      padding: 4,
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress color="primary" />
                  </Box>
                </Modal>
              </Container>
            </>
          ) : (
            <ScanDeck />
          )}
        </>
      )}

      {processing && <LoadingModal />}
    </>
  );
}

const DeckRow = (props) => {
  const {
    heading,
    deck,
    index,
    setProcessing,
    tagState,
    setTagState,
    deleteState,
    setDeleteState,
    userState,
  } = props;

  const mediaLT600 = useMediaQuery("(max-width:599px)");
  const mediaLT800 = useMediaQuery("(max-width:799px)");
  const mediaLT900 = useMediaQuery("(max-width:899px)");

  const releaseDeck = async () => {
    await setDeleteState({
      ...deleteState,
      deleteItem: { ...deck, index: index },
      dialogOpen: true,
    });
  };

  if (heading) {
    if (mediaLT900) {
      return <></>;
    }
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid
          item
          sx={{ flex: 1, maxWidth: "calc(100% - 120px - 140px - 230px)" }}
        >
          Name
        </Grid>
        <Grid item sx={{ minWidth: "120px" }}>
          Rank
        </Grid>
        <Grid item sx={{ minWidth: "140px" }}>
          Faction
        </Grid>
        <Grid item sx={{ minWidth: "230px" }}></Grid>
      </Grid>
    );
  }

  if (!deck.forgeborn) {
    // Removes the set and forge codes to get forgeborn name
    try {
      let forgebornName = deck.forgebornId.slice(5).replace(/\d+/g, '')
      forgebornName = forgebornName.split("-")
      forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
      deck.forgeborn = { title: forgebornName.join(" ") }
    } catch (error) {
      deck.forgeborn = { title: "" }
    }
  }

  if (mediaLT600) {
    return (
      <Box
        sx={deck?.forgeborn?.title ? {
          position: "relative",
          background: `url(/images/cards/forgeborn/${deck.forgeborn.title
            .split(" ")
            .join("-")
            .toLowerCase()}.jpg)`,
          backgroundSize: "cover",
          width: "100%",
          pb: "100%",
          borderRadius: "4px",

        } : {
          position: "relative",
          background: ``,
          backgroundSize: "cover",
          width: "100%",
          pb: "100%",
          borderRadius: "4px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            display: "flex",
          }}
        >
          <Grid container spacing={1} sx={{ m: "10px" }}>
            <Grid item>
              <RankChip rank={deck?.deckRank} />
            </Grid>
            <Grid item>
              <FactionChip faction={deck?.faction} />
            </Grid>
            {userState && userState.isAuthenticated &&
              <Grid item sx={{ flex: 1, pr: 1 }} textAlign="right">
                <Tooltip title="Release Deck">
                  <Button
                    aria-label="Release Deck"
                    sx={{
                      minWidth: "unset",
                      backgroundColor: "#ffffff !important",
                      color: "#000000",
                    }}
                    onClick={async (e) => {
                      await releaseDeck();
                    }}
                    size="large"
                  >
                    <RemoveCircleOutlineIcon />
                  </Button>
                </Tooltip>
              </Grid>
            }
          </Grid>
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            background: "rgba(0, 0, 0, 0.75)",
            color: "#FFFFFF",
            width: "100%",
            height: "40%",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ minWidth: "100%", display: "block", p: "20px" }}>
            <Link href={`/decks/${deck.id}`} color="inherit">
              <Box sx={{ minWidth: "100%", display: "block", mb: 1 }}>
                <CustomChip
                  label={deck?.forgeborn?.title}
                  wrapperSX={{
                    p: "5px",
                  }}
                  typographySX={{
                    fontSize: "12px",
                    lineHeight: "15px",
                  }}
                />
              </Box>

              <Box sx={{ minWidth: "100%", display: "block", mb: 1 }}>
                {deck?.name}
              </Box>
            </Link>
            <Box sx={{ minWidth: "100%", display: "block" }}>
              <Typography
                variant="caption"
                sx={{
                  color: "#FFFFFF",
                  pt: 1,
                }}
                gutterBottom
              >
                <LocalOfferIcon fontSize="inherit" />{" "}
                {deck.myCategories && (
                  <Tooltip title={Object.values(deck.myCategories).join(" ")}>
                    <Typography
                      variant="caption"
                      sx={{
                        color: "inherit",
                        maxWidth: "calc(100% - 24px - 100px)",
                        display: "inline-block",
                        verticalAlign: "middle",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      gutterBottom
                    >
                      {Object.keys(deck.myCategories).map((cat, index) => {
                        return (
                          <>
                            #{deck.myCategories[cat]}
                            &nbsp;
                          </>
                        );
                      })}
                    </Typography>
                  </Tooltip>
                )}{" "}
                {userState && userState.isAuthenticated &&
                  <Button
                    aria-label="Add Tags"
                    color="primary"
                    size="small"
                    startIcon={<AddCircleIcon />}
                    onClick={async (e) => {
                      if (deck.myCategories) {
                        var catList = "";
                        Object.keys(deck.myCategories).forEach((catKey) => {
                          if (catList !== "") {
                            catList += ",";
                          }
                          catList += deck.myCategories[catKey];
                        });
                        deck.categoryList = catList;
                      } else {
                        deck.myCategories = {};
                        deck.categoryList = "";
                      }
                      await setTagState({
                        ...tagState,
                        tagItem: {
                          ...deck,
                          index: index,
                        },
                        values: {
                          myCategories: deck.myCategories,
                          categoryList: deck.categoryList,
                        },
                        dialogOpen: true,
                      });
                    }}
                    sx={{
                      p: 0,
                      ml: 1,
                      mt: -1,
                      textTransform: "unset",
                      minWidth: "unset",
                      "& .MuiButton-startIcon": {
                        color: "primary.main",
                        mr: "4px",
                        "& .MuiSvgIcon-root": {
                          color: "primary.main",
                          fontSize: "14px",
                          "& path": {
                            color: "primary.main",
                          },
                        },
                      },
                    }}
                  >
                    {deck.myCategories ? "Edit" : "Add"}
                  </Button>
                }
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item sx={{ minWidth: "110px" }}>
        <Link href={`/decks/${deck.id}`} color="inherit">
          <Avatar
            alt={deck?.forgeborn?.title}
            src={
              deck?.forgeborn?.title
                ? `/images/cards/forgeborn/${deck.forgeborn.title
                  .split(" ")
                  .join("-")
                  .toLowerCase()}.jpg`
                : ""
            }
            sx={{
              borderRadius: "8px",
              height: "100px",
              width: "100px",
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          />
        </Link>
      </Grid>
      <Grid
        item
        sx={{
          flex: 1,
          maxWidth: mediaLT800
            ? "calc(100% - 110px - 140px - 40px)"
            : mediaLT900
              ? "calc(100% - 110px - 140px - 230px)"
              : "calc(100% - 110px - 120px - 140px - 230px)",
        }}
      >
        <Link href={`/decks/${deck.id}`} color="inherit">
          <CustomChip
            label={deck?.forgeborn?.title}
            wrapperSX={{
              p: "5px",
            }}
            typographySX={{
              fontSize: "12px",
              lineHeight: "15px",
            }}
          />
          <Typography sx={{ mb: 0 }}>{deck?.name}</Typography>
        </Link>
        <Typography
          variant="caption"
          sx={{
            color: "text.secondary",
            pt: 1,
          }}
          gutterBottom
        >
          <LocalOfferIcon fontSize="inherit" />{" "}
          {deck.myCategories && (
            <Tooltip title={Object.values(deck.myCategories).join(" ")}>
              <Typography
                variant="caption"
                sx={{
                  color: "inherit",
                  maxWidth: "calc(100% - 24px - 65px)",
                  display: "inline-block",
                  verticalAlign: "middle",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                gutterBottom
              >
                {Object.keys(deck.myCategories).map((cat, index) => {
                  return (
                    <>
                      #{deck.myCategories[cat]}
                      &nbsp;
                    </>
                  );
                })}
              </Typography>
            </Tooltip>
          )}{" "}
          {userState && userState.isAuthenticated &&
            <Button
              aria-label="Add Tags"
              color="primary"
              size="small"
              startIcon={<AddCircleIcon />}
              onClick={async () => {
                if (deck.myCategories) {
                  var catList = "";
                  Object.keys(deck.myCategories).forEach((catKey) => {
                    if (catList !== "") {
                      catList += ",";
                    }
                    catList += deck.myCategories[catKey];
                  });
                  deck.categoryList = catList;
                } else {
                  deck.myCategories = {};
                  deck.categoryList = "";
                }
                await setTagState({
                  ...tagState,
                  tagItem: {
                    ...deck,
                    index: index,
                  },
                  values: {
                    myCategories: deck.myCategories,
                    categoryList: deck.categoryList,
                  },
                  dialogOpen: true,
                });
              }}
              sx={{
                p: 0,
                ml: 1,
                mt: -1,
                textTransform: "unset",
                minWidth: "unset",
                "& .MuiButton-startIcon": {
                  mr: "4px",
                  "& .MuiSvgIcon-root": {
                    fontSize: "14px",
                    // lineHeight: "1.65",
                  },
                },
              }}
            >
              {deck.myCategories ? "Edit" : "Add"}
            </Button>}
        </Typography>
      </Grid>
      {mediaLT900 ? (
        <Grid item sx={{ minWidth: "140px" }}>
          <RankChip rank={deck?.deckRank} />
          <FactionChip faction={deck?.faction} />
        </Grid>
      ) : (
        <>
          <Grid item sx={{ minWidth: "120px" }}>
            <RankChip rank={deck?.deckRank} />
          </Grid>
          <Grid item sx={{ minWidth: "140px" }}>
            <FactionChip faction={deck?.faction} />
          </Grid>
        </>
      )}
      {userState && userState.isAuthenticated && (!deck?.digital || deck?.digital == 0) &&
        <Grid item sx={{ minWidth: mediaLT800 ? "40px" : "230px" }}>
          <Tooltip title="Release Deck">
            <IconButton
              aria-label="Release Deck"
              sx={{ padding: 0 }}
              onClick={async () => {
                await releaseDeck();
              }}
              size="large"
            >
              <RemoveCircleOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>}
      {deck?.digital == 1 &&
        <Grid item>
          <Tooltip title="Digital">
            <Typography>
              Digital
            </Typography>
          </Tooltip>
        </Grid>}
    </Grid>
  );
};

function isOdd(num) {
  return num % 2;
}
