import React from "react";
import { Container, Link } from "@mui/material";

import "./Cookies.css";

export default function Cookies() {
  return (
    <Container
      className="cssCookies"
      sx={{
        paddingTop: 2,
        paddingBottom: 2,
      }}
      maxWidth="lg"
    >
      <div>
        <p class="c2 c4">
          <span class="c0"></span>
        </p>
        <table class="c6">
          <tbody>
            <tr class="c7">
              <td class="c10" colspan="1" rowspan="1">
                <p class="c1 c2">
                  <span class="c0"></span>
                </p>
              </td>
            </tr>
            <tr class="c7">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2 c16">
                  <span class="c0"></span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="c4 c2">
          <span class="c0"></span>
        </p>
      </div>
      <p class="c17">
        <span class="c14">Stone Blade Entertainment</span>
      </p>
      <p class="c17">
        <span class="c14">Cookie Policy</span>
      </p>
      <ol class="c5 lst-kix_list_6-0 start" start="1">
        <li class="c1 c11 li-bullet-0">
          <span class="c12">What are cookies?</span>
        </li>
      </ol>
      <p class="c1">
        <span class="c0">
          Cookies enable us to customize and personalize your experience on
          Stone Blade Entertainment&rsquo;s products and services (the
          &ldquo;Service&rdquo;). Essentially, a cookie is a small amount of
          data that is sent to your browser from a web server and is stored on
          your computer&rsquo;s hard drive. Cookies are not spyware or adware,
          and can&rsquo;t deliver viruses or run programs on your computer.
        </span>
      </p>
      <ol class="c5 lst-kix_list_6-0" start="2">
        <li class="c1 c11 li-bullet-0">
          <span class="c12">How do we use cookies?</span>
        </li>
      </ol>
      <p class="c1">
        <span class="c0">
          We use cookies for different purposes in connection with the operation
          of the Service:
        </span>
      </p>
      <ul class="c5 lst-kix_list_2-0 start">
        <li class="c3 li-bullet-0">
          <span class="c0">
            We may allow you to save certain login info, so that you can stay
            signed in and do not have to re-enter it every time you visit our
            website in order to leave comments and for other purposes. In
            providing you with this service, we use cookies.
          </span>
        </li>
        <li class="c3 li-bullet-0">
          <span class="c0">
            We use cookies to analyze our userbase and usage patterns. Each
            browser accessing our website may be given a unique cookie that is
            then used to determine the extent of repeat visits and their
            activity during those visits. We may use this information to help
            optimize the website and develop new features and content that users
            are looking for.
          </span>
        </li>
      </ul>
      <ol class="c5 lst-kix_list_6-0" start="3">
        <li class="c1 c11 li-bullet-0">
          <span class="c12">Do you have choices about cookies?</span>
        </li>
      </ol>
      <p class="c1">
        <span class="c0">
          Yes, you have several choices with respect to cookies. You can modify
          your browser preferences to accept all cookies, to notify you when a
          cookie is set, or to reject all cookies.{" "}
        </span>
      </p>
      <p class="c1">
        <span class="c0">
          However, if you choose to reject all cookies, you may not be able to
          use all of our services.
        </span>
      </p>
      <p class="c1">
        <span class="c0">
          Please note that you may not opt out of cookies that are strictly
          necessary for the Service to function.
        </span>
      </p>
      <p class="c1">
        <span class="c0">
          If you have any questions, please contact us at{" "}
          <Link href="mailto:contact@stoneblade.com">
            contact@stoneblade.com
          </Link>
        </span>
      </p>
    </Container>
  );
}
