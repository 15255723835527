import React from "react";

import { Box } from "@mui/material";

export default function CarouselBackground(props) {
  const { backgroundImage } = props;
  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        color: "common.white",
        height: "50vh",
        width: "100%",
        boxShadow: "inset 0 0 0 1000px rgb(0 0 0 / 20%)",
        objectFit: "contain",
        background: `url('${backgroundImage}') center center/cover no-repeat`,
      }}
    >
      <Box
        sx={{
          // backgroundColor: `rgba(0, 0, 0, 0.6)`,
          // pt: 2,
          // pb: 2,
          height: "100%",
          width: "100%",
          textAlign: "center",
          display: "flex",
          flexFirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
}
