import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { getS3Bucket } from "../utils/UtilityFunctions";
// import { getResizedFileName } from "../utils/UtilityFunctions";

export default function CardCarousel({
  cardState,
  height,
  width,
  isModal,
  index,
  setIndex,
}) {
  var offset = -2250 * (width / 250);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadImage, setLoadImage] = useState(null);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
      setLoadImage(
        `https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
          cardState.PK
        }${
          cardState.cardType === "Forgeborn" ? "" : `_${selectedIndex + 1}`
        }.jpg`
      );
  };

  useEffect(() => {
    // Removed this check a it no longer applies, was used for set 1 to clean up some things but now they are clean
      setLoadImage(`https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
              cardState.PK
            }${cardState.cardType === "Forgeborn" ? "" : "_1"}.jpg`
      );
  }, [cardState]);

  return (
    <>
      <Box
        sx={{
          width: cardState && cardState.levels ? `${width}px` : "100%",
          // height: !isModal ? height : null,
          maxWidth: "95vw",
          maxHeight: height,
          position: "relative",
        }}
      >
        {cardState?.cardType !== "Forgeborn" && cardState?.levels && (
          <>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              indicators={false}
            >
              {Object.keys(cardState.levels).map((level) => {
                  return (
                      <Carousel.Item>
                          <Box
                            sx={{
                              width: `${height}px`,
                              // height: `${width}px`,
                              maxWidth: "100%",
                              // maxHeight: "95vw",
                              maxHeight: "100%", //`${height}px`,
                              visibility: "visible",
                              // background: `url("${cardState.imageUrl}")`,
                            }}
                          >
                            <img
                              alt={cardState.title || "SolForge Fusion"}
                              style={{
                                width: "100%",
                                maxWidth: "100%",
                                maxHeight: height || "100%",
                                marginBottom: "-8px",
                              }}
                              src={`https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
                                cardState.PK
                              }_${level}.jpg`}
                            />
                          </Box>

                          <Box
                            sx={{
                              width: `${width}px`,
                              height: `${height}px`,
                              maxWidth: "100%",
                              // maxHeight: "95vw",
                              maxHeight: "100%", //`${height}px`,
                              visibility: "visible",
                              background: `url("${cardState.imageUrl}") ${
                                offset + (10 - parseInt(cardState.cardNo)) * width
                              }px ${parseInt(level) * height}px / 1000% 300%`,
                            }}
                          />
                      </Carousel.Item>
                  );
              })}
            </Carousel>
          </>
        )}
        {cardState &&
          cardState?.cardType === "Forgeborn" && (
            <Box
              sx={{
                width: `${height}px`,
                // height: `${width}px`,
                maxWidth: "100%",
                // maxHeight: "95vw",
                maxHeight: "100%", //`${height}px`,
                visibility: "visible",
                // background: `url("${cardState.imageUrl}")`,
                textAlign: "center",
              }}
            >
              <img
                alt={cardState.title || "SolForge Fusion"}
                style={{
                  width: "100%",
                  maxWidth: "100%",
                  maxHeight: width || "100%",
                  marginBottom: "-8px",
                }}
                src={`https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
                        cardState.PK
                      }.jpg`
                }
              />
            </Box>
            // <>
            //   <img
            //     alt={cardState.title || "SolForge Fusion"}
            //     style={{
            //       maxWidth: "100%",
            //       maxHeight: height || "100%",
            //       marginBottom: "-8px",
            //     }}
            //     src={
            //       // cardState.myImage
            //       //   ? `${cardState.myImage.url}${getResizedFileName(
            //       //       cardState.myImage.filename,
            //       //       "xl"
            //       //     )}`
            //       //   :
            //       cardState.imageUrl
            //     }
            //   />
            // </>
          )}
        {!imageLoaded && (
          <Box
            sx={{
              width: `${width}px`,

              position: "absolute",
              left: "calc(50% - 20px)",
              top: "calc(50% - 20px)",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!imageLoaded && loadImage && (
          <img
            alt="loader"
            style={{ display: "none" }}
            src={loadImage}
            onLoad={() => {
              setImageLoaded(true);
            }}
          />
        )}
      </Box>
    </>
  );
}
