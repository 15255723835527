import React, { useEffect } from "react";
import { Box, Button, CircularProgress, Grid, TextField } from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";

import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

export default function SecurityTab({ profile, setLoading }) {
  const classes = useStyles();

  const initialValues = {
    Password: "",
    NewPassword: "",
  };

  useEffect(() => {
    const check = async () => {
      setLoading(false);
    };

    check();
  }, [profile]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          Password: Yup.string().required("Required"),
          NewPassword: Yup.string()
            .min(8, "Too Short")
            .max(255, "Too Long")
            .required("Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setLoading(true);
          Auth.currentAuthenticatedUser()
            .then((user) => {
              return Auth.changePassword(
                user,
                values.Password,
                values.NewPassword
              );
            })
            .then((data) => {
              postNotification("success", "Password updated");
              setSubmitting(false);
              setLoading(false);
            })
            .catch((err) => {
              postNotification(
                "error",
                err.message ? err.message : "Unknown error"
              );
              setSubmitting(false);
              setLoading(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.Password && errors.Password)}
                  fullWidth
                  helperText={touched.Password && errors.Password}
                  label="Password"
                  margin="normal"
                  name="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Password}
                  color="secondary"
                  variant="outlined"
                  type="password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.NewPassword && errors.NewPassword)}
                  fullWidth
                  helperText={touched.NewPassword && errors.NewPassword}
                  label="New Password"
                  margin="normal"
                  name="NewPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.NewPassword}
                  color="secondary"
                  variant="outlined"
                  type="password"
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box className={classes.buttonWrapper}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Update Password
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    color="primary"
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
