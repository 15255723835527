import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { handleBPCPaging } from "../../bcp/BCPUtilities";
import ListResponsive from "../../layout/ListResponsive";
import OrganizationCard from "../../bcp/OrganizationCard";
import { getEnv } from "../../utils/UtilityFunctions";

const env = getEnv();

export default function OrganizationTab({ profile, setLoading }) {
  const [gameStores, setGameStores] = useState(null);

  useEffect(() => {
    const check = async () => {
      var apiGameStores = await handleBPCPaging(`/gamestores`, {
        myStores: true,
        sortAscending: false,
        "expand[]": ["location", "owner", "gameStore", "user"],
      });

      apiGameStores.forEach((apiGameStore) => {
        if (apiGameStore.location) {
          apiGameStore.location.displayLocation = "";
          if (apiGameStore.location.city && apiGameStore.location.city !== "") {
            if (apiGameStore.location.displayLocation !== "") {
              apiGameStore.location.displayLocation += ", ";
            }
            apiGameStore.location.displayLocation += apiGameStore.location.city;
          }
          if (
            apiGameStore.location.state &&
            apiGameStore.location.state !== ""
          ) {
            if (apiGameStore.location.displayLocation !== "") {
              apiGameStore.location.displayLocation += ", ";
            }
            apiGameStore.location.displayLocation +=
              apiGameStore.location.state;
          }
          if (
            apiGameStore.location.country &&
            apiGameStore.location.country !== ""
          ) {
            if (apiGameStore.location.displayLocation !== "") {
              apiGameStore.location.displayLocation += ", ";
            }
            apiGameStore.location.displayLocation +=
              apiGameStore.location.country;
          }
        }
      });
      setGameStores(apiGameStores);
    };
    check();
  }, []);

  return (
    <Grid container>
      <ListResponsive
        originalList={gameStores}
        doPages
        doSearch
        initialRowsPerPage={24}
        rowsPerPageArray={[12, 24, 48, 96, { label: "All", value: -1 }]}
        rowProps={{
          mapFunction: (row, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} sx={{ p: 1 }}>
              <OrganizationCard organize organization={row} key={index} />
            </Grid>
          ),
        }}
        searchProps={{
          label: "Search Organizations",
          performSearch: (originalList, searchText) => {
            if (!searchText || searchText === "") {
              return originalList;
            } else {
              var filteredList = originalList.filter(function (el) {
                if (el?.name?.toLowerCase().includes(searchText)) return true;
                if (el?.owner?.firstName?.toLowerCase().includes(searchText))
                  return true;
                if (el?.owner?.lastName?.toLowerCase().includes(searchText))
                  return true;
                if (
                  el?.location?.displayLocation
                    ?.toLowerCase()
                    .includes(searchText)
                )
                  return true;

                return false;
              });
              return filteredList;
            }
          },
        }}
        actionButton={{
          props: {
            variant: "contained",
            color: "primary",
            fullWidth: true,
            href: `${
              env === "main"
                ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
            }/organize/organization`,
            startIcon: <AddBoxIcon />,
          },
          label: "New Organization",
        }}
      />
    </Grid>
  );
}
