import { Avatar, Box, Typography } from "@mui/material";

export default function FactionChip(props) {
  const { faction } = props;
  return (
    <Box
      sx={{
        display: "flex",
        width: "fit-content",
        backgroundColor: `factions.${faction?.toLowerCase()}`,
        color: "#ffffff",
        border: "2px solid #FFFFFF",
        borderRadius: "4px",
        p: 1,
        mb: "4px",
      }}
    >
      <Avatar
        alt={faction}
        src={`/images/factions/${faction?.toLowerCase()}-w.png`}
        sx={{
          borderRadius: "unset",
          maxHeight: "19px",
          maxWidth: "19px",
        }}
      />
      <Typography
        variant="caption"
        color="inherit"
        sx={{
          ml: 1,
          mb: 0,
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {faction}
      </Typography>
    </Box>
  );
}
