import React from "react";
import { Carousel } from "react-bootstrap";

import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import Hero from "../components/Hero";
import CarouselBackground from "../components/CarouselBackground";
import PostGallery from "../components/posts/PostGallery";

export default function Home() {
  return (
    <>
      <Hero backgroundImage="/images/heros/hero1.jpg">
        <Container sx={{ p: 2 }} maxWidth="lg">
          <img
            src="images/logo.png"
            alt="logo"
            style={{ maxWidth: "70%", margin: "24px" }}
          />
          <Grid container sx={{ width: "100%", textAlign: "left" }}>
            <Grid item xs={12} lg={6}>
              <Typography variant="h5" color="inherit" paragraph>
                SolForge Fusion is a{" "}
                <Box
                  component="span"
                  sx={{ color: "headingTextHighlight.main" }}
                >
                  hybrid deck game
                </Box>{" "}
                from the creators of Magic: The Gathering and Ascension Deck
                Building Game.
              </Typography>
              <List dense={true} sx={{ listStyleType: "disc", ml: 2 }}>
                <ListItem sx={{ display: "list-item" }}>
                  <Box component="span" sx={{ color: "primary.light" }}>
                    Algorithmically-generated
                  </Box>{" "}
                  half-decks uniquely printed just for you.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <Box component="span" sx={{ color: "primary.light" }}>
                    Fuse your decks together
                  </Box>{" "}
                  and explore an endless array of strategies.
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  <Box component="span" sx={{ color: "primary.light" }}>
                    Level up your cards
                  </Box>{" "}
                  and overwhelm your foes!
                </ListItem>
              </List>

              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      href="/how-to-play"
                      variant="contained"
                      color="primary"
                    >
                      Learn How To Play
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      href="https://discord.com/invite/YFugCMtrP9"
                      target="_blank"
                      variant="outlined"
                      color="primary"
                      sx={{
                        backgroundColor: "background.paper",
                        "&:hover": {
                          backgroundColor: "primary.dark",
                          color: "primary.contrastText",
                        },
                      }}
                    >
                      Join Our Discord
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Hidden lgDown>
              <Grid
                item
                xs={12}
                lg={6}
                sx={{
                  mt: "-32px",
                  mb: "-32px", // assumes hero pb: 2
                  background: `url('images/assets/cards-fanout.png') no-repeat`,
                  backgroundSize: "558px 500px", // original 628 x 562
                }}
              ></Grid>
            </Hidden>
          </Grid>
        </Container>
      </Hero>
      <Box>
        <Carousel
          fade={true}
          indicators={true}
          interval={4000}
          wrap={true}
          touch={true}
        >
          {/* Remove until last winter is live */}
          {/* <Carousel.Item>
            <CarouselBackground backgroundImage="/images/assets/last-winter.jpg">
            <Container sx={{ p: 2 }} >
              <Grid container>
                <Grid item xs={6} lg={6}>
                  <img
                    src="images/assets/last-winter-logo.png"
                    alt="logo"
                    style={{ maxWidth: "90%", margin: "20px" }}
                  />
                </Grid>
                <Grid item xs={0} lg={1}>
                </Grid>
                <Grid item xs={6} lg={5} sx={{
                  textAlign: "center",
                  display: "flex",
                  flexFirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                  <Grid container>
                    <Grid item xs={12} lg={12}>
                      <Typography variant="h3" color="inherit" >
                        ARE YOU READY TO FIGHT FOR THE FUTURE OF SOLIS?
                      </Typography>
                      <br />
                      <Button
                        href="https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffbanner"
                        variant="contained"
                        color="blue"
                        sx={{
                          fontSize: "1.125em"
                        }}
                      >
                        PLAY NOW ON STEAM
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
            </CarouselBackground>
          </Carousel.Item> */}
          <Carousel.Item>
            <CarouselBackground backgroundImage="/images/assets/cards-background.png">
              <Container sx={{ p: 2 }} >
                <Grid container >
                  <Grid item xs={9} lg={4} sx={{
                    textAlign: "center",
                    display: "flex",
                    flexFirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <Typography variant="h3" color="inherit" >
                          TRANSFORM YOUR PHYSICAL CARD COLLECTION INTO A DIGITAL BATTLEGROUND AND CHALLENGE PLAYERS GLOBALLY!
                        </Typography>
                        <br />
                        <Button
                          href="https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffbanner"
                          variant="contained"
                          color="orange"
                          sx={{
                            fontSize: "1.125em"
                          }}
                        >
                          PLAY NOW ON STEAM
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={0} lg={2}>
                  </Grid>
                  <Grid item xs={3} lg={6} sx={{
                    textAlign: "center",
                    display: "flex",
                    flexFirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                    <img
                      src="images/assets/computer-sffd.png"
                      alt="logo"
                      style={{ maxWidth: "90%", margin: "20px" }}
                    />
                  </Grid>
                </Grid>
              </Container>
            </CarouselBackground>
          </Carousel.Item>
          <Carousel.Item>
            <CarouselBackground backgroundImage="/images/assets/rm-sf-sunderrgb.png">
              <Container sx={{ p: 2, display: "flex", justifyContent: "center", textAlign: "center", flexDirection: "column", position: "relative" }} maxWidth="lg">
                <Grid container sx={{ width: "100%" }}>
                  <Grid item xs={12} lg={12}>
                    <Grid container>
                      <Grid item xs={12} lg={12}>
                        <Typography variant="h1" color="inherit" >
                          CHECK OUT THE NEXT EVOLUTION OF SOLFORGE FUSION
                        </Typography>
                        <br />
                        <Button
                          href="https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffbanner"
                          variant="contained"
                          color="primary"
                          sx={{
                            fontSize: "1.5em"
                          }}
                        >
                          PLAY NOW ON STEAM
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            </CarouselBackground>
          </Carousel.Item>
        </Carousel>
      </Box>
      <Hero backgroundImage="/images/heros/hero2.jpg">
        <Container sx={{ p: 2 }} maxWidth="lg">
          <Grid
            container
            sx={{ width: "100%", textAlign: ["center", "center", "left"] }}
          >
            <Grid item xs={12} md={6} sx={{ p: 2 }}>
              <img
                src="images/assets/forgeborn-fanout.png"
                alt="forgeborn"
                style={{ maxWidth: "100%" }}
              />
            </Grid>

            <Grid item xs={12} md={6} sx={{ pt: 2, textAlign: "left" }}>
              <Typography variant="h4" color="inherit" paragraph>
                Build Your Collection!
              </Typography>
              <Typography variant="body1" color="inherit" paragraph>
                With tens of thousands of possible cards in the first set alone,
                part of the fun of SolForge is collecting unique decks and
                sharing them with the community!
              </Typography>
              <Typography variant="body1" color="inherit" paragraph>
                Each deck comes with a unique QR code you can use to scan and
                add that deck into your online collection. Decks in your
                collection can be used to register for tournaments and other
                community events.
              </Typography>
              <Typography variant="body1" color="inherit" paragraph>
                Join in on the fun and scan your deck today!
              </Typography>

              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button href="/decks" variant="contained" color="primary">
                      Go To My Collection
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Hero>

      <PostGallery title="LATEST NEWS" cacheid="home" />

      <Hero backgroundImage="/images/heros/hero3.jpg">
        <Container sx={{ p: 2 }} maxWidth="lg">
          <Grid container sx={{ width: "100%", textAlign: "center" }}>
            <Grid item xs={12} md={6} sx={{ pt: 2, textAlign: "left" }}>
              <Typography variant="h4" color="inherit" paragraph>
                Pick up a Starter Kit today!
              </Typography>
              <Hidden only={["xs", "md", "lg", "xl"]}>
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <img
                    src="images/assets/kickstarter-kit.png"
                    alt="kickstarter kit"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Hidden>
              <Hidden smUp>
                <Box sx={{ p: 2, textAlign: "center" }}>
                  <img
                    src="images/assets/kickstarter-kit-mobile.png"
                    alt="kickstarter kit"
                    style={{ maxWidth: "100%" }}
                  />
                </Box>
              </Hidden>
              <Typography variant="body1" color="inherit" paragraph>
                Everything you need to start playing SolForge Fusion!
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "headingTextHighlight.main" }}
                paragraph
              >
                Each box contains:
              </Typography>
              <List dense={true} sx={{ listStyleType: "disc", ml: 2 }}>
                <ListItem sx={{ display: "list-item" }}>
                  4 unique decks (124 cards)
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  4 forgeborn cards
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>
                  12 double-sided minion cards
                </ListItem>
                <ListItem sx={{ display: "list-item" }}>2 playmats</ListItem>
              </List>

              <Box sx={{ mt: 4 }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      href="/products"
                      variant="contained"
                      color="primary"
                    >
                      Explore Products
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Hidden mdDown>
              <Grid item xs={12} md={6} sx={{ p: 2, textAlign: "right" }}>
                <img
                  src="images/assets/kickstarter-kit.png"
                  alt="kickstarter kit"
                  style={{ maxWidth: "100%" }}
                />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Hero>
    </>
  );
}
