import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";

import { API } from "aws-amplify";
// import awsExports from "../../../aws-exports";
import { customAPIHeader } from "../../utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({}));

export default function GeneralTab({ profile, setLoading }) {
  const classes = useStyles();

  const initialValues = {
    username: "",
    givenName: "",
    familyName: "",
    displayName: "",
    email: "",
    allowMarketing: false,
  };

  const [formFields, setFormFields] = useState(initialValues);

  useEffect(() => {
    const check = async () => {
      if (profile.id) {
        setLoading(true);
        var formData = initialValues;
        Object.keys(formData).forEach((key) => {
          if (profile[key] !== undefined) {
            formData[key] = profile[key];
          }
        });
        setFormFields(formData);

        setLoading(false);
      }
    };

    check();
  }, [profile]);

  return (
    <>
      <Formik
        initialValues={formFields}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          givenName: Yup.string().max(255, "Too Long").required("Required"),
          familyName: Yup.string().max(255, "Too Long").required("Required"),
          displayName: Yup.string().max(255, "Too Long").required("Required"),
          email: Yup.string()
            .max(255)
            .email("Must be a valid email")
            .required("Required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(true);
          if (values.username && values.username !== "") {
            var apiPostData = await API.post(
              "sfwApi",
              "/user/" + values.username,
              {
                body: values,
                headers: await customAPIHeader(),
              }
            );

            if (apiPostData.error) {
              postNotification("error", apiPostData.error);
            } else {
              postNotification("success", "Account updated");
            }
          }

          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.givenName && errors.givenName)}
                  fullWidth
                  helperText={touched.givenName && errors.givenName}
                  label="First Name"
                  margin="normal"
                  name="givenName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.givenName}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.familyName && errors.familyName)}
                  fullWidth
                  helperText={touched.familyName && errors.familyName}
                  label="Last Name"
                  margin="normal"
                  name="familyName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.familyName}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.displayName && errors.displayName)}
                  fullWidth
                  helperText={touched.displayName && errors.displayName}
                  label="Nickname / Handle"
                  margin="normal"
                  name="displayName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.displayName}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="allowMarketing"
                      checked={values.allowMarketing}
                      defaultChecked={false}
                      onChange={handleChange}
                    />
                  }
                  label="Allow Marketing"
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box className={classes.buttonWrapper}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Update General
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    color="primary"
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
