import React, { useContext, useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CardHeader,
  Link,
  Fade,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";

import CardCarousel from "./CardCarousel";
import CancelIcon from "@mui/icons-material/Cancel";
// import { GiCrossedSwords, GiHealthNormal } from "react-icons/gi";
import makeStyles from "@mui/styles/makeStyles";
import Markdown from "../layout/Markdown";
import { UserContext } from "../../context/UserContext";

const useStyles = makeStyles((theme) => ({
  root: {
    "@media (min-width: 700px)": {
      display: "flex",
    },
    "@media (max-width: 701px)": {
      justifyContent: "center",
    },
  },
  gridLeft: {
    padding: theme.spacing(1),
    maxWidth: "300px",
    "@media (min-width: 700px)": {
      flexGrow: 1,
    },
    "@media (max-width: 701px)": {
      display: "flex",
      justifyContent: "center",
      maxWidth: "unset",
    },
  },
  gridRight: {
    "@media (min-width: 700px)": {
      flexGrow: 1,
    },
    padding: theme.spacing(1),
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    //border: "2px solid #000",
    boxShadow: theme.shadows[5],
    //padding: theme.spacing(2, 4, 3),
  },
  modalCardWrapper: { position: "relative" },
  modalCloseBtn: {
    float: "right",
    position: "absolute",
    right: "10px",
    top: "0px",
    zIndex: "1000",
    padding: "5px",
  },
  modalCardImg: {
    maxHeight: "75vh",
    width: "100%",
    maxWidth: "100%",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
  listItemAvatarSmall: { minWidth: "30px" },
  avatarSmall: { width: "20px", height: "20px" },
  rotate45: {
    transform: "rotate(-45deg)",
  },
}));

const interleave = (arr, thing) =>
  [].concat(...arr.map((n) => [n, thing])).slice(0, -1);

export function replaceWithIcons(text) {
  var replaceItems = {};
  var match;
  text = text.replace(/\\/g, "");

  for (var i = 1; i <= 4; i++) {
    if (text.includes(`[l${i}]`)) {
      replaceItems[`[l${i}]`] = (
        <img
          src={`/images/cards/levels/lv${i}-icon.png`}
          alt={`Level ${i}`}
          style={{
            height: "18px",
            marginBottom: "-4px",
          }}
        />
      );
    }
  }

  let reBold = /<b>(.*?)<\/b>/g;
  while ((match = reBold.exec(text)) != null) {
    replaceItems[match[0]] = <b>{match[1]}</b>;
  }
  let reItalic = /<i>(.*?)<\/i>/g;
  while ((match = reItalic.exec(text)) != null) {
    replaceItems[match[0]] = <i>{match[1]}</i>;
  }

  let reA = /\d+[A]{1}/g;
  while ((match = reA.exec(text)) != null) {
    replaceItems[match[0]] = (
      <>
        {match[0].substring(0, match[0].length - 1)}
        <img
          src={`/images/icons/attack.png`}
          alt={`Attack`}
          style={{
            height: "18px",
            marginBottom: "-4px",
          }}
        />
        {/* <GiCrossedSwords style={{ color: "red" }} /> */}
      </>
    );
  }
  let reA1 = /(?:^|\W)A.(?:$|\W)/g;
  while ((match = reA1.exec(text)) != null) {
    replaceItems[match[0]] = (
      <>
        <img
          src={`/images/icons/attack.png`}
          alt={`Attack`}
          style={{
            height: "18px",
            marginBottom: "-4px",
            marginLeft: "0.25rem",
          }}
        />
        .
      </>
    );
  }
  let reA2 = /(?:^|\W)A(?:$|\W)/g;
  while ((match = reA2.exec(text)) != null) {
    replaceItems[match[0]] = (
      <img
        src={`/images/icons/attack.png`}
        alt={`Attack`}
        style={{
          height: "18px",
          marginBottom: "-4px",
          marginLeft: "0.25rem",
          marginRight: "0.25rem",
        }}
      />
    );
  }

  let reH = /\d+[H]{1}/g;
  while ((match = reH.exec(text)) != null) {
    replaceItems[match[0]] = (
      <>
        {match[0].substring(0, match[0].length - 1)}
        <img
          src={`/images/icons/health.png`}
          alt={`Health`}
          style={{
            height: "18px",
            marginBottom: "-4px",
          }}
        />
        {/* <GiHealthNormal style={{ color: "green" }} /> */}
      </>
    );
  }
  let reH1 = /(?:^|\W)H.(?:$|\W)/g;
  while ((match = reH1.exec(text)) != null) {
    replaceItems[match[0]] = (
      <>
        <img
          src={`/images/icons/health.png`}
          alt={`Health`}
          style={{
            height: "18px",
            marginBottom: "-4px",
            marginLeft: "0.25rem",
          }}
        />
        .
      </>
    );
  }
  let reH2 = /(?:^|\W)H(?:$|\W)/g;
  while ((match = reH2.exec(text)) != null) {
    if (match[0].length === 3) {
      // must be "inbetween"
      replaceItems[match[0]] = (
        <>
          {match[0][0]}
          <img
            src={`/images/icons/health.png`}
            alt={`Health`}
            style={{
              height: "18px",
              marginBottom: "-4px",
            }}
          />
          {match[0][2]}
        </>
      );
    } else if (match[0].length === 2) {
      if (match[0][0] === "H") {
        replaceItems[match[0]] = (
          <>
            <img
              src={`/images/icons/health.png`}
              alt={`Health`}
              style={{
                height: "18px",
                marginBottom: "-4px",
              }}
            />
            {match[0][1]}
          </>
        );
      } else {
        replaceItems[match[0]] = (
          <>
            {match[0][0]}
            <img
              src={`/images/icons/health.png`}
              alt={`Health`}
              style={{
                height: "18px",
                marginBottom: "-4px",
              }}
            />
          </>
        );
      }
    } else {
      replaceItems[match[0]] = (
        <img
          src={`/images/icons/health.png`}
          alt={`Health`}
          style={{
            height: "18px",
            marginBottom: "-4px",
          }}
        />
      );
    }
  }

  let reD = /\d[D]{1}/g;
  while ((match = reD.exec(text)) != null) {
    replaceItems[match[0]] = (
      <>
        {match[0].substring(0, match[0].length - 1)}
        <img
          src={`/images/icons/armor.png`}
          alt={`Armor`}
          style={{
            height: "18px",
            marginBottom: "-4px",
          }}
        />
      </>
    );
  }
  let reD1 = /(?:^|\W)D.(?:$|\W)/g;
  while ((match = reD1.exec(text)) != null) {
    replaceItems[match[0]] = (
      <>
        <img
          src={`/images/icons/armor.png`}
          alt={`Armor`}
          style={{
            height: "18px",
            marginLeft: "0.25rem",
            marginBottom: "-4px",
          }}
        />
        .
      </>
    );
  }
  let reD2 = /(?:^|\W)D(?:$|\W)/g;
  while ((match = reD2.exec(text)) != null) {
    replaceItems[match[0]] = (
      <img
        src={`/images/icons/armor.png`}
        alt={`Armor`}
        style={{
          height: "18px",
          marginBottom: "-4px",
          marginLeft: "0.25rem",
          marginRight: "0.25rem",
        }}
      />
    );
  }

  if (!Object.keys(replaceItems).length) {
    return text;
  }
  var newTextArray = [text];

  Object.keys(replaceItems).forEach((replaceItem) => {
    // loop through each replaceItem
    for (var i = 0; i < newTextArray.length; i++) {
      // loop through each already split item
      if (typeof newTextArray[i] === "string") {
        var item = newTextArray[i].split(replaceItem);
        if (item.length > 1) {
          item = interleave(item, replaceItems[replaceItem]);
          newTextArray.splice(i, 1, ...item);
        }
      }
    }
  });

  return newTextArray;
}

export default function CardWidget({ card }) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [index, setIndex] = useState(0);
  var userState = useContext(UserContext);

  return (
    <>
      {card && (
        <>
          <Box className={classes.root}>
            <Box className={classes.gridLeft}>
              <Link
                href="#"
                onClick={(e) => {
                  setModalOpen(true);
                }}
              >
                <CardCarousel
                  cardState={card}
                  height={350}
                  width={250}
                  isModal={false}
                  index={index}
                  setIndex={setIndex}
                />
              </Link>
            </Box>
            <Box className={classes.gridRight}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Card Name
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">{card.name || ""}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Faction
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">
                        {`${card.faction || ""} ${
                          card.betrayer ? " (Betrayer)" : ""
                        }`.trim()}
                      </Typography>
                    </Grid>

                    {card.crossFaction &&
                      card.crossFaction !== card.faction && (
                        <>
                          <Grid item xs={4}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >
                              Original Faction
                            </Typography>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body2">
                              {card.crossFaction}
                            </Typography>
                          </Grid>
                        </>
                      )}

                    <Grid item xs={4}>
                      <Typography variant="body2" sx={{ fontWeight: 600 }}>
                        Types
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="body2">{`${card.cardType || ""}${
                        card.cardSubType && card.cardSubType !== "0"
                          ? " - " + card.cardSubType
                          : ""
                      }`}</Typography>
                    </Grid>

                    {!card.levels && card.cardText && (
                      <>
                        <Grid item xs={4}>
                          <Typography
                            variant="body2"
                            paragraph
                            sx={{ fontWeight: 600 }}
                          >
                            Card Text
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          {card.cardText && (
                            <>
                              {card.cardText.split("\n").map((i, key) => (
                                <Typography variant="body2" key={key}>
                                  {i}
                                </Typography>
                              ))}
                            </>
                          )}
                        </Grid>
                      </>
                    )}
                    {card.levels && (
                      <>
                        {Object.keys(card.levels).map((level) => (
                          <>
                            <Grid item xs={12}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600 }}
                              >
                                {`Level ${level}`}{" "}
                              </Typography>
                            </Grid>
                            {(card.levels[level].attack ||
                              card.levels[level].health) && (
                              <>
                                <Grid item xs={4}>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: 600 }}
                                  >
                                    <img
                                      src={`/images/icons/attack.png`}
                                      alt={`Attack`}
                                      style={{
                                        height: "18px",
                                        marginBottom: "-4px",
                                      }}
                                    />
                                    /
                                    <img
                                      src={`/images/icons/health.png`}
                                      alt={`Health`}
                                      style={{
                                        height: "18px",
                                        marginBottom: "-4px",
                                      }}
                                    />
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <Typography variant="body2">
                                    {card.levels[level].attack !== undefined
                                      ? card.levels[level].attack + "/"
                                      : ""}
                                    {card.levels[level].health !== undefined
                                      ? card.levels[level].health
                                      : ""}
                                  </Typography>
                                </Grid>
                              </>
                            )}

                            <Grid item xs={4}>
                              <Typography
                                variant="body2"
                                sx={{ fontWeight: 600 }}
                              >
                                Card Text
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              {card.levels[level].text &&
                                card.levels[level].text !== "0" && (
                                  <>
                                    {card.levels[level].text
                                      .split("\n")
                                      .map((i, key) => (
                                        <Typography
                                          variant="body2"
                                          key={key}
                                          sx={{ minHeight: "0.875rem" }}
                                        >
                                          {replaceWithIcons(i)}
                                        </Typography>
                                      ))}
                                  </>
                                )}
                            </Grid>
                          </>
                        ))}
                      </>
                    )}

                    {card.rarity && (
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Rarity
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">{card.rarity}</Typography>
                        </Grid>
                      </>
                    )}

                    {card.masterCardSets?.length > 1 ? (
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Card Sets
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2" key={index}>
                            {card.masterCardSets
                              .sort((a, b) => a.no - b.no)
                              .map((cardSet, index) => {
                                if (cardSet.card === card.id) {
                                  return (
                                    <>
                                      {index > 0 ? <span>, </span> : <></>}
                                      <span
                                        style={
                                          index + 1 ===
                                          card.masterCardSets?.length
                                            ? {
                                                fontWeight: 700,
                                              }
                                            : null
                                        }
                                      >
                                        <img
                                          src={`/images/cards/rarity/S${
                                            cardSet.no
                                          }_${card.rarity
                                            .split(" ")
                                            .join("")}.png`}
                                          alt={card.rarity}
                                          style={{
                                            height: "18px",
                                            marginBottom: "-4px",
                                          }}
                                        />{" "}
                                        {cardSet.name}
                                      </span>
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      {index > 0 ? <span>, </span> : <></>}
                                      <Link
                                        href={`/cards/${cardSet.card}`}
                                        style={
                                          index + 1 ===
                                          card.masterCardSets?.length
                                            ? {
                                                fontWeight: 700,
                                              }
                                            : null
                                        }
                                      >
                                        <img
                                          src={`/images/cards/rarity/S${
                                            cardSet.no
                                          }_${card.rarity
                                            .split(" ")
                                            .join("")}.png`}
                                          alt={card.rarity}
                                          style={{
                                            height: "18px",
                                            marginBottom: "-4px",
                                          }}
                                        />{" "}
                                        {cardSet.name}
                                      </Link>
                                    </>
                                  );
                                }
                              })}
                          </Typography>
                        </Grid>
                      </>
                    ) : card.cardSetName ? (
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Card Set
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            <img
                              src={`/images/cards/rarity/S${
                                card.cardSetNo
                              }_${card.rarity.split(" ").join("")}.png`}
                              alt={card.rarity}
                              style={{
                                height: "18px",
                                marginBottom: "-4px",
                              }}
                            />{" "}
                            {card.cardSetName}
                          </Typography>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* Change this to not show if 0 */}
                    {card.stats?.registeredDeckCount !== undefined && (
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Found in the wild
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {card.stats.registeredDeckCount}
                          </Typography>
                        </Grid>
                      </>
                    )}

                    {card.protectedStats?.deckCount !== undefined && (
                      <>
                        <Grid item xs={4}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Total Deck Count
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography variant="body2">
                            {card.protectedStats.deckCount}
                          </Typography>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          </Box>
          {(Boolean(card.faq) || userState?.access?.isAdmin) && (
            <Box sx={{ width: "100%" }}>
              <Card>
                <CardContent className={classes.cardContent}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">FAQ</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Markdown source={card.faq || ""} />
                    </Grid>
                    <Grid item xs={12} textAlign="right">
                      <Link
                        href={`/app/admin/cards/${card.PK}/faq`}
                        sx={{ textDecoration: "none" }}
                      >
                        Edit
                      </Link>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Box>
          )}
          <Modal
            disableAutoFocus
            open={modalOpen}
            onClose={(e) => setModalOpen(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            sx={{ zIndex: 99990 }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                // width: ["95vw", "85vw", 600],
                // maxHeight: "95vh",

                // bgcolor: "background.paper",
                // border: "1px solid rgba(0, 0, 0, 0.12)",
                // borderRadius: 1,
                boxShadow: 24,
              }}
            >
              <Box className={classes.modalPaper}>
                <Box className={classes.modalCardWrapper}>
                  <IconButton
                    edge="end"
                    aria-label="Close"
                    onClick={(e) => {
                      setModalOpen(false);
                    }}
                    variant="contained"
                    color="inherit"
                    fontSize="large"
                    className={classes.modalCloseBtn}
                    size="large"
                  >
                    <CancelIcon />
                  </IconButton>

                  <CardCarousel
                    cardState={card}
                    height={350 * 1.5}
                    width={250 * 1.5}
                    isModal={true}
                    index={index}
                    setIndex={setIndex}
                  />
                </Box>
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}
