import { Box, Typography } from "@mui/material";

export default function RankChip(props) {
  // const {  } = props;
  const rank = props.rank || "Unranked";
  return (
    <Box
      sx={{
        display: "flex",
        width: "fit-content",
        backgroundColor: `${rank.toLowerCase()}.main`,
        color: `${rank.toLowerCase()}.contrastText`,
        border: "2px solid #FFFFFF",
        borderRadius: "4px",
        p: 1,
      }}
    >
      <Typography
        variant="caption"
        color="inherit"
        sx={{
          mb: 0,
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          display: "flex",
          alignItems: "center",
        }}
      >
        {rank}
      </Typography>
    </Box>
  );
}
{
  /* <Chip
          label="Silver"
          color="silver"
          sx={{
            borderRadius: "4px",
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "700",
            lineHeight: "19px",
            letterSpacing: "0em",
          }}
        /> */
}
