import React, { useState, useEffect } from "react";
import ProgressModal from "../../components/modals/ProgressModal";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  IconButton,
  Modal,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  preview: {
    maxWidth: "100%",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    /*height: "60vh",
    overflowY: "scroll",*/
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
}));

export default function CategoryItem(props) {
  const classes = useStyles();

  const initialValues = {
    id: null,
    name: "",
    slug: "",
  };
  const [category, setCategory] = useState(initialValues);

  const validationSchema = {
    name: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
    slug: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
  };

  const [open, setOpen] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);

  const [slugInfo, setSlugInfo] = useState({
    manual: false,
  });

  useEffect(() => {
    const check = async () => {
      if (props.catagoryId) {
        const apiGetData = await API.get(
          "sfwApi",
          "/category/" + props.catagoryId
        );
        setCategory({ ...category, ...apiGetData });
      }
    };

    check();
  }, [props.catagoryId]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="button"
        color={props.color || "secondary"}
        variant={props.variant || "contained"}
      >
        Add Category
      </Button>
      <Modal
        disableAutoFocus
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ zIndex: 99990 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Add Category"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />

            <ProgressModal open={progressOpen} />
            <Formik
              initialValues={category}
              enableReinitialize={true}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                setProgressOpen(true);
                try {
                  var postBody = {
                    name: values.name,
                    slug: values.slug,
                  };
                  var vCategoryId = values.id;

                  var apiPostData = await API.post(
                    "sfwApi",
                    `/category${vCategoryId ? "/" + vCategoryId : ""}`,
                    {
                      body: postBody,
                    }
                  );
                  if (apiPostData.data) {
                    props.updateItems(apiPostData.data, false);

                    if (
                      !props.categoryId &&
                      apiPostData.data &&
                      apiPostData.data.id
                    ) {
                      setFieldValue("id", apiPostData.data.id);
                    }
                    setOpen(false);
                    postNotification(
                      "success",
                      `Category ${props.avatarId ? "updated" : "created"}`
                    );
                  } else if (apiPostData.error) {
                    postNotification("error", apiPostData.error);
                  }

                  setSubmitting(false);
                  setProgressOpen(false);
                } catch (error) {
                  postNotification(
                    "error",
                    error.message || JSON.stringify(error)
                  );
                  setSubmitting(false);
                  setProgressOpen(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Name"
                          margin="normal"
                          name="name"
                          onBlur={async (e) => {
                            handleBlur(e);
                            if (!slugInfo.manual) {
                              var slug = e.target.value
                                .split(" ")
                                .join("-")
                                .toLowerCase();
                              setFieldValue("slug", slug);
                            }
                          }}
                          onChange={(e) => {
                            handleChange(e);
                            if (!slugInfo.manual) {
                              setFieldValue(
                                "slug",
                                e.target.value
                                  .split(" ")
                                  .join("-")
                                  .toLowerCase()
                              );
                            }
                          }}
                          value={values.name}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.slug && errors.slug)}
                          fullWidth
                          helperText={touched.slug && errors.slug}
                          label="Slug"
                          margin="normal"
                          name="slug"
                          onBlur={async (e) => {
                            if (!slugInfo.manual) {
                              setSlugInfo({
                                ...slugInfo,
                                manual: true,
                              });
                            }
                            e.target.value = e.target.value
                              .split(" ")
                              .join("-")
                              .toLowerCase();
                            handleBlur(e);
                          }}
                          onChange={(e) => {
                            e.target.value = e.target.value
                              .split(" ")
                              .join("-")
                              .toLowerCase();
                            handleChange(e);
                            if (!slugInfo.manual) {
                              setSlugInfo({
                                ...slugInfo,
                                manual: true,
                              });
                            }
                          }}
                          value={values.slug}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button type="submit" color="primary" variant="contained">
                      Save
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      </Modal>
    </>
  );
}
