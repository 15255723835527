import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import {
  getResizedFileName,
  splitUrl,
  findGetParameter,
  customAPIHeader,
} from "../../components/utils/UtilityFunctions";
import { UserContext } from "../../context/UserContext";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  FormHelperText,
  Grid,
  IconButton,
  Link,
  Modal,
  TextField,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LinkIcon from "@mui/icons-material/Link";
import CancelIcon from "@mui/icons-material/Cancel";

import { useParams } from "react-router-dom";

import Deck from "../../components/decks/Deck";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

import * as Yup from "yup";
import { Formik } from "formik";

import MainPageHeader from "../../components/layout/MainPageHeader";
import { postNotification } from "../../components/utils/Notifications";
import LoadingModal from "../../components/modals/LoadingModal";

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    width: "100%",
    height: "calc(100vh - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

export default function HalfDeck() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  let { deckId } = useParams();
  const unlock = findGetParameter("unlock");
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [deck, setDeck] = useState({});

  const [storyModal, setStoryModal] = useState(false);

  const fetchDeck = async () => {
    const queryStringParameters = {
      inclCards: true,
      inclUsers: true,
    };
    if (userState && userState.user && userState.user.username) {
      queryStringParameters.username = userState.user.username;
    }
    if (unlock) {
      queryStringParameters.unlockCode = unlock;
    }

    const apiGetData = await API.get("sfwApi", "/deck/" + deckId, {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    if (apiGetData.error) {
      console.log(apiGetData.error);
    } else {
      apiGetData.forgeborn.faction = apiGetData.faction;
      apiGetData.creatures = [];
      apiGetData.spells = [];
      var stats = [];
      var cardBreakdown = {
        title: "Card Type Breakdown",
        legend: {},
      };
      var creatureTypes = {
        title: "Creature Breakdown",
        legend: {},
      };
      if (
        apiGetData.cards &&
        Object.keys(apiGetData.cards).length === apiGetData.cardList?.length
      ) {
        apiGetData.cardList.forEach((card, index) => {
          apiGetData.cardList[index].deckId = apiGetData.id;
          apiGetData.cardList[index].cardNo = index + 1;
          // apiGetData.cards[cardNo].faction = apiGetData.faction;
          // apiGetData.cards[cardNo].imageUrl = apiGetData.imageUrl;
          if (!cardBreakdown.legend[card.cardType]) {
            cardBreakdown.legend[card.cardType] = 0;
          }
          cardBreakdown.legend[card.cardType]++;

          if (card.cardType === "Creature") {
            apiGetData.creatures.push(card);
            // if cardSubType has spaces, include original AND split into individual words
            var subTypes = card.cardSubType.split(" ");
            if (subTypes.length > 1) {
              subTypes.forEach((subType) => {
                if (!creatureTypes.legend[subType]) {
                  creatureTypes.legend[subType] = 0;
                }
                creatureTypes.legend[subType]++;
              });
            }
            if (!creatureTypes.legend[card.cardSubType]) {
              creatureTypes.legend[card.cardSubType] = 0;
            }
            creatureTypes.legend[card.cardSubType]++;
          } else if (card.cardType === "Spell") {
            apiGetData.spells.push(card);
          }
        });
      } else if (apiGetData.cards) {
        Object.keys(apiGetData.cards).forEach((cardNo) => {
          apiGetData.cards[cardNo].deckId = apiGetData.id;
          apiGetData.cards[cardNo].cardNo = cardNo;
          apiGetData.cards[cardNo].faction = apiGetData.faction;
          apiGetData.cards[cardNo].imageUrl = apiGetData.imageUrl;
          if (!cardBreakdown.legend[apiGetData.cards[cardNo].cardType]) {
            cardBreakdown.legend[apiGetData.cards[cardNo].cardType] = 0;
          }
          cardBreakdown.legend[apiGetData.cards[cardNo].cardType]++;

          if (apiGetData.cards[cardNo].cardType === "Creature") {
            apiGetData.creatures.push(apiGetData.cards[cardNo]);
            // if cardSubType has spaces, include original AND split into individual words
            var subTypes = apiGetData.cards[cardNo].cardSubType.split(" ");
            if (subTypes.length > 1) {
              subTypes.forEach((subType) => {
                if (!creatureTypes.legend[subType]) {
                  creatureTypes.legend[subType] = 0;
                }
                creatureTypes.legend[subType]++;
              });
            }
            if (!creatureTypes.legend[apiGetData.cards[cardNo].cardSubType]) {
              creatureTypes.legend[apiGetData.cards[cardNo].cardSubType] = 0;
            }
            creatureTypes.legend[apiGetData.cards[cardNo].cardSubType]++;
          } else if (apiGetData.cards[cardNo].cardType === "Spell") {
            apiGetData.spells.push(apiGetData.cards[cardNo]);
          }
        });
      }

      var ctbArray = [];
      Object.keys(cardBreakdown.legend).forEach((cardType) => {
        ctbArray.push({
          name: cardType,
          value: cardBreakdown.legend[cardType],
        });
      });
      ctbArray.sort((a, b) => {
        if (a.value === undefined && b.value === undefined) return 0;
        if (a.value === undefined) return 1;
        if (b.value === undefined) return -1;
        if (a.value === b.value) {
          var aVal2 = a.name.toUpperCase();
          var bVal2 = b.name.toUpperCase();

          if (aVal2 < bVal2) return -1;
          if (aVal2 > bVal2) return 1;
        }
        return b.value - a.value;
      });
      cardBreakdown.legend = ctbArray;
      stats.push(cardBreakdown);

      var ctArray = [];
      Object.keys(creatureTypes.legend).forEach((creatureType) => {
        ctArray.push({
          name: creatureType,
          value: creatureTypes.legend[creatureType],
        });
      });
      ctArray.sort((a, b) => {
        if (a.value === undefined && b.value === undefined) return 0;
        if (a.value === undefined) return 1;
        if (b.value === undefined) return -1;
        if (a.value === b.value) {
          var aVal2 = a.name.toUpperCase();
          var bVal2 = b.name.toUpperCase();

          if (aVal2 < bVal2) return -1;
          if (aVal2 > bVal2) return 1;
        }
        return b.value - a.value;
      });
      creatureTypes.legend = ctArray;
      stats.push(creatureTypes);

      setDeck({
        ...apiGetData,
        myUser: apiGetData.myUser,
        name: apiGetData.name,
        decks: [apiGetData],
        stats: stats,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const check = async () => {
      if (userState && userState.isLoading === false) {
        await fetchDeck();
      }
    };
    check();
  }, [userState]);

  const seo = {
    title:
      (deck && deck.name ? deck.name + " | Deck" : "Deck") +
      " | SolForge Fusion",
    description:
      deck && deck.name
        ? deck.name
        : "SolForge Fusion - the next generation of card games",
    image: findGetParameter("imageUrl")
      ? findGetParameter("imageUrl")
      : deck?.decks?.[0]?.forgeborn?.imageUrl
      ? `${splitUrl(deck.decks[0].forgeborn.imageUrl).url}${getResizedFileName(
          splitUrl(deck.decks[0].forgeborn.imageUrl).filename,
          "medium"
        )}`
      : "https://solforgefusion.com/images/icon-200x200.jpg",
  };

  return (
    <>
      {(loading || processing) && (
        <Container className={classes.containerLoading}>
          <CircularProgress size={100} />
        </Container>
      )}
      {!loading && (
        <>
          <Helmet
            title={seo.title}
            description={seo.description}
            meta={[
              {
                name: "description",
                property: "og:description",
                content: seo.description,
              },
              { property: "og:title", content: seo.title },
              { property: "og:image", content: seo.image },
              { property: "og:image:type", content: "image/jpeg" },
              { property: "twitter:image:src", content: seo.image },
              { property: "twitter:title", content: seo.title },
              { property: "twitter:description", content: seo.description },
            ]}
          />
          <MainPageHeader
            title={"Deck"}
            right={
              unlock && deck?.decks?.[0]?.unlockCode && !deck?.myUser ? (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<AddIcon />}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#B53114",
                    borderColor: "#B53114",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                  onClick={async () => {
                    try {
                      if (!userState?.user?.username) {
                        const loginUrl = `/login?claim=true&redirect=decks/${deckId}?token%3D${deck.name}%26unlock%3D${unlock}`
                        postNotification(
                          "error",
                          "Please login to claim your deck"
                        );
                        window.location.href= loginUrl;
                        return;
                      }

                      setProcessing(true);
                      var postBody = {
                        username: userState.user.username,
                        unlockCode: unlock,
                      };

                      var apiPostData = await API.post(
                        "sfwApi",
                        `/deck/${deckId}/unlock`,
                        {
                          body: postBody,
                          headers: await customAPIHeader(),
                        }
                      );
                      if (apiPostData.error) {
                        postNotification("error", apiPostData.error);
                      } else {
                        postNotification(
                          "success",
                          "Congratulations! Your deck has been unlocked."
                        );
                        navigate("/decks");
                      }
                    } catch (error) {
                      postNotification(
                        "error",
                        error.message || JSON.stringify(error)
                      );
                      setProcessing(false);
                    }
                  }}
                >
                  Claim
                </Button>
              ) : userState?.access?.isAdmin || userState?.access?.isEditor ? (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<LinkIcon />}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    color: "#B53114",
                    borderColor: "#B53114",
                    "&:hover": {
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                  onClick={() => {
                    setStoryModal(true);
                  }}
                >
                  Link Story
                </Button>
              ) : null
            }
          />
          <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
            {deck && (
              <Deck
                name={deck.name}
                user={deck.myUser}
                decks={deck.decks}
                stats={deck.stats}
                deck={deck}
              />
            )}
          </Container>
          {storyModal && deck && (
            <Modal
              open={true}
              onClose={() => setStoryModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: ["95vw", "85vw", 600],
                  maxHeight: "95vh",

                  bgcolor: "background.paper",
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRadius: 1,
                  boxShadow: 24,
                }}
              >
                <Card>
                  <CardHeader
                    title="Link Story"
                    action={
                      <IconButton
                        aria-label="close"
                        onClick={() => {
                          setStoryModal(false);
                        }}
                        size="large"
                      >
                        <CancelIcon />
                      </IconButton>
                    }
                  />

                  <Formik
                    initialValues={{
                      storySlug: deck.storySlug,
                    }}
                    onSubmit={async (values) => {
                      try {
                        setUpdating(true);
                        var apiDeckData = await API.post(
                          "sfwApi",
                          `/deck/${deck.id}`,
                          {
                            body: { storySlug: values.storySlug },
                            headers: await customAPIHeader(),
                          }
                        );

                        if (apiDeckData.error) {
                          postNotification("error", apiDeckData.error);
                        } else {
                          postNotification("success", "Story linked.");
                          await fetchDeck();
                        }
                      } catch (error) {
                        postNotification(
                          "error",
                          error.message || JSON.stringify(error)
                        );
                      }
                      setUpdating(false);
                      setStoryModal(false);
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.storySlug && errors.storySlug
                                )}
                                fullWidth
                                helperText={
                                  touched.storySlug && errors.storySlug
                                }
                                label="Story Slug"
                                margin="normal"
                                name="storySlug"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.storySlug}
                                color="secondary"
                                variant="outlined"
                              />
                              <FormHelperText>
                                Example:{" "}
                                <Link
                                  href={`/${values.storySlug}`}
                                  target="_blank"
                                >{`/${values.storySlug}`}</Link>
                              </FormHelperText>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <CardActions
                          sx={{
                            paddingTop: 1,
                            paddingRight: 2,
                            display: "block",
                            textAlign: "right",
                          }}
                        >
                          <Button
                            type="submit"
                            color="primary"
                            variant="contained"
                          >
                            Save
                          </Button>
                        </CardActions>
                      </form>
                    )}
                  </Formik>
                </Card>
              </Box>
            </Modal>
          )}
          {updating && <LoadingModal />}
        </>
      )}
    </>
  );
}
