import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";

import {
  Box,
  Button,
  Breadcrumbs,
  CardMedia,
  Chip,
  Container,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import {
  customAPIHeader,
  getResizedFileName,
} from "../../components/utils/UtilityFunctions";
import HomeIcon from "@mui/icons-material/Home";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { green, red } from "@mui/material/colors";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import ProgressModal from "../../components/modals/ProgressModal";

import makeStyles from "@mui/styles/makeStyles";
import { API, Auth } from "aws-amplify";

import MediaSelectModal from "../../components/modals/MediaSelectModal";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(2),
  },
}));

export default function CardEditFAQ() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  if (!userState.access.isAdmin && !userState.access.isEditor) {
    navigate("/access-denied");
  }

  const location = useLocation();
  let { cardId } = useParams();
  const [card, setCard] = useState(null);

  const [open, setOpen] = useState(true);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    const check = async () => {
      if (cardId !== undefined) {
        const apiGetData = await API.get("sfwApi", "/card/" + cardId, {
          queryStringParameters: {
            // inclBody: true,
            // inclAuthor: true,
            // inclFeaturedImage: true,
            // inclCategories: true,
            // postType: "Page",
          },
        });

        setCard(apiGetData);
      }

      setOpen(false);
      setProgress(0);
    };

    check();
  }, [cardId]);

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      {card && (
        <>
          <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
            <Link color="inherit" href="/app/admin">
              <HomeIcon color="inherit" />
            </Link>
            <Link color="inherit" href="/app/admin/cards/">
              Cards
            </Link>
            <Typography color="textPrimary">FAQ</Typography>
          </Breadcrumbs>

          <Formik
            initialValues={{
              faq: card?.faq || "",
            }}
            enableReinitialize
            onSubmit={async (values, { setSubmitting, setFieldValue }) => {
              setOpen(true);
              try {
                var apiPostData = await API.post(
                  "sfwApi",
                  `/card/${cardId}/faq`,
                  {
                    body: { faq: values.faq },
                    headers: await customAPIHeader(),
                  }
                );

                if (apiPostData.error) {
                  postNotification("error", apiPostData.error);
                } else {
                  // if (apiPostData.data) {
                  //   if (!postId && apiPostData.data.id) {
                  //     setFieldValue("id", apiPostData.data.id);
                  //     navigate(`${location.pathname}/${apiPostData.data.id}`, {
                  //       replace: false,
                  //     });
                  //   }
                  //   if (values.published !== apiPostData.data.published) {
                  //     setFieldValue("published", apiPostData.data.published);
                  //     setFieldValue("publish", false);
                  //     setFieldValue("unpublish", false);
                  //   }
                  // }

                  postNotification("success", `FAQ updated`);
                }

                setSubmitting(false);
                setOpen(false);
                setProgress(null);
              } catch (error) {
                postNotification(
                  "error",
                  error.message || JSON.stringify(error)
                );
                setSubmitting(false);
                setOpen(false);
                setProgress(null);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
              submitForm,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      {card?.cardSetId} {card?.cardSetName} - {card?.name}
                    </Typography>
                    <Typography variant="caption">
                      <Link
                        href={`/cards/${cardId}`}
                        target="_blank"
                        color="inherit"
                      >{`/cards/${cardId}`}</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <MDEditor
                      height={500}
                      preview="edit"
                      value={values.faq}
                      onChange={(val) => {
                        setFieldValue("faq", val);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Button
                          disabled={isSubmitting}
                          type="submit"
                          color="primary"
                          variant="contained"
                          fullWidth
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Box sx={{ padding: "50px 0 0 0" }} />
              </form>
            )}
          </Formik>
        </>
      )}
      <ProgressModal progress={progress} open={open} />
    </Container>
  );
}
