import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import {
  customAPIHeader,
  findGetParameter,
} from "../../components/utils/UtilityFunctions";
import { UserContext } from "../../context/UserContext";

import { CircularProgress, Container } from "@mui/material";

import { useParams } from "react-router-dom";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

import CardWidget from "../../components/decks/CardWidget";
import SolbindCardWidget from "../../components/decks/SolbindCardWidget"
import MainPageHeader from "../../components/layout/MainPageHeader";
import { postNotification } from "../../components/utils/Notifications";

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    width: "100%",
    height: "calc(100vh - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

export default function CardItem() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  let { cardId } = useParams();
  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState({});
  const [solbindCard1, setSolbindCard1] = useState({});
  const [solbindCard2, setSolbindCard2] = useState({});

  const fetchCard = async () => {
    try {
      const apiGetData = await API.get("sfwApi", `/card/${cardId}`, {
        headers: await customAPIHeader(),
      });

      setCard(apiGetData);
      if (apiGetData.solbindId1) {
        const apiGetDataSolbind1 = await API.get("sfwApi", `/card/${apiGetData.solbindId1}`, {
          headers: await customAPIHeader(),
        });
        setSolbindCard1(apiGetDataSolbind1);
      }
      if (apiGetData.solbindId2) {
        const apiGetDataSolbind2 = await API.get("sfwApi", `/card/${apiGetData.solbindId2}`, {
          headers: await customAPIHeader(),
        });
        setSolbindCard2(apiGetDataSolbind2);
      }
    } catch (err) {
      postNotification("error", err);
      window.location.assign("/cards");
      return;
    }
    setLoading(false);
  };

  useEffect(() => {
    const check = async () => {
      if (userState && userState.isLoading === false) {
        await fetchCard();
      }
    };
    check();
  }, [userState]);

  const seo = {
    title:
      (card && card.name ? "Card: " + card.name : "Deck") +
      " | SolForge Fusion",
    description:
      card && card.name
        ? card.name
        : "SolForge Fusion - the next generation of card games",
    image: findGetParameter("imageUrl")
      ? findGetParameter("imageUrl")
      : "https://solforgefusion.com/images/icon-200x200.jpg",
  };

  return (
    <>
      {loading && (
        <Container className={classes.containerLoading}>
          <CircularProgress size={100} />
        </Container>
      )}
      {!loading && (
        <>
          <Helmet
            title={seo.title}
            description={seo.description}
            meta={[
              {
                name: "description",
                property: "og:description",
                content: seo.description,
              },
              { property: "og:title", content: seo.title },
              { property: "og:image", content: seo.image },
              { property: "og:image:type", content: "image/jpeg" },
              { property: "twitter:image:src", content: seo.image },
              { property: "twitter:title", content: seo.title },
              { property: "twitter:description", content: seo.description },
            ]}
          />
          <MainPageHeader title={card?.name || "Card"} />
          <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
            {card && <CardWidget card={card} />}
            {card.solbindId1 && card.solbindId2 && <SolbindCardWidget solbindCard={solbindCard1} />}
            {card.solbindId1 && card.solbindId2 && <SolbindCardWidget solbindCard={solbindCard2} />}
          </Container>
        </>
      )}
    </>
  );
}
