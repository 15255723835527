import React, { useState } from "react";

import { useNavigate, matchPath, useLocation } from "react-router-dom";

import {
  Box,
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  Link,
  List,
  Toolbar,
  Grid,
  Modal,
  Backdrop,
  Card,
  CardContent,
  useMediaQuery,
  useTheme,
  Button,
  ListItem,
  Collapse,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon, ExpandLess, ExpandMore } from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import ClearIcon from "@mui/icons-material/Clear";
import QrCodeIcon from "@mui/icons-material/QrCode";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import SideNavItem from "./SideNavItem";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";

import ProfileMenu from "./ProfileMenu";
import CustomMenuItem from "./CustomMenuItem";
import ScanDeck from "../decks/ScanDeck";
import { getEnv } from "../utils/UtilityFunctions";

const env = getEnv();

export default function Header(props) {
  // global
  const {
    // drawerOpen,
    // setDrawerOpen,
    userState,
    // event,
    itemsTop,
    itemsMiddleTop,
    itemsMiddle,
    itemsBottom,
  } = props;

  const navigate = useNavigate();
  const theme = useTheme();

  const mediaLT800 = useMediaQuery("(max-width:799px)");
  const mediaLT1150 = useMediaQuery("(max-width:1149px)");
  const mediaLT1350 = useMediaQuery("(max-width:1349px)");
  const mediaLT1200 = useMediaQuery("(max-width:1199px)");

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [scanDeckOpen, setScanDeckOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const leftMenu = [];
  leftMenu.push({
    id: 0,
    href: `${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/news`,
    label: "News",
  });
  leftMenu.push({
    id: 10,
    label: "Purchase",
    children: [
      {
        href: `${
          process.env.REACT_APP_HEADER_SOLFORGE_URL || ""
        }/store-locator`,
        label: "Store Locator",
      },
      {
        href: "https://www.stoneblade.com/sf-store",
        label: "Buy Solforge Fusion",
      },
    ],
  });
  leftMenu.push({
    id: 20,
    href: `${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/how-to-play`,
    label: "How to Play",
  });
  leftMenu.push({
    id: 30,
    href: `${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/the-story`,
    label: "The Story",
  });
  leftMenu.push({
    id: 35,
    href: `${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/organized-play`,
    label: "Organized Play",
  });
  leftMenu.push({
    id: 40,
    href: `${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/faq`,
    label: "FAQ",
  });
  leftMenu.push({
    id: 45,
    href: `https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffnavbar`,
    label: "Solforge on Steam",
  });

  const rightMenu = [];
  rightMenu.push({
    id: 0,
    onClick: (e) => {
      setScanDeckOpen(!scanDeckOpen);
    },
    MenuIcon: <QrCodeIcon sx={{ mr: 1 }} />,
    label: "Scan Deck",
  });
  rightMenu.push({
    id: 10,
    MenuIcon: <PlayCircleIcon sx={{ mr: 1 }} />,
    label: "Events",
    children: [
      {
        href: `${
          env === "main"
            ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
            : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
        }/`,
        label: "Find Events",
        MenuIcon: <SearchIcon sx={{ mr: 1 }} />,
      },
      {
        href: `${
          env === "main"
            ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
            : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
        }/play/my-events`,
        label: "My Events",
        MenuIcon: <EventSeatIcon sx={{ mr: 1 }} />,
      },
      {
        href: `${
          env === "main"
            ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
            : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
        }/organize/events`,
        label: "Organize Events",
        MenuIcon: <CalendarMonthIcon sx={{ mr: 1 }} />,
      },
      {
        href: `${
          env === "main"
            ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
            : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
        }/rankings`,
        label: "Rankings",
        MenuIcon: <FormatListNumberedIcon sx={{ mr: 1 }} />,
      },
    ],
  });
  rightMenu.push({
    id: 20,
    href: `${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/cards`,
    MenuIcon: <SearchIcon sx={{ mr: 1 }} />,
    label: "Card Database",
  });
  const location = useLocation();

  const content = (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Hidden mdUp>
        <Box sx={{ pl: 2, pr: 2 }}>
          <List>
            <NavItem
              href={`${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/`}
              icon={HomeIcon}
              title="Home"
            />
            {!itemsMiddleTop &&
              itemsTop?.length > 0 &&
              itemsTop.map((item, index) => {
                if (item?.divider) {
                  return (
                    <Divider
                      key={index}
                      sx={{ mt: 2, mb: 2, ml: "-32px", mr: "-32px" }}
                    />
                  );
                }
                return <NavItem key={index} {...item} />;
              })}
            {itemsMiddleTop?.length > 0 &&
              itemsMiddleTop.map((item, index) => {
                if (item?.divider) {
                  return (
                    <Divider
                      key={index}
                      sx={{ mt: 2, mb: 2, ml: "-32px", mr: "-32px" }}
                    />
                  );
                }
                return <NavItem key={index} {...item} />;
              })}
          </List>
        </Box>
        {itemsMiddle && itemsMiddle.length > 0 && <Divider />}
      </Hidden>
      {itemsMiddle && itemsMiddle.length > 0 && (
        <Box sx={{ pl: 2, pr: 2 }}>
          <List>
            {itemsMiddle.map((item, index) => {
              if (item?.divider) {
                return (
                  <Divider
                    key={index}
                    sx={{ mt: 2, mb: 2, ml: "-32px", mr: "-32px" }}
                  />
                );
              }
              return <NavItem key={index} {...item} />;
            })}
          </List>
        </Box>
      )}
      <Box sx={{ flexGrow: 1 }} />
      {itemsBottom && itemsBottom.length > 0 && (
        <Box sx={{ pl: 2, pr: 2 }}>
          <Divider />
          <List>
            {itemsBottom.map((item, index) => {
              if (item?.divider) {
                return (
                  <Divider
                    key={index}
                    sx={{ mt: 2, mb: 2, ml: "-32px", mr: "-32px" }}
                  />
                );
              }
              return <NavItem key={index} {...item} />;
            })}
          </List>
        </Box>
      )}
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        // position="static"
        sx={{
          backgroundColor: "common.black",
          color: "common.white",
          // zIndex: 2000,
          // position: "relative",
          width: "100%",
        }}
      >
        <Toolbar>
          <Box
            sx={{
              display:
                mediaLT1350 || (mediaLT1200 && itemsMiddle?.length > 0)
                  ? "flex"
                  : "none",
            }}
          >
            <IconButton
              edge="start"
              sx={{ m: "unset" }}
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              flexGrow: [1, 1, null],
              textAlign: mediaLT1350 ? "center" : null,
            }}
          >
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href={`${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/`}
              sx={{
                ml: 2,
                mr: 2,

                "&:hover": {
                  color: "primary.main",
                  filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
                    theme.palette.primary.main
                  })`,
                  textDecoration: "none",
                },
              }}
            >
              <img
                src="/images/logo.png"
                alt="Solforge Fusion"
                style={{
                  maxHeight: "35px",
                  width: "unset",
                  borderRadius: "unset",
                  mr: ["unset", "unset", 2],
                  "& img": {
                    width: "unset",
                    height: "35px",
                    maxHeight: "35px",
                  },
                }}
              />
            </Link>
          </Box>

          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", display: mediaLT1350 ? "none" : "flex" }}
          >
            {leftMenu.map((menuItem, index) => {
              return (
                <Grid key={index} item xs="auto">
                  <CustomMenuItem {...menuItem} />
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              flexGrow: mediaLT800 ? null : 1,
            }}
          />
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ width: "100%", display: mediaLT800 ? "none" : "flex" }}
          >
            {rightMenu.map((menuItem, index) => {
              return (
                <Grid key={index} item xs="auto">
                  <CustomMenuItem {...menuItem} />
                </Grid>
              );
            })}
          </Grid>

          {!userState.isAuthenticated && (
            <CustomMenuItem
              href={process.env.REACT_APP_HEADER_SOLFORGE_URL ? null : "/login?redirect="+location.pathname}
              onClick={
                process.env.REACT_APP_HEADER_SOLFORGE_URL
                  ? (e) => {
                      userState.setIsLogin(true);
                    }
                  : null
              }
              label="Login"
              MenuIcon={<LoginIcon sx={{ ml: 1, mr: 1 }} />}
            />
          )}
          {userState.isAuthenticated && (
            <ProfileMenu userState={userState} navigate={navigate} />
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{ flexShrink: 0, zIndex: 1099 }}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "common.black",
            color: "common.white",
          },
        }}
      >
        <Toolbar />
        <Box
          role="presentation"
          // onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          sx={{ width: 350 }}
        >
          <List
            sx={{
              width: "100%",
              maxWidth: 350,
            }}
            component="nav"
          >
            {mediaLT1200 &&
              itemsMiddle?.length > 0 &&
              itemsMiddle.map((menuItem, index) => {
                return <SideNavItem key={index} {...menuItem} />;
              })}

            {mediaLT1350 && <Divider sx={{ borderColor: "#FFFFFF" }} />}

            {mediaLT1350 &&
              leftMenu.map((menuItem, index) => {
                return <SideNavItem key={index} {...menuItem} />;
              })}

            {mediaLT800 && <Divider sx={{ borderColor: "#FFFFFF" }} />}

            {mediaLT800 &&
              rightMenu.map((menuItem, index) => {
                return <SideNavItem key={index} {...menuItem} />;
              })}
          </List>
        </Box>
      </Drawer>
      <Modal
        disableAutoFocus
        open={scanDeckOpen}
        onClose={() => {
          setScanDeckOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        sx={{ zIndex: 99990 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <IconButton
            aria-label="cancel"
            color="error"
            sx={{ position: "absolute", top: "4px", right: "4px" }}
            onClick={() => {
              setScanDeckOpen(false);
            }}
          >
            <ClearIcon />
          </IconButton>
          <Card>
            <CardContent>
              <ScanDeck
                label={{
                  align: "left",
                  text: "Scan a Deck",
                }}
              />
            </CardContent>
          </Card>
        </Box>
      </Modal>

      <Hidden lgDown lgUp={Boolean(!itemsMiddle || itemsMiddle.length === 0)}>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
              overflowX: "clip",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </Box>
  );
}

const NavItem = (props) => {
  const {
    onClick,
    href,
    icon: Icon,
    title,
    target,
    children,
    nested,
    ...rest
  } = props;

  const theme = useTheme();
  const location = useLocation();
  var [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const active = href
    ? !!matchPath(
        {
          path: href,
          end: false,
        },
        location.pathname
      )
    : false;

  if (children) {
    return (
      <>
        <ListItem
          disableGutters
          sx={{ display: "flex", pb: 0 }}
          onClick={handleClick}
        >
          <Button
            fullWidth
            component={Link}
            sx={{
              color: active ? "primary.main" : "text.secondary",
              fontWeight: "500",
              justifyContent: "flex-start",
              letterSpacing: 0,
              padding: `10px ${theme.spacing(1)}`,
              textTransform: "none",
              "& svg": {
                marginRight: 1,
              },
            }}
            href={href || null}
          >
            {Icon && <Icon size="24" />}
            <span>{title}</span>
          </Button>
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="Box" disablePadding>
            {children.map((child, index) => (
              <NavItem key={index} nested {...child} />
            ))}
          </List>
        </Collapse>
      </>
    );
  } else {
    return (
      <ListItem disableGutters sx={{ display: "flex", pb: 0 }} {...rest}>
        {Boolean(href || onClick) ? (
          <Button
            fullWidth
            component={href ? Link : null}
            sx={
              nested
                ? {
                    color: "text.secondary",
                    fontWeight: "500",
                    justifyContent: "flex-start",
                    letterSpacing: 0,
                    padding: `10px ${theme.spacing(1)}`,
                    textTransform: "none",
                    // width: "200px", //"100%",
                    // minWidth: "200px",
                    // maxWidth: "200px",
                    "& svg": {
                      marginRight: 1,
                    },

                    marginLeft: 2,

                    fontSize: "0.8rem",
                    "&:hover": {
                      color: "primary.main",
                      filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
                        theme.palette.primary.main
                      })`,
                      textDecoration: "none",
                    },
                  }
                : {
                    color: active ? "primary.main" : "text.secondary",
                    fontWeight: "500",
                    justifyContent: "flex-start",
                    letterSpacing: 0,
                    padding: `10px ${theme.spacing(1)}`,
                    textTransform: "none",
                    "& svg": {
                      marginRight: 1,
                    },
                  }
            }
            href={href || null}
            target={target || null}
            onClick={onClick || null}
          >
            {Icon && <Icon size="24" />}
            <span>{title}</span>
          </Button>
        ) : (
          <Typography sx={{ width: "100%" }}>
            {Icon && <Icon size="24" />}
            <span
              style={{
                maxWidth: Icon ? "calc(100% - 24px)" : "100%",
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {title}
            </span>
          </Typography>
        )}
      </ListItem>
    );
  }
};
