import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
} from "@mui/material";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";

import { API } from "aws-amplify";
// import awsExports from "../../../aws-exports";
import { customAPIHeader } from "../../utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

export default function SocialsTab({ profile, setLoading }) {
  const classes = useStyles();

  const initialValues = {
    username: "",
    allowPublicSocials: !!profile.allowPublicSocials || false,
    Facebook: "",
    Twitter: "",
    Instagram: "",
    YouTube: "",
    Twitch: "",
    Discord: "",
  };

  const [formFields, setFormFields] = useState(initialValues);

  useEffect(() => {
    const check = async () => {
      setLoading(true);
      if (!profile.socialLinks) {
        profile.socialLinks = {};
      }
      setLoading(true);
      var formData = initialValues;
      Object.keys(formData).forEach((key) => {
        if (profile.socialLinks[key] !== undefined) {
          formData[key] = profile.socialLinks[key];
        } else if (profile[key] !== undefined) {
          formData[key] = profile[key];
        }
      });
      setFormFields(formData);

      setLoading(false);

      setFormFields(formData);
      setLoading(false);
    };

    check();
  }, [profile]);

  return (
    <>
      <Formik
        initialValues={formFields}
        enableReinitialize={true}
        validationSchema={Yup.object().shape({
          Facebook: Yup.string().url("Enter correct url"),
          Twitter: Yup.string().url("Enter correct url"),
          Instagram: Yup.string().url("Enter correct url"),
          YouTube: Yup.string().url("Enter correct url"),
          Twitch: Yup.string().url("Enter correct url"),
          Discord: Yup.string().url("Enter correct url"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setLoading(true);
          var data = {
            allowPublicSocials: values.allowPublicSocials,
            socialLinks: {
              Facebook: values.Facebook,
              Twitter: values.Twitter,
              Instagram: values.Instagram,
              YouTube: values.YouTube,
              Twitch: values.Twitch,
              Discord: values.Discord,
            },
          };

          if (values.username && values.username !== "") {
            var apiPostData = await API.post(
              "sfwApi",
              "/user/" + values.username,
              {
                body: data,
                headers: await customAPIHeader(),
              }
            );

            if (apiPostData.error) {
              postNotification("error", apiPostData.error);
            } else {
              postNotification("success", "Account updated");
            }
          }

          setSubmitting(false);
          setLoading(false);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="allowPublicSocials"
                      checked={values.allowPublicSocials}
                      defaultChecked={false}
                      onChange={handleChange}
                    />
                  }
                  label="Show Socials on Public Profile"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.Facebook && errors.Facebook)}
                  fullWidth
                  helperText={touched.Facebook && errors.Facebook}
                  label="Facebook"
                  margin="normal"
                  name="Facebook"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Facebook}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.Twitter && errors.Twitter)}
                  fullWidth
                  helperText={touched.Twitter && errors.Twitter}
                  label="Twitter"
                  margin="normal"
                  name="Twitter"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Twitter}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.Instagram && errors.Instagram)}
                  fullWidth
                  helperText={touched.Instagram && errors.Instagram}
                  label="Instagram"
                  margin="normal"
                  name="Instagram"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Instagram}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.YouTube && errors.YouTube)}
                  fullWidth
                  helperText={touched.YouTube && errors.YouTube}
                  label="YouTube"
                  margin="normal"
                  name="YouTube"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.YouTube}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.Twitch && errors.Twitch)}
                  fullWidth
                  helperText={touched.Twitch && errors.Twitch}
                  label="Twitch"
                  margin="normal"
                  name="Twitch"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Twitch}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error={Boolean(touched.Discord && errors.Discord)}
                  fullWidth
                  helperText={touched.Discord && errors.Discord}
                  label="Discord"
                  margin="normal"
                  name="Discord"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Discord}
                  color="secondary"
                  variant="outlined"
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Box className={classes.buttonWrapper}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  Update Socials
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    color="primary"
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
}
