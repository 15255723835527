import { Box, Typography } from "@mui/material";

export default function CustomChip(props) {
  const { label, wrapperSX, typographySX, color } = props;
  return (
    <Box
      sx={{
        color: color ? `${color.toLowerCase()}.contrastText` : "#FFFFFF",
        background: color ? `${color.toLowerCase()}.main` : "#383838",
        border: "2px solid #FFFFFF",
        borderRadius: "4px",
        display: "flex",
        width: "fit-content",
        p: 1,
        ...wrapperSX,
      }}
    >
      <Typography
        variant="caption"
        color="inherit"
        sx={{
          mb: 0,
          fontFamily: "'Inter'",
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "16px",
          lineHeight: "19px",
          display: "flex",
          alignItems: "center",
          ...typographySX,
        }}
      >
        {label}
      </Typography>
    </Box>
  );
}
