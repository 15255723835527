import React from "react";
import { Helmet } from "react-helmet";
import { titleCase, findGetParameter } from "../utils/UtilityFunctions";
import { Box, CssBaseline } from "@mui/material";
import { Outlet } from "react-router-dom";

import AdminHeader from "../nav/AdminHeader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  root: { maxWidth: "100%", minWidth: "100%", overflowX: "hidden" },
  appMain: {
    minHeight: "calc(100vh - 390px)",
  },
}));

export default function AdminLayout() {
  const classes = useStyles();

  var pathSplit = window.location.pathname.split("/");
  var basePath = "Admin";
  if (pathSplit.length > 3) {
    basePath +=
      ": " +
      titleCase(window.location.pathname.split("/").pop().split("-").join(" "));
  }

  const title = basePath + " | SolForge Fusion";

  const seo = {
    title: title,
    description: "SolForge Fusion - the next generation of card games",
    image: findGetParameter("imageUrl")
      ? findGetParameter("imageUrl")
      : "https://solforgefusion.com/images/icon-200x200.jpg",
  };
  return (
    <>
      <Helmet
        title={seo.title}
        description={seo.description}
        meta={[
          {
            name: "description",
            property: "og:description",
            content: seo.description,
          },
          { property: "og:title", content: seo.title },
          { property: "og:image", content: seo.image },
          { property: "og:image:type", content: "image/jpeg" },
          { property: "twitter:image:src", content: seo.image },
          { property: "twitter:title", content: seo.title },
          { property: "twitter:description", content: seo.description },
        ]}
      />
      <Box className={classes.root}>
        <CssBaseline />
        <AdminHeader />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <main className={classes.appMain}>
          <Outlet />
        </main>
      </Box>
    </>
  );
}
