import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 99999, //theme.zIndex.drawer + 1,
    color: theme.palette.primary.main,
  },
}));

export default function LoadingModal() {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}
