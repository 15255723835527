import { Storage } from "aws-amplify";

export const checkExists = async (
  fileName = "",
  folder = "",
  allowOverride = false
) => {
  if (!fileName || fileName === "") {
    throw new Error("fileName is a mandatory field");
  }
  var splitFilename = fileName.split(".");
  var ext = splitFilename.length > 1 ? "." + splitFilename.pop() : "";
  var tempName = splitFilename.join(".");
  var returnObj = {
    override: false,
    name: tempName,
    ext: ext,
    fileName: fileName,
    count: 0,
  };
  return Storage.list(folder)
    .then((list) => {
      var found = false;
      if (list && list.length > 0) {
        do {
          found = false;
          for (let i = 0; i < list.length; i++) {
            if (list[i].key === returnObj.fileName) {
              found = true;
              if (allowOverride) {
                returnObj.override = true;
              } else {
                returnObj.count++;
                returnObj.fileName = `${returnObj.name}-${returnObj.count}${returnObj.ext}`;
              }
              break;
            }
          }
        } while (found && !allowOverride);
      }
      if (found && !allowOverride) {
        throw new Error("File already exists");
      }
      return returnObj;
    })
    .catch((error) => {
      throw error;
    });
};

export const uploadFile = async (
  file,
  fileName = "",
  folder = "",
  allowOverride = false,
  progCallBack = undefined
) => {
  if (file) {
    var tempFileName = (fileName && fileName !== "" ? fileName : file.name)
      .split(" ")
      .join("_");
    try {
      var result = await checkExists(tempFileName, folder, allowOverride);

      if (result) {
        if (result.fileName) {
          var data;
          if (progCallBack === undefined) {
            data = await Storage.put(
              `${folder && folder !== "" ? folder + "/" : ""}${
                result.fileName
              }`,
              file,
              {
                acl: "public-read",
              }
            );
          } else {
            data = await Storage.put(
              `${folder && folder !== "" ? folder + "/" : ""}${
                result.fileName
              }`,
              file,
              {
                acl: "public-read",
                progressCallback(progress) {
                  progCallBack(progress);
                },
              }
            );
          }
          result = {
            ...result,
            ...data,
          };
          return result;
        }
        throw new Error("Error checking if file exists");
      } else {
        throw new Error("Error checking if file exists");
      }
    } catch (error) {
      throw error;
    }
  } else {
    throw new Error("No file to upload");
  }
};
