import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  LinearProgress,
  InputAdornment,
  SvgIcon,
  IconButton,
  Button,
  ButtonGroup,
  Typography,
  CircularProgress,
} from "@mui/material";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";

import { FaSearch } from "react-icons/fa";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import makeStyles from "@mui/styles/makeStyles";
import GenericPicker from "../utils/GenericPicker";

const useStyles = makeStyles((theme) => ({
  searchField: {
    "& .MuiOutlinedInput-input": {
      padding: theme.spacing(1),
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: theme.spacing(0),
    },
  },
}));

export default function ListResponsive(props) {
  const {
    originalList,
    searchProps,
    rowProps,
    doPages,
    initialRowsPerPage,
    doSearch,
    actionButton,
    rowsPerPageArray,
    pagingFunction,
    endOfPaging,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [processing, setProcessing] = useState(false);

  const [currentSearch, setCurrentSearch] = useState(null);
  const [currentList, setCurrentList] = useState(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage || 10);
  var rowsPerPageOptions =
    rowsPerPageArray !== undefined
      ? rowsPerPageArray
      : [10, 25, 50, 100, { label: "All", value: -1 }];
  if (
    initialRowsPerPage &&
    rowsPerPageOptions &&
    !rowsPerPageOptions.includes(initialRowsPerPage)
  ) {
    for (var i = 0; i < rowsPerPageOptions.length; i++) {
      if (Number.isInteger(rowsPerPageOptions[i])) {
        if (rowsPerPageOptions[i] > initialRowsPerPage) {
          rowsPerPageOptions.splice(i, 0, initialRowsPerPage);
          break;
        }
      } else if (rowsPerPageOptions[i].label) {
        rowsPerPageOptions.splice(i, 0, initialRowsPerPage);
        break;
      }
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleFirstPageButtonClick = (event) => {
    handleChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    handleChangePage(event, page - 1);
  };

  const handleNextButtonClick = async (event) => {
    if (
      page >=
      Math.ceil((currentList ? currentList.length : 0) / rowsPerPage) - 1
    ) {
      setProcessing(true);
      await pagingFunction();
      setProcessing(false);
    }
    handleChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    handleChangePage(
      event,
      Math.max(
        0,
        Math.ceil((currentList ? currentList.length : 0) / rowsPerPage) - 1
      )
    );
  };

  const performSearch = (searchText) => {
    if (!searchText || searchText === "" || !originalList?.length) {
      setCurrentList(originalList);
    } else {
      var filteredList = searchProps.performSearch(originalList, searchText);
      setCurrentList(filteredList);
    }
  };

  useEffect(() => {
    const check = async () => {
      setCurrentList(originalList || null);
      if (currentSearch) performSearch(currentSearch);
    };
    check();
  }, [originalList]);

  return (
    <>
      {Boolean(doSearch || actionButton) && (
        <Grid container spacing={2} sx={{ mb: 2 }}>
          {doSearch && (
            <Grid item xs={12} md={actionButton ? 6 : 12}>
              <TextField
                fullWidth
                className={classes.searchField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <FaSearch />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
                placeholder={searchProps.label}
                variant="outlined"
                onChange={(e) => {
                  performSearch(e.target.value.toLowerCase());
                  setCurrentSearch(e.target.value.toLowerCase());
                }}
              />
            </Grid>
          )}
          {actionButton ? (
            Array.isArray(actionButton) ? (
              <Grid item xs={12} md={doSearch ? 6 : 12}>
                <ButtonGroup fullWidth>
                  {actionButton.map((action, index) => {
                    return (
                      <Button key={index} {...action.props}>
                        {action.label}
                      </Button>
                    );
                  })}
                </ButtonGroup>
              </Grid>
            ) : (
              <Grid item xs={12} md={doSearch ? 6 : 12}>
                <Button {...actionButton.props}>{actionButton.label}</Button>
              </Grid>
            )
          ) : null}
        </Grid>
      )}
      <Grid container sx={{ width: "100%" }}>
        {rowProps.headings}
        {!originalList || !currentList || processing ? (
          <Grid item xs={12} sx={{ height: "50px" }}>
            <LinearProgress />
          </Grid>
        ) : (
          (rowsPerPage > 0 && doPages
            ? currentList.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : currentList
          ).map(rowProps.mapFunction)
        )}
      </Grid>
      {doPages && originalList && currentList && (
        <Grid container sx={{ mt: 1, alignItems: "center" }}>
          {rowsPerPageOptions?.length > 1 && (
            <Grid item xs={12} sm={6}>
              <GenericPicker
                label="Rows per page"
                required
                inclAny={false}
                //name="rankingsAlgorithm"
                list={rowsPerPageOptions}
                value={rowsPerPage}
                formControlSX={{
                  "& .MuiSelect-select": {
                    p: "4px",
                  },
                }}
                handleChange={(e) => {
                  setRowsPerPage(e.target.value);
                }}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={rowsPerPageOptions?.length > 1 ? 6 : 12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: ["center", "flex-end"],
              }}
            >
              <Typography sx={{ display: "table-cell" }}>
                {`${page * rowsPerPage + 1}-${
                  page * rowsPerPage + rowsPerPage
                } of ${
                  !processing
                    ? `${currentList?.length}${
                        endOfPaging === false ? "+" : ""
                      }`
                    : ""
                }`}
                {processing && <CircularProgress size={12} />}
              </Typography>
              <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0 || processing}
                aria-label="first page"
                size="large"
              >
                {theme.direction === "rtl" ? (
                  <LastPageIcon />
                ) : (
                  <FirstPageIcon />
                )}
              </IconButton>
              <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0 || processing}
                aria-label="previous page"
                size="large"
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </IconButton>
              <IconButton
                onClick={handleNextButtonClick}
                disabled={
                  processing || endOfPaging === undefined
                    ? page >=
                      Math.ceil(
                        (currentList ? currentList.length : 0) / rowsPerPage
                      ) -
                        1
                    : endOfPaging === false
                    ? false
                    : page >=
                      Math.ceil(
                        (currentList ? currentList.length : 0) / rowsPerPage
                      ) -
                        1
                }
                aria-label="next page"
                size="large"
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </IconButton>
              <IconButton
                onClick={handleLastPageButtonClick}
                disabled={
                  processing ||
                  endOfPaging === false ||
                  page >=
                    Math.ceil(
                      (currentList ? currentList.length : 0) / rowsPerPage
                    ) -
                      1
                }
                aria-label="last page"
                size="large"
              >
                {theme.direction === "rtl" ? (
                  <FirstPageIcon />
                ) : (
                  <LastPageIcon />
                )}
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  );
}
