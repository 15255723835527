import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Carousel } from "react-bootstrap";
import { getS3Bucket } from "../utils/UtilityFunctions";
// import { getResizedFileName } from "../utils/UtilityFunctions";

export default function SolbindCardCarousel({
  solbindCard,
  height,
  width,
  index,
  setIndex,
}) {
  var offset = -2250 * (width / 250);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadImage, setLoadImage] = useState(null);
  const solbind1CardLevel = Object.keys(solbindCard.levels)[0]
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
      setLoadImage(
        `https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
          solbindCard.PK
        }${
          solbindCard.cardType === "Forgeborn" ? "" : `_${solbind1CardLevel}`
        }.jpg`
      );
  };


  return (
    <>
      <Box
        sx={{
          width: solbindCard && solbindCard.levels ? `${width}px` : "100%",
          // height: !isModal ? height : null,
          maxWidth: "95vw",
          maxHeight: height,
          position: "relative",
        }}
      >
        {solbindCard?.cardType !== "Forgeborn" && solbindCard?.levels && (
          <>
            <Carousel
              activeIndex={index}
              onSelect={handleSelect}
              interval={null}
              indicators={false}
            >
            
            {solbindCard.levels && (
              <Carousel.Item>
                  <Box
                    sx={{
                      width: `${height}px`,
                      // height: `${width}px`,
                      maxWidth: "100%",
                      // maxHeight: "95vw",
                      maxHeight: "100%", //`${height}px`,
                      visibility: "visible",
                      // background: `url("${cardState.imageUrl}")`,
                    }}
                  >
                    <img
                      alt={solbindCard.title || "SolForge Fusion"}
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        maxHeight: height || "100%",
                        marginBottom: "-8px",
                      }}
                      src={`https://${getS3Bucket()}.s3.amazonaws.com/public/cards/resized/${
                        solbindCard.PK
                      }_${solbind1CardLevel}.jpg`}
                    />
                  </Box>

                  <Box
                    sx={{
                      width: `${width}px`,
                      height: `${height}px`,
                      maxWidth: "100%",
                      // maxHeight: "95vw",
                      maxHeight: "100%", //`${height}px`,
                      visibility: "visible",
                      background: `url("${solbindCard.imageUrl}") ${
                        offset + (10 - parseInt(solbindCard.cardNo)) * width
                      }px ${parseInt(solbind1CardLevel) * height}px / 1000% 300%`,
                    }}
                  />
              </Carousel.Item>
            )};
            </Carousel>
          </>
        )}

        {!imageLoaded && loadImage && (
          <img
            alt="loader"
            style={{ display: "none" }}
            src={loadImage}
            onLoad={() => {
              setImageLoaded(true);
            }}
          />
        )}
      </Box>
    </>
  );
}
