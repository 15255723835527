import React from "react";
import { Container, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({}));

export default function About() {
  const classes = useStyles();

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        About Us
      </Typography>
      <Typography paragraph>
        From Richard Garfield, creator of Magic the Gathering, and Justin Gary,
        creator of the Ascension Deckbuilding Game, comes SolForge Fusion, the
        next evolution in trading card games. Experience the first-ever Hybrid
        Card Game with over 15,000 cards in the base set alone!
      </Typography>

      <Typography paragraph>
        In SolForge Fusion, you can customize your one-of-a-kind algorithmically
        generated deck—the strategic possibilities are endless. This
        revolutionary new game will be supported by a robust tournament and
        organized play program, but if you prefer remote play, you’ll also be
        able to scan your deck into our database and play online!
      </Typography>

      <Typography paragraph>
        Sign up below to learn more about SolForge Fusion and be the first to
        know when our Kickstarter goes live!
      </Typography>
    </Container>
  );
}
