import React, { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { API } from "aws-amplify";

import HalfDeckList from "../../components/decks/HalfDeckList";

export default function HalfDecks() {
  const userState = useContext(UserContext);
  const navigate = useNavigate();

  let { userId } = useParams();
  let label = ''

  useEffect(() => {
    const check = async () => {
      if (
        userState &&
        userState.isLoading === false &&
        !userState.isAuthenticated &&
        !userId
      ) {
        navigate("/login");
      }
      
    };
    check(); 
  }, [userState]);
  if (
    userState &&
    !userState.user &&
    userId
    ) {
    userState.user = {};
    userId = userId
    const capitalUserId = userId.charAt(0).toUpperCase() + userId.slice(1)
    label = capitalUserId + "'s Decks"
    // const axios = require('axios');
    // axios({
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   method: "get",
    //   url: "https://hpp2exnbyd.execute-api.us-east-1.amazonaws.com/solforprod/users/"+userId,
    // }).then(
    //   function(value) {
    //     userState.user = value.data
    //     // console.log(userState)
    //     userState.user.username = value.data.nickname;
    //   }
    // );
  } else if(userState) {
    userId = userState.user.username;
    label = "My Decks";
  }
  return (
    <>
      <HalfDeckList
        label={label}
        userState={userState}
        username={userState && userState.user && userId}
      />
    </>
  );
  
}
