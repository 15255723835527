import { Box, Container, Grid, Typography } from "@mui/material";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function MainPageHeader(props) {
  const { title, subTitle, headerImage, right } = props;

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  return (
    <Box
      sx={{
        color: "#fff",
        width: "100%",
        minHeight: "150px",
        display: "flex",
        alignItems: "center",
        boxShadow: "inset 0 0 0 1000px rgb(0 0 0 / 50%)",
        objectFit: "contain",
        background: `url(${
          headerImage?.url || "/images/assets/page-header.jpg"
        }) center top/cover no-repeat`,
      }}
    >
      <Container
        style={{
          maxWidth: lgUp ? "1200px" : mdUp ? "1050px" : smUp ? "900px" : "100%",
        }}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} md={right ? 6 : 12}>
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                fontWeight: 700,
                fontSize: "48px",
                lineHeight: "54px",
              }}
            >
              {title}
            </Typography>
            {subTitle && (
              <Typography
                gutterBottom
                sx={{
                  fontFamily: "'Inter'",
                  fontStyle: "normal",
                  fontWeight: 400,
                  fontSize: "22px",
                  lineHeight: "27px",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                {subTitle}
              </Typography>
            )}
          </Grid>
          {right && (
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: ["left", "left", "right"] }}
            >
              {right}
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
