import React from "react";
import { Navigate } from "react-router-dom";

import MainLayout from "./components/layout/MainLayout";
import AdminLayout from "./components/layout/AdminLayout";
import NotFound from "./pages/NotFound";
import AccessDenied from "./pages/AccessDenied";

import Home from "./pages/Home";
import Play from "./pages/Play";
import Events from "./pages/Events";

import FusedDecks from "./pages/collections/FusedDecks";
import HalfDecks from "./pages/collections/HalfDecks";
import FusedDeck from "./pages/collections/FusedDeck";
import HalfDeck from "./pages/collections/HalfDeck";

import Blog from "./pages/blog/Blog";
import ViewPost from "./pages/blog/ViewPost";
import EditPost from "./pages/blog/EditPost";

import Login from "./pages/user/Login";
import Account from "./pages/user/Account";
import Profile from "./pages/user/Profile";
import Loading from "./components/nav/Loading";

import About from "./pages/static/About";
import Contact from "./pages/static/Contact";
import Privacy from "./pages/static/Privacy";
import Terms from "./pages/static/Terms";
import Cookies from "./pages/static/Cookies";
import Dashboard from "./pages/admin/Dashboard";
import PostsList from "./pages/admin/PostsList";
import PagesList from "./pages/admin/PagesList";
import MediaList from "./pages/admin/MediaList";
import CategoryList from "./pages/admin/CategoryList";
import AvatarList from "./pages/admin/AvatarList";
import DeckList from "./pages/admin/DeckList";
import DeckItem from "./pages/admin/DeckItem";
import FactionList from "./pages/admin/FactionList";
import CardSearch from "./pages/collections/CardSearch";
import CardItem from "./pages/collections/CardItem";
import EditPage from "./pages/cms/EditPage";
import ViewPage from "./pages/cms/ViewPage";
import AccountTagList from "./pages/admin/AccountTagList";
import Products from "./pages/Products";
import CardList from "./pages/admin/CardList";
import CardEditFAQ from "./pages/admin/CardEditFAQ";
import { Switch } from "@mui/material";

const Routes = (isAuthenticated, access, isLoading) => [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "", element: <Home /> },
      { path: "news", element: <Blog /> },
      { path: "news/:category", element: <Blog /> },
      { path: "play", element: <Play /> },
      { path: "fused/:deckId", element: <FusedDeck /> },
      {
        path: "fused",
        element: !!isLoading ? (
          <Loading />
        ) : !!isAuthenticated ? (
          <FusedDecks />
        ) : (
          <Navigate to="/login?redirect=fused"/>
        ),
      },

      {
        path: "decks",
        element: !!isLoading ? (
          <Loading />
        ) : !!isAuthenticated ? (
          <HalfDecks />
        ) : (
          <Navigate to="/login?redirect=decks" />
        ),
      },
      { path: "userDecks/:userId", element: <HalfDecks/>},
      { path: "fusedUserDecks/:userId", element: <FusedDecks/>},
      { path: "decks/:deckId", element: <HalfDeck /> },
      { path: "cards/:cardId", element: <CardItem /> },
      { path: "cards", element: <CardSearch /> },

      { path: "products", element: <Products /> },
      { path: "store-locator", element: <Products /> },
      { path: "events", element: <Events /> },
      { path: "post/:postUrl", element: <ViewPost /> },
      { path: "profile/:userId", element: <Profile /> },
      { path: "profile", element: <Profile /> },
      { path: "about", element: <About /> },
      { path: "contact", element: <Contact /> },
      // { path: "privacy", element: <Privacy /> },
      // { path: "terms", element: <Terms /> },
      { path: "cookies", element: <Cookies /> },
      { path: "access-denied", element: <AccessDenied /> },
      { path: ":pageUrl", element: <ViewPage /> },
    ],
  },
  {
    path: "app/admin",
    element: !!isLoading ? (
      <Loading />
    ) : !!isAuthenticated && (!!access.isAdmin || !!access.isEditor) ? (
      <AdminLayout />
    ) : !!isAuthenticated ? (
      <Navigate to="/access-denied" />
    ) : (
      <Navigate to="/login?redirect=app/admin" />
    ),
    children: [
      { path: "", element: <Dashboard /> },
      { path: "posts", element: <PostsList /> },
      { path: "post/:postId", element: <EditPost /> },
      { path: "post", element: <EditPost /> },
      { path: "pages", element: <PagesList /> },
      { path: "page/:postId", element: <EditPage /> },
      { path: "page", element: <EditPage /> },
      { path: "library", element: <MediaList /> },
      { path: "categories", element: <CategoryList /> },
      { path: "avatars", element: <AvatarList /> },
      { path: "factions", element: <FactionList /> },
      { path: "decks", element: <DeckList /> },
      { path: "deck", element: <DeckItem /> },
      { path: "cards", element: <CardList /> },
      { path: "cards/:cardId/faq", element: <CardEditFAQ /> },
      { path: "account-tags", element: <AccountTagList /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "app",
    element: !!isLoading ? (
      <Loading />
    ) : !!isAuthenticated ? (
      <MainLayout />
    ) : (
      <Navigate to="/login?redirect=app" />
    ),
    children: [
      { path: "", element: <Navigate to="/" /> },
      { path: "account", element: <Account /> },
      { path: "*", element: <NotFound /> },
    ],
  },
  {
    path: "login",
    element: !isAuthenticated ? <MainLayout /> : <Navigate to="/" />,
    children: [{ path: "", element: <Login /> }],
  },
  {
    path: "*",
    element: <MainLayout />,
    children: [{ path: "*", element: <NotFound /> }],
  },
];

export default Routes;
