import React from "react";

import { Container, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({}));

export default function AccessDenied() {
  const classes = useStyles();

  return (
    <>
      <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          403 - Access Denied
        </Typography>
      </Container>
    </>
  );
}
