import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { titleCase, findGetParameter } from "../utils/UtilityFunctions";
import { UserContext } from "../../context/UserContext";
import { Box, CssBaseline, Link } from "@mui/material";
import { Outlet } from "react-router-dom";

import Header from "../nav/Header";
import Footer from "../nav/Footer";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";
import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  root: { maxWidth: "100%", minWidth: "100%", overflowX: "hidden" },
  appMain: {
    paddingTop: "56px",
    "@media (min-width: 600px)": {
      paddingTop: "64px",
    },
    minHeight: "calc(100vh - 390px)",
  },
  CookieConsentButton: {
    color: `${theme.palette.primary.contrastText} !important`,
    backgroundColor: `${theme.palette.primary.main} !important`,
    boxShadow:
      "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
    textTransform: "uppercase",
    borderRadius: "4px !important",
    padding: "6px 16px !important",
    fontSize: "0.875rem !important",
    "&:hover": {
      backgroundColor: `${theme.palette.primary.dark} !important`,
    },
  },
}));

export default function MainLayout() {
  const classes = useStyles();
  const userState = useContext(UserContext);

  var pathSplit = window.location.pathname.split("/");
  var basePath = "";
  if (pathSplit.length >= 3 && pathSplit[1] === "app") {
    basePath = titleCase(
      window.location.pathname.split("/").pop().split("-").join(" ")
    );
  } else {
    basePath = titleCase(
      window.location.pathname.split("/")[1].split("-").join(" ")
    );
  }

  if (basePath === "Decks") basePath = "My Decks";
  else if (basePath === "Fused") basePath = "My Fused Decks";
  const title =
    basePath === "" ? "SolForge Fusion" : basePath + " | SolForge Fusion";

  const seo = {
    title: title,
    description: "SolForge Fusion - the next generation of card games",
    image: findGetParameter("imageUrl")
      ? findGetParameter("imageUrl")
      : "https://solforgefusion.com/images/icon-200x200.jpg",
  };

  const handleReferrals = () => {
    var referralCode = findGetParameter("referral");
    if (referralCode) {
      var cookieConsent = Cookies.get("CookieConsent");
      if (cookieConsent) {
        Cookies.set("referralCode", referralCode, { expires: 60 });
      }
    }
  };

  // handle referrals
  useEffect(() => {
    handleReferrals();
  }, []);
  return (
    <>
      <Helmet
        title={seo.title}
        description={seo.description}
        meta={[
          {
            name: "description",
            property: "og:description",
            content: seo.description,
          },
          { property: "og:title", content: seo.title },
          { property: "og:image", content: seo.image },
          { property: "og:image:type", content: "image/jpeg" },
          { property: "twitter:image:src", content: seo.image },
          { property: "twitter:title", content: seo.title },
          { property: "twitter:description", content: seo.description },
        ]}
      />
      <Box className={classes.root}>
        <CssBaseline />
        <Header userState={userState} />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
        />
        <main className={classes.appMain}>
          <Outlet />
        </main>

        <Footer />
        <CookieConsent
          buttonText="I Accept"
          buttonClasses={classes.CookieConsentButton}
          onAccept={() => {
            userState.handleCookieConsent(true);
            handleReferrals();
          }}
        >
          This website uses cookies to ensure you get the best experience on our
          website. Read our <Link href="/cookies">Cookie Policy</Link> here. By
          clicking any link on this page or by clicking{" "}
          <strong>I Accept</strong>, you are giving your consent for us to set
          cookies.
        </CookieConsent>
      </Box>
    </>
  );
}
