
//Not sure if we need all of this but this is the way it was done so ill roll with it
export const rarities = {
    Common: { 
        name: "Common",
        s1: {
            img: "/images/cards/rarity/S1_Common.png"
        },
        s2: {
            img: "/images/cards/rarity/S2_Common.png"
        },
        s3: {
            img: "/images/cards/rarity/S3_Common.png"
        },
    },
    "Common Common": {
        name: "Common Common",
        s1: {
            img: "/images/cards/rarity/S1_CommonCommon.png",
        },
        s2: {
            img: "/images/cards/rarity/S2_CommonCommon.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_CommonCommon.png",
        },
    },
    "Common Rare": {
        name: "Common Rare",
        s1: {
            img: "/images/cards/rarity/S1_CommonRare.png",
        },
        s2: {
            img: "/images/cards/rarity/S2_CommonRare.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_CommonRare.png",
        },
    },
    Rare: { 
        name: "Rare", 
        s1: {
            img: "/images/cards/rarity/S1_Rare.png",
        },
        s2: {
            img: "/images/cards/rarity/S2_Rare.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_Rare.png",
        },
    },
    "Rare Common": {
        name: "Rare Common",
        s1: {
            img: "/images/cards/rarity/S1_RareCommon.png",
        },
        s2: {
            img: "/images/cards/rarity/S2_RareCommon.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_RareCommon.png",
        },
    },
    "Rare Rare": {
        name: "Rare Rare",
        s1: {
            img: "/images/cards/rarity/S1_RareRare.png",
        },
        s2: {
            img: "/images/cards/rarity/S2_RareRare.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_RareRare.png",
        },
    },
    LS: {
        name: "LS",
        s1: {
            img: "/images/cards/rarity/S1_LS.png",
        },
        s2: {
            img: "/images/cards/rarity/S2_LS.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_LS.png",
        },
    },
    Solbind: {
        name: "Solbind",
        s2: {
            img: "/images/cards/rarity/S2_Solbind.png",
        },
        s3: {
            img: "/images/cards/rarity/S3_Solbind.png",
        },
    },
};

export default function Rarity() {
    console.log(rarities)
    return rarities
}