import React from "react";

import { Box, Container, Link, Typography } from "@mui/material";

import SocialsGroup from "./SocialsGroup";

import makeStyles from "@mui/styles/makeStyles";
import { getEnv } from "../utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    // marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    maxWidth: "unset",
    width: "100vw",
  },
  footerSocialsWrapper: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    paddingBottom: theme.spacing(2),
  },
  footerLinksWrapper: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
  },
  footerLinksItem: {
    textAlign: "center",
    display: "flex",
    flexFirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(
      1
    )}px !important`,
    "&:hover": {
      textDecoration: "none",
    },
  },
}));

const footerLinks = [
  /* { label: "About", href: "/about" },
  { label: "Contact us", href: "/contact" },
  { label: "Privacy policy", href: "/privacy" },
  { label: "Terms of use", href: "/terms" },
  { label: "Cookie Policy", href: "/cookies" },*/
];

const env = getEnv();

export default function Footer() {
  const classes = useStyles();
  return (
    <>
      <Container component="footer" className={classes.footer}>
        <Box className={classes.footerSocialsWrapper}>
          <SocialsGroup title="STAY CONNECTED" titleSize="h5" />
        </Box>
        <Box className={classes.footerLinksWrapper}>
          {footerLinks.map((footerLink) => (
            <Link
              href={footerLink.href}
              className={classes.footerLinksItem}
              variant="subtitle1"
              color="inherit"
            >
              {footerLink.label}
            </Link>
          ))}
        </Box>

        <Box mt={5}>
          <Typography variant="body2" color="inherit" align="center">
            {"Copyright © "}
            {new Date().getFullYear()} Stone Blade Entertainment. All rights
            reserved.
          </Typography>
          <Typography variant="body2" color="inherit" align="center">
            <Link
              href={`${
                process.env.REACT_APP_HEADER_SOLFORGE_URL || ""
              }/privacy`}
              sx={{
                color: "common.white",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              Privacy Policy
            </Link>{" "}
            |{" "}
            <Link
              href={`${process.env.REACT_APP_HEADER_SOLFORGE_URL || ""}/terms`}
              sx={{
                color: "common.white",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              Terms of Service
            </Link>{" "}
            |{" "}
            <Link
              href={`${
                process.env.REACT_APP_HEADER_SOLFORGE_URL || ""
              }/cookies`}
              sx={{
                color: "common.white",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              Cookie Policy
            </Link>{" "}
            |{" "}
            <Link
              href={`${
                env === "main"
                  ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                  : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
              }/pricing`}
              sx={{
                color: "common.white",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              Ticketing
            </Link>{" "}
            |{" "}
            <Link
              href={`${
                env === "main"
                  ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                  : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
              }/support`}
              sx={{
                color: "common.white",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              Events Support
            </Link>
          </Typography>
        </Box>
      </Container>
    </>
  );
}
