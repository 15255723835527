import React from "react";
import { useParams } from "react-router-dom";
import { Container } from "@mui/material";
import PostGallery from "../../components/posts/PostGallery";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({}));

export default function Blog() {
  const classes = useStyles();
  const { category } = useParams();
  const defaultParams = {
    pageSize: 24,
  };

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <PostGallery
        title="Latest News"
        category={category && category.toLowerCase()}
        params={defaultParams}
      />
    </Container>
  );
}
