import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import {
  Button,
  Breadcrumbs,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  splitUrl,
  getResizedFileName,
} from "../../components/utils/UtilityFunctions";
import ProgressModal from "../../components/modals/ProgressModal";
import FactionItem from "./FactionItem";

import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableCell: { padding: theme.spacing(1), minHeight: "84px" },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  preview: {
    maxHeight: "60px",
    maxWidth: "150px",
  },
  previewDelete: {
    paddingTop: theme.spacing(1),
    maxHeight: "300px",
    maxWidth: "100%",
  },
}));

export default function FactionList() {
  const classes = useStyles();

  const [mainState, setMainState] = useState({
    loading: true,
    allowLoadMore: false,
    lastEvaluatedKey: null,
    allRows: [],
    dialogOpen: false,
    dialogProgress: false,
    deleteItem: null,
  });

  const handleDialogOpen = (deleteItem) => {
    setMainState({
      ...mainState,
      deleteItem: deleteItem,
      dialogOpen: true,
    });
  };

  const handleDialogClose = () => {
    setMainState({
      ...mainState,
      dialogOpen: false,
    });
  };

  const handleDelete = async () => {
    try {
      if (mainState.deleteItem && mainState.deleteItem.id) {
        setMainState({
          ...mainState,
          dialogProgress: true,
        });
        var allItems = mainState.allRows;
        const apiDeleteData = await API.del(
          "sfwApi",
          "/faction/" + mainState.deleteItem.id
        );
        if (apiDeleteData.success) {
          if (mainState.deleteItem.index !== undefined) {
            delete allItems[mainState.deleteItem.index];
          }

          setMainState({
            ...mainState,
            allRows: allItems,
            dialogProgress: false,
            dialogOpen: false,
            deleteItem: null,
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRows = async () => {
    setMainState({
      ...mainState,
      loading: true,
      allowLoadMore: false,
    });
    var allItems = mainState.allRows;
    const queryStringParameters = {
      pageSize: 50,
    };
    if (mainState.lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey = JSON.stringify(
        mainState.lastEvaluatedKey
      );
    }
    const apiGetData = await API.get("sfwApi", "/faction", {
      queryStringParameters: queryStringParameters,
    });

    if (apiGetData && apiGetData.Items) {
      allItems = [...allItems, ...apiGetData.Items];
    }
    if (apiGetData && apiGetData.LastEvaluatedKey) {
      setMainState({
        ...mainState,
        lastEvaluatedKey: apiGetData.LastEvaluatedKey,
        allowLoadMore: true,
        loading: false,
        allRows: allItems,
      });
    } else {
      setMainState({
        ...mainState,
        loading: false,
        allRows: allItems,
      });
    }
  };

  function updateItems(item, push) {
    var allItems = mainState.allRows;
    if (push) {
      allItems.push(item);
    } else {
      allItems.unshift(item);
    }
    setMainState({
      ...mainState,
      allRows: allItems,
    });
  }

  useEffect(() => {
    const check = async () => {
      fetchRows();
    };
    check();
  }, []);

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/app/admin">
          <HomeIcon color="inherit" />
        </Link>
        <Typography color="textPrimary">Faction Library</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>
        Faction Library{" "}
        <FactionItem
          updateItems={updateItems}
          color="primary"
          variant="outlined"
        />
      </Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>Name</TableCell>
              <TableCell className={classes.tableCell}>Preview</TableCell>
              <TableCell className={classes.tableCell}>
                <IconButton aria-label="delete" disabled size="large">
                  <DeleteForeverIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mainState.allRows.map((row, index) => {
              // rows get deleted
              if (row && row.id) {
                if (row.imageUrl) {
                  row.myImage = splitUrl(row.imageUrl);
                }
                return (
                  <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.myImage && (
                        <img
                          src={`${row.myImage.url}${getResizedFileName(
                            row.myImage.filename,
                            "medium"
                          )}`}
                          alt={row.name}
                          className={classes.preview}
                        />
                      )}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <IconButton
                        aria-label="delete"
                        onClick={async () => {
                          await handleDialogOpen({ ...row, index: index });
                        }}
                        size="large"
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
          <TableFooter>
            {mainState.loading && (
              <TableRow sx={{ height: 84 }}>
                <TableCell colSpan={3}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow sx={{ height: 84 }}>
              <TableCell sx={{ textAlign: "center" }} colSpan={3}>
                <Button
                  onClick={fetchRows}
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={!mainState.allowLoadMore}
                >
                  Load More
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Dialog
        open={mainState.dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {mainState.deleteItem && `Delete ${mainState.deleteItem.name}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography>
              Are you sure you want to delete{" "}
              {mainState.deleteItem && ` ${mainState.deleteItem.name}`}?
            </Typography>
            <Typography>
              This will not delete the underlying media item?
            </Typography>
            {mainState.deleteItem && (
              <img
                src={`${mainState.deleteItem.myFeaturedImage.url}${mainState.deleteItem.myFeaturedImage.fileName}`}
                alt={mainState.deleteItem.name}
                className={classes.previewDelete}
              />
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ProgressModal open={mainState.dialogProgress} />
    </Container>
  );
}
