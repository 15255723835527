import { Auth, API } from "aws-amplify";
import JSZip from "jszip";
import awsExports from "../../aws-exports";
import { postNotification } from "../../components/utils/Notifications"

export const customAPIHeader = async () => {
  var header = {};
  try {
    header = {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    };
  } catch (err) {
    header = {};
  }
  return header;
};

export function getEnv() {
  return awsExports?.aws_user_files_s3_bucket
    ? awsExports.aws_user_files_s3_bucket.split("-")[1] || "dev"
    : "dev";
}

export function getS3Bucket() {
  return awsExports?.aws_user_files_s3_bucket;
}

const colors = {
  red: 0,
  orange: 22,
  yellow: 60,
  green: 120,
  turquoise: 180,
  blue: 240,
  purple: 291,
  pink: 300,
};

export function hsl_col_perc(percent, c1, c2) {
  var a = percent / 100,
    b = (colors[c2] - colors[c1]) * a,
    c = b + colors[c1];

  // Return a CSS HSL string
  return "hsl(" + c + ", 100%, 50%)";
}

export function getResizedFileName(fileName, size) {
  var filenameSplit = splitFilename(fileName);
  if (filenameSplit.ext === "jpg" || filenameSplit.ext === "png") {
    return `resized/${filenameSplit.name}-${size}.${filenameSplit.ext}`;
  }

  return fileName;
}

export function splitUrl(url) {
  if (url) {
    var pos = url.lastIndexOf("/");
    if (pos > 0) {
      return {
        url: url.substring(0, pos + 1),
        filename: url.substring(pos + 1),
      };
    } else {
      return { url: url, filename: "" };
    }
  }
  return { url: "", filename: "" };
}

export function splitFilename(filename) {
  if (filename) {
    var pos = filename.lastIndexOf(".");
    if (pos > 0) {
      return {
        name: filename.substring(0, pos),
        ext: filename.substring(pos + 1),
      };
    } else {
      return { name: filename, ext: "" };
    }
  }
  return { name: "", ext: "" };
}

export function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

export function findGetParameter(parameterName) {
  var result = null,
    tmp = [];
  if (window.location.search) {
    var items = window.location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
      tmp = items[index].split("=");
      if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
  }
  return result;
}

export function copyShareDeckLink(url) {
  var content = url;
  navigator.clipboard.writeText(content)
  .then(() => {
    postNotification('success','User Decks link has been copied!')
  })
  .catch(err => {
    postNotification('error','Something is not right, Oops!');
  })
};

// export async function downloadDeck(deck) {
//   const apiGetData = await API.get("sfwApi", `/deck/${deck.id}/ttsDownload`, {
//     queryStringParameters: {},
//     headers: await customAPIHeader(),
//   });

//   if (apiGetData) {
//     const zip = new JSZip();
//     const deckName = (deck.name ? deck.name.replace(/[^\w\s]/gi, "") : "Deck")
//       .split(" ")
//       .join("-");
//     zip.file(`${deckName}.json`, JSON.stringify(apiGetData));
//     if (deck.forgeborn?.title) {
//       const imageUrl = `/images/cards/forgeborn/tts/${deck.forgeborn.title
//         .split(" ")
//         .join("-")
//         .toLowerCase()}.png`;
//       const imageResponse = await fetch(imageUrl);
//       const imageBlob = await imageResponse.blob();
//       zip.file(`${deckName}.png`, imageBlob);
//     }
//     var zipContent = await zip.generateAsync({ type: "blob" });
//     //Check the Browser.
//     var isIE = false || !!document.documentMode;
//     if (isIE) {
//       window.navigator.msSaveBlob(
//         zipContent,
//         `${(deck.name ? deck.name.replace(/[^\w\s]/gi, "") : "Deck")
//           .split(" ")
//           .join("-")}.zip`
//       );
//     } else {
//       // var url = window.URL || window.webkitURL;
//       var link = URL.createObjectURL(zipContent);
//       var a = document.createElement("a");
//       a.download = `${(deck.name ? deck.name.replace(/[^\w\s]/gi, "") : "Deck")
//         .split(" ")
//         .join("-")}.zip`;
//       a.href = link;
//       document.body.appendChild(a);
//       a.click();
//       document.body.removeChild(a);
//     }
//   }
// }

// export async function downloadFusedDeck(fusedDeck) {
//   if (!fusedDeck.myDecks?.length) {
//     throw new Error("No decks found");
//   }
//   const zip = new JSZip();
//   for (let deck of fusedDeck.myDecks) {
//     var apiGetData = await API.get("sfwApi", `/deck/${deck.id}/ttsDownload`, {
//       queryStringParameters: {},
//       headers: await customAPIHeader(),
//     });

//     if (apiGetData) {
//       const deckName = (deck.name ? deck.name.replace(/[^\w\s]/gi, "") : "Deck")
//         .split(" ")
//         .join("-");
//       zip.file(`${deckName}.json`, JSON.stringify(apiGetData));
//       if (deck.forgeborn?.title) {
//         const imageUrl = `/images/cards/forgeborn/tts/${deck.forgeborn.title
//           .split(" ")
//           .join("-")
//           .toLowerCase()}.png`;
//         const imageResponse = await fetch(imageUrl);
//         const imageBlob = await imageResponse.blob();
//         zip.file(`${deckName}.png`, imageBlob);
//       }
//     }
//   }

//   const deckName = (
//     fusedDeck.decks?.length
//       ? fusedDeck.decks.map((el) => el.name).join(" - ")
//       : fusedDeck.name || "Fused Deck"
//   ).replace(/[^\w\s]/gi, "");

//   var zipContent = await zip.generateAsync({ type: "blob" });
//   //Check the Browser.
//   var isIE = false || !!document.documentMode;
//   if (isIE) {
//     window.navigator.msSaveBlob(
//       zipContent,
//       `${deckName.split(" ").join("-")}.zip`
//     );
//   } else {
//     // var url = window.URL || window.webkitURL;
//     var link = URL.createObjectURL(zipContent);
//     var a = document.createElement("a");
//     a.download = `${deckName.split(" ").join("-")}.zip`;
//     a.href = link;
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//   }
// }

// export async function downloadDecks(decks) {
//   if (!decks?.length) {
//     throw new Error("No decks found");
//   }
//   const zip = new JSZip();
//   for (let deck of decks) {
//     var apiGetData = await API.get("sfwApi", `/deck/${deck.id}/ttsDownload`, {
//       queryStringParameters: {},
//       headers: await customAPIHeader(),
//     });

//     if (apiGetData) {
//       const deckName = (deck.name ? deck.name.replace(/[^\w\s]/gi, "") : "Deck")
//         .split(" ")
//         .join("-");
//       zip.file(`${deckName}.json`, JSON.stringify(apiGetData));
//       if (deck.forgeborn?.title) {
//         const imageUrl = `/images/cards/forgeborn/tts/${deck.forgeborn.title
//           .split(" ")
//           .join("-")
//           .toLowerCase()}.png`;
//         const imageResponse = await fetch(imageUrl);
//         const imageBlob = await imageResponse.blob();
//         zip.file(`${deckName}.png`, imageBlob);
//       }
//     }
//   }

//   var zipContent = await zip.generateAsync({ type: "blob" });
//   //Check the Browser.
//   var isIE = false || !!document.documentMode;
//   if (isIE) {
//     window.navigator.msSaveBlob(zipContent, `decks.zip`);
//   } else {
//     // var url = window.URL || window.webkitURL;
//     var link = URL.createObjectURL(zipContent);
//     var a = document.createElement("a");
//     a.download = `decks.zip`;
//     a.href = link;
//     document.body.appendChild(a);
//     a.click();
//     document.body.removeChild(a);
//   }
// }
