import React, { useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  Link,
  List,
  Toolbar,
} from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import SideNavItem from "./SideNavItem";

import makeStyles from "@mui/styles/makeStyles";
import ProfileMenu from "./ProfileMenu";
const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerBar: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    zIndex: 2000,
    position: "relative",
    width: "100%",
  },
  sectionMobile: {
    display: "flex",
  },
  menuButton: {
    margin: "unset",
  },
  sectionMobileGrow: {
    [theme.breakpoints.down("md")]: {
      flexGrow: 1,
      textAlign: "center",
    },
  },
  headerLink: {
    margin: theme.spacing(2),
    fontSize: "1rem",
    fontWeight: "700",
    textTransform: "uppercase",
    "&:hover": {
      color: theme.palette.primary.main,
      filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
        theme.palette.primary.main
      })`,
      textDecoration: "none",
    },
  },
  headerLogoImg: {
    height: "35px",
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: { marginRight: "unset" },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionDesktopGrow: {
    [theme.breakpoints.up("md")]: {
      flexGrow: 1,
    },
  },
  headerIconLink: {
    "&:hover": {
      color: theme.palette.primary.main,
      filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
        theme.palette.primary.main
      })`,
    },
  },
  headerMenu: {
    marginTop: theme.spacing(1),
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
  },
  profileMenuLink: {
    fontSize: 16,
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  sideDrawer: { flexShrink: 0 },
  sideDrawerPaper: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  list: { width: 250 },
}));

export default function AdminHeader() {
  // global
  const navigate = useNavigate();
  const userState = useContext(UserContext);
  const classes = useStyles();

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const menus = [
    { id: 0, href: "/", label: "Home" },
    { id: 10, href: "/app/admin", label: "Dashboard" },
    { id: 20, href: "/app/admin/pages", label: "Pages" },
    { id: 30, href: "/app/admin/posts", label: "Posts" },
    { id: 40, href: "/app/admin/library", label: "Media Library" },
    { id: 50, href: "/app/admin/categories", label: "Categories" },
    { id: 60, href: "/app/admin/avatars", label: "Avatars" },
    { id: 65, href: "/app/admin/factions", label: "Factions" },
    { id: 70, href: "/app/admin/decks", label: "Deck Library" },
    { id: 72, href: "/app/admin/cards", label: "Card Library" },
    { id: 75, href: "/app/admin/account-tags", label: "Account Tags" },
  ];

  return (
    <Box className={classes.grow}>
      <AppBar position="static" className={classes.headerBar}>
        <Toolbar>
          <Box className={classes.sectionMobile}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box className={classes.sectionMobileGrow}>
            <Link
              color="inherit"
              noWrap
              variant="body2"
              href="/admin"
              className={classes.headerLink}
            >
              Admin
            </Link>
          </Box>

          <Box className={classes.sectionDesktopGrow} />
          {!userState.isAuthenticated && (
            <Box>
              <IconButton
                aria-haspopup="true"
                color="inherit"
                className={classes.headerMenuButton}
                aria-controls="profile-menu"
                onClick={(e) => {
                  navigate("/login");
                }}
                size="large"
              >
                <AccountCircle className={classes.headerIconLink} />
              </IconButton>
            </Box>
          )}
          {userState.isAuthenticated && (
            <ProfileMenu
              classes={classes}
              userState={userState}
              navigate={navigate}
            />
          )}
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.sideDrawer}
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        classes={{
          paper: classes.sideDrawerPaper,
        }}
      >
        <Toolbar />
        <Box
          className={classes.list}
          role="presentation"
          // onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List>
            {menus.map((menuItem) => {
              return <SideNavItem {...menuItem} />;
            })}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
}
