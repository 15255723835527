import React, { useState, useEffect, useRef } from "react";
import { uploadFile } from "../../components/utils/Storage";
import ProgressModal from "../../components/modals/ProgressModal";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  IconButton,
  Modal,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";
import awsExports from "../../aws-exports";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  preview: {
    maxWidth: "100%",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
}));

export default function MediaItem(props) {
  const classes = useStyles();

  const fileRef = useRef();
  const [fileUrl, setFileUrl] = useState("");

  const initialValues = {
    id: null,
    name: "",
    fileName: "",
    fileType: "Image",
    url: "",
    file: null,
  };
  const [media, setMedia] = useState(initialValues);

  const validationSchema = {
    name: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
  };

  const [open, setOpen] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);
  const [progress, setProgress] = useState(null);
  const progressCallBack = (progress) => {
    setProgress(Math.round((progress.loaded / progress.total) * 90));
  };

  useEffect(() => {
    const check = async () => {
      if (props.mediaId) {
        const apiGetData = await API.get("sfwApi", "/media/" + props.mediaId);

        if (apiGetData.url) {
          apiGetData.url += apiGetData.fileName;
        }
        setMedia({ ...media, ...apiGetData });
      }
      setOpen(false);
      setProgress(0);
    };

    check();
  }, [props.mediaId]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="button"
        color={props.color || "secondary"}
        variant={props.variant || "contained"}
      >
        Add Image
      </Button>
      <Modal
        disableAutoFocus
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{ zIndex: 99990 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Add Image"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />

            <Formik
              initialValues={media}
              enableReinitialize={true}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                setProgressOpen(true);
                try {
                  var postBody = {
                    name: values.name,
                    fileType: values.fileType,
                  };
                  var vMediaId = values.id;
                  var result = undefined;
                  if (!vMediaId && !values.file) {
                    postNotification(
                      "error",
                      "A file is mandatory for a new Media Item"
                    );
                  } else {
                    if (values.file) {
                      result = await uploadFile(
                        values.file,
                        values.fileName,
                        "",
                        vMediaId !== undefined && vMediaId !== null,
                        progressCallBack
                      );

                      postBody.fileName = result.fileName;
                      postBody.url = `https://${awsExports["aws_user_files_s3_bucket"]}.s3.${awsExports["aws_user_files_s3_bucket_region"]}.amazonaws.com/public/`;
                    }

                    var apiPostData = await API.post(
                      "sfwApi",
                      `/media${vMediaId ? "/" + vMediaId : ""}`,
                      {
                        body: postBody,
                      }
                    );

                    if (apiPostData.data) {
                      apiPostData.data.tempUrl = fileUrl;
                      props.updateItems(apiPostData.data, false);

                      if (values.file) {
                        setFieldValue("file", null);
                        fileRef.current.value = null;
                      }

                      if (
                        !props.mediaId &&
                        apiPostData.data &&
                        apiPostData.data.id
                      ) {
                        setFieldValue("id", apiPostData.data.id);
                      }
                      postNotification(
                        "success",
                        `Media item ${props.mediaId ? "updated" : "created"}`
                      );
                    } else if (apiPostData.error) {
                      postNotification("error", apiPostData.error);
                    }

                    setProgress(100);

                    setOpen(false);
                  }
                  setSubmitting(false);
                  setProgressOpen(false);
                  setProgress(0);
                } catch (error) {
                  postNotification(
                    "error",
                    error.message || JSON.stringify(error)
                  );
                  setSubmitting(false);
                  setProgressOpen(false);
                  setProgress(0);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          helperText={touched.name && errors.name}
                          label="Name"
                          margin="normal"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <input
                          id="file"
                          name="file"
                          type="file"
                          accept="image/*"
                          ref={fileRef}
                          onChange={(event) => {
                            var file = event.currentTarget.files[0];
                            setFieldValue("file", file);
                            if (values.name === "" || values.name === null) {
                              setFieldValue(
                                "name",
                                file.name.replace(/.[^.]+$/, "")
                              );
                            }
                            setFileUrl(URL.createObjectURL(file));
                          }}
                        />
                      </Grid>
                      {values.fileType === "Image" &&
                        (values.url || fileUrl !== "") && (
                          <Grid item xs={12}>
                            <img
                              src={fileUrl !== "" ? fileUrl : values.url}
                              alt={values.name}
                              className={classes.preview}
                            />
                          </Grid>
                        )}
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button type="submit" color="primary" variant="contained">
                      Save
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      </Modal>
      <ProgressModal progress={progress} open={progressOpen} />
    </>
  );
}
