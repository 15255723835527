import React, { useEffect, useState } from "react";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Link,
  Modal,
  TextField,
  Typography,
  Tooltip,
  ButtonBase,
  Avatar,
  InputAdornment,
  Alert,
  useMediaQuery,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import ShareIcon from "@mui/icons-material/Share";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HelpIcon from "@mui/icons-material/Help";
import CancelIcon from "@mui/icons-material/Cancel";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InventoryIcon from "@mui/icons-material/Inventory";

import MainPageHeader from "../layout/MainPageHeader";

import ProgressModal from "../../components/modals/ProgressModal";
import LoadingModal from "../modals/LoadingModal";

import ListResponsive from "../layout/ListResponsive";
import FactionChip from "../utils/FactionChip";
import FactionButton from "../utils/FactionButton";
import RankChip from "../utils/RankChip";

import { alpha } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";
import { copyShareDeckLink } from "../../components/utils/UtilityFunctions"

import { API } from "aws-amplify";

import { ScanFusedDeck } from "./ScanDeck";
import { customAPIHeader } from "../utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  heading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexFirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: `0px 0px 70px -12.125px ${alpha(
        theme.palette.primary.main,
        0.4
      )}`,
    },
  },
}));

export default function FusedList({ userState, filters, label }) {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [decks, setDecks] = useState(null);
  const [halfDecks, setHalfDecks] = useState(null);

  const [searchState, setSearchState] = useState({
    open: false,
    values: {
      simpleSearch: "",
      factions: {},
    },
  });

  const [open, setOpen] = useState(false);

  const fetchDecks = async (lastEvaluatedKey) => {
    const queryStringParameters = {
      pageSize: 200
    };
    if (userState && userState.user && userState.user.username) {
      queryStringParameters.username = userState.user.username;
    }
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }
    const apiGetData = await API.get("sfwApi", "/fuseddeck/app", {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    if (apiGetData.error) {
    }
    return { decks: apiGetData.Items, lastEvaluatedKey: apiGetData.LastEvaluatedKey } || {};
  };

  // Changed for Dynamo call since there is an issue with OpenSearch decks
  const fetchHalfDecks = async (lastEvaluatedKey) => {
    const queryStringParameters = {
      pageSize: 200,
      inclPve: true
    };
    if (userState && userState.user && userState.user.username) {
      queryStringParameters.username = userState.user.username;
    }
    if (lastEvaluatedKey) {
      //   queryStringParameters.exclusiveStartKey =
      //     JSON.stringify(lastEvaluatedKey);
        if(lastEvaluatedKey.SK && lastEvaluatedKey.PK) {
          queryStringParameters.exclusiveStartKeyPK = lastEvaluatedKey.PK;
          queryStringParameters.exclusiveStartKeySK = lastEvaluatedKey.SK;
        }
      }  
    const apiGetData = await API.get("sfwApi", "/deck/app", {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    if (apiGetData.error) {
    }
    return { decks: apiGetData.Items, lastEvaluatedKey: apiGetData.LastEvaluatedKey } || {};
  };

  const [deleteState, setDeleteState] = useState({
    dialogOpen: false,
    dialogProgress: false,
    deleteItem: null,
  });
  const handleDelete = async () => {
    try {
      if (deleteState.deleteItem && deleteState.deleteItem.id) {
        setDeleteState({
          ...deleteState,
          dialogProgress: true,
        });
        var allItems = decks;

        var apiPostData = await API.post(
          "sfwApi",
          "/fuseddeck/" + deleteState.deleteItem.id,
          {
            body: {
              isArchived: true,
            },
            headers: await customAPIHeader(),
          }
        );

        if (apiPostData.success) {
          if (deleteState.deleteItem.index !== undefined) {
            delete allItems[deleteState.deleteItem.index];
          }

          setDecks(allItems);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setDeleteState({
      ...deleteState,
      dialogProgress: false,
      dialogOpen: false,
      deleteItem: null,
    });
  };

  useEffect(() => {
    const check = async () => {
      var fusedDecksData = await fetchDecks(null);
      var fd = fusedDecksData?.decks
      if (fd?.length) {
        fd = fd.filter((deck) => !deck.isArchived);
      }
      var lastFusedDeckEvaluatedKey = fd?.lastEvaluatedKey
      setDecks(fd);
      setLoading(false);
      var halfDecksData = await fetchHalfDecks(null)
      var hd = halfDecksData?.decks
      var lastHalfDeckEvaluatedKey = halfDecksData?.lastEvaluatedKey
      setHalfDecks(hd)

      while(lastFusedDeckEvaluatedKey) {
        fusedDecksData = await fetchDecks(lastFusedDeckEvaluatedKey)
        var extraFusedDecks = fusedDecksData?.decks
        fd = [...fd, ...extraFusedDecks]
        setDecks(fd)
        lastFusedDeckEvaluatedKey = fusedDecksData?.lastEvaluatedKey
      }
      while(lastHalfDeckEvaluatedKey) {
        halfDecksData = await fetchHalfDecks(lastHalfDeckEvaluatedKey)
        var extraHalfDecks = halfDecksData?.decks
        hd = [...hd, ...extraHalfDecks]
        setHalfDecks(hd)
        lastHalfDeckEvaluatedKey = halfDecksData?.lastEvaluatedKey
      }
    };
    check();
  }, []);

  return (
    <>
      <MainPageHeader
        title={label}
        right={
          userState.isAuthenticated ? (
            <Button
              variant="outlined"
              onClick={async () => {
                setOpen(true);
                console.log(halfDecks)
                if (!halfDecks) {
                  var hd = await fetchHalfDecks(null);
                  setHalfDecks(hd);
                }
              }}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#B53114",
                borderColor: "#B53114",
                "&:hover": {
                  backgroundColor: "#FFFFFF",
                },
              }}
            >
              Fuse New Deck
            </Button>
          ) : userState && userState.user ? (
            <Button
              aria-label="Share Deck"
              sx={{
                minWidth: "unset",
                color: "#ffffff",
              }}
              onClick={async (e) => {
                await copyShareDeckLink(window.location.origin + "/fusedUserDecks/" + userState.user.username);
              }}
              size="large"
            >
              <ShareIcon />
            </Button>) : null
        }
      />
      {(loading || processing) && <LoadingModal />}

      {!loading && (
        <>
          {decks?.length > 0 ? (
            <>
              <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
                <Grid container spacing={1} alignItems="center">
                  <Grid item sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      className={classes.searchField}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search"
                      variant="outlined"
                      size="small"
                      value={searchState?.values?.simpleSearch}
                      onChange={(e) => {
                        setSearchState({
                          ...searchState,
                          values: {
                            ...searchState?.values,
                            simpleSearch: e.target.value.toLowerCase(),
                          },
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <Button
                      variant="contained"
                      color="gray"
                      fullWidth
                      startIcon={<FilterAltIcon />}
                      endIcon={
                        searchState.open ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )
                      }
                      onClick={() => {
                        setSearchState({
                          ...searchState,
                          open: !searchState.open,
                        });
                      }}
                    >
                      Filter
                    </Button>
                  </Grid>
                </Grid>
              </Container>
              {searchState.open && (
                <Box sx={{ widht: "100%", background: "#E0E0E0" }}>
                  <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
                    <Grid container spacing={1} alignItems="center">
                      {["Alloyin", "Tempys", "Uterra", "Nekrium"].map(
                        (faction, index) => {
                          return (
                            <Grid key={index} item xs={6} sm="auto">
                              <FactionButton
                                faction={faction}
                                fullWidth
                                active={
                                  searchState?.values?.factions?.[faction]
                                }
                                onClick={() => {
                                  var newFilterState = {
                                    ...searchState.values.factions,
                                  };
                                  if (!!newFilterState[faction]) {
                                    delete newFilterState[faction];
                                  } else {
                                    newFilterState[faction] = true;
                                  }

                                  if (Object.keys(newFilterState).length > 2) {
                                    postNotification(
                                      "error",
                                      "You can filter fused decks on a maximum of 2 factions"
                                    );
                                    return;
                                  }
                                  setSearchState({
                                    ...searchState,
                                    values: {
                                      ...searchState?.values,
                                      factions: newFilterState,
                                    },
                                  });
                                }}
                              />
                            </Grid>
                          );
                        }
                      )}
                    </Grid>
                  </Container>
                </Box>
              )}
              <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Alert
                      icon={
                        <HelpIcon
                          fontSize="inherit"
                          sx={{ color: "#ffffff" }}
                        />
                      }
                      sx={{
                        mt: 2,
                        mb: 2,
                        backgroundColor: "#A55645",
                        color: "#ffffff",
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>Did you know:</span> You
                      can play SolForge Fusion online! To learn more,{" "}
                      <Link
                        href="https://store.steampowered.com/app/2400960/SolForge_Fusion?utm_source=sffdyk"
                        color="inherit"
                        underline="always"
                        target="_blank"
                      >
                        click here
                      </Link>
                      .
                    </Alert>
                  </Grid>
                  <Grid item xs={12}>
                    <ListResponsive
                      originalList={decks.filter((fusedDeck) => {
                        
                        // check factions first
                        if (
                          Object.keys(searchState.values?.factions).length !== 0
                        ) {
                          for (let faction of Object.keys(
                            searchState.values?.factions
                          )) {
                            let found = false;
                            for (let deck of fusedDeck.myDecks) {
                              if(!deck.forgeborn?.title) {
                                // Removes the set and forge codes to get forgeborn name
                                try {
                                  let forgebornName = deck.forgeborn.id.slice(5).replace(/\d+/g, '')
                                  forgebornName = forgebornName.split("-")
                                  forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
                                  deck.forgeborn.title = forgebornName.join(" ")
                                } catch (error) {
                                  deck.forgeborn.title = ""
                                }
                              }
                              if (faction === deck?.faction) {
                                found = true;
                                break;
                              }
                            }
                            if (!found) {
                              return false;
                            }
                          }
                        }

                        // check simple text
                        if (
                          searchState.values?.simpleSearch &&
                          searchState.values.simpleSearch !== ""
                        ) {
                          var searchFields =
                            searchState.values.simpleSearch.split(" ");

                          for (let searchField of searchFields) {
                            let found = false;
                            for (let deck of fusedDeck.myDecks) {
                              if (
                                deck?.name
                                  ?.toLowerCase()
                                  .includes(searchField.toLowerCase()) ||
                                deck?.forgeborn?.title
                                  ?.toLowerCase()
                                  .includes(searchField.toLowerCase())
                              ) {
                                found = true;
                                break;
                              }
                            }
                            if (!found) return false;
                          }
                        }

                        return true;
                      })}
                      doPages={true}
                      rowProps={{
                        headings: (
                          <Hidden mdDown>
                            <Grid
                              item
                              xs={12}
                              sx={{
                                p: 1,
                                color: "#767676",
                              }}
                            >
                              <DeckRow heading />
                            </Grid>
                          </Hidden>
                        ),
                        mapFunction: (row, index) => (
                          <Grid
                            item
                            xs={12}
                            key={index}
                            sx={{
                              p: 1,
                              "&:hover": {
                                backgroundColor: "#E7E7E7",
                              },
                              // backgroundColor: isOdd(index + 1)
                              //   ? "#E7E7E7"
                              //   : null,
                            }}
                          >
                            <DeckRow
                              key={index}
                              index={index}
                              deck={row}
                              setProcessing={setProcessing}
                              // tagState={tagState}
                              // setTagState={setTagState}
                              deleteState={deleteState}
                              setDeleteState={setDeleteState}
                              userState={userState}
                            />
                          </Grid>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Container>

              <Dialog
                open={deleteState.dialogOpen}
                onClose={(e) =>
                  setDeleteState({ ...deleteState, dialogOpen: false })
                }
              >
                <DialogTitle>Archive Fused Deck</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <Typography variant="body1" gutterBottom>
                      Are you sure you want to archive this fused deck?
                    </Typography>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={(e) =>
                      setDeleteState({
                        ...deleteState,
                        dialogOpen: false,
                      })
                    }
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleDelete}
                    variant="contained"
                    color="primary"
                    autoFocus
                  >
                    Yes, Archive Deck
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          ) : (
            <ScanFusedDeck />
          )}
          <FuseDeckModal
            halfDecks={halfDecks}
            decks={decks}
            setDecks={setDecks}
            userState={userState}
            open={open}
            setOpen={setOpen}
          />
        </>
      )}
    </>
  );
}

const DeckRow = (props) => {
  const {
    heading,
    deck,
    index,
    setProcessing,
    tagState,
    setTagState,
    deleteState,
    setDeleteState,
    userState
  } = props;

  const mediaLT750 = useMediaQuery("(max-width:749px)");
  const mediaLT850 = useMediaQuery("(max-width:849px)");

  const releaseDeck = async () => {
    await setDeleteState({
      ...deleteState,
      deleteItem: { ...deck, index: index },
      dialogOpen: true,
    });
  };

  if (heading) {
    if (mediaLT850) {
      return <></>;
    }
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid
          item
          sx={{ flex: 1, maxWidth: "calc(100% - 120px - 140px - 230px)" }}
        >
          Name
        </Grid>
        <Grid item sx={{ minWidth: "120px" }}>
          Rank
        </Grid>
        <Grid item sx={{ minWidth: "140px" }}>
          Factions
        </Grid>
        <Grid item sx={{ minWidth: "230px" }}></Grid>
      </Grid>
    );
  }
  if (mediaLT750) {
    return (
      <Box
        sx={{
          position: "relative",
          backgroundColor: "#FFFFFF",
          width: "100%",
          pb: "100%",
          borderRadius: "4px",
          overflow: "hidden",
        }}
      >
        {[0, 1].map((deckNo) => {
          if (!deck?.myDecks?.[deckNo]) {
            return <></>;
          }
          if(!deck?.myDecks?.[deckNo].forgeborn.title) {
            // Removes the set and forge codes to get forgeborn name
            try {
              let forgebornName = deck?.myDecks?.[deckNo].forgeborn.id.slice(5).replace(/\d+/g, '')
              forgebornName = forgebornName.split("-")
              forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
              deck.myDecks[deckNo].forgeborn.title = forgebornName.join(" ")
            } catch (error) {
              deck.myDecks[deckNo].forgeborn.title = ""
            }
          }
          return (
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                backgroundSize: "cover",
                width: "50%",
                pb: "100%",
                position: "absolute",
                [deckNo === 0 ? "left" : "right"]: 0,
                display: "flex",
              }}
            >
              <Box
                sx={{
                  background: `url(/images/cards/forgeborn/${deck?.myDecks?.[
                    deckNo
                  ].forgeborn.title
                    .split(" ")
                    .join("-")
                    .toLowerCase()}.jpg)`,
                  backgroundSize: "cover",
                  backgroundPosition: "70%, center",
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  // [deckNo === 0 ? "left" : "right"]: "calc(50% - 60%)",
                  display: "flex",
                }}
              />
            </Box>
          );
        })}
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            display: "flex",
          }}
        >
          <Grid container spacing={1} sx={{ m: "10px" }}>
            <Grid item>
              <RankChip rank={deck?.deckRank} />
            </Grid>
            {userState && userState.isAuthenticated && (
            <Grid item sx={{ flex: 1, pr: 1 }} textAlign="right">
              <Tooltip title="Archive Deck">
                <Button
                  aria-label="Archive Deck"
                  sx={{
                    minWidth: "unset",
                    backgroundColor: "#ffffff !important",
                    color: "#000000",
                  }}
                  onClick={async (e) => {
                    await releaseDeck();
                  }}
                  size="large"
                >
                  <InventoryIcon />
                </Button>
              </Tooltip>
            </Grid>)}
          </Grid>
        </Box>
        <Box
          sx={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            background: "rgba(0, 0, 0, 0.75)",
            color: "#FFFFFF",
            width: "100%",
            height: "40%",
            borderRadius: "4px",
          }}
        >
          <Box sx={{ minWidth: "100%", display: "block", p: "20px" }}>
            <Link href={`/fused/${deck.id}`} color="inherit">
              <Box sx={{ minWidth: "100%", display: "block", mb: 1 }}>
                {deck?.name}
              </Box>
            </Link>

            <Grid container spacing={1}>
              {[0, 1].map((deckNo) => {
                if (!deck?.myDecks?.[deckNo]?.faction) {
                  return <></>;
                }
                return (
                  <Grid item>
                    <FactionChip faction={deck.myDecks[deckNo].faction} />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
  if (mediaLT850) {
    return (
      <Grid container spacing={1} alignItems="center">
        <Grid item sx={{ minWidth: "110px" }}>
          <Link
            href={`/fused/${deck.id}`}
            color="inherit"
            sx={{ display: "flex" }}
          >
            <Box
              sx={{
                position: "relative",
                backgroundColor: "#FFFFFF",
                width: "100px",
                pb: "100px",
                borderRadius: "4px",
                overflow: "hidden",
              }}
            >
              {[0, 1].map((deckNo) => {
                if (!deck?.myDecks?.[deckNo]) {
                  return <></>;
                }
                if(!deck?.myDecks?.[deckNo].forgeborn.title) {
                  // Removes the set and forge codes to get forgeborn name
                  try {
                    let forgebornName = deck?.myDecks?.[deckNo].forgeborn.id.slice(5).replace(/\d+/g, '')
                    forgebornName = forgebornName.split("-")
                    forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
                    deck.myDecks[deckNo].forgeborn.title = forgebornName.join(" ")
                  } catch (error) {
                    deck.myDecks[deckNo].forgeborn.title =""
                  }
                }
                return (
                  <Box
                    sx={{
                      backgroundColor: "#FFFFFF",
                      backgroundSize: "cover",
                      width: "50%",
                      pb: "100%",
                      position: "absolute",
                      [deckNo === 0 ? "left" : "right"]: 0,
                      display: "flex",
                    }}
                  >
                    <Box
                      sx={{
                        background: `url(/images/cards/forgeborn/${deck?.myDecks?.[
                          deckNo
                        ].forgeborn.title
                          .split(" ")
                          .join("-")
                          .toLowerCase()}.jpg)`,
                        backgroundSize: "cover",
                        backgroundPosition: "70%, center",
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        // [deckNo === 0 ? "left" : "right"]: "calc(50% - 60%)",
                        display: "flex",
                      }}
                    />
                  </Box>
                );
              })}
            </Box>
          </Link>
        </Grid>
        <Grid
          item
          sx={{
            flex: 1,
            maxWidth: "calc(100% - 110px - 120px - 140px - 230px)",
          }}
        >
          <Link href={`/fused/${deck.id}`} color="inherit">
            <Typography sx={{ mb: 0 }}>{deck?.name}</Typography>
          </Link>
        </Grid>
        <Grid item sx={{ minWidth: "120px" }}>
          <RankChip rank={deck?.deckRank} />
        </Grid>
        <Grid item sx={{ minWidth: "140px" }}>
          {deck.myDecks?.length > 0 &&
            deck.myDecks.map((halfDeck, index) => {
              return <FactionChip key={index} faction={halfDeck?.faction} />;
            })}
        </Grid>
        {userState && userState.isAuthenticated && (
        <Grid item sx={{ minWidth: "230px" }}>
          <Tooltip title="Archive Deck">
            <IconButton
              aria-label="Archive Deck"
              sx={{ padding: 0 }}
              onClick={async () => {
                await releaseDeck();
              }}
              size="large"
            >
              <InventoryIcon />
            </IconButton>
          </Tooltip>
        </Grid>)}
      </Grid>
    );
  }

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item sx={{ minWidth: "220px" }}>
        <Link
          href={`/fused/${deck.id}`}
          color="inherit"
          sx={{ display: "flex" }}
        >
          {deck.myDecks?.length > 0 &&
            deck.myDecks.map((halfDeck, index) => {
              if(!halfDeck.forgeborn?.title) {
                // Removes the set and forge codes to get forgeborn name
                try {
                  let forgebornName = halfDeck.forgeborn.id.slice(5).replace(/\d+/g, '')
                  forgebornName = forgebornName.split("-")
                  forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
                  halfDeck.forgeborn.title = forgebornName.join(" ")
                } catch (error) {
                  halfDeck.forgeborn.title = ""
                }
              }
              return (
                <Avatar
                  key={index}
                  alt={halfDeck?.forgeborn?.title}
                  src={
                    halfDeck?.forgeborn?.title
                      ? `/images/cards/forgeborn/${halfDeck.forgeborn.title
                          .split(" ")
                          .join("-")
                          .toLowerCase()}.jpg`
                      : ""
                  }
                  sx={{
                    ml: index > 0 ? 1 : null,
                    borderRadius: "8px",
                    height: "100px",
                    width: "100px",
                    maxHeight: "100px",
                    maxWidth: "100px",
                  }}
                />
              );
            })}
        </Link>
      </Grid>
      <Grid
        item
        sx={{ flex: 1, maxWidth: "calc(100% - 220px - 120px - 140px - 230px)" }}
      >
        <Link href={`/fused/${deck.id}`} color="inherit">
          <Typography sx={{ mb: 0 }}>{deck?.name}</Typography>
        </Link>
      </Grid>
      <Grid item sx={{ minWidth: "120px" }}>
        <RankChip rank={deck?.deckRank} />
      </Grid>
      <Grid item sx={{ minWidth: "140px" }}>
        {deck.myDecks?.length > 0 &&
          deck.myDecks.map((halfDeck, index) => {
            return <FactionChip key={index} faction={halfDeck?.faction} />;
          })}
      </Grid>
      {userState && userState.isAuthenticated && (
      <Grid item sx={{ minWidth: "230px" }}>
        <Tooltip title="Archive Deck">
          <IconButton
            aria-label="Archive Deck"
            sx={{ padding: 0 }}
            onClick={async () => {
              await releaseDeck();
            }}
            size="large"
          >
            <InventoryIcon />
          </IconButton>
        </Tooltip>
      </Grid>)}
    </Grid>
  );
};

const FuseDeckModal = (props) => {
  const { halfDecks, decks, setDecks, userState, open, setOpen } = props;
  const [progressOpen, setProgressOpen] = useState(false);

  const initialValues = {
    name: "",
    deck1: null,
    deck2: null,
  };
  const validationSchema = {
    name: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
  };

  const [searchText, setSearchText] = useState("");

  const [subOpen, setSubOpen] = useState(false);
  const [deckNo, setDeckNo] = useState(0);

  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      sx={{ zIndex: 99990 }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: ["95vw", "85vw", 600],
          maxHeight: "95vh",

          bgcolor: "background.paper",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 1,
          boxShadow: 24,
        }}
      >
        <Card>
          <CardHeader
            title="Fuse New Deck"
            sx={{ pb: 0 }}
            action={
              <IconButton
                aria-label="close"
                onClick={() => {
                  setOpen(false);
                }}
                size="large"
              >
                <CancelIcon />
              </IconButton>
            }
          />

          <ProgressModal open={progressOpen} />
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={Yup.object().shape(validationSchema)}
            onSubmit={async (values, { setSubmitting, setFieldValue }) => {
              try {
                setProgressOpen(true);
                // console.log(values);

                if (userState && userState.user && userState.user.username) {
                  if (!values.deck1) {
                    postNotification(
                      "error",
                      "Please select a deck for Deck 1"
                    );
                  }
                  if (!values.deck2) {
                    postNotification(
                      "error",
                      "Please select a deck for Deck 2"
                    );
                  }
                  if (values.deck1 && values.deck2) {
                    if (values.deck1.faction === values.deck2.faction) {
                      postNotification(
                        "error",
                        "Fused Decks cannot contain 2 Decks with the same Faction"
                      );
                    } else {
                      var ok = true;
                      decks.forEach((deck) => {
                        if (
                          values.name.toLowerCase() === deck.name.toLowerCase()
                        ) {
                          ok = false;
                          postNotification(
                            "error",
                            "You already have a Fused Deck with this name."
                          );
                        }
                        var hasDeck1 = false;
                        var hasDeck2 = false;
                        deck.myDecks.forEach((halfDeck) => {
                          if (halfDeck.id === values.deck1.id) {
                            hasDeck1 = true;
                          }
                          if (halfDeck.id === values.deck2.id) {
                            hasDeck2 = true;
                          }
                        });
                        if (hasDeck1 && hasDeck2) {
                          ok = false;
                          postNotification(
                            "error",
                            `You already have this Deck combination in Fused Deck: ${deck.name}.`
                          );
                        }
                      });
                      if (ok) {
                        if(!values.deck1.forgeborn) {
                          // Removes the set and forge codes to get forgeborn name
                          try {
                            let forgebornName = values.deck1.forgebornId.slice(5).replace(/\d+/g, '')
                            forgebornName = forgebornName.split("-")
                            forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
                            values.deck1.forgeborn = {title: forgebornName.join(" ")} 
                          } catch (error) {
                            values.deck1.forgeborn = {title: ""}
                          }
                        }
                        if(!values.deck2.forgeborn) {
                          // Removes the set and forge codes to get forgeborn name
                          try {
                            let forgebornName = values.deck2.forgebornId.slice(5).replace(/\d+/g, '')
                            forgebornName = forgebornName.split("-")
                            forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
                            values.deck2.forgeborn = {title: forgebornName.join(" ")} 
                          } catch (error) {
                            values.deck2.forgeborn = {title: ""}
                          }
                        }
                        var postBody = {
                          username: userState.user.username,
                          name: values.name,
                          myDecks: [
                            {
                              id: values.deck1.id,
                              name: values.deck1.name,
                              faction: values.deck1.faction,
                              forgeborn: {
                                title: values.deck1.forgeborn.title,
                              },
                            },
                            {
                              id: values.deck2.id,
                              name: values.deck2.name,
                              faction: values.deck2.faction,
                              forgeborn: {
                                title: values.deck2.forgeborn.title,
                              },
                            },
                          ],
                        };
                        // if (values.deck1.forgeborn.imageUrl) {
                        //   postBody.imageUrl =
                        //     values.deck1.forgeborn.imageUrl;
                        // } else if (values.deck2.forgeborn.imageUrl) {
                        //   postBody.imageUrl =
                        //     values.deck2.forgeborn.imageUrl;
                        // }

                        var apiPostData = await API.post(
                          "sfwApi",
                          "/fuseddeck",
                          {
                            body: postBody,
                            headers: await customAPIHeader(),
                          }
                        );

                        if (apiPostData.error) {
                          postNotification("error", apiPostData.error);
                        } else {
                          var allItems = decks;
                          allItems.unshift(apiPostData.data);
                          setDecks(allItems);
                          setOpen(false);
                          postNotification(
                            "success",
                            "Success! Your deck has been created."
                          );
                        }
                      }
                    }
                  }
                } else {
                  postNotification(
                    "error",
                    "You are not logged in, please login and try again."
                  );
                }

                setSubmitting(false);
                setProgressOpen(false);
              } catch (error) {
                postNotification(
                  "error",
                  error.message || JSON.stringify(error)
                );
                setSubmitting(false);
                setProgressOpen(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <CardContent sx={{ flexGrow: 1, pt: 0 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Name your deck"
                        margin="normal"
                        name="name"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.name}
                        color="secondary"
                        variant="outlined"
                        required
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </Grid>

                    {[1, 2].map((deckNo) => {
                      return (
                        <Grid item xs={12}>
                          <ButtonBase
                            onClick={(e) => {
                              setDeckNo(deckNo);
                              setSubOpen(true);
                            }}
                            sx={{
                              textAlign: "left",
                              width: "100%",
                              border: "1px solid rgba(0, 0, 0, 0.12)",
                              borderRadius: "4px",
                              p: 1,
                            }}
                          >
                            {values[`deck${deckNo}`] && (
                              <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                  <Avatar
                                    sx={{ borderRadius: "4px" }}
                                    src={`/images/cards/forgeborn/${values[
                                      `deck${deckNo}`
                                    ].forgeborn.title
                                      .split(" ")
                                      .join("-")
                                      .toLowerCase()}.jpg`}
                                  />
                                </Grid>
                                <Grid item sx={{ flex: 1 }}>
                                  <Typography
                                    gutterBottom
                                    variant="h6"
                                    component="h3"
                                  >
                                    {values[`deck${deckNo}`].name}
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <EditIcon color="primary" />
                                </Grid>
                              </Grid>
                            )}
                            {!values[`deck${deckNo}`] && (
                              <Box sx={{ width: "100%", pt: 1 }}>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="h3"
                                  color="primary"
                                >
                                  <AddCircleIcon
                                    color="inherit"
                                    sx={{ fontSize: "inherit" }}
                                  />{" "}
                                  Add Deck {deckNo}
                                </Typography>
                              </Box>
                            )}
                          </ButtonBase>
                        </Grid>
                      );
                    })}
                  </Grid>
                </CardContent>
                <CardActions
                  sx={{
                    pt: 1,
                    pr: 2,
                    pb: 2,
                    display: "block",
                    textAlign: "right",
                  }}
                >
                  <Button
                    onClick={() => setOpen(false)}
                    color="primary"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" variant="contained">
                    Save
                  </Button>
                </CardActions>
                <Modal
                  disableAutoFocus
                  open={subOpen}
                  onClose={() => {
                    setSubOpen(false);
                  }}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                  sx={{ zIndex: 99990 }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: ["95vw", "85vw", 600],
                      maxHeight: "95vh",

                      bgcolor: "background.paper",
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: 1,
                      boxShadow: 24,
                    }}
                  >
                    <Card>
                      <CardHeader
                        avatar={
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              setSubOpen(false);
                            }}
                            size="large"
                          >
                            <ArrowBackIcon />
                          </IconButton>
                        }
                        title="Select Deck"
                        action={
                          <IconButton
                            aria-label="close"
                            onClick={() => {
                              setSubOpen(false);
                              setOpen(false);
                            }}
                            size="large"
                          >
                            <CancelIcon />
                          </IconButton>
                        }
                      />

                      <CardContent>
                        <Grid container spacing={2} sx={{ mb: 2 }}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <SearchIcon />
                                  </InputAdornment>
                                ),
                              }}
                              placeholder="Search for a deck"
                              helperText="Search by name, faction or forgeborn"
                              variant="outlined"
                              size="small"
                              value={searchText}
                              onChange={(e) => {
                                setSearchText(e.target.value.toLowerCase());
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Box
                          sx={{
                            height: "60vh",
                            pr: 1,
                            overflowY: "scroll",
                          }}
                        >
                          <Grid container spacing={2}>
                            {halfDecks &&
                              halfDecks
                                .filter((deck) => {
                                  if (!searchText || searchText.trim() === "") {
                                    return true;
                                  }

                                  var searchFields = searchText.split(" ");
                                  for (let searchField of searchFields) {
                                    var found = false;
                                    if (
                                      deck?.name
                                        ?.toLowerCase()
                                        .includes(searchField.toLowerCase()) ||
                                      deck?.faction
                                        ?.toLowerCase()
                                        .includes(searchField.toLowerCase()) ||
                                      deck?.forgeborn?.title
                                        ?.toLowerCase()
                                        .includes(searchField.toLowerCase())
                                    ) {
                                      found = true;
                                    }
                                    if (!found) return false;
                                  }
                                  return true;
                                })
                                .map((deck) => {
                                  if(!deck?.forgeborn) {
                                    // Removes the set and forge codes to get forgeborn name
                                    try {
                                      let forgebornName = deck?.forgebornId.slice(5).replace(/\d+/g, '')
                                      forgebornName = forgebornName.split("-")
                                      forgebornName.forEach((item, i) => forgebornName[i] = item.replace(/^./, item[0].toUpperCase()))
                                      deck.forgeborn = {}
                                      deck.forgeborn.title = forgebornName.join(" ")
                                    } catch (error) {
                                      deck.forgeborn.title = ""
                                    }
                                  }
                                  return (
                                    <Grid item key={deck.id} xs={12}>
                                      <ButtonBase
                                        sx={{
                                          width: "100%",
                                          textAlign: "left",
                                          border:
                                            "1px solid rgba(0, 0, 0, 0.12)",
                                          borderRadius: 1,
                                          p: 1,
                                        }}
                                        onClick={(e) => {
                                          setFieldValue("deck" + deckNo, deck);
                                          setDeckNo(0);
                                          setSubOpen(false);
                                        }}
                                      >
                                        <Grid
                                          container
                                          spacing={1}
                                          alignItems="center"
                                        >
                                          <Grid item>
                                            <Avatar
                                              sx={{ borderRadius: "4px" }}
                                              src={`/images/cards/forgeborn/${deck.forgeborn.title
                                                .split(" ")
                                                .join("-")
                                                .toLowerCase()}.jpg`}
                                            />
                                          </Grid>
                                          <Grid item sx={{ flex: 1 }}>
                                            <Typography
                                              gutterBottom
                                              variant="h6"
                                              component="h3"
                                            >
                                              {deck.name}
                                            </Typography>
                                          </Grid>
                                          <Grid item>
                                            <AddCircleIcon color="primary" />
                                          </Grid>
                                        </Grid>
                                      </ButtonBase>
                                    </Grid>
                                  );
                                })}

                            {!halfDecks && (
                              <Grid item xs={12}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginBottom: "22%",
                                  }}
                                >
                                  <CircularProgress size={100} />
                                </Box>
                              </Grid>
                            )}

                            {halfDecks && halfDecks.length === 0 && (
                              <Grid item xs={12} sm={6} md={4}>
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="h2"
                                >
                                  No decks found
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Modal>
              </form>
            )}
          </Formik>
        </Card>
      </Box>
    </Modal>
  );
};
function isOdd(num) {
  return num % 2;
}
