import React, { useState, useEffect } from "react";
import { Button, Grid, Link, Typography } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

import { handleBPCPaging } from "../../bcp/BCPUtilities";
import ListResponsive from "../../layout/ListResponsive";
import OrganizationCard from "../../bcp/OrganizationCard";
import { getEnv } from "../../utils/UtilityFunctions";
import { API } from "aws-amplify";

const env = getEnv();

export default function BankingTab({ profile, setLoading }) {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Want to sell tickets to your events?
        </Typography>

        {process.env.REACT_APP_IS_BCP === "true" ? (
          <Typography variant="body1" paragraph>
            Solforge Fusion provide Event Organizers with a simple way to
            register and process payments for events!.
          </Typography>
        ) : (
          <Typography variant="body1" paragraph>
            Solforge Fusion has partnered with Best Coast Pairings to provide
            Event Organizers with a simple way to register and process payments
            for events!
          </Typography>
        )}
        <Typography variant="body1" paragraph>
          This powerful tool is powered by the secure and trusted{" "}
          <Link
            href="https://stripe.com/"
            target="_Blank"
            underline="none"
            sx={{ color: "#7b1fa2" }}
          >
            Stripe
          </Link>{" "}
          payment platform. Event Organizers can attach a Business Account,
          Debit Card or personal checking account to their SFOP account and once
          enabling online registration within Event Details, can set the price
          for their events. Ticket proceeds will be directly deposited within
          2-3 days of purchase. You can find more information on our{" "}
          <Link
            href={`${
              env === "main"
                ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
            }/pricing`}
            target="_Blank"
            underline="none"
            color="primary"
          >
            Pricing
          </Link>{" "}
          page.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="success"
          fullWidth
          onClick={async () => {
            if (profile?.play?.bankAccount?.id) {
              setLoading(true);
              var link = await API.get(
                env === "main" ? "BCPProdApi" : "BCPDevApi",
                `/stripe/expresslink/${profile.play.bankAccount.id}`
              );
              window.open(
                link.url,
                "popup"
                //"width=600,height=600"
              );
              setLoading(false);
            } else {
              var addParams = `?redirect_uri=${
                env === "main"
                  ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                  : process.env.REACT_APP_HEADER_BCP_URL_DEV
              }/stripe/account-creation`;
              if (profile?.play?.email) {
                addParams =
                  addParams + "&stripe_user[email]=" + profile?.play?.email;
              }
              addParams =
                addParams +
                "&stripe_user[first_name]=" +
                profile?.play?.firstName;
              addParams =
                addParams +
                "&stripe_user[last_name]=" +
                profile?.play?.lastName;
              addParams =
                addParams +
                `&state=${profile?.play?.id}&client_id=${
                  env === "main"
                    ? process.env.REACT_APP_STRIPE_CLIENT_ID_MAIN
                    : process.env.REACT_APP_STRIPE_CLIENT_ID_DEV
                }`;
              window.location.assign(
                "https://connect.stripe.com/express/oauth/authorize" + addParams
              );
            }
          }}
        >
          {profile?.play?.bankAccount?.id
            ? "Update Bank Account"
            : "Link Bank Account"}
        </Button>
      </Grid>
    </Grid>
  );
}
