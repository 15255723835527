import React, { useEffect, useContext, useState } from "react";
import { Helmet } from "react-helmet";
import {
  customAPIHeader,
  findGetParameter,
} from "../../components/utils/UtilityFunctions";
import { UserContext } from "../../context/UserContext";

import { CircularProgress, Container } from "@mui/material";

import { useParams } from "react-router-dom";

import Deck from "../../components/decks/Deck";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

import MainPageHeader from "../../components/layout/MainPageHeader";
const useStyles = makeStyles((theme) => ({
  containerLoading: {
    width: "100%",
    height: "calc(100vh - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
}));

export default function FusedDeck() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  let { deckId } = useParams();
  const [loading, setLoading] = useState(true);
  const [fusedDeck, setFusedDeck] = useState({});

  const fetchFusedDeck = async () => {
    const queryStringParameters = {
      inclDecks: true,
      inclCards: true,
      inclUsers: true,
    };
    if (userState && userState.user && userState.user.username) {
      queryStringParameters.username = userState.user.username;
    }
    const apiGetData = await API.get("sfwApi", "/fuseddeck/" + deckId, {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    if (apiGetData.error) {
      console.log(apiGetData.error);
    } else {
      var stats = [];
      var cardBreakdown = {
        title: "Card Type Breakdown",
        legend: {},
      };
      var creatureTypes = {
        title: "Creature Breakdown",
        legend: {},
      };

      if (apiGetData.myDecks) {
        apiGetData.myDecks.forEach((deck) => {
          deck.forgeborn.faction = deck.faction;
          deck.creatures = [];
          deck.spells = [];

          if (
            deck.cardList?.length
          ) {
            deck.cardList.forEach((card, index) => {
              deck.cardList[index].deckId = deck.id;
              deck.cardList[index].cardNo = index + 1;
              // deck.cards[cardNo].faction = deck.faction;
              // deck.cards[cardNo].imageUrl = deck.imageUrl;
              if (!cardBreakdown.legend[card.cardType]) {
                cardBreakdown.legend[card.cardType] = 0;
              }
              cardBreakdown.legend[card.cardType]++;

              if (card.cardType === "Creature") {
                deck.creatures.push(card);
                // if cardSubType has spaces, include original AND split into individual words
                var subTypes = card.cardSubType.split(" ");
                if (subTypes.length > 1) {
                  subTypes.forEach((subType) => {
                    if (!creatureTypes.legend[subType]) {
                      creatureTypes.legend[subType] = 0;
                    }
                    creatureTypes.legend[subType]++;
                  });
                }
                if (!creatureTypes.legend[card.cardSubType]) {
                  creatureTypes.legend[card.cardSubType] = 0;
                }
                creatureTypes.legend[card.cardSubType]++;
              } else if (card.cardType === "Spell") {
                deck.spells.push(card);
              }
            });
          } else if (deck.cards) {
            Object.keys(deck.cards).forEach((cardNo) => {
              deck.cards[cardNo].deckId = deck.id;
              deck.cards[cardNo].cardNo = cardNo;
              deck.cards[cardNo].faction = deck.faction;
              deck.cards[cardNo].imageUrl = deck.imageUrl;

              if (!cardBreakdown.legend[deck.cards[cardNo].cardType]) {
                cardBreakdown.legend[deck.cards[cardNo].cardType] = 0;
              }
              cardBreakdown.legend[deck.cards[cardNo].cardType]++;

              if (deck.cards[cardNo].cardType === "Creature") {
                deck.creatures.push(deck.cards[cardNo]);
                // if cardSubType has spaces, include original AND split into individual words
                var subTypes = deck.cards[cardNo].cardSubType.split(" ");
                if (subTypes.length > 1) {
                  subTypes.forEach((subType) => {
                    if (!creatureTypes.legend[subType]) {
                      creatureTypes.legend[subType] = 0;
                    }
                    creatureTypes.legend[subType]++;
                  });
                }
                if (!creatureTypes.legend[deck.cards[cardNo].cardSubType]) {
                  creatureTypes.legend[deck.cards[cardNo].cardSubType] = 0;
                }
                creatureTypes.legend[deck.cards[cardNo].cardSubType]++;
              } else if (deck.cards[cardNo].cardType === "Spell") {
                deck.spells.push(deck.cards[cardNo]);
              }
            });
          }
        });

        var ctbArray = [];
        Object.keys(cardBreakdown.legend).forEach((cardType) => {
          ctbArray.push({
            name: cardType,
            value: cardBreakdown.legend[cardType],
          });
        });
        ctbArray.sort((a, b) => {
          if (a.value === undefined && b.value === undefined) return 0;
          if (a.value === undefined) return 1;
          if (b.value === undefined) return -1;
          if (a.value === b.value) {
            var aVal2 = a.name.toUpperCase();
            var bVal2 = b.name.toUpperCase();

            if (aVal2 < bVal2) return -1;
            if (aVal2 > bVal2) return 1;
          }
          return b.value - a.value;
        });
        cardBreakdown.legend = ctbArray;
        stats.push(cardBreakdown);

        var ctArray = [];
        Object.keys(creatureTypes.legend).forEach((creatureType) => {
          ctArray.push({
            name: creatureType,
            value: creatureTypes.legend[creatureType],
          });
        });
        ctArray.sort((a, b) => {
          if (a.value === undefined && b.value === undefined) return 0;
          if (a.value === undefined) return 1;
          if (b.value === undefined) return -1;
          if (a.value === b.value) {
            var aVal2 = a.name.toUpperCase();
            var bVal2 = b.name.toUpperCase();

            if (aVal2 < bVal2) return -1;
            if (aVal2 > bVal2) return 1;
          }
          return b.value - a.value;
        });
        creatureTypes.legend = ctArray;
        stats.push(creatureTypes);
      }
      setFusedDeck({
        myUser: apiGetData.myUser,
        name: apiGetData.name,
        decks: apiGetData.myDecks,
        stats: stats,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    const check = async () => {
      if (userState && userState.isLoading === false) {
        await fetchFusedDeck();
      }
    };
    check();
  }, [userState]);

  const seo = {
    title:
      (fusedDeck && fusedDeck.name
        ? "Fused Deck: " + fusedDeck.name
        : "Fused Deck") + " | SolForge Fusion",
    description:
      fusedDeck && fusedDeck.name
        ? fusedDeck.name
        : "SolForge Fusion - the next generation of card games",
    image: findGetParameter("imageUrl")
      ? findGetParameter("imageUrl")
      : fusedDeck &&
        fusedDeck.decks &&
        fusedDeck.decks[0] &&
        fusedDeck.decks[0].forgeborn &&
        fusedDeck.decks[0].forgeborn.imageUrl
      ? fusedDeck.decks[0].forgeborn.imageUrl
      : // ? `${
        //     splitUrl(fusedDeck.decks[0].forgeborn.imageUrl).url
        //   }${getResizedFileName(
        //     splitUrl(fusedDeck.decks[0].forgeborn.imageUrl).filename,
        //     "medium"
        //   )}`
        "https://solforgefusion.com/images/icon-200x200.jpg",
  };

  return (
    <>
      {loading && (
        <Container className={classes.containerLoading}>
          <CircularProgress size={100} />
        </Container>
      )}
      {!loading && (
        <>
          <Helmet
            title={seo.title}
            description={seo.description}
            meta={[
              {
                name: "description",
                property: "og:description",
                content: seo.description,
              },
              { property: "og:title", content: seo.title },
              { property: "og:image", content: seo.image },
              { property: "og:image:type", content: "image/jpeg" },
              { property: "twitter:image:src", content: seo.image },
              { property: "twitter:title", content: seo.title },
              { property: "twitter:description", content: seo.description },
            ]}
          />
          <MainPageHeader title={"Fused Deck"} />
          <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
            {fusedDeck && (
              <Deck
                user={fusedDeck.myUser}
                name={
                  fusedDeck.decks?.length
                    ? fusedDeck.decks.map((el) => el.name).join(" - ")
                    : fusedDeck.name
                }
                decks={fusedDeck.decks}
                stats={fusedDeck.stats}
                fusedDeck={fusedDeck}
                fused
              />
            )}
          </Container>
        </>
      )}
    </>
  );
}
