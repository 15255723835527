import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  formControl: { backgroundColor: theme.palette.background.paper },
  category: {
    color: theme.palette.secondary.main,
    fontWeight: "bold",
    opacity: "1 !important",
  },
  selectFlex: {
    "& .MuiSelect-select": {
      display: "flex",
    },
  },
}));

export default function GenericPicker(props) {
  const {
    name,
    label,
    value,
    handleChange,
    list,
    touched,
    error,
    inclAny,
    anyLabel,
    open,
    setOpen,
    disabled,
    formControlSX,
    selectSX,
  } = props;
  const classes = useStyles();

  const selectOptions = {};
  if (open !== undefined && open !== null) {
    selectOptions.open = open;
    selectOptions.native = false;
  }
  if (setOpen !== undefined && setOpen !== null) {
    selectOptions.onOpen = () => setOpen(true);
    selectOptions.onClose = () => setOpen(false);
  }

  return (
    <FormControl
      fullWidth
      variant="outlined"
      className={classes.formControl}
      sx={{ mt: 2, mb: 1, ...formControlSX }}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        error={Boolean(touched && error)}
        fullWidth
        helperText={touched && error}
        labelId={`${name}-label`}
        name={name}
        value={value}
        onChange={(e) => {
          handleChange(e);
          if (setOpen) {
            setOpen(false);
          }
        }}
        label={label}
        color="primary"
        variant="outlined"
        disabled={disabled}
        {...selectOptions}
      >
        {Boolean(!!inclAny || inclAny === undefined) && (
          <MenuItem value="">
            <em>{anyLabel || "Any"}</em>
          </MenuItem>
        )}
        {list &&
          list.map((item, index) => {
            if (!item) return;
            if (typeof item == "object") {
              if (item.label !== undefined || item.value !== undefined) {
                return (
                  <MenuItem
                    key={index}
                    value={item.value !== undefined ? item.value : item.label}
                  >
                    {item.label || item.value}
                  </MenuItem>
                );
              } else {
                return item;
              }
            } else {
              return (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              );
            }
          })}
      </Select>
    </FormControl>
  );
}
