import { API } from "aws-amplify";
import { getEnv } from "../utils/UtilityFunctions";

const env = getEnv();

export async function handleBPCPaging(endPoint, filters, setData) {
  const fetchData = async (nextKey, previousData) => {
    const queryStringParameters = {
      limit: 100,
      ...filters,
    };
    if (nextKey) {
      queryStringParameters.nextKey = nextKey;
    }
    var apiGetData = await API.get(
      env === "main" ? "BCPProdApi" : "BCPDevApi",
      endPoint,
      {
        queryStringParameters: queryStringParameters,
      }
    );

    if (nextKey && apiGetData.nextKey === nextKey) {
      return { data: [] };
    }

    var newData;
    if (setData) {
      if (previousData?.length > 0) {
        newData = [...previousData, ...apiGetData.data];
      } else {
        newData = apiGetData.data;
      }

      setData(newData);
    }

    if (
      apiGetData.data?.length !== 0 &&
      apiGetData.nextKey &&
      apiGetData.nextKey !== nextKey
    ) {
      var nextFetch = await fetchData(apiGetData.nextKey, newData);
      if (nextFetch?.data.length > 0) {
        apiGetData.data = [...apiGetData.data, ...nextFetch.data];
      }
    }

    return apiGetData;
  };

  var apiData = await fetchData();
  return apiData.data;
}

export async function fetchBCPFile(fileId) {
  if (!fileId) return;

  var returnObj;
  try {
    returnObj = await API.get(
      env === "main" ? "BCPProdApi" : "BCPDevApi",
      `/files/${fileId}`,
      {
        queryStringParameters: {
          //resize type?
        },
      }
    );
  } catch (err) {
    console.log(err);
  }
  return returnObj;
}

export function getDistanceInt(lat, lon, coords) {
  return parseInt(getDistance(lat, lon, coords));
}

export function getDistance(lat, lon, coords) {
  var currentLat = coords?.latitude || 41.850033;
  var currentLon = coords?.longitude || -87.6500523;

  var R = 3958.8; // Radius of the earth in miles, 6371kms
  var dLat = deg2rad(currentLat - lat); // deg2rad below
  var dLon = deg2rad(currentLon - lon);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat)) *
      Math.cos(deg2rad(currentLat)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in miles
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export function cleanLocation(location) {
  if (!location) return "";
  if (location.isOnline) return location.name;
  return (
    ((location.streetNum || "") + " " + (location.streetName || "")).trim() +
    (location.city && location.city !== ""
      ? (location.streetNum || location.streetName ? " " : "") + location.city
      : "") +
    ((location.state && location.state !== "") ||
    (location.zip && location.zip !== "")
      ? (location.streetNum || location.streetName || location.city
          ? ", "
          : "") + (location.state + " " + location.zip).trim()
      : "") +
    (location.country && location.country !== ""
      ? (location.streetNum ||
        location.streetName ||
        location.city ||
        location.state
          ? ", "
          : "") + location.country
      : "")
  );
}
