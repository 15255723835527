import React, { useState } from "react";

import {
  Collapse,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

import { useTheme } from "@mui/styles";

export default function SideNavItem(props) {
  const {
    type, // Solforge
    divider, // BCP field

    label, // Solforge label
    title, // BCP label

    MenuIcon, // Solforge Icon
    icon: Icon, // BCP Icon

    href,
    target,
    children,
    nested,
    onClick,
  } = props;

  const theme = useTheme();

  var [isOpen, setIsOpen] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  if (!Object.keys(props)?.length) {
    return <></>;
  }
  if (type === "title" || (!href && !children && !onClick && title)) {
    return (
      <ListItemText
        sx={{
          margin: 1,
          ml: "22px",
          fontSize: "0.8rem",
          fontWeight: "600",
          textTransform: "uppercase",
        }}
      >
        {label || title}
      </ListItemText>
      // <Typography color="inherit" noWrap variant="body2">
      //   {label || title}
      // </Typography>
    );
  } else if (type === "divider" || divider) {
    return (
      <Divider
        sx={{
          mt: 1,
          mb: 1,
          borderColor: "#FFFFFF",
        }}
      />
    );
  } else if (children) {
    return (
      <>
        <ListItemButton
          onClick={handleClick}
          sx={{
            margin: 1,
            fontSize: "1rem",
            fontWeight: "700",
            textTransform: "uppercase",
            "&:hover": {
              color: "primary.main",
              filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
                theme.palette.primary.main
              })`,
              textDecoration: "none",
            },
          }}
        >
          {MenuIcon ? (
            <ListItemIcon sx={{ color: "inherit" }}>{MenuIcon}</ListItemIcon>
          ) : Icon ? (
            <ListItemIcon sx={{ color: "inherit" }}>
              <Icon />
            </ListItemIcon>
          ) : (
            <></>
          )}
          <ListItemText>{label || title}</ListItemText>

          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((child) => (
              <SideNavItem nested {...child} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  return (
    <ListItemButton
      component={href !== undefined ? "a" : null}
      onClick={onClick}
      href={href}
      target={target}
      sx={
        nested
          ? {
              margin: 1,
              fontSize: "0.8rem",
              fontWeight: "600",
              textTransform: "uppercase",
              "&:hover": {
                color: "primary.main",
                filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
                  theme.palette.primary.main
                })`,
                textDecoration: "none",
              },
            }
          : {
              margin: 1,
              fontSize: "1rem",
              fontWeight: "700",
              textTransform: "uppercase",
              "&:hover": {
                color: "primary.main",
                filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
                  theme.palette.primary.main
                })`,
                textDecoration: "none",
              },
            }
      }
    >
      {MenuIcon ? (
        <ListItemIcon sx={{ color: "inherit" }}>{MenuIcon}</ListItemIcon>
      ) : Icon ? (
        <ListItemIcon sx={{ color: "inherit" }}>
          <Icon />
        </ListItemIcon>
      ) : (
        <></>
      )}
      <ListItemText
        inset={nested}
        sx={{
          fontFamily: "inherit",
          fontSize: nested ? "0.8rem" : "1rem",
          fontWeight: nested ? "600" : "700",
        }}
      >
        {label || title}
      </ListItemText>
    </ListItemButton>
  );
}
