import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import {
  getResizedFileName,
  titleCase,
  findGetParameter,
} from "../../components/utils/UtilityFunctions";
import { Box, CircularProgress, Container, Link } from "@mui/material";

import Markdown from "../../components/layout/Markdown";

import makeStyles from "@mui/styles/makeStyles";

import moment from "moment";
import "moment-timezone";

import { API } from "aws-amplify";

import NotFound from "../NotFound";
import MainPageHeader from "../../components/layout/MainPageHeader";
const useStyles = makeStyles((theme) => ({
  primaryContainer: {
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    maxWidth: "100%",
    //  "100%", "900px", "1050px", "1200px"],
    [theme.breakpoints.up("sm")]: {
      maxWidth: "900px",
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "1050px",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1200px",
    },
  },
  containerLoading: {
    width: "100%",
    height: "calc(100vh - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  infoWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    paddingBottom: "3px",
  },
  fullWidthImage: {
    width: "100%",
    maxWidth: "100%",
  },
  cardMedia: {
    paddingTop: "56.25%",
    marginBottom: theme.spacing(2),
  },
}));

function ViewPost() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  let { postUrl } = useParams();
  const [loading, setLoading] = useState(true);
  const [post, setPost] = useState(null);

  useEffect(() => {
    const check = async () => {
      if (postUrl !== undefined) {
        const apiGetData = await API.get("sfwApi", "/post", {
          queryStringParameters: {
            slug: postUrl,
            inclBody: true,
            inclAuthor: true,
            inclFeaturedImage: true,
            inclCategories: true,
            postType: "Post",
          },
        });

        if (apiGetData.error) {
          //
        } else {
          if (!apiGetData.body) {
            apiGetData.body = "";
          }
          setPost(apiGetData);
        }
      }
      setLoading(false);
    };
    check();
  }, [postUrl]);

  const seo = {
    title:
      (post && post.title
        ? post.title
        : titleCase(
            window.location.pathname.split("/").pop().split("-").join(" ")
          )) + " | SolForge Fusion",
    description:
      post && post.summary
        ? post.summary
        : "SolForge Fusion - the next generation of card games",
    image: findGetParameter("imageUrl")
      ? findGetParameter("imageUrl")
      : post &&
        post.myFeaturedImage &&
        post.myFeaturedImage.fileName &&
        post.myFeaturedImage.url
      ? `${post.myFeaturedImage.url}${getResizedFileName(
          post.myFeaturedImage.fileName,
          "large"
        )}`
      : "https://solforgefusion.com/images/icon-200x200.jpg",
  };

  return (
    <>
      {loading && (
        <Container className={classes.containerLoading}>
          <CircularProgress size={100} />
        </Container>
      )}
      {!loading && post && (
        <>
          <Helmet
            title={seo.title}
            description={seo.description}
            meta={[
              {
                name: "description",
                property: "og:description",
                content: seo.description,
              },
              { property: "og:title", content: seo.title },
              { property: "og:image", content: seo.image },
              { property: "og:image:type", content: "image/jpeg" },
              { property: "twitter:image:src", content: seo.image },
              { property: "twitter:title", content: seo.title },
              { property: "twitter:description", content: seo.description },
            ]}
          />
          <MainPageHeader
            title={post?.title}
            subTitle={`${moment(post.PublishedAt || post.UpdatedAt).format(
              "MMMM D, YYYY"
            )}`}
            headerImage={{
              url:
                post.myFeaturedImage &&
                post.myFeaturedImage.fileName &&
                post.myFeaturedImage.url
                  ? `${post.myFeaturedImage.url}${post.myFeaturedImage.fileName}`
                  : null,
            }}
          />

          <Box
            sx={{
              backgroundColor: post?.backgroundColor,
              color: post?.textColor,
            }}
          >
            <Container className={classes.primaryContainer} sx={{ pb: "60px" }}>
              <Markdown source={post.body} />
              <Box
                sx={{
                  display: "flex",
                }}
              >
                {Object.keys(post.myCategories).map((cat, index) => {
                  return (
                    <>
                      <Link href={`/news/${cat}`}>
                        #{titleCase(post.myCategories[cat])}
                      </Link>
                      &nbsp;
                    </>
                  );
                })}
              </Box>
              {userState && userState.access && userState.access.isEditor && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    p: 2,
                  }}
                >
                  <Link
                    size="small"
                    color="primary"
                    href={`/app/admin/post/${post.id}`}
                  >
                    Edit
                  </Link>
                </Box>
              )}
            </Container>
          </Box>
        </>
      )}
      {!loading && !post && <NotFound title="404 - Post Not Found" />}
    </>
  );
}

export default ViewPost;
