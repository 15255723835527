import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Modal,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import GeneralTab from "../../components/user/Account/GeneralTab";
import SocialsTab from "../../components/user/Account/SocialsTab";
import AvatarTab from "../../components/user/Account/AvatarTab";
import SecurityTab from "../../components/user/Account/SecurityTab";

import { UserContext } from "../../context/UserContext";
import { API } from "aws-amplify";
import OrganizationTab from "../../components/user/Account/OrganizationTab";
import BankingTab from "../../components/user/Account/BankingTab";
import {
  customAPIHeader,
  getEnv,
} from "../../components/utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  progressModalPaper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const env = getEnv();

export default function Account() {
  const classes = useStyles();
  const userState = useContext(UserContext);

  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const handleModalClose = () => {
    setLoading(false);
  };

  const [tabValue, setTabValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const check = async () => {
      const apiGetData = await API.get(
        "sfwApi",
        "/user/" + userState.user.username,
        {
          queryStringParameters: {
            inclAvatar: true,
            inclSocials: true,
          },
          headers: await customAPIHeader(),
        }
      );

      var apiUser = await API.get(
        env === "main" ? "BCPProdApi" : "BCPDevApi",
        `/users/${apiGetData?.playId}`,
        {
          queryStringParameters: {
            "expand[]": ["team", "bankAccount"],
          },
        }
      );

      setProfile({ ...apiGetData, play: apiUser });
    };

    check();
  }, []);

  return (
    <>
      <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
        <Typography variant="h4" gutterBottom>
          Account Settings
        </Typography>
        <Box className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="General" {...a11yProps(0)} />
              <Tab label="Socials" {...a11yProps(1)} />
              <Tab label="Avatar" {...a11yProps(2)} />
              <Tab label="Security" {...a11yProps(3)} />
              <Tab label="Organizations" {...a11yProps(4)} />
              <Tab label="Ticketing" {...a11yProps(5)} />
            </Tabs>
          </AppBar>
          <TabPanel value={tabValue} index={0}>
            <GeneralTab profile={profile} setLoading={setLoading} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SocialsTab profile={profile} setLoading={setLoading} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <AvatarTab
              userState={userState}
              profile={profile}
              setLoading={setLoading}
            />
          </TabPanel>
          <TabPanel value={tabValue} index={3}>
            <SecurityTab profile={profile} setLoading={setLoading} />
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <OrganizationTab profile={profile} setLoading={setLoading} />
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <BankingTab profile={profile} setLoading={setLoading} />
          </TabPanel>
        </Box>
      </Container>
      <Modal // `disableBackdropClick` is removed by codemod.
        // You can find more details about this breaking change in [the migration guide](https://mui.com/guides/migration-v4/#modal)
        disableAutoFocus
        open={loading}
        onClose={handleModalClose}
        disableScrollLock
      >
        <Box className={classes.progressModalPaper}>
          <CircularProgress color="primary" />
        </Box>
      </Modal>
    </>
  );
}
