import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Button,
  Breadcrumbs,
  Container,
  Grid,
  TextField,
  IconButton,
  Link,
  Modal,
  Typography,
  Box,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@mui/material";

import { Formik } from "formik";

import { postNotification } from "../../components/utils/Notifications";

import CancelIcon from "@mui/icons-material/Cancel";
import HomeIcon from "@mui/icons-material/Home";
import { customAPIHeader } from "../../components/utils/UtilityFunctions";
import ProgressModal from "../../components/modals/ProgressModal";

import { API } from "aws-amplify";
import CardSearchWidget from "../../components/decks/CardSearchWidget";

export default function CardList() {
  const [processing, setProcessing] = useState(false);
  const [progress, setProgress] = useState(null);

  const [loading, setLoading] = useState(false);

  const [cards, setCards] = useState([]);

  const [bulkAddOpen, setBulkAddOpen] = useState(false);

  useEffect(() => {
    const check = async () => {
      // fetchRows();
    };
    check();
  }, []);

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/app/admin">
          <HomeIcon color="inherit" />
        </Link>
        <Typography color="textPrimary">Card Library</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>
        Card Library{" "}
        <Button
          onClick={() => setBulkAddOpen(true)}
          type="button"
          color="secondary"
          variant="contained"
        >
          Upload Cards
        </Button>
      </Typography>

      <CardSearchWidget allRows={cards} setAllRows={setCards} admin />

      <CardUploader
        open={bulkAddOpen}
        setOpen={setBulkAddOpen}
        setProcessing={setProcessing}
        setProgress={setProgress}
      />

      <ProgressModal open={processing || loading} progress={progress} />
    </Container>
  );
}

const levelMappingArray = [
  { key: "abilityNo", type: "number" },
  { key: "name" },
  { key: "text" },
  { key: "attack", type: "number" },
  { key: "health", type: "number" },
  { key: "ttsStatLine" },
];
const mappingArray = [
  {
    key: "cardSetId",
    required: true,
    maxLength: 2,
    mapFunction: (card) => {
      return card.cardSetId.toLowerCase();
    },
  },
  { key: "name", required: true },
  {
    key: "cardType",
    required: true,
    validList: ["Forgeborn", "Creature", "Spell", "Exalt"],
  },
  { key: "cardSubType" },
  {
    key: "faction",
    required: true,
    validList: ["Alloyin", "Nekrium", "Tempys", "Uterra"],
  },
  {
    key: "crossFaction",
    validList: ["Alloyin", "Nekrium", "Tempys", "Uterra"],
  },
  {
    key: "imageFileName",
  },
  {
    key: "rarity",
  },
  {
    key: "spliced",
    type: "boolean",
  },
  {
    key: "keywords",
  },
  {
    key: "provides",
  },
  {
    key: "seeks",
  },
];
const CardUploader = (props) => {
  const { open, setOpen, setProcessing, setProgress } = props;

  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      {open && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "95vw",
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Upload Cards"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />

            <Formik
              initialValues={{
                csvLoad: "",
              }}
              enableReinitialize
              onSubmit={async (values, { setFieldValue }) => {
                var successLoad = [];
                var errorLoad = [];
                try {
                  setProgress(0);
                  setProcessing(true);

                  var rows = (values.csvLoad || "")
                    .split("\n")
                    .filter((el) => el !== "");

                  var counter = 0;
                  for (let row of rows) {
                    var cols = row.split("\t").map((el) => el.trim());
                    var newCard = { levels: {} };
                    try {
                      for (let i = 0; i < mappingArray.length; i++) {
                        let key = mappingArray[i];
                        let col = cols[i];
                        if (key.required && !col) {
                          throw new Error("missing: " + key.key);
                        }
                        if (col) {
                          if (key.type === "number") {
                            newCard[key.key] = parseFloat(col);
                          } else if (key.type === "boolean") {
                            newCard[key.key] = col.toLowerCase() === "true";
                          } else {
                            newCard[key.key] = col;
                          }

                          if (
                            key.maxLength &&
                            newCard[key.key].length > key.maxLength
                          ) {
                            throw new Error("invalid length: " + key.key);
                          }
                          if (
                            key.validList &&
                            !key.validList.includes(newCard[key.key])
                          ) {
                            throw new Error("invalid value: " + key.key);
                          }
                        }
                      }

                      newCard.id = `${newCard.cardSetId}${
                        newCard.faction?.[0]
                      }${newCard.crossFaction?.[0] || newCard.faction?.[0]}1${
                        newCard.name
                      }`
                        .replace(/[^\w\s]/gi, "")
                        .split(" ")
                        .join("-")
                        .trim()
                        .toLowerCase();

                      for (let l = 1; l < 10; l++) {
                        for (let i = 0; i < levelMappingArray.length; i++) {
                          let key = levelMappingArray[i];
                          let col =
                            cols[
                              mappingArray.length +
                                (l - 1) * levelMappingArray.length +
                                i
                            ];
                          if (key.required && !col) {
                            throw new Error("missing: " + key.key);
                          }
                          if (col) {
                            if (!newCard.levels[l]) {
                              newCard.levels[l] = {};
                            }
                            if (key.type === "number") {
                              newCard.levels[l][key.key] = parseFloat(col);
                            } else if (key.type === "boolean") {
                              newCard.levels[l][key.key] =
                                col.toLowerCase() === "true";
                            } else {
                              newCard.levels[l][key.key] = col;
                            }
                          }
                        }
                        if (
                          newCard.levels[l] &&
                          newCard.cardType === "Forgeborn"
                        ) {
                          console.log(newCard.levels[l]);
                          if (newCard.levels[l]?.abilityNo !== undefined) {
                            newCard.id += newCard.levels[l].abilityNo;
                          }
                          if (newCard.levels[l]?.name !== undefined) {
                            newCard[`a${l}n`] = newCard.levels[l].name;
                          }
                          if (newCard.levels[l]?.text !== undefined) {
                            newCard[`a${l}t`] = newCard.levels[l].text;
                          }
                        }
                      }

                      for (let i = 0; i < mappingArray.length; i++) {
                        let key = mappingArray[i];
                        if (key.mapFunction) {
                          newCard[key.key] = key.mapFunction(newCard);
                        }
                      }

                      await API.post("sfwApi", "/card", {
                        body: newCard,
                        headers: await customAPIHeader(),
                      });
                      successLoad.push(row);
                    } catch (err) {
                      console.error(err);
                      errorLoad.push(row);
                    }
                    counter++;
                    setProgress((counter / rows.length) * 100);
                  }
                } catch (err) {
                  postNotification("error", err);
                }

                if (successLoad.length) {
                  postNotification(
                    "success",
                    `${successLoad.length} cards successfully loaded`
                  );
                }
                if (errorLoad.length) {
                  postNotification(
                    "error",
                    `${errorLoad.length} cards contained errors`
                  );
                  setFieldValue("csvLoad", errorLoad.join("\n"));
                }

                setProgress(null);
                setProcessing(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent sx={{ height: "60vh", overflowY: "scroll" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        <TextField
                          name="csvLoad"
                          label="Card List"
                          value={values.csvLoad}
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={6}
                          margin="normal"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          error={Boolean(touched.csvLoad && errors.csvLoad)}
                          helperText={
                            <>
                              <Typography sx={{ fontSize: "inherit" }}>
                                {mappingArray.map((el) => el.key).join(" ")}{" "}
                                {levelMappingArray
                                  .map((el) => `l1${el.key}`)
                                  .join(" ")}{" "}
                                {levelMappingArray
                                  .map((el) => `l2${el.key}`)
                                  .join(" ")}
                                {" ... "}
                                {levelMappingArray
                                  .map((el) => `l10${el.key}`)
                                  .join(" ")}
                              </Typography>
                            </>
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions
                    sx={{
                      paddingTop: 1,
                      paddingRight: 2,
                      display: "block",
                      textAlign: "right",
                    }}
                  >
                    <Button type="submit" color="primary" variant="contained">
                      Save
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      )}
    </Modal>
  );
};
