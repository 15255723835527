import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Grid,
  LinearProgress,
  Link,
  Modal,
  TextField,
  Typography,
  IconButton,
  CircularProgress,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import TuneIcon from "@mui/icons-material/Tune";

import { postNotification } from "../utils/Notifications";

import { Formik } from "formik";

import { API, Auth } from "aws-amplify";

import ListResponsive from "../layout/ListResponsive";
import { customAPIHeader } from "../utils/UtilityFunctions";
import { rarities } from "../utils/Rarity";

const factions = {
  Alloyin: {
    name: "Alloyin",
    img: "/images/factions/alloyin.png",
  },
  Nekrium: {
    name: "Nekrium",
    img: "/images/factions/nekrium.png",
  },
  Tempys: {
    name: "Tempys",
    img: "/images/factions/tempys.png",
  },
  Uterra: {
    name: "Uterra",
    img: "/images/factions/uterra.png",
  },
};

const masterFactions = [
  {
    name: "Alloyin",
    img: "/images/factions/alloyin.png",
  },
  {
    name: "Nekrium",
    img: "/images/factions/nekrium.png",
  },
  {
    name: "Tempys",
    img: "/images/factions/tempys.png",
  },
  {
    name: "Uterra",
    img: "/images/factions/uterra.png",
  },
];

export default function CardSearchWidget(props) {
  const { allRows, setAllRows, admin } = props;
  const userState = useContext(UserContext);

  const [loading, setLoading] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [hasAdvancedFilters, setHasAdvancedFilters] = useState(false);
  const [message, setMessage] = useState("");
  const searchValues = {
    factions: {},
    cardTypes: {},
    rarities: {},
    cardSets: {},
    advanced: {},
    searchText: "",
    betrayer: false
  };

  var initialFactions = [];
  if (userState.cookieConsent) {
    var getGetCachedData = localStorage.getItem(`factions`);
    if (getGetCachedData) {
      initialFactions = JSON.parse(getGetCachedData);
    }
  }
  const [filterFactions, setFilterFactions] = useState(initialFactions);

  const fetchRows = async (filters, rows, lastEvaluatedKey) => {
    if (!lastEvaluatedKey) {
      setLoading(true);
      setAllRows([]);
    }

    var queryStringParameters = {
      pageSize: 100,
    };
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }
    if (filters) {
      queryStringParameters = {
        ...queryStringParameters,
        ...filters,
      };
    }

    const apiGetData = await API.get("sfwApi", "/card", {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    var newRows = [...rows, ...apiGetData.Items];
    setAllRows(newRows);

    if (apiGetData.LastEvaluatedKey) {
      var nextFetch = await fetchRows(
        filters,
        newRows,
        apiGetData.LastEvaluatedKey
      );
      return [...apiGetData.Items, ...nextFetch];
    } else {
      return apiGetData.Items || [];
    }
  };

  const fetchFactions = async (lastEvaluatedKey) => {
    const queryStringParameters = {
      pageSize: 200,
    };
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }
    const apiGetData = await API.get("sfwApi", "/faction", {
      queryStringParameters: queryStringParameters,
    });

    if (apiGetData.error) {
    }
    if (apiGetData.LastEvaluatedKey) {
      var nextFetch = await fetchFactions(apiGetData.LastEvaluatedKey);
      return [...apiGetData.Items, ...nextFetch];
    } else {
      return apiGetData.Items || [];
    }
  };

  useEffect(() => {
    const check = async () => {
      var data = await fetchFactions(null);
      setFilterFactions(data);
      if (userState.cookieConsent) {
        localStorage.setItem("factions", JSON.stringify(data));
        var getCachedItems = localStorage.getItem("CachedItems");
        var cachedItems = getCachedItems ? JSON.parse(getCachedItems) : {};
        cachedItems["factions"] = true;
        localStorage.setItem("CachedItems", JSON.stringify(cachedItems));
      }
    };
    check();
  }, []);

  const noSpecials = (value) => {
    var specials = /[*|!:<>[\]{}`\\/();@&$^]/;
    return !specials.test(value);
  };

  return (
    <>
      <Formik
        initialValues={searchValues}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          var queryObject = {
            query: {
              bool: {
                filter: [],
              },
            },
            sort: [{ "nameSearch.keyword": { order: "asc" } }],
          };

          if (values.searchText && values.searchText !== "") {
            var searchText = values.searchText;
            let regexQuotes = /"(.*?[^\\])"/g;

            var quoteTerms = [];
            var match;
            while ((match = regexQuotes.exec(searchText)) != null) {
              if (match[1]) {
                quoteTerms.push(match[1]);
              }
            }

            if (quoteTerms.length) {
              quoteTerms.forEach((quoteTerm) => {
                searchText = searchText.split(`"${quoteTerm}"`).join("");
              });
            }

            var terms = searchText.split('"').join("").split(" ");
            if (quoteTerms.length) {
              terms = [...terms, ...quoteTerms];
            }
            const termFields = [
              "nameSearch",
              "cardSubType",
              "faction",
              "cardType",
              "textSearch",
              "crossFaction"
            ];
            terms.forEach((term) => {
              if (term !== "") {
                var termQueries = [];
                termFields.forEach((termField) => {
                  termQueries.push({
                    query_string: {
                      query: `*${term}*`,
                      default_field: termField,
                    },
                  });
                });
                queryObject.query.bool.filter.push({
                  bool: {
                    should: termQueries,
                    minimum_should_match: 1,
                  },
                });
              }
            });
          }
        if(!values.betrayer) {
          queryObject.query.bool.filter.push({
            match_phrase: {
              betrayer: values.betrayer,
            },
          });
        }

          if (values.factions && Object.keys(values.factions)?.length > 0) {
            var factionQueries = [];
            Object.keys(values.factions).forEach((faction) => {
              if (values.factions[faction] === true) {
                factionQueries.push({
                  match_phrase: {
                    faction: faction,
                  },
                });
              }
            });

            if (factionQueries.length > 0) {
              queryObject.query.bool.filter.push({
                bool: {
                  should: factionQueries,
                  minimum_should_match: 1,
                },
              });
            }
          }

          if (values.cardTypes && Object.keys(values.cardTypes)?.length > 0) {
            var cardTypeQueries = [];
            Object.keys(values.cardTypes).forEach((cardType) => {
              if (values.cardTypes[cardType] === true) {
                cardTypeQueries.push({
                  match_phrase: {
                    cardType: cardType,
                  },
                });
              }
            });

            if (cardTypeQueries.length > 0) {
              queryObject.query.bool.filter.push({
                bool: {
                  should: cardTypeQueries,
                  minimum_should_match: 1,
                },
              });
            }
          }
          console.log(values.cardSets)
          if (values.cardSets && Object.keys(values.cardSets)?.length > 0) {
            var cardSetQueries = [];
            Object.keys(values.cardSets).forEach((cardSet) => {
              if (values.cardSets[cardSet] === true) {
                cardSetQueries.push({
                  match_phrase: {
                    cardSetNo: cardSet,
                  },
                });
              }
            });
            console.log(cardSetQueries)
            if (cardSetQueries.length > 0) {
              queryObject.query.bool.filter.push({
                bool: {
                  should: cardSetQueries,
                  minimum_should_match: 1,
                },
              });
            }
          }

          if (values.rarities && Object.keys(values.rarities)?.length > 0) {
            var rarityQueries = [];
            Object.keys(values.rarities).forEach((rarity) => {
              if (values.rarities[rarity] === true) {
                rarityQueries.push({
                  match_phrase: {
                    "rarity.keyword": rarity,
                  },
                });
              }
            });

            if (rarityQueries.length > 0) {
              queryObject.query.bool.filter.push({
                bool: {
                  should: rarityQueries,
                  minimum_should_match: 1,
                },
              });
            }
          }

          if (!queryObject.query.bool.filter.length) {
            postNotification("error", "Please select at least one filter");
            return;
          }

          try {
            setMessage("");
            var rows = await fetchRows(
              { queryObject: JSON.stringify(queryObject) },
              [],
              null
            );
            setAllRows(rows);
            if (!rows || rows.length === 0) {
              setMessage("No cards found matching your filters");
            }

            setSubmitting(false);
            setLoading(false);
          } catch (error) {
            postNotification("error", error.message || JSON.stringify(error));
            setSubmitting(false);
            setLoading(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          submitForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  error={Boolean(touched.searchText && errors.searchText)}
                  fullWidth
                  helperText={touched.searchText && errors.searchText}
                  label="Search"
                  margin="normal"
                  name="searchText"
                  onBlur={(e) => {
                    if (noSpecials(e.target.value)) {
                      handleBlur(e);
                    }
                  }}
                  onChange={(e) => {
                    if (noSpecials(e.target.value)) {
                      handleChange(e);
                    }
                  }}
                  value={values.searchText}
                  color="secondary"
                  variant="outlined"
                  disabled={loading}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  mt: 2,
                  mb: 1,
                }}
              >
                {masterFactions.map((faction) => {
                  return (
                    <IconButton
                      variant="contained"
                      color="primary"
                      disabled={loading}
                      onClick={() => {
                        setFieldValue(
                          `factions.${faction.name}`,
                          !values?.factions?.[faction.name]
                        );
                      }}
                      sx={
                        !!values?.factions?.[faction.name]
                          ? {
                              backgroundColor:
                                "rgba(244, 67, 54, 0.5) !important",
                            }
                          : null
                      }
                    >
                      <Avatar
                        // sx={{ width: "20px", height: "20px" }}
                        alt={faction.name}
                        src={faction.img}
                        color="primary"
                      />
                    </IconButton>
                  );
                })}
                <IconButton
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={() => {
                    setFiltersOpen(true);
                  }}
                  sx={
                    !!hasAdvancedFilters
                      ? {
                          backgroundColor: "rgba(244, 67, 54, 0.5) !important",
                        }
                      : null
                  }
                >
                  <TuneIcon sx={{ fontSize: 40 }} />
                </IconButton>
                <AdvancedFiltersModal
                  values={values}
                  open={filtersOpen}
                  setOpen={setFiltersOpen}
                  setFieldValue={setFieldValue}
                  setHasAdvancedFilters={setHasAdvancedFilters}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={2}
                sx={{
                  mt: [null, 2],
                  mb: [null, 1],
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                >
                  Search
                </Button>
              </Grid>
              
              <FormControlLabel
              control={<Checkbox />}
              variant={values.betrayer ? "contained" : "outlined"}
              sx={{ margin: "4px", padding: "4px", textTransform: "unset" }}
              onChange={() => {
                setFieldValue(`betrayer`, !values.betrayer);
              }}
              label="Include Betrayers"
            />
            </Grid>
            <Box sx={{ padding: "50px 0 0 0" }} />
          </form>
        )}
      </Formik>
      {Boolean(!loading && message !== "") && (
        <>
          <Box>{message}</Box>
        </>
      )}
      {loading && <LinearProgress />}
      {Boolean(allRows.length > 0) && (
        <>
          <Box sx={{ width: "100%", mb: 1 }}>
            <Typography variant="h4">
              Search Results: <b>{allRows.length}</b>
              {loading && <CircularProgress size={28} />}
            </Typography>
          </Box>
          <ListResponsive
            originalList={loading && !allRows.length ? null : allRows}
            doPages
            initialRowsPerPage={50}
            rowProps={{
              mapFunction: (row, index) => (
                <Grid key={index} item xs={12}>
                  <Link href={"/cards/" + row.id} target="_blank">
                    <Grid
                      container
                      sx={{
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRadius: "4px",
                        mb: 1,
                        p: 1,
                      }}
                    >
                      <Grid item xs={9}>
                        <Typography variant="body1">{row.name}</Typography>
                        <Typography variant="caption" color="text.primary">{`${
                          row.cardType || ""
                        }${
                          row.cardSubType && row.cardSubType !== "0"
                            ? " - " + row.cardSubType
                            : ""
                        }`}</Typography>
                      </Grid>

                      <Grid item xs={3} sx={{ textAlign: "right" }}>
                        <Box
                          sx={{ display: "inline-flex", position: "relative" }}
                        >
                          <Avatar
                            alt={row.faction}
                            src={factions[row.faction]?.img}
                            color="primary"
                            sx={{ display: "inline-flex" }}
                          />
                          {row.betrayer && (
                            <Avatar
                              alt={`Betrayer ${row.faction}`}
                              src="/images/cards/betrayer.png"
                              sx={{ position: "absolute" }}
                            />
                          )}
                        </Box>
                        <Avatar
                          alt={row.rarity}
                          // src={rarities[row.rarity]?.img}
                          src={`/images/cards/rarity/S${
                            row.cardSetNo === undefined ||
                            row.cardSetNo >
                              process.env.REACT_APP_CURRENT_SET_NUMBER
                              ? process.env.REACT_APP_CURRENT_SET_NUMBER
                              : row.cardSetNo
                          }_${row.rarity.split(" ").join("")}.png`}
                          color="primary"
                          sx={{ display: "inline-flex" }}
                        />
                      </Grid>
                    </Grid>
                  </Link>
                </Grid>
              ),
            }}
          />
        </>
      )}
    </>
  );
}

const AdvancedFiltersModal = (props) => {
  const { values, open, setOpen, setFieldValue, setHasAdvancedFilters } = props;

  const cardTypes = [
    // "Forgeborn",
    "Creature",
    "Spell",
  ];

  const cardSets = [
    ["Alpha", 1],
    ["Battle for Whitefang Pass", 2],
    ["The Last Winter", 3],
  ];

  const updateHasAdvancedFilters = (newValues) => {
    if (newValues.cardTypes && Object.keys(newValues.cardTypes)?.length > 0) {
      var cardTypesArray = Object.keys(newValues.cardTypes);
      for (let i = 0; i < cardTypesArray.length; i++) {
        if (newValues.cardTypes[cardTypesArray[i]] === true) {
          setHasAdvancedFilters(true);
          return;
        }
      }
    }

    if (newValues.cardSets && Object.keys(newValues.cardSets)?.length > 0) {
      var cardTypesArray = Object.keys(newValues.cardSets);
      for (let i = 0; i < cardTypesArray.length; i++) {
        if (newValues.cardSets[cardTypesArray[i]] === true) {
          setHasAdvancedFilters(true);
          return;
        }
      }
    }

    if (newValues.rarities && Object.keys(newValues.rarities)?.length > 0) {
      var raritiesArray = Object.keys(newValues.rarities);
      for (let i = 0; i < raritiesArray.length; i++) {
        if (newValues.rarities[raritiesArray[i]] === true) {
          setHasAdvancedFilters(true);
          return;
        }
      }
    }

    if (newValues.advanced && Object.keys(newValues.advanced)?.length > 0) {
      var advancedArray = Object.keys(newValues.advanced);
      for (let i = 0; i < advancedArray.length; i++) {
        if (newValues.advanced[advancedArray[i]] === true) {
          setHasAdvancedFilters(true);
          return;
        }
      }
    }

    setHasAdvancedFilters(false);
  };

  return (
    <Modal
      disableAutoFocus
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: ["95vw", "85vw", 600],
          //minHeight: "95vh",
          maxHeight: "95vh",
          overflowY: "scroll",
          bgcolor: "background.paper",
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: 1,
          boxShadow: 24,
          p: 4,
        }}
      >
        <IconButton
          aria-label="cancel"
          color="error"
          sx={{ position: "absolute", top: "4px", right: "4px" }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <ClearIcon />
        </IconButton>
        <Grid
          container
          spacing={2}
          sx={{
            pb: 1,
            mb: 2,
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <Typography variant="h4" component="h2">
              Advanced Filters
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {cardSets.map((cardSet) => {
              return (
                <Button
                  variant={
                    values.cardSets[cardSet[1]] ? "contained" : "outlined"
                  }
                  sx={{ margin: "4px", padding: "4px", textTransform: "unset" }}
                  onClick={() => {
                    var newValues = {
                      ...values,
                      cardSets: {
                        ...values.cardSets,
                        [cardSet[1]]: !values?.cardSets?.[cardSet[1]],
                      },
                    };
                    setFieldValue(
                      `cardSets["${cardSet[1]}"]`,
                      !values.cardSets[cardSet[1]]
                    );
                    updateHasAdvancedFilters(newValues);
                  }}
                >
                  {cardSet[0]}
                </Button>
              );
            })}
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {Object.values(rarities).map((rarity) => {
              return (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    var newValues = {
                      ...values,
                      rarities: {
                        ...values.rarities,
                        [rarity.name]: !values?.rarities?.[rarity.name],
                      },
                    };

                    setFieldValue(
                      `rarities.${rarity.name}`,
                      !values?.rarities?.[rarity.name]
                    );

                    updateHasAdvancedFilters(newValues);
                  }}
                  sx={
                    !!values?.rarities?.[rarity.name]
                      ? {
                          backgroundColor: "rgba(244, 67, 54, 0.5) !important",
                        }
                      : null
                  }
                >
                  <Avatar
                    // sx={{ width: "20px", height: "20px" }}
                    alt={rarity.name}
                    src={`/images/cards/rarity/S${
                      process.env.REACT_APP_CURRENT_SET_NUMBER
                    }_${rarity.name.split(" ").join("")}.png`}
                    color="primary"
                  />
                </IconButton>
              );
            })}
            {/* {rarities.map((rarity) => {
              return (
                <Button
                  variant={values.rarities[rarity] ? "contained" : "outlined"}
                  sx={{ margin: "4px", padding: "4px", textTransform: "unset" }}
                  onClick={() => {
                    setFieldValue(
                      `rarities["${rarity}"]`,
                      !values.rarities[rarity]
                    );
                  }}
                >
                  {rarity}
                </Button>
              );
            })} */}
          </Grid>
        </Grid>
        <Grid container spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            {cardTypes.map((cardType) => {
              return (
                <Button
                  variant={
                    values.cardTypes[cardType] ? "contained" : "outlined"
                  }
                  sx={{ margin: "4px", padding: "4px", textTransform: "unset" }}
                  onClick={() => {
                    var newValues = {
                      ...values,
                      cardTypes: {
                        ...values.cardTypes,
                        [cardType]: !values?.cardTypes?.[cardType],
                      },
                    };
                    setFieldValue(
                      `cardTypes["${cardType}"]`,
                      !values.cardTypes[cardType]
                    );
                    updateHasAdvancedFilters(newValues);
                  }}
                >
                  {cardType}
                </Button>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
