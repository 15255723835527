import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  Tooltip,
  Typography,
} from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import PersonIcon from "@mui/icons-material/Person";

import { API } from "aws-amplify";
import { cleanLocation } from "./BCPUtilities";
import { getEnv } from "../utils/UtilityFunctions";

const env = getEnv();

export default function OrganizationCard(props) {
  const { organization, organize } = props;
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    const check = async () => {
      setPhoto(null);
      if (organization?.photoFileId) {
        try {
          var apiPhoto = await API.get(
            env === "main" ? "BCPProdApi" : "BCPDevApi",
            `/files/${organization.photoFileId}`,
            {
              queryStringParameters: {
                //resize type?
              },
            }
          );
          setPhoto(apiPhoto);
        } catch (err) {
          console.log(err);
        }
      }
    };
    check();
  }, [props]);

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <CardActionArea
          href={`${
            env === "main"
              ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
              : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
          }${organize ? "/organize" : ""}/organization/${organization.id}`}
        >
          <CardMedia
            component="img"
            image={
              photo?.url ||
              organization?.imageURL ||
              `${
                env === "main"
                  ? process.env.REACT_APP_HEADER_BCP_URL_MAIN
                  : process.env.REACT_APP_HEADER_BCP_URL_DEV || ""
              }/images/EventImage.png`
            }
            alt={organization?.name}
            sx={{
              maxHeight: "calc((100vw - 24px) * 0.5625)",
              "@media (min-width: 600px)": {
                maxHeight: "calc((100vw - 24px) * 0.5 * 0.5625)",
              },
              "@media (min-width: 900px)": {
                maxHeight: "calc((100vw - 24px) * 0.3333 * 0.5625)",
              },
              "@media (min-width: 1218px)": {
                maxHeight: "215px",
              },
            }}
          />
          <CardHeader
            title={organization?.name}
            subheader={
              organization?.distance !== undefined
                ? `${
                    organization.distance > 10
                      ? parseInt(organization.distance)
                      : organization.distance.toFixed(2)
                  } ${organization.distanceType || "miles"}`
                : null
            }
          />
        </CardActionArea>
        <CardContent>
          {organization.owner && (
            <Typography gutterBottom>
              <Box
                sx={{
                  minWidth: "35px",
                  verticalAlign: "bottom",
                  display: "inline-flex",
                }}
              >
                <Tooltip title="Organization Owner">
                  <PersonIcon
                    sx={{
                      color: "text.secondary",
                      verticalAlign: "bottom",
                    }}
                  />
                </Tooltip>
              </Box>
              {`${organization?.owner?.firstName || ""} ${
                organization?.owner?.lastName || ""
              }`}
            </Typography>
          )}

          {organization.location && (
            <Typography gutterBottom>
              <Box
                sx={{
                  minWidth: "35px",
                  verticalAlign: "bottom",
                  display: "inline-flex",
                }}
              >
                <Tooltip title="Location">
                  <LocationOnIcon
                    sx={{
                      color: "text.secondary",
                      verticalAlign: "bottom",
                    }}
                  />
                </Tooltip>
              </Box>
              {cleanLocation(organization.location)}
            </Typography>
          )}
        </CardContent>
      </Card>
    </>
  );
}
