import { SvgIcon } from "@mui/material";

export default function TrophyIcon(props) {
  return (
    <SvgIcon viewBox="0 0 576 512">
      <path
        fill={props.trophy ? props.trophy : "inherit"}
        d="M552,64H448V24c0-13.3-10.7-24-24-24H152c-13.3,0-24,10.7-24,24v40H24C10.7,64,0,74.7,0,88v56
	c0,66.5,77.9,131.7,171.9,142.4C203.3,338.5,240,360,240,360v72h-48c-35.3,0-64,20.7-64,56v12c0,6.6,5.4,12,12,12h296
	c6.6,0,12-5.4,12-12v-12c0-35.3-28.7-56-64-56h-48v-72c0,0,36.7-21.5,68.1-73.6C498.4,275.6,576,210.3,576,144V88
	C576,74.7,565.3,64,552,64z M64,144v-16h64.2c1,32.6,5.8,61.2,12.8,86.2C93.5,197.8,64,164.3,64,144z M512,144
	c0,20.2-29.4,53.8-77,70.2c7-25,11.8-53.6,12.8-86.2H512V144z"
      />
      <path
        fill={props.star ? props.star : "rgba(255,255,255,0.1"}
        d="M384.7,148.7l-39.6,38.6l9.4,54.6c1.7,9.8-8.7,17.2-17.4,12.6l-49-25.8l-49,25.8c-8.8,4.6-19.1-2.9-17.4-12.6
	l9.4-54.6l-39.6-38.6c-7.1-6.9-3.2-19,6.7-20.5l54.8-8l24.5-49.6c4.4-8.9,17.1-8.9,21.5,0l24.5,49.6l54.8,8
	C387.9,129.7,391.8,141.8,384.7,148.7z"
      />
    </SvgIcon>
  );
}
