import {  Auth } from "aws-amplify";
import { createContext, useState, useEffect, useMemo } from "react";
import Cookies from "js-cookie";


const defaultAccess = {
  isAdmin: false,
  isEditor: false,
};

function getAcccess(user) {
  var response = {
    ...defaultAccess,
  };

  if (
    user &&
    user.signInUserSession &&
    user.signInUserSession.accessToken &&
    user.signInUserSession.accessToken.payload &&
    user.signInUserSession.accessToken.payload["cognito:groups"]
  ) {
    user.signInUserSession.accessToken.payload["cognito:groups"].forEach(
      function (userGroup) {
        if (userGroup === "Admins") {
          response.isAdmin = true;
        } else if (userGroup === "Editors") {
          response.isEditor = true;
        }
      }
    );
  }

  return response;
}

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  var cookieVal = Cookies.get("CookieConsent");
  const [cookieConsent, setCookieConsent] = useState(
    cookieVal && cookieVal === "true"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [access, setAccess] = useState(defaultAccess);
  // const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    // AWS Cognito

    Auth.currentAuthenticatedUser()
      .then((x) => {
        setUserState(x);

        // Auth.currentSession()
        //   .then((idToken) => {
        //     var idTokenPayload = idToken.getIdToken().payload;
        //     console.log(idTokenPayload);
        //     if (idTokenPayload?.avatarUrl) {
        //       setAvatar({
        //         id: idTokenPayload.avatarId,
        //         name: idTokenPayload.avatarName,
        //         url: idTokenPayload.avatarUrl,
        //       });
        //     }
        //   })
        //   .catch((err) => {
        //     setAvatar(null);
        //   });
      })
      .catch((err) => {
        setUserState();
        // setAvatar(null);
      });
  }, []);

  const handleCookieConsent = (x) => {
    setCookieConsent(x);
  };

  const setUserState = (x) => {
    if (x !== undefined && x !== null) {
      var newUser = { ...x };
      if (newUser.signInUserSession?.idToken?.payload) {
        Object.keys(newUser.signInUserSession.idToken.payload).forEach(
          (key) => {
            newUser[key.split("custom:").join("")] =
              newUser.signInUserSession.idToken.payload[key];
          }
        );
      }
      setUser(newUser);
      setAccess(getAcccess(x));
      setIsAuthenticated(true);
      setIsLoading(false);
    } else {
      setIsAuthenticated(false);
      setUser(null);
      setAccess(defaultAccess);
      setIsLoading(false);
    }
  };

  const login = (loginInfo, postNotification, setSubmitting) => {
    const { username, password, remember } = loginInfo;
    return Auth.signIn(username, password)
      .then((x) => {
        if (remember) {
          Auth.rememberDevice()
            .then((result) => {
              setUserState(x);
              setSubmitting(false);
              setIsLoading(false);
              return Promise.resolve(x);
            })
            .catch((error) => {
              setUserState(x);
              setSubmitting(false);
              setIsLoading(false);
              return Promise.resolve(x);
            });
        } else {
          setUserState(x);
          setSubmitting(false);
          setIsLoading(false);
          return Promise.resolve(x);
        }
      })
      .catch((err) => {
        postNotification("error", err.message);
        setUserState();
        setSubmitting(false);
        setIsLoading(false);
        return Promise.reject(err);
      });
  };

  const logout = (navigate) => {
    Auth.signOut()
      .then(() => {
        setUserState(null);
        setIsLoading(false);

        Cookies.remove("CookieConsent");
        Cookies.remove("referralCode");
        var getCachedItems = localStorage.getItem("CachedItems");
        var cachedItems = getCachedItems ? JSON.parse(getCachedItems) : {};
        Object.keys(cachedItems).forEach((cachedKey) => {
          if (!!cachedItems[cachedKey]) {
            localStorage.removeItem(cachedKey);
            delete cachedItems[cachedKey];
          }
        });
        localStorage.setItem("CachedItems", JSON.stringify(cachedItems));
        setCookieConsent(false);
        navigate("/login");
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const vals = useMemo(
    () => ({
      access,
      user,
      // avatar,
      // setAvatar,
      login,
      logout,
      isAuthenticated,
      isLoading,
      cookieConsent,
      handleCookieConsent,
    }),
    [
      access,
      user,
      //  avatar,
      isAuthenticated,
      isLoading,
      cookieConsent,
    ]
  );

  return <UserContext.Provider value={vals}>{children}</UserContext.Provider>;
};
