import React, { useContext } from "react";

import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";

import * as Yup from "yup";
import { Formik } from "formik";

import { UserContext } from "../../context/UserContext";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

export default function LoginForm({
  setAuthStep,
  postNotification,
  sharedInfo,
  setSharedInfo,
  processing,
  setProcessing,
  redirect,
  claim,
}) {
  const classes = useStyles();
  const userState = useContext(UserContext);
  const [forgotProgress, setForgotProgress] = React.useState(false);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {claim==='true' && postNotification(
        "info",
        "Please login to claim deck"
        )}
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Formik
          initialValues={{
            username: sharedInfo.username,
            password: sharedInfo.password,
            remember: false,
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(255).required("Username is required"),
            password: Yup.string().max(255).required("Password is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setProcessing(true);
            userState
              .login(values, postNotification, setSubmitting)
              .then((user) => {
                if (redirect) {
                  window.location.assign(redirect);
                } else {
                  setProcessing(false);
                }
              })
              .catch((error) => {
                setProcessing(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setSubmitting,
            setFieldTouched,
            touched,
            values,
          }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <TextField
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
                autoComplete="username"
                name="username"
                variant="outlined"
                color="secondary"
                required
                fullWidth
                id="username"
                label="Username or Email"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.username}
              />

              <TextField
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                variant="outlined"
                color="secondary"
                required
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                margin="normal"
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Button
                    type="button"
                    disabled={isSubmitting || forgotProgress}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={async (e) => {
                      setFieldTouched("username", true, true);
                      await Promise.resolve();
                      if (!errors.username && values.username !== "") {
                        setForgotProgress(true);
                        await sendForgotPassword(values, setForgotProgress);
                      }
                    }}
                  >
                    Forgot Password
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      disabled={isSubmitting || forgotProgress}
                      fullWidth
                      variant="contained"
                      color="primary"
                      className={classes.submit}
                    >
                      Sign In
                    </Button>
                    {isSubmitting && (
                      <CircularProgress
                        color="primary"
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={12}>
                  Don't have an account?{" "}
                  <Link
                    onClick={() => {
                      setAuthStep(1);
                    }}
                    variant="body2"
                  >
                    Sign Up
                  </Link>
                </Grid>
                <Grid item xs={12}>
                  Need to{" "}
                  <Link
                    onClick={() => {
                      setAuthStep(3);
                    }}
                    variant="body2"
                  >
                    Verify
                  </Link>{" "}
                  your account?
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );

  async function sendForgotPassword(loginInfo, setSubmitting) {
    const { username } = loginInfo;
    try {
      await Auth.forgotPassword(username);
      postNotification(
        "info",
        "Please check your email for a verification code"
      );

      setSharedInfo({
        username: username,
        email: sharedInfo.email,
        password: "",
      });
      setAuthStep(2);
    } catch (error) {
      var unhandledError = true;
      if (error.code) {
        if (error.code === "UserNotFoundException") {
          unhandledError = false;
          postNotification("error", "Unknown username or email");
        }
      }
      if (unhandledError) {
        postNotification(
          "error",
          cognitoFriendlyError(
            error,
            "Error: something went wrong sending you your code, please try again."
          )
        );
      }
      setSubmitting(false);
    }

    function cognitoFriendlyError(error, def) {
      if (error) {
        if (error.code === "UserNotFoundException") {
          // confirmSignUp
          return "Username/Email not found.";
        } else if (
          error.code === "NotAuthorizedException" &&
          error.message ===
            "User cannot be confirmed. Current status is CONFIRMED"
        ) {
          // confirmSignUp
          return "Email has already been verified. Try logging in.";
        } else if (error.code === "UsernameExistsException") {
          // signUp
          return "Username already exists, please try another.";
        }

        if (error.message) {
          return error.message;
        }
      }
      if (def) {
        return def;
      }
      return "Unknown Error";
    }
  }
}
/*

*/
