import React, { useContext } from "react";
import { Box } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import Container from "@mui/material/Container";

import * as Yup from "yup";
import { Formik } from "formik";

import { UserContext } from "../../context/UserContext";
import { Auth } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: "relative",
  },
}));

export default function ForgotForm({
  setAuthStep,
  postNotification,
  sharedInfo,
  setSharedInfo,
  processing,
  setProcessing,
  redirect,
}) {
  const classes = useStyles();
  const userState = useContext(UserContext);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Set new password
        </Typography>
        <Formik
          initialValues={{
            username: sharedInfo.username,
            password: "",
            verifyCode: "",
          }}
          validationSchema={Yup.object().shape({
            username: Yup.string().max(255).required("Username is required"),
            password: Yup.string()
              .min(8)
              .max(255)
              .required("Password is required"),
            verifyCode: Yup.string()
              .max(255)
              .required("Verification Code is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setProcessing(true);
            verifyForgotPassword(values, setSubmitting)
              .then(() => {
                setProcessing(false);
              })
              .catch((error) => {
                setProcessing(false);
              });
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setSubmitting,
            setFieldTouched,
            touched,
            values,
          }) => (
            <form className={classes.form} noValidate onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.username && errors.username)}
                    helperText={touched.username && errors.username}
                    autoComplete="username"
                    name="username"
                    variant="outlined"
                    color="secondary"
                    required
                    fullWidth
                    id="username"
                    label="Username or Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.username}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.verifyCode && errors.verifyCode)}
                    helperText={touched.verifyCode && errors.verifyCode}
                    name="verifyCode"
                    variant="outlined"
                    color="secondary"
                    required
                    fullWidth
                    id="verifyCode"
                    label="Verify Code"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.verifyCode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    variant="outlined"
                    color="secondary"
                    required
                    fullWidth
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    margin="normal"
                    name="password"
                    label="New Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                </Grid>
              </Grid>
              <Box className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Update
                </Button>
                {isSubmitting && (
                  <CircularProgress
                    color="primary"
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </Box>
              <Grid container>
                <Grid item xs>
                  <Link
                    onClick={() => {
                      setAuthStep(0);
                    }}
                    variant="body2"
                  >
                    Sign In?
                  </Link>
                </Grid>
                <Grid item>
                  <Link
                    onClick={() => {
                      setAuthStep(1);
                    }}
                    variant="body2"
                  >
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Box>
    </Container>
  );

  function verifyForgotPassword(loginInfo, setSubmitting) {
    const { username, password, verifyCode } = loginInfo;

    if (!!username && !!password && !!verifyCode) {
      try {
        Auth.forgotPasswordSubmit(
          username.toLowerCase(),
          verifyCode,
          password
        ).then(() => {});
      } catch (error) {
        var unhandledError = true;
        if (error.code) {
          if (error.code === "UserNotFoundException") {
            unhandledError = false;
            postNotification("error", "Unknown username or email");
          } else if (error.code === "CodeMismatchException") {
            unhandledError = false;
            postNotification("error", "Invalid Verification Code");
          }
        }
        if (unhandledError) {
          postNotification(
            "error",
            cognitoFriendlyError(
              error,
              "Error: something went wrong with your verificaition, please try again."
            )
          );
        }
        setSubmitting(false);
      }
    } else {
      postNotification("error", "Missing required fields");
    }
  }

  function cognitoFriendlyError(error, def) {
    if (error) {
      if (error.code === "UserNotFoundException") {
        // confirmSignUp
        return "Username/Email not found.";
      } else if (
        error.code === "NotAuthorizedException" &&
        error.message ===
          "User cannot be confirmed. Current status is CONFIRMED"
      ) {
        // confirmSignUp
        return "Email has already been verified. Try logging in.";
      } else if (error.code === "UsernameExistsException") {
        // signUp
        return "Username already exists, please try another.";
      }

      if (error.message) {
        return error.message;
      }
    }
    if (def) {
      return def;
    }
    return "Unknown Error";
  }
}
