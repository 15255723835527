import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/UserContext";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Grid,
  Typography,
  Container,
  ButtonBase,
} from "@mui/material";
import { getResizedFileName } from "../utils/UtilityFunctions";
import { alpha } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  containerLoading: {
    width: "100%",
    height: "calc(80vh - 50px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cardContainer: {
    paddingBottom: theme.spacing(8),
  },
  cardGrid: {
    minHeight: 400,
  },
  heading: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    width: "100%",
    textAlign: "center",
    display: "flex",
    flexFirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textTransform: "uppercase",
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: `0px 0px 70px -12.125px ${alpha(
        theme.palette.primary.main,
        0.4
      )}`,
    },
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
}));

function titleCase(str) {
  var splitStr = str.split("-");
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
}

export default function PostGallery({
  params,
  title,
  titleSize,
  category,
  cacheid,
}) {
  const classes = useStyles();

  var initialPosts = [];
  var userState = useContext(UserContext);
  if (userState.cookieConsent) {
    if (cacheid) {
      var getGetCachedData = localStorage.getItem(`${cacheid}Posts`);
      if (getGetCachedData) {
        initialPosts = JSON.parse(getGetCachedData);
      }
    }
  }

  const [posts, setPosts] = useState(initialPosts);
  const [loading, setLoading] = useState(initialPosts.length === 0);
  const [allowLoadMore, setAllowLoadMore] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);

  const fetchPosts = async () => {
    setAllowLoadMore(false);
    //setLoading(true);

    const queryStringParameters = {
      pageSize: 12,
      inclAuthor: true,
      inclFeaturedImage: true,
      postType: "Post",
      filterByAppsToPublish: "{\"website\": true}",
      ...params,
    };
    if (lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey =
        JSON.stringify(lastEvaluatedKey);
    }
    if (category) {
      queryStringParameters.category = category;
    }
    const apiGetData = await API.get("sfwApi", "/post", {
      queryStringParameters: queryStringParameters,
    });
    if (apiGetData) {
      if (apiGetData.Items) {
        var allItems = [...apiGetData.Items];
        setPosts(allItems);
        if (cacheid && userState.cookieConsent) {
          var cachedata = [];
          allItems.forEach((item) => {
            cachedata.push({
              id: item.id,
              PublishedAt: item.PublishedAt || "",
              myFeaturedImage: item.myFeaturedImage || null,
              slug: item.slug,
              summary: item.summary,
              title: item.title,
            });
          });

          localStorage.setItem(`${cacheid}Posts`, JSON.stringify(cachedata));
          var getCachedItems = localStorage.getItem("CachedItems");
          var cachedItems = getCachedItems ? JSON.parse(getCachedItems) : {};
          cachedItems[`${cacheid}Posts`] = false;
          localStorage.setItem("CachedItems", JSON.stringify(cachedItems));
        }
      }
      if (apiGetData.LastEvaluatedKey) {
        setLastEvaluatedKey(apiGetData.LastEvaluatedKey);
        setAllowLoadMore(true);
        if (apiGetData.Count < queryStringParameters.pageSize) {
          var totalCount = apiGetData.Count
          console.log(totalCount)
          queryStringParameters.exclusiveStartKey = JSON.stringify(apiGetData.LastEvaluatedKey)
          var keepLooping = true
          var pageSize = queryStringParameters.pageSize
          while(totalCount<pageSize && keepLooping) {
            queryStringParameters.pageSize = queryStringParameters.pageSize-totalCount
            var apiData = await API.get("sfwApi", "/post", {
              queryStringParameters: queryStringParameters,
            });
            allItems = [...allItems,...apiData.Items];
            setPosts(allItems);
            totalCount += apiData.Count
            console.log(totalCount)
            if (apiData.LastEvaluatedKey && apiGetData.Count < queryStringParameters.pageSize) {
              setLastEvaluatedKey(apiData.LastEvaluatedKey);
              queryStringParameters.exclusiveStartKey = JSON.stringify(apiData.LastEvaluatedKey)
              
            } else {
              setLastEvaluatedKey(null)
              setAllowLoadMore(false)
              keepLooping = false
            }
          } 
        }
      }
    }
  };

  useEffect(() => {
    const check = async () => {
      await fetchPosts();
      if (loading) {
        setLoading(false);
      }
    };
    check();
  }, []);

  return (
    <>
      <Container className={classes.cardContainer} maxWidth="lg">
        {title && (
          <Typography
            className={classes.heading}
            gutterBottom
            variant={titleSize ? titleSize : "h2"}
            component="h2"
          >
            {`${title}${category ? ": " + titleCase(category) : ""}`}
          </Typography>
        )}
        {loading && (
          <Container className={classes.containerLoading}>
            <CircularProgress size={100} />
          </Container>
        )}
        <Grid className={classes.cardGrid} container spacing={4}>
          {!loading &&
            posts.map((post) => (
              <Grid item key={post.id} xs={12} sm={6} md={4}>
                <ButtonBase
                  href={`/post/${post.slug}`}
                  sx={{ width: "100% !important" }}
                >
                  <Card className={classes.card} sx={{ width: "100%" }}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={
                        post.myFeaturedImage &&
                        post.myFeaturedImage.fileName &&
                        post.myFeaturedImage.url
                          ? `${post.myFeaturedImage.url}${getResizedFileName(
                              post.myFeaturedImage.fileName,
                              "large"
                            )}`
                          : "/images/logo.png"
                      }
                      title={post.myFeaturedImage?.name || "SolForge Fusion"}
                    />
                    <CardContent
                      className={classes.cardContent}
                      sx={{
                        minHeight: ["unset", "180px"],
                      }}
                    >
                      <Typography gutterBottom variant="h5" component="h2">
                        {post.title}
                      </Typography>
                      <Typography>{post.summary}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        color="primary"
                        href={`/post/${post.slug}`}
                      >
                        Read More
                      </Button>
                    </CardActions>
                  </Card>
                </ButtonBase>
              </Grid>
            ))}
        </Grid>
      </Container>
    </>
  );
}
