import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Container, Typography } from "@mui/material";

import CardSearchWidget from "../../components/decks/CardSearchWidget";
import MainPageHeader from "../../components/layout/MainPageHeader";

export default function CardItem() {
  const [allRows, setAllRows] = useState([]);

  const seo = {
    title: "Card Search | SolForge Fusion",
    description: "SolForge Fusion - the next generation of card games",
    image: "https://solforgefusion.com/images/icon-200x200.jpg",
  };

  return (
    <>
      <Helmet
        title={seo.title}
        description={seo.description}
        meta={[
          {
            name: "description",
            property: "og:description",
            content: seo.description,
          },
          { property: "og:title", content: seo.title },
          { property: "og:image", content: seo.image },
          { property: "og:image:type", content: "image/jpeg" },
          { property: "twitter:image:src", content: seo.image },
          { property: "twitter:title", content: seo.title },
          { property: "twitter:description", content: seo.description },
        ]}
      />
      <MainPageHeader title={"Card Search"} />
      <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
        <CardSearchWidget allRows={allRows} setAllRows={setAllRows} />
      </Container>
    </>
  );
}
