import React, { useState, useEffect } from "react";
import ProgressModal from "../../components/modals/ProgressModal";
import MediaSelectModal from "../../components/modals/MediaSelectModal";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  TextField,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Fade,
  IconButton,
  Modal,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import { getResizedFileName } from "../../components/utils/UtilityFunctions";
import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import makeStyles from "@mui/styles/makeStyles";
import { API } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  preview: {
    maxWidth: "100%",
  },
  root: {
    width: "90%",
    maxWidth: "90%",
  },
  cardContent: {
    height: "60vh",
    overflowY: "scroll",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
    backgroundColor: "#242424",
  },
  cardActions: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    display: "block",
    textAlign: "right",
  },
}));

export default function AvatarItem(props) {
  const classes = useStyles();

  const initialValues = {
    id: null,
    name: "",
    myFeaturedImage: null,
    points: 0,
    ...props.avatar,
  };
  const [avatar, setAvatar] = useState(initialValues);

  const validationSchema = {
    points: Yup.number().min(0).required("Required"),
    name: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
  };

  const [open, setOpen] = useState(false);
  const [progressOpen, setProgressOpen] = useState(false);
  const [featuredOpen, setFeaturedOpen] = useState(false);

  useEffect(() => {
    const check = async () => {
      if (props.avatarId) {
        setOpen(true);
        setProgressOpen(true);
        const apiGetData = await API.get("sfwApi", "/avatar/" + props.avatarId);
        //("apiGetData", apiGetData);
        setAvatar({ ...avatar, ...apiGetData });
        setProgressOpen(false);
      } else {
        setOpen(false);
      }
    };

    check();
  }, [props.avatarId]);

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        type="button"
        color={props.color || "secondary"}
        variant={props.variant || "contained"}
      >
        {props.avatarId ? "Edit" : "Add"} Avatar
      </Button>

      <Modal
        disableAutoFocus
        open={open}
        onClose={() => {
          props.setAvatar(null);
          setOpen(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // sx={{ zIndex: 9999 }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: ["95vw", "85vw", 600],
            maxHeight: "95vh",

            bgcolor: "background.paper",
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: 1,
            boxShadow: 24,
          }}
        >
          <Card>
            <CardHeader
              title="Add Avatar"
              action={
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    props.setAvatar(null);
                    setOpen(false);
                  }}
                  size="large"
                >
                  <CancelIcon />
                </IconButton>
              }
            />

            <Formik
              initialValues={avatar}
              enableReinitialize={true}
              validationSchema={Yup.object().shape(validationSchema)}
              onSubmit={async (values, { setSubmitting, setFieldValue }) => {
                try {
                  setProgressOpen(true);
                  if (values.myFeaturedImage) {
                    var postBody = {
                      name: values.name,
                      featuredImage: values.myFeaturedImage.id,
                      points: values.points,
                      // featuredImage: values.myFeaturedImage.fileName,
                    };
                    var vAvatarId = values.id;

                    var apiPostData = await API.post(
                      "sfwApi",
                      `/avatar${vAvatarId ? "/" + vAvatarId : ""}`,
                      {
                        body: postBody,
                      }
                    );
                    if (apiPostData.data) {
                      apiPostData.data.myFeaturedImage = {
                        ...apiPostData.data.myFeaturedImage,
                        ...values.myFeaturedImage,
                      };
                      props.updateItems(apiPostData.data, false);

                      if (
                        !props.avatarId &&
                        apiPostData.data &&
                        apiPostData.data.id
                      ) {
                        setFieldValue("id", apiPostData.data.id);
                      }
                      props.setAvatar(null);
                      setOpen(false);
                      postNotification(
                        "success",
                        `Avatar ${props.avatarId ? "updated" : "created"}`
                      );
                    } else if (apiPostData.error) {
                      postNotification("error", apiPostData.error);
                    }
                  } else {
                    postNotification("error", "Please select a Media Item");
                  }

                  setSubmitting(false);
                  setProgressOpen(false);
                } catch (error) {
                  postNotification(
                    "error",
                    error.message || JSON.stringify(error)
                  );
                  setSubmitting(false);
                  setProgressOpen(false);
                }
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <CardContent className={classes.cardContent}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.name && errors.name)}
                          fullWidth
                          required
                          helperText={touched.name && errors.name}
                          label="Name"
                          margin="normal"
                          name="name"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.name}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          error={Boolean(touched.points && errors.points)}
                          fullWidth
                          required
                          helperText={touched.points && errors.points}
                          label="Guardian Points"
                          margin="normal"
                          name="points"
                          onBlur={handleBlur}
                          onChange={handleChange}
                          value={values.points}
                          color="secondary"
                          variant="outlined"
                        />
                      </Grid>
                      {values.myFeaturedImage &&
                        values.myFeaturedImage.fileName &&
                        values.myFeaturedImage.url && (
                          <Grid item xs={12}>
                            <img
                              src={`${
                                values.myFeaturedImage.url
                              }${getResizedFileName(
                                values.myFeaturedImage.fileName,
                                "medium"
                              )}`}
                              alt={values.name}
                              className={classes.preview}
                            />
                          </Grid>
                        )}
                      <Grid item xs={12}>
                        <MediaSelectModal
                          open={featuredOpen}
                          setOpen={setFeaturedOpen}
                          existingImage={values.myFeaturedImage}
                          setFieldValue={setFieldValue}
                          linkedField="myFeaturedImage"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button type="submit" color="primary" variant="contained">
                      Save
                    </Button>
                  </CardActions>
                </form>
              )}
            </Formik>
          </Card>
        </Box>
      </Modal>
      <ProgressModal open={progressOpen} />
    </>
  );
}
