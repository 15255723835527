import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import {
  Button,
  Breadcrumbs,
  Container,
  LinearProgress,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";

import HomeIcon from "@mui/icons-material/Home";

import makeStyles from "@mui/styles/makeStyles";
import DeckItem from "./DeckItem";

import { API, Auth } from "aws-amplify";
import { customAPIHeader } from "../../components/utils/UtilityFunctions";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },
  tableCell: { padding: theme.spacing(1), minHeight: "84px" },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  preview: {
    maxHeight: "60px",
    maxWidth: "150px",
  },
  previewDelete: {
    paddingTop: theme.spacing(1),
    maxHeight: "300px",
    maxWidth: "100%",
  },
}));

export default function DeckList() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  if (!userState.access.isAdmin && !userState.access.isEditor) {
    navigate("/access-denied");
  }

  const [mainState, setMainState] = useState({
    loading: true,
    allowLoadMore: false,
    lastEvaluatedKey: null,
    allRows: [],
    dialogOpen: false,
    dialogProgress: false,
    deleteItem: null,
  });

  const fetchRows = async () => {
    setMainState({
      ...mainState,
      loading: true,
      allowLoadMore: false,
    });
    var allItems = mainState.allRows;
    const queryStringParameters = {
      pageSize: 20,
      inclUsers: true,
      inclUnlockCode: true,
    };
    if (mainState.lastEvaluatedKey) {
      queryStringParameters.exclusiveStartKey = JSON.stringify(
        mainState.lastEvaluatedKey
      );
    }
    const apiGetData = await API.get("sfwApi", "/deck", {
      queryStringParameters: queryStringParameters,
      headers: await customAPIHeader(),
    });

    if (apiGetData && apiGetData.Items) {
      allItems = [...allItems, ...apiGetData.Items];
    }
    if (apiGetData && apiGetData.LastEvaluatedKey) {
      setMainState({
        ...mainState,
        lastEvaluatedKey: apiGetData.LastEvaluatedKey,
        allowLoadMore: true,
        loading: false,
        allRows: allItems,
      });
    } else {
      setMainState({
        ...mainState,
        loading: false,
        allRows: allItems,
      });
    }
  };

  function updateDeckItems(item, push) {
    var allItems = mainState.allRows;
    if (push) {
      allItems.push(item);
    } else {
      allItems.unshift(item);
    }
    setMainState({
      ...mainState,
      allRows: allItems,
    });
  }

  useEffect(() => {
    const check = async () => {
      fetchRows();
    };
    check();
  }, []);

  const headerRows = [
    { label: "Name" },
    { label: "Faction" },
    { label: "Username" },
    { label: "Unlock Code" },
    { label: "Link" },
  ];

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/app/admin">
          <HomeIcon color="inherit" />
        </Link>
        <Typography color="textPrimary">Deck Library</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>
        Deck Library{" "}
        <DeckItem
          updateItems={updateDeckItems}
          color="primary"
          variant="outlined"
        />
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerRows.map((row, index) => {
                return (
                  <TableCell className={classes.tableCell}>
                    {row.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {mainState.allRows.map((row, index) => {
              // rows get deleted
              if (row && row.id) {
                return (
                  <TableRow key={row.id} hover role="checkbox" tabIndex={-1}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row"
                    >
                      {row.name}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.faction}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.myUser && row.myUser.username}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {row.unlockCode}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Link
                        href={`/decks/${row.id}`}
                        target="_blank"
                        color="inherit"
                      >
                        {`/decks/${row.id}`}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              } else {
                return null;
              }
            })}
          </TableBody>
          <TableFooter>
            {mainState.loading && (
              <TableRow sx={{ height: 84 }}>
                <TableCell colSpan={6}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            <TableRow sx={{ height: 84 }}>
              <TableCell sx={{ textAlign: "center" }} colSpan={6}>
                <Button
                  onClick={fetchRows}
                  type="button"
                  color="primary"
                  variant="outlined"
                  disabled={!mainState.allowLoadMore}
                >
                  Load More
                </Button>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
}
