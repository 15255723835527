import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../context/UserContext";

import {
  Box,
  Button,
  Breadcrumbs,
  CardMedia,
  Checkbox,
  Chip,
  Container,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { 
	getResizedFileName,
	customAPIHeader,
} from "../../components/utils/UtilityFunctions";
import HomeIcon from "@mui/icons-material/Home";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import BlockIcon from "@mui/icons-material/Block";
import { green, red } from "@mui/material/colors";

import { useParams, useNavigate, useLocation } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";

import * as Yup from "yup";
import { Formik } from "formik";
import { postNotification } from "../../components/utils/Notifications";

import ProgressModal from "../../components/modals/ProgressModal";

import makeStyles from "@mui/styles/makeStyles";
import { API, Auth } from "aws-amplify";

import MediaSelectModal from "../../components/modals/MediaSelectModal";

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(2),
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
}));

export default function EditPost() {
  const classes = useStyles();
  const userState = useContext(UserContext);
  const navigate = useNavigate();
  if (!userState.access.isAdmin && !userState.access.isEditor) {
    navigate("/access-denied");
  }

  const location = useLocation();
  let { postId } = useParams();

  const [open, setOpen] = useState(true);
  const [progress, setProgress] = useState(null);

  const initialValues = {
    id: null,
    slug: "",
    title: "",
    author: "",
    summary: "",
    body: "",
    backgroundColor: "#FFFFFF",
    textColor: "#000000",
    published: false,
    myFeaturedImage: null,
    categoryList: "",
    myCategories: {},
    publish: false,
    unpublish: false,
    appsToPublish: {website: true, desktopApp: false}  
  };
  const [post, setPost] = useState(initialValues);
  const [body, setBody] = useState(initialValues.body);
  const [authors, setAuthors] = useState({});

  const [featuredOpen, setFeaturedOpen] = useState(false);
  const [slugInfo, setSlugInfo] = useState({
    searching: false,
    manual: false,
    success: false,
    error: false,
  });

  const [appsToPublish, setAppsToPublish] = useState(initialValues.appsToPublish)
  const { website, desktopApp } = appsToPublish;

  const validationSchema = {
    slug: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
    title: Yup.string()
      .min(3, "Too Short")
      .max(255, "Too Long")
      .required("Required"),
    summary: Yup.string().min(3, "Too Short").required("Required"),
    backgroundColor: Yup.string()
      .required("Required")
      .matches(
        /^#[0-9A-F]{6}$/,
        "Must be a color hex beginning with #, eg. #020202"
      ),
    textColor: Yup.string()
      .required("Required")
      .matches(
        /^#[0-9A-F]{6}$/,
        "Must be a color hex beginning with #, eg. #020202"
      ),
    //date: Yup.string().max(255).required("Required"),
    author: Yup.string().max(255).required("Required"),
  };

  const [nextToken, setNextToken] = useState(null);

  async function getEditors(limit) {
    let apiName = "AdminQueries";
    let path = "/listUsersInGroup";
    let myInit = {
      queryStringParameters: {
        groupname: "Editors",
        limit: limit,
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    setNextToken(NextToken);
    return rest;
  }

  async function checkSlug(slug, id) {
    const apiGetData = await API.get("sfwApi", "/post", {
      queryStringParameters: {
        slug: slug,
        postType: "Post",
      },
    });
    return apiGetData.error || (id && apiGetData.id && id === apiGetData.id);
  }

  function handleDeleteCategory(category, values, setFieldValue) {
    var myCategories = {};
    var catList = "";
    Object.keys(values.myCategories).forEach((catKey) => {
      if (catKey !== category) {
        myCategories[catKey] = values.myCategories[catKey];
        if (catList !== "") {
          catList += ",";
        }
        catList += values.myCategories[catKey];
      }
    });
    setFieldValue("categoryList", catList);
    setFieldValue("myCategories", myCategories);
  }

  useEffect(() => {
    const check = async () => {
      if (postId !== undefined) {
        const apiGetData = await API.get("sfwApi", "/post/" + postId, {
          queryStringParameters: {
            postType: "Post",
            inclBody: true,
            inclAuthor: true,
            inclFeaturedImage: true,
            inclCategories: true,
            inclAppsToPublish: true
          },
        });

        if (apiGetData.myAuthor) {
          apiGetData.author = apiGetData.myAuthor.username;
          delete apiGetData.myAuthor;
        }
        if (apiGetData.body) {
          setBody(apiGetData.body);
        }
        if (apiGetData.myCategories) {
          var catList = "";
          Object.keys(apiGetData.myCategories).forEach((catKey) => {
            if (catList !== "") {
              catList += ",";
            }
            catList += apiGetData.myCategories[catKey];
          });
          apiGetData.categoryList = catList;
        }
        if(apiGetData.appsToPublish){
          setAppsToPublish(apiGetData.appsToPublish)
        }
        setPost({ ...post, ...apiGetData });
        setSlugInfo({
          ...slugInfo,
          manual: true,
        });
      }

      var currentUsername =
        userState.user.attributes.preferred_username || userState.user.username;
      var userDict = {
        [currentUsername]: (
          (userState.user.attributes.given_name || "") +
          " " +
          (userState.user.attributes.family_name || "")
        ).trim(),
      };
      var users = await getEditors();
      if (users.Users) {
        users.Users.forEach((user) => {
          user.Attributes.forEach((attribute) => {
            user[attribute.Name] = attribute.Value;
          });
          delete user.Attributes;

          var username = user.preferred_username || user.Username;
          userDict[username] = (
            (user.given_name || "") +
            " " +
            (user.family_name || "")
          ).trim();
        });
      }
      setAuthors(userDict);

      setOpen(false);
      setProgress(0);
    };

    check();
  }, [postId]);

  return (
    <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <Link color="inherit" href="/app/admin">
          <HomeIcon color="inherit" />
        </Link>
        <Link color="inherit" href="/app/admin/posts/">
          Posts
        </Link>
        <Typography color="textPrimary">Post</Typography>
      </Breadcrumbs>
      <ProgressModal progress={progress} open={open} />
      <Formik
        initialValues={post}
        enableReinitialize={true}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={async (values, { setSubmitting, setFieldValue }) => {
          setOpen(true);
          try {
            const slugOk = await checkSlug(values.slug, values.id);

            if (slugOk) {
              // an error means slug not in use
              var postBody = {
                postType: "Post",
                title: values.title,
                slug: values.slug,
                summary: values.summary,
                body: values.body,
                backgroundColor: values.backgroundColor,
                textColor: values.textColor,
                myCategories: values.myCategories,
                author:
                  values.author !== ""
                    ? values.author
                    : userState.user.attributes.preferred_username ||
                      userState.user.username,
                appsToPublish: appsToPublish
              };
              if (values.myFeaturedImage && values.myFeaturedImage.id) {
                postBody.featuredImage = values.myFeaturedImage.id;
              }
              if (values.publish) {
                postBody.publish = true;
              } else if (values.unpublish) {
                postBody.unpublish = true;
              }
              var vPostId = values.id;

              var apiPostData = await API.post(
                "sfwApi",
                `/post${vPostId ? "/" + vPostId : ""}`,
                {
                  body: postBody,
                  headers: await customAPIHeader(),
                }
              );

              if (apiPostData.error) {
                postNotification("error", apiPostData.error);
              } else {
                if (apiPostData.data) {
                  if (!postId && apiPostData.data.id) {
                    setFieldValue("id", apiPostData.data.id);
                    navigate(`${location.pathname}/${apiPostData.data.id}`, {
                      replace: false,
                    });
                  }
                  if (values.published !== apiPostData.data.published) {
                    setFieldValue("published", apiPostData.data.published);
                    setFieldValue("publish", false);
                    setFieldValue("unpublish", false);
                  }
                }

                postNotification(
                  "success",
                  `Post ${postId ? "updated" : "created"}`
                );
              }
            } else {
              postNotification("error", "Post Slug is already in use!");
            }

            setSubmitting(false);
            setOpen(false);
            setProgress(null);
          } catch (error) {
            postNotification("error", error.message || JSON.stringify(error));
            setSubmitting(false);
            setOpen(false);
            setProgress(null);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          submitForm,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <MDEditor
                  height={500}
                  preview="edit"
                  value={body}
                  onChange={(val) => {
                    setBody(val);
                    setFieldValue("body", val);
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      variant="contained"
                      fullWidth
                    >
                      {postId ? "Update" : "Save"}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={isSubmitting}
                      type="button"
                      color="secondary"
                      variant="contained"
                      fullWidth
                      onClick={() => {
                        if (values.published) {
                          setFieldValue("unpublish", true);
                          setFieldValue("publish", false);
                        } else {
                          setFieldValue("unpublish", false);
                          setFieldValue("publish", true);
                        }
                        submitForm();
                      }}
                    >
                      {values.published ? "Unpublish" : "Publish"}
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.title && errors.title)}
                      fullWidth
                      helperText={touched.title && errors.title}
                      label="Title"
                      margin="normal"
                      name="title"
                      onBlur={async (e) => {
                        handleBlur(e);
                        if (!slugInfo.manual) {
                          setFieldValue(
                            "slug",
                            e.target.value.toLowerCase().split(" ").join("-")
                          );

                          setSlugInfo({
                            ...slugInfo,
                            searching: true,
                            success: false,
                            error: false,
                            manual: true,
                          });
                          const slugOk = await checkSlug(
                            e.target.value.toLowerCase().split(" ").join("-"),
                            values.id
                          );
                          if (!slugOk) {
                            postNotification(
                              "error",
                              "Post Slug is already in use!"
                            );
                            setSlugInfo({
                              ...slugInfo,
                              searching: false,
                              success: false,
                              error: true,
                            });
                          } else {
                            setSlugInfo({
                              ...slugInfo,
                              searching: false,
                              success: true,
                              error: false,
                              manual: true,
                            });
                          }
                        }
                      }}
                      onChange={(e) => {
                        handleChange(e);
                        if (!slugInfo.manual) {
                          setFieldValue(
                            "slug",
                            e.target.value.toLowerCase().split(" ").join("-")
                          );
                        }
                      }}
                      value={values.title}
                      color="secondary"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.slug && errors.slug)}
                      fullWidth
                      helperText={touched.slug && errors.slug}
                      label="Post Slug"
                      margin="normal"
                      name="slug"
                      onBlur={async (e) => {
                        e.target.value = e.target.value.toLowerCase();
                        handleBlur(e);

                        if (e.target.value?.length < 3) {
                          setSlugInfo({
                            ...slugInfo,
                            searching: false,
                            success: false,
                            error: true,
                          });
                          return;
                        }

                        setSlugInfo({
                          ...slugInfo,
                          searching: true,
                          success: false,
                          error: false,
                        });

                        const slugOk = await checkSlug(
                          e.target.value,
                          values.id
                        );
                        if (!slugOk) {
                          postNotification(
                            "error",
                            "Post Slug is already in use!"
                          );
                          setSlugInfo({
                            ...slugInfo,
                            searching: false,
                            success: false,
                            error: true,
                          });
                        } else {
                          setSlugInfo({
                            ...slugInfo,
                            searching: false,
                            success: true,
                            error: false,
                          });
                        }
                      }}
                      onChange={(e) => {
                        e.target.value = e.target.value.toLowerCase();
                        handleChange(e);
                        if (!slugInfo.manual) {
                          setSlugInfo({
                            ...slugInfo,
                            manual: true,
                          });
                        }
                      }}
                      value={values.slug}
                      color="secondary"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {!!slugInfo.searching && (
                              <CircularProgress size={20} />
                            )}
                            {!!slugInfo.success && (
                              <CheckCircleOutlineIcon
                                sx={{ color: green[500] }}
                              />
                            )}
                            {!!slugInfo.error && (
                              <BlockIcon sx={{ color: red[500] }} />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography variant="caption" sx={{ ml: 2 }}>
                      <Link
                        href={`post/${values.slug}`}
                        target="_blank"
                        color="inherit"
                      >{`/${values.slug}`}</Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <InputLabel id="author-label">Author</InputLabel>
                      <Select
                        error={Boolean(touched.author && errors.author)}
                        fullWidth
                        helperText={touched.author && errors.author}
                        labelId="author-label"
                        id="author"
                        name="author"
                        value={values.author}
                        onChange={handleChange}
                        label="Author"
                        color="secondary"
                        variant="outlined"
                      >
                        {Object.keys(authors).map((authorKey, index) => {
                          return (
                            <MenuItem value={authorKey}>
                              {authors[authorKey]}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.categoryList && errors.categoryList
                      )}
                      fullWidth
                      helperText={touched.categoryList && errors.categoryList}
                      label="Categories"
                      margin="normal"
                      name="categoryList"
                      onBlur={async (e) => {
                        var myCategories = {};
                        var addCommaBack =
                          e.target.value.length > 0 &&
                          e.target.value.charAt(e.target.value.length - 1);
                        var cats = e.target.value.split(",");
                        var catList = "";
                        cats.forEach((cat) => {
                          if (cat !== "") {
                            myCategories[
                              cat.trim().toLowerCase().split(" ").join("-")
                            ] = cat;
                            if (catList !== "") {
                              catList += ",";
                            }
                            catList += cat;
                          }
                        });
                        if (addCommaBack) {
                          catList += ",";
                        }
                        e.target.value = catList;
                        handleBlur(e);
                        setFieldValue("myCategories", myCategories);
                      }}
                      onChange={(e) => {
                        var myCategories = {};
                        var addCommaBack =
                          e.target.value.length > 0 &&
                          e.target.value.charAt(e.target.value.length - 1) ===
                            ",";
                        var cats = e.target.value.split(",");
                        var catList = "";
                        cats.forEach((cat) => {
                          if (cat !== "") {
                            myCategories[
                              cat.trim().toLowerCase().split(" ").join("-")
                            ] = cat;
                            if (catList !== "") {
                              catList += ",";
                            }
                            catList += cat;
                          }
                        });
                        if (addCommaBack) {
                          catList += ",";
                        }
                        e.target.value = catList;
                        handleChange(e);
                        setFieldValue("myCategories", myCategories);
                      }}
                      value={values.categoryList}
                      color="secondary"
                      variant="outlined"
                    />
                    {Object.keys(values.myCategories).map((cat, index) => {
                      return (
                        <Chip
                          label={cat}
                          onDelete={(e) => {
                            handleDeleteCategory(cat, values, setFieldValue);
                          }}
                          color="secondary"
                          variant="outlined"
                        />
                      );
                    })}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.summary && errors.summary)}
                      fullWidth
                      helperText={touched.summary && errors.summary}
                      label="Summary"
                      margin="normal"
                      name="summary"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.summary}
                      color="secondary"
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(touched.textColor && errors.textColor)}
                      fullWidth
                      helperText={touched.textColor && errors.textColor}
                      label="Text Color"
                      margin="normal"
                      name="textColor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.textColor}
                      color="secondary"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.backgroundColor && errors.backgroundColor
                      )}
                      fullWidth
                      helperText={
                        touched.backgroundColor && errors.backgroundColor
                      }
                      label="Page Background"
                      margin="normal"
                      name="backgroundColor"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.backgroundColor}
                      color="secondary"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography gutterBottom>Featured Image</Typography>
                    <CardMedia
                      className={classes.cardMedia}
                      image={
                        values.myFeaturedImage && values.myFeaturedImage.tempUrl
                          ? values.myFeaturedImage.tempUrl
                          : values.myFeaturedImage &&
                            values.myFeaturedImage.fileName &&
                            values.myFeaturedImage.url
                          ? `${values.myFeaturedImage.url}${getResizedFileName(
                              values.myFeaturedImage.fileName,
                              "medium"
                            )}`
                          : "/images/logo.png"
                      }
                      title={
                        (values.myFeaturedImage &&
                          values.myFeaturedImage.name) ||
                        "SolForge Fusion"
                      }
                    />
                    <MediaSelectModal
                      open={featuredOpen}
                      setOpen={setFeaturedOpen}
                      existingImage={values.myFeaturedImage}
                      setFieldValue={setFieldValue}
                      linkedField="myFeaturedImage"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormLabel id="app-use-radio-buttons-group-label">Where should post show?</FormLabel>
                      <FormGroup
                        aria-labelledby="app-use-checkbox-group-label"
                        name="checkbox-group"
                        onChange={(e) => {
                          setAppsToPublish({
                            ...appsToPublish,
                            [e.target.name]: Boolean(e.target.checked)
                          });
                          console.log(appsToPublish)
                        }}
                      >
                        <FormControlLabel control={<Checkbox checked={website} onChange={handleChange} name="website" />} label="Website" />
                        <FormControlLabel control={<Checkbox checked={desktopApp} onChange={handleChange} name="desktopApp" />} label="Desktop App" />
                      </FormGroup>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box sx={{ padding: "50px 0 0 0" }} />
          </form>
        )}
      </Formik>
    </Container>
  );
}
