import React from "react";

import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import {
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  Twitter as TwitterIcon,
  YouTube as YouTubeIcon,
} from "@mui/icons-material/";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitch } from "@fortawesome/free-brands-svg-icons";
import TrophyIcon from "../../components/icons/TrophyIcon";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
  avatarWrapper: {
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
      display: "flex",
      flexFirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  avatarLarge: {
    width: "250px",
    height: "250px",
    [theme.breakpoints.down("lg")]: { width: "225px", height: "225px" },
    [theme.breakpoints.down("md")]: { width: "200px", height: "200px" },
  },
  profileContentContainer: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: { display: "block" },
  },
  profileContentWrapper: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      flexFirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  profileSocialsWrapper: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      display: "flex",
      flexFirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  profileSocialButton: {
    paddingLeft: "0px",
    //padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1)}`,
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: "unset",
    },
  },
  divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableCellTH: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    fontSize: 14,
    paddingRight: "30px",
  },
  tableCellTD: {
    fontSize: 14,
    paddingRight: "30px",
  },
}));

export default function Profile() {
  const classes = useStyles();

  function createData(icon, name, score) {
    return { icon, name, score };
  }

  const profile = {
    name: "Joshua Diffey",
    handle: "difsta",
    avatar: "/images/avatars/avatar1.png",
    socials: [
      {
        label: "Facebook",
        icon: <FacebookIcon />,
        url: "https://www.facebook.com/StoneBladeEnt/",
      },
      {
        label: "Twitter",
        icon: <TwitterIcon />,
        url: "https://twitter.com/SolforgeFusion",
      },
      {
        label: "Instagram",
        icon: <InstagramIcon />,
        url: "https://www.instagram.com/stone_blade/",
      },
      {
        label: "YouTube",
        icon: <YouTubeIcon />,
        url: "https://www.youtube.com/channel/UC5bMVEUdJyNboIbTIdy2iuQ",
      },
      {
        label: "Twitch",
        icon: <FontAwesomeIcon className="MuiSvgIcon-root" icon={faTwitch} />,
        url: "https://www.twitch.tv/stoneblade",
      },
      {
        label: "Discord",
        icon: <FontAwesomeIcon className="MuiSvgIcon-root" icon={faDiscord} />,
        url: "https://discord.com",
      },
    ],
    achievements: [
      createData(
        <TrophyIcon star="#fbe5a9" />,
        "Win 1 Quick Match Game",
        "1/1"
      ),
      createData(
        <TrophyIcon star="#fdcc48" />,
        "Win 10 Quick Match Games",
        "10/10"
      ),
      createData(
        <TrophyIcon star="#ffffff" trophy="#adb5bd" />,
        "Win 100 Quick Match Games",
        "55/100"
      ),
      createData(<TrophyIcon star="#f7a9aa" />, "Play 20 Creatures", "20/20"),
      createData(
        <TrophyIcon star="#8b544f" />,
        "Play 200 Creatures",
        "200/200"
      ),
      createData(
        <TrophyIcon star="#ffffff" trophy="#adb5bd" />,
        "Play 1000 Creatures",
        "624/1000"
      ),
      createData(<TrophyIcon star="#8b544f" />, "Play 20 Spells", "20/20"),
      createData(<TrophyIcon star="#8b544f" />, "Play 200 Spells", "200/200"),
      createData(
        <TrophyIcon star="#ffffff" trophy="#adb5bd" />,
        "Play 1000 Spells",
        "624/1000"
      ),
    ],
  };

  return (
    <>
      <Container sx={{ pt: 2, pb: 2 }} maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4} md={3}>
            <Box className={classes.avatarWrapper}>
              <Avatar
                alt={profile.name}
                src={profile.avatar}
                className={classes.avatarLarge}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={9}
            className={classes.profileContentContainer}
          >
            <Box className={classes.profileContentWrapper}>
              <Typography gutterBottom variant="h4" component="h2">
                {profile.name}
              </Typography>
              <Typography gutterBottom variant="h5" component="h2">
                "{profile.handle}"
              </Typography>
              <Grid
                container
                spacing={1}
                className={classes.profileSocialsWrapper}
              >
                {profile.socials.map((socialLink) => (
                  <Grid item>
                    <IconButton
                      edge="end"
                      aria-label={socialLink.label}
                      href={socialLink.url}
                      color="inherit"
                      target="_blank"
                      className={classes.profileSocialButton}
                      size="large"
                    >
                      {socialLink.icon}
                    </IconButton>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellTH} align="center">
                  <TrophyIcon star="#000000" />
                </TableCell>
                <TableCell className={classes.tableCellTH}>
                  Achievement
                </TableCell>
                <TableCell className={classes.tableCellTH} align="right">
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {profile.achievements.map((row) => (
                <TableRow key={row.name} className={classes.tableRow}>
                  <TableCell className={classes.tableCellTD} align="center">
                    {row.icon}
                  </TableCell>
                  <TableCell className={classes.tableCellTD} scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.tableCellTD} align="right">
                    {row.score}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </>
  );
}
