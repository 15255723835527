import React, { useState } from "react";

import { Box, CircularProgress, Container, Modal } from "@mui/material";

import { postNotification } from "../../components/utils/Notifications";

import LoginForm from "../../components/user/LoginForm";
import SignUpForm from "../../components/user/SignUpForm";
import ForgotForm from "../../components/user/ForgotForm";
import VerifyForm from "../../components/user/VerifyForm";

import makeStyles from "@mui/styles/makeStyles";
import { findGetParameter } from "../../components/utils/UtilityFunctions";
import LoadingModal from "../../components/modals/LoadingModal";
const useStyles = makeStyles((theme) => ({
  progressModalPaper: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
  },
}));

const authSteps = {
  login: 0,
  signup: 1,
  forgot: 2,
  verify: 3,
};
export default function Login() {
  const classes = useStyles();

  var initialAuthStep = findGetParameter("step");
  var redirect = findGetParameter("redirect");
  var claim = findGetParameter("claim");

  const [authStep, setAuthStep] = useState(
    initialAuthStep && authSteps[initialAuthStep] !== undefined
      ? authSteps[initialAuthStep]
      : 0
  );
  const [processing, setProcessing] = useState(false);
  const [sharedInfo, setSharedInfo] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleModalClose = () => {
    setProcessing(false);
  };

  return (
    <>
      <Container maxWidth="lg" sx={{ pt: 2, pb: 2 }}>
        {authStep === 0 && (
          <LoginForm
            setAuthStep={setAuthStep}
            classes={classes}
            postNotification={postNotification}
            sharedInfo={sharedInfo}
            setSharedInfo={setSharedInfo}
            processing={processing}
            setProcessing={setProcessing}
            redirect={redirect}
            claim={claim}
          />
        )}
        {authStep === 1 && (
          <SignUpForm
            setAuthStep={setAuthStep}
            classes={classes}
            postNotification={postNotification}
            sharedInfo={sharedInfo}
            setSharedInfo={setSharedInfo}
            processing={processing}
            setProcessing={setProcessing}
            formFields={[
              {
                type: "preferred_username",
                label: "Username",
                placeholder: "Username",
                inputProps: {
                  required: true,
                  autocomplete: "preferred_username",
                },
              },
              {
                type: "given_name",
                label: "First Name",
                placeholder: "First Name",
                inputProps: { required: true, autocomplete: "given_name" },
              },
              {
                type: "family_name",
                label: "Last Name",
                placeholder: "Last Name",
                inputProps: { required: true, autocomplete: "family_name" },
              },
              {
                type: "nickname",
                label: "Nickname / Handle",
                placeholder: "Nickname / Handle",
                inputProps: {},
              },

              {
                type: "email",
                label: "Email",
                placeholder: "Email",
                inputProps: {
                  required: true,
                  type: "email",
                  autocomplete: "username",
                },
              },
              {
                type: "password",
                label: "Password",
                placeholder: "Password",
                inputProps: {
                  required: true,
                  min: 8,
                  autocomplete: "new-password",
                },
              },
              {
                type: "custom:allow_marketing",
                label:
                  "I  want to receive inspiration, marketing promotions and updates via email.",
                placeholder: "",
                inputProps: {
                  type: "checkbox",
                  default: false,
                },
              },
              {
                type: "policy",
                label: "I have read the Terms and Conditions",
                placeholder: "",
                inputProps: {
                  type: "checkbox",
                  required: false,
                },
              },
            ]}
          />
        )}
        {authStep === 2 && (
          <ForgotForm
            setAuthStep={setAuthStep}
            classes={classes}
            postNotification={postNotification}
            sharedInfo={sharedInfo}
            setSharedInfo={setSharedInfo}
            processing={processing}
            setProcessing={setProcessing}
            redirect={redirect}
          />
        )}
        {authStep === 3 && (
          <VerifyForm
            setAuthStep={setAuthStep}
            classes={classes}
            postNotification={postNotification}
            sharedInfo={sharedInfo}
            setSharedInfo={setSharedInfo}
            processing={processing}
            setProcessing={setProcessing}
            redirect={redirect}
          />
        )}
      </Container>
      {processing && <LoadingModal />}
    </>
  );
}
