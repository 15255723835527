import { useState } from "react";
import ReactMarkdown from "markdown-to-jsx";
import uniqid from "uniqid";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Grid,
  Link,
  Tabs,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tab,
  Typography,
  Alert,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";

import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  ul: {
    marginLeft: theme.spacing(2),
    listStyle: "disc",
  },
  ol: {
    marginLeft: theme.spacing(2),
  },
}));

function a11yProps(id, index) {
  return {
    id: `tab-${id}-${index}`,
    "aria-controls": `tabpanel-${id}-${index}`,
  };
}

const CustomAccordion = (props) => {
  const {
    children,
    summary,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = props;
  const id = uniqid();

  return (
    <Accordion
      sx={{
        mb: "8px !important",
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#ffffff" }} />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
        sx={{
          backgroundColor: "#363636",
          color: "#ffffff",
          borderRadius: "4px",
        }}
      >
        <Typography>{summary}</Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

const CustomAlert = (props) => {
  const {
    children,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    ...rest
  } = props;
  return (
    <Alert
      icon={<HelpIcon fontSize="inherit" sx={{ color: "#ffffff" }} />}
      {...rest}
      sx={{
        mt: 2,
        mb: 2,
        backgroundColor: "#A55645",
        color: "#ffffff",
        "& a": {
          color: "inherit",
          textDecoration: "underline",
        },
        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}
    >
      {children}
    </Alert>
  );
};

const CustomPreview = (props) => {
  const {
    children,
    borderColor,
    fontColor,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = props;
  return (
    <Box
      sx={{
        mt: 2,
        pl: paddingLeft !== undefined ? paddingLeft : 2,
        mb: 2,
        borderLeftWidth: "10px",
        borderLeftStyle: "solid",
        borderLeftColor: borderColor || "#DA6225",
        paddingTop,
        paddingBottom,
        paddingRight,
      }}
    >
      <Typography
        sx={{
          fontFamily: "'Prometo'",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: ["18px", "18px", "24px"],
          lineHeight: ["24px", "24px", "38px"],
          color: fontColor || "#7E3A17",
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

const CustomYouTube = (props) => {
  const { embedUrl, paddingTop, paddingLeft, paddingRight } = props;
  return (
    <Box
      sx={{
        mb: 2,
        overflow: "hidden",
        paddingBottom: "56.25%",
        position: "relative",
        height: "0",
        "& iframe": {
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          position: "absolute",
        },
        paddingTop,
        paddingLeft,
        paddingRight,
      }}
    >
      <iframe
        width="853"
        height="480"
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </Box>
  );
};

const CustomTabs = (props) => {
  const {
    children,
    defaultTab,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    ...rest
  } = props;
  const tabsId = uniqid();
  const [value, setValue] = useState(
    defaultTab !== undefined ? parseInt(defaultTab) : 0
  );
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        mb: 2,
        borderBottom: 1,
        borderColor: "divider",

        "& .Mui-selected": {
          borderColor: "primary.main",
          borderBottom: 4,
          borderStyle: "solid",
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },

        paddingTop,
        paddingBottom,
        paddingLeft,
        paddingRight,
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        orientation={smallScreen ? "vertical" : null}
        {...rest}
      >
        {children?.length &&
          children.map((child, index) => {
            const { href, ...rest } = child.props;
            if (href) {
              return (
                <CustomTab
                  key={index}
                  index={index}
                  tabsId={tabsId}
                  component={Link}
                  href={href}
                  {...rest}
                />
              );
            }
            return (
              <CustomTab key={index} index={index} tabsId={tabsId} {...rest} />
            );
          })}
      </Tabs>
    </Box>
  );
};

const CustomTab = (props) => {
  const { tabsId, index, ...rest } = props;
  return (
    <Tab {...rest} sx={{ color: "inherit" }} {...a11yProps(tabsId, index)} />
  );
};

const CustomButton = (props) => {
  const {
    children,
    center,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    sx,
    ...rest
  } = props;

  const TheButton = () => {
    return (
      <Button
        color="primary"
        variant="contained"
        sx={{ ...sx, paddingTop, paddingBottom, paddingLeft, paddingRight }}
        {...rest}
      >
        {children}
      </Button>
    );
  };

  if (center) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <TheButton />
      </Box>
    );
  }
  return <TheButton />;
};

const CustomStoryBlock = (props) => {
  const {
    children,
    align,
    imageUrl,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
  } = props;

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const wrapperSX = {
    // backgroundColor: "#000000",
    // color: "#ffffff",
    // ml: "calc((100% - 100vw)/2)",
    // mr: "calc((100% - 100vw)/2)",
    // width: "100vw",
  };

  const TextBlock = () => {
    return (
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          p: 4,
          display: "flex",
          alignItems: "center",
          paddingTop,
          paddingBottom,
          paddingLeft,
          paddingRight,
        }}
      >
        {children}
      </Grid>
    );
  };
  const ImageBlock = () => {
    return (
      <Grid item xs={12} md={6}>
        <img src={imageUrl} style={{ width: "100%", maxWidth: "100%" }} />
      </Grid>
    );
  };

  if (smallScreen || align === "right") {
    return (
      <Grid container sx={wrapperSX}>
        <ImageBlock />
        <TextBlock />
      </Grid>
    );
  } else {
    return (
      <Grid container sx={wrapperSX}>
        <TextBlock />
        <ImageBlock />
      </Grid>
    );
  }
};

const CustomTable = (props) => {
  const { children, ...rest } = props;

  return (
    <TableContainer component={Paper} sx={{ mt: 1, mb: 2 }}>
      <Table>{children}</Table>
    </TableContainer>
  );
};

const CustomImage = ({ children, ...props }) => {
  return (
    <Box sx={{ textAlign: "center" }}>
      <img {...props} style={{ maxWidth: "100%" }} />
    </Box>
  );
};

export default function Markdown({ source }) {
  const classes = useStyles();

  const options = {
    overrides: {
      Accordion: {
        component: CustomAccordion,
      },
      Button: {
        component: CustomButton,
      },
      TableContainer: { component: CustomTable },
      // Table: { component: Table },
      TableHead: { component: TableHead },
      TableBody: { component: TableBody },
      TableRow: { component: TableRow },
      TableCell: { component: TableCell },
      Tabs: {
        component: CustomTabs,
      },
      Tab: {
        component: CustomTab,
      },
      StoryBlock: {
        component: CustomStoryBlock,
      },
      YouTube: {
        component: CustomYouTube,
      },
      Alert: {
        component: CustomAlert,
      },
      Preview: {
        component: CustomPreview,
      },

      h1: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: "h5",
          textAlign: "center",
          mt: "60px",
        },
      },
      h2: {
        component: Typography,
        props: { gutterBottom: true, variant: "h6", mt: "20px" },
      },
      h3: {
        component: Typography,
        props: { gutterBottom: true, variant: "subtitle1", mt: "10px" },
      },
      h4: {
        component: Typography,
        props: { gutterBottom: true, variant: "caption", paragraph: true },
      },
      p: { component: Typography, props: { paragraph: true } },
      a: { component: Link },
      ul: { props: { className: classes.ul } },
      ol: { props: { className: classes.ol } },
      img: { component: CustomImage },
    },
  };

  return <ReactMarkdown options={options}>{source}</ReactMarkdown>;
}
