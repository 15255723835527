import {
  Box,
  Container,
  Link,
  Typography,
  Alert,
  useMediaQuery,
} from "@mui/material";

import HelpIcon from "@mui/icons-material/Help";

export default function ScanDeck(props) {
  const { label } = props;
  const mediaLT740 = useMediaQuery("(max-height:740px)");
  return (
    <Container
      sx={{
        pt: 2,
        pb: 2,
        maxHeight: "85vh",
        overflowY: mediaLT740 ? "scroll" : null,
      }}
      maxWidth="sm"
    >
      <Typography
        variant="h4"
        textAlign={label?.align || "center"}
        gutterBottom
      >
        {label?.text || "You don't have a deck registered yet"}
      </Typography>
      <Box sx={{ textAlign: "center" }}>
        <img
          alt="Scan Deck"
          src="/images/assets/scan-deck-cards.png"
          style={{ maxWidth: "100%", maxHeight: "250px" }}
        />
      </Box>
      <Typography sx={{ mb: 2 }}>
        Each deck comes with a unique QR code. To add a deck into your
        collection, scan the QR code using your mobile device.
      </Typography>
      <ol style={{ marginLeft: "16px" }}>
        <li style={{ paddingLeft: "16px", marginBottom: "8px" }}>
          Sign into your{" "}
          <span style={{ color: "#B53114" }}>solforgefusion.com</span> account
          on your mobile device
        </li>
        <li style={{ paddingLeft: "16px", marginBottom: "8px" }}>
          Open the camera app on your phone to scan the QR code
        </li>
        <li style={{ paddingLeft: "16px", marginBottom: "8px" }}>
          Your deck will be added to your collection automatically
        </li>
      </ol>
      <Alert
        icon={<HelpIcon fontSize="inherit" sx={{ color: "#ffffff" }} />}
        sx={{ mt: 2, mb: 2, backgroundColor: "#A55645", color: "#ffffff" }}
      >
        Having trouble? Get support on our{" "}
        <Link
          href="https://discord.gg/FQnAnrb2Sw"
          color="inherit"
          underline="always"
          target="_blank"
        >
          Discord Community
        </Link>
        .
      </Alert>
    </Container>
  );
}

export function ScanFusedDeck(props) {
  const { label } = props;
  const mediaLT740 = useMediaQuery("(max-height:740px)");
  return (
    <Container
      sx={{
        pt: 2,
        pb: 2,
        maxHeight: "85vh",
        overflowY: mediaLT740 ? "scroll" : null,
      }}
      maxWidth="sm"
    >
      <Typography
        variant="h4"
        textAlign={label?.align || "center"}
        gutterBottom
      >
        {label?.text || "You haven't fused a deck yet"}
      </Typography>
      <Box sx={{ textAlign: "center" }}>
        <img
          alt="Scan Deck"
          src="/images/assets/scan-deck-cards.png"
          style={{ maxWidth: "100%", maxHeight: "250px" }}
        />
      </Box>
      <Typography paragraph>
        In SolForge Fusion, each booster comes with algorithmically-generated
        half-decks uniquely printed just for you. Fuse two decks together to
        explore an endless array of strategies.
      </Typography>
      <Typography paragraph>
        You can register Fused Decks in your collection to reference
        combinations of half-decks that you like to play with regularly. This
        data is privately viewable from your account only.
      </Typography>
      <Typography gutterBottom>To get started:</Typography>
      <ol style={{ marginLeft: "16px" }}>
        <li style={{ paddingLeft: "16px", marginBottom: "8px" }}>
          Make sure you have registered at least 2 decks over at the{" "}
          <Link href="/decks">My Decks</Link> page
        </li>
        <li style={{ paddingLeft: "16px", marginBottom: "8px" }}>
          Then, click the <b>Fuse New Deck</b> button above to select 2 and
          combine 2 decks from your collection.
        </li>
        <li style={{ paddingLeft: "16px", marginBottom: "8px" }}>
          You can fuse any 2 decks together, and can even use the same deck for
          multiple different fused decks.
        </li>
      </ol>
      <Alert
        icon={<HelpIcon fontSize="inherit" sx={{ color: "#ffffff" }} />}
        sx={{ mt: 2, mb: 2, backgroundColor: "#A55645", color: "#ffffff" }}
      >
        Having trouble? Get support on our{" "}
        <Link
          href="https://discord.gg/FQnAnrb2Sw"
          color="inherit"
          underline="always"
          target="_blank"
        >
          Discord Community
        </Link>
        .
      </Alert>
    </Container>
  );
}
