import { alpha } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";

const standardFont = "'Prometo'";

export const themeJson = {
  typography: {
    fontFamily: standardFont,
    body1: {
      // fontWeight: 400,
      fontSize: 18,
      // fontFamily: standardFont,
    },
    h1: {
      fontWeight: 700,
      fontSize: 35,
      fontFamily: standardFont,
    },
    h2: {
      fontWeight: 700,
      fontSize: 29,
      fontFamily: standardFont,
    },
    h3: {
      fontWeight: 700,
      fontSize: 24,
      fontFamily: standardFont,
    },
    h4: {
      fontWeight: 700,
      fontSize: 22,
      fontFamily: standardFont,
    },
    h5: {
      fontWeight: 700,
      fontSize: 20,
      fontFamily: standardFont,
    },
    h6: {
      fontWeight: 700,
      fontSize: 14,
      fontFamily: standardFont,
    },
    overline: {
      fontWeight: 700,
    },
  },
  palette: {
    headingTextHighlight: {
      main: "#FFD64F",
    },
    factions: {
      nekrium: "#6253A7",
      uterra: "#24821E",
      alloyin: "#347EB0",
      tempys: "#DF6911",
    },

    gray: {
      contrastText: "#FFFFFF",
      main: "#5F5F5F",
      light: "#767676",
      dark: "#4F4F4F",
    },

    casual: {
      contrastText: "#FFFFFF",
      main: "#383838",
      light: "#383838",
      dark: "#383838",
    },
    unranked: {
      contrastText: "#FFFFFF",
      main: "#383838",
      light: "#383838",
      dark: "#383838",
    },
    bronze: {
      contrastText: "#F1E5B9",
      main: "#8A4E16",
      light: "#8A4E16",
      dark: "#8A4E16",
    },
    silver: {
      contrastText: "#5F736F",
      main: "#D7DFDE",
      light: "#D7DFDE",
      dark: "#D7DFDE",
    },
    gold: {
      contrastText: "#FFF6DE",
      main: "#CE9911",
      light: "#CE9911",
      dark: "#CE9911",
    },
    platinum: {
      contrastText: "#43828D",
      main: "#DEF4F8",
      light: "#DEF4F8",
      dark: "#DEF4F8",
    },

    primary: {
      contrastText: "#ffffff",
      main: "#b53114",
      light: "#fb7b5f",
      dark: "#a32c12",
    },
    secondary: {
      contrastText: "#ffffff",
      main: "#121212",
      light: "#242424",
      dark: "#000000",
    },
    blue: {
      contrastText: "#ffffff",
      main: "#00308f",
      light: "#242424",
      dark: "#000000",
    },
    orange: {
      contrastText: "#ffffff",
      main: "#ff6700",
      light: "#242424",
      dark: "#000000",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
      },
    },
  },
};

export const themeStyles = makeStyles(
  (theme) => ({
    // root
    root: { maxWidth: "100%", minWidth: "100%", overflowX: "hidden" },
    "@global": {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: "none",
      },
    },
    appMain: {
      minHeight: "calc(100vh - 390px)",
    },
    grow: {
      flexGrow: 1,
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionDesktopGrow: {
      [theme.breakpoints.up("md")]: {
        flexGrow: 1,
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    sectionMobileGrow: {
      [theme.breakpoints.down("md")]: {
        flexGrow: 1,
        textAlign: "center",
      },
    },

    // nav
    headerBar: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      zIndex: 2000,
      position: "relative",
      width: "100%",
    },
    headerToolbar: {
      flexWrap: "wrap",
    },
    headerTitle: {
      flexGrow: 1,
    },
    headerMenu: {
      marginTop: theme.spacing(7),
    },
    headerLogoLink: {},
    headerLogoImg: {
      height: "35px",
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("md")]: { marginRight: "unset" },
    },
    headerLink: {
      margin: theme.spacing(2),
      fontSize: "1rem",
      fontWeight: "700",
      textTransform: "uppercase",
      "&:hover": {
        color: theme.palette.primary.main,
        filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
          theme.palette.primary.main
        })`,
        textDecoration: "none",
      },
    },
    headerChildLink: {
      margin: theme.spacing(2),
      fontSize: "0.8rem",
      fontWeight: "600",
      textTransform: "uppercase",
      "&:hover": {
        color: theme.palette.primary.main,
        filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
          theme.palette.primary.main
        })`,
        textDecoration: "none",
      },
    },
    headerIconLink: {
      "&:hover": {
        color: theme.palette.primary.main,
        filter: `drop-shadow(0 0 ${theme.spacing(2)} ${
          theme.palette.primary.main
        })`,
      },
    },
    menuButton: {
      margin: "unset",
    },
    menuIconWrapper: {
      width: "40px",
    },
    sideDrawer: { flexShrink: 0 },
    sideDrawerPaper: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    heading: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      width: "100%",
      textAlign: "center",
      display: "flex",
      flexFirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    footer: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      marginTop: theme.spacing(4),
      padding: theme.spacing(4),
      maxWidth: "unset",
      width: "100vw",
    },
    footerSocialsWrapper: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      paddingBottom: theme.spacing(4),
    },
    footerLinksWrapper: {
      justifyContent: "center",
      display: "flex",
      flexWrap: "wrap",
    },
    footerLinksItem: {
      textAlign: "center",
      display: "flex",
      flexFirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(
        1
      )}px !important`,
      "&:hover": {
        textDecoration: "none",
      },
    },

    // utils
    divider: { marginTop: theme.spacing(1), marginBottom: theme.spacing(1) },
    list: { width: 250 },
    icon: {
      marginRight: theme.spacing(2),
    },
    markdown: {
      ...theme.typography.body2,
      padding: theme.spacing(3, 0),
    },
    listItem: {
      marginTop: theme.spacing(1),
    },
    fullWidthImage: {
      width: "100%",
      maxWidth: "100%",
    },
    tabHeader: {
      backgroundColor: "unset",
      boxShadow: "unset",
      color: "unset",
    },

    // charts
    pieChartLegendWrapper: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-end",
      marginRight: theme.spacing(1),
    },
    legendItemContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },

    // buttons
    socialButton: {
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: "unset", //alpha(theme.palette.primary.main, 0.05),
      },
    },
    profileSocialButton: {
      paddingLeft: "0px",
      //padding: `${theme.spacing(0)} ${theme.spacing(1)} ${theme.spacing(1)}`,
      "&:hover": {
        color: theme.palette.primary.main,
        backgroundColor: "unset",
      },
    },

    // containers
    tabContainer: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      flexGrow: 1,
    },
    blogContainer: {
      paddingTop: theme.spacing(4),
    },
    loginContainer: {
      paddingBottom: theme.spacing(2),
    },

    // cards
    cardGrid: {
      // paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
    },
    card: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 0px 40px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        transform: "scale(1.05)",
        boxShadow: `0px 0px 70px -12.125px ${alpha(
          theme.palette.primary.main,
          0.4
        )}`,
      },
    },
    cardMedia: {
      paddingTop: "56.25%", // 16:9
    },
    cardContent: {
      flexGrow: 1,
    },

    // Tables
    tableRow: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
    tableCellTH: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
      fontSize: 14,
      paddingRight: "30px",
    },
    tableCellTD: {
      fontSize: 14,
      paddingRight: "30px",
    },

    // Avatars
    avatarLarge: {
      width: "250px",
      height: "250px",
      [theme.breakpoints.down("lg")]: { width: "225px", height: "225px" },
      [theme.breakpoints.down("md")]: { width: "200px", height: "200px" },
    },
    avatarWrapper: {
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
        display: "flex",
        flexFirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    // Hero
    heroContent: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.common.white,
      height: "70vh",
      width: "100%",
      boxShadow: "inset 0 0 0 1000px rgb(0 0 0 / 20%)",
      objectFit: "contain",
    },
    heroWrapper: {
      backgroundColor: `rgba(0, 0, 0, 0.6)`,
      padding: theme.spacing(8, 0, 6),
      height: "100%",
      width: "100%",
      textAlign: "center",
      display: "flex",
      flexFirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    heroLogo: {
      maxWidth: "70%",
      margin: theme.spacing(3, 3, 3),
    },
    heroButtons: {
      marginTop: theme.spacing(4),
    },

    // Profile
    profileMenu: {
      minWidth: 265,
    },
    profileMenuUser: {
      display: "flex",
      flexDirection: "column",
      padding: theme.spacing(2),
    },
    profileMenuItem: {
      color: theme.palette.text.hint,
    },
    profileMenuIcon: {
      marginRight: theme.spacing(2),
      color: theme.palette.text.hint,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    profileMenuLink: {
      fontSize: 16,
    },
    profileContentContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: { display: "block" },
    },
    profileContentWrapper: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        flexFirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },
    profileSocialsWrapper: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        display: "flex",
        flexFirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
    },

    // modal
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    modalPaper: {
      //backgroundColor: theme.palette.background.paper,
      //border: "2px solid #000",
      boxShadow: theme.shadows[5],
      //padding: theme.spacing(2, 4, 3),
    },
    modalCardWrapper: { position: "relative" },
    modalCloseBtn: {
      float: "right",
      position: "absolute",
      right: "20px",
      top: "20px",
      zIndex: "1000",
      padding: "5px",
    },

    filterButtonSelected: {
      backgroundColor: `${alpha(theme.palette.primary.main, 0.5)} !important`,
    },
    listItemSmall: { paddingTop: "0px" },
    listItemAvatarSmall: { minWidth: "30px" },
    avatarSmall: { width: "20px", height: "20px" },
    cardImg: {
      width: "100%",
      maxWidth: "100%",
    },
    cardLink: {
      "&:hover": {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
    },
    formControlWrapper: {},
  }),
  { index: 999 }
);
